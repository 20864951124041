export default function sessionSelector(state: StateTree): CurrentSession | PreviousSession {
	const groupData = state.groupData || {}
	const viewingPreviousSession = Boolean(state.viewingPreviousSession)
	const previousSession = (viewingPreviousSession && state.previousSession) || ({} as PreviousSession)
	const currentSession = groupData.currentSession || state.currentSession || ({} as CurrentSession)

	const session = viewingPreviousSession ? previousSession : currentSession

	return session
}
