import React, { FunctionComponent } from 'react'

const LoadingScreen: FunctionComponent = () => (
	<div className="login">
		<div className="login__inner">
			<svg className="login__white-bit" preserveAspectRatio="none" viewBox="0 0 1000 1000">
				<g>
					<path fill="#FFFFFF" stroke="none" d="M 500 0 L 0 1000 1000 1000 1000 0 500 0 Z" />
				</g>
			</svg>
			<div className="login__left">
				<div className="login__main-logo" />
			</div>
			<div className="login__right">
				<div className="login__overlay login__fadeIn-enter-done">
					<div className="preloader-dual-ring">
						<div className="preloader-dual-ring__ring1" />
						<div className="preloader-dual-ring__ring2" />
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default LoadingScreen
