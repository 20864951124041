/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useInterval, useMount, useUnmount, useWindowSize } from 'react-use'
import { CaptureContext } from '@sentry/types'
import * as Sentry from '@sentry/browser'
import { largestRect } from 'rect-scaler'
import { connect } from 'react-redux'

import Actions from '../../actions'
import config from '../../../config'

import { ParticipantDetails, VideoClientEvents } from './VideoTypes'
import AgoraClient from '../../util/AgoraClient'

import loggedInAsParticipantSelector from '../../selectors/loggedInAsParticipant'
import loggedInAsObserverSelector from '../../selectors/loggedInAsObserver'
import loggedInAsGroupSelector from '../../selectors/loggedInAsGroup'
import facilitatorIdSelector from '../../selectors/facilitatorId'

import calculateVideoCallParticipantsToDisplay from '../../util/calculateVideoCallParticipantsToDisplay'
import calculateDominantSpeaker from '../../util/calculateDominantSpeaker'

import VideoCallParticipantAgora from './VideoCallParticipantAgora'
import FullScreenSpeaker from './FullScreenSpeaker'
import VideoCallButtons from './VideoCallButtonsTwilio'
import Button from '../../elements/Button'
import Chat from './Chat'

type VideoCallAgoraProps = {
	displayName: string
	callId: string
	testMode?: boolean

	selectedAudioOut?: string
	selectedCamera?: string
	selectedMic?: string
	selectAudioOut?: (deviceId: string) => void
	selectCamera?: typeof Actions.videoconf.selectCamera
	selectMic?: (deviceId: string) => void

	displayModalPopup?: typeof Actions.misc.displayModalPopup
	notifyMainCall?: (callId: string, participantId: string) => void
	endMainCall?: () => void

	loggedInAsObserver?: boolean
	loggedInAsParticipant?: boolean
	loggedInAsFacilitator?: boolean

	selectedInterpreter?: string
	selectInterpreter?: typeof Actions.videoconf.selectInterpreter
	isInterpreter?: boolean
	interpreterChannel?: string
	interpreterOnMainChannel?: boolean
	interpreterDetails?: InterpreterDetails

	interpreterChangeChannel?: (toMainChannel: boolean) => void

	clientId?: string
	facilitatorId?: string
	participantId?: string
	interpreters?: VideoConfDetails['interpreters']
	participants?: Participant[]

	videoCallParticipantsStartMuted: boolean
	videoCallObserversStartMuted: boolean
	videoCallFacilitatorsStartMuted: boolean
}

const globalVars = {
	interpreters: [],
}

function VideoCallAgora(props: VideoCallAgoraProps): ReactElement<VideoCallAgoraProps> {
	const { displayName, displayModalPopup, clientId, testMode: isVideoTest } = props
	const { interpreters, selectedInterpreter, callId } = props
	let { facilitatorId } = props
	if (!facilitatorId) facilitatorId = 'james@jessian.io'
	const { loggedInAsObserver, loggedInAsFacilitator, loggedInAsParticipant } = props
	// const { selectInterpreter, interpreterChangeChannel, interpreterDetails } = props
	const { interpreterOnMainChannel, isInterpreter, participants } = props
	const { interpreterChannel } = props

	// const { selectCamera, selectMic, selectAudioOut, selectedAudioOut } = props
	const { selectCamera, selectMic, selectedAudioOut, selectedCamera = null, selectedMic = null } = props
	const {
		videoCallParticipantsStartMuted = true,
		videoCallObserversStartMuted = false,
		videoCallFacilitatorsStartMuted = false,
	} = props

	const { MUTED_MESSAGE } = config.strings

	type Speakers = Record<string, Date>

	// eslint-disable-next-line react/destructuring-assignment
	const v360ParticipantId = props.participantId

	globalVars.interpreters = interpreters

	const { notifyMainCall, endMainCall } = props

	const client = useRef<>(null)
	const strip = useRef<HTMLDivElement>(null)
	const eventHandlerRefs = useRef<Array<number>>([])
	const [remoteParticipants, setRemoteParticipants] = useState<ParticipantDetails[]>([])
	const [localError, setLocalError] = useState('')
	// const [stripPage, setStripPage] = useState(0)

	const [selectedViewMode, setSelectedViewMode] = useState('TILE')
	const [selectedSpeakerId, setSelectedSpeakerId] = useState(null)
	const [muteMainAudio, setMuteMainAudio] = useState(false)
	const [systemError, setSystemError] = useState('')
	const [chatOpen, setChatOpen] = useState(false)
	const [localParticipant, setLocalParticipant] = useState({} as ParticipantDetails)
	const [speakers, setSpeakers] = useState<Speakers>({})
	const [participantScreensharing, setParticipantScreensharing] = useState<ParticipantDetails>(null)

	const buttonArea = useRef<HTMLDivElement>()
	const tileview = useRef<HTMLDivElement>()
	const mainArea = useRef<HTMLDivElement>()
	const joined = useRef<boolean>(false)

	useMount(async () => {
		document.body.classList.add('in-call')

		// Initialise Jitsi client
		const agoraClient = new AgoraClient({ conferenceId: callId, displayName })
		client.current = agoraClient
		window.VideoClient = agoraClient

		// Set some custom properties to be assigned to participants in the room (such as the observer ID for observers)
		if (loggedInAsObserver) agoraClient.setProperty('clientId', clientId)
		if (!loggedInAsObserver) agoraClient.setProperty('clientId', '')
		if (loggedInAsParticipant) agoraClient.setProperty('participantId', v360ParticipantId)
		if (!loggedInAsParticipant) agoraClient.setProperty('participantId', '')
		if (loggedInAsFacilitator) client.current.setProperty('isFacilitator', 'true')
		// Close any active modal popup
		if (loggedInAsParticipant) displayModalPopup('')

		const { REMOTE_PARTICIPANTS_CHANGED, LOCAL_PARTICIPANT_CHANGED, DOMINANT_SPEAKER_CHANGED } = VideoClientEvents
		const { ROOM_JOINED } = VideoClientEvents

		// Helper function for adding event listeners to the jitsi client and keeping an array of the
		// references we get back, which allows us to easily detach those event handlers later.
		const addEventListener = (type: VideoClientEvents, callback: (...args: Array<unknown>) => void) => {
			eventHandlers.push(agoraClient.on(type, callback))
		}

		const eventHandlers = eventHandlerRefs.current
		// Add event listeners
		addEventListener(LOCAL_PARTICIPANT_CHANGED, () => {
			const videoClient = client.current
			setLocalParticipant({ ...videoClient.localParticipant })

			const { videoTrack, audioTrack } = videoClient.localParticipant

			const audioDeviceId = audioTrack?.deviceId
			const selectedMicDifferent = !selectedMic || selectedMic !== audioDeviceId
			if (audioTrack && selectedMicDifferent) selectMic(audioDeviceId)

			const videoDeviceId = videoTrack?.deviceId
			const selectedCameraDifferent = !selectedCamera || selectedCamera !== videoDeviceId
			if (videoTrack && selectedCameraDifferent && videoTrack.videoType !== 'desktop') selectCamera(videoDeviceId)

			// if (twilioClient.videoUnavailable) {
			// 	setLocalError('Video device unavailable')
			// } else {
			// 	setLocalError('')
			// }
		})

		addEventListener(REMOTE_PARTICIPANTS_CHANGED, () => {
			setRemoteParticipants([...agoraClient.remoteParticipants])
		})

		// Notify all users that new call has started
		addEventListener(ROOM_JOINED, () => {
			if (joined.current) return
			joined.current = true
			setLocalParticipant({ ...client.current.localParticipant })
			// client.current.setMaxReceivedVideoHeight(480)
			if (loggedInAsFacilitator) notifyMainCall(callId, agoraClient.participantId)
		})

		addEventListener(DOMINANT_SPEAKER_CHANGED, ({ participantId }) => {
			// Update the 'lastSpoke' time for this participant in our list of speakers.
			// It will help us determine if we want to show this participant full screen as the
			// dominant speaker or not. We might choose not to, for instance if they are an interpreter.
			setSpeakers(prevSpeakers => ({ ...prevSpeakers, [participantId]: new Date() }))
			agoraClient.setPrimaryRemoteParticipant(participantId)
		})

		agoraClient
			.connect({
				cameraDeviceId: selectedCamera,
				audioInputDeviceId: selectedMic,
				startAudioMuted:
					isVideoTest ||
					(loggedInAsFacilitator && videoCallFacilitatorsStartMuted) ||
					(loggedInAsParticipant && videoCallParticipantsStartMuted) ||
					(loggedInAsObserver && videoCallObserversStartMuted),
			})
			.catch(err => {
				setSystemError(err.message)
				Sentry.captureException(err)
				agoraClient.unload()
			})
	})

	useUnmount(async () => {
		console.log('useUnmounting video call')
		document.body.classList.remove('in-call')
		if (!client.current) return

		// Detach/remove all event listeners from the active video client
		eventHandlerRefs.current.forEach(ref => client.current.off(ref))

		// Also "unload" the video client
		await client.current.unload()
	})

	// Switch camera/audio devices used by video client if they have changed in global state (i.e. in the user's settings)
	useEffect(() => {
		if (client.current) client.current.changeVideoInput(selectedCamera)
	}, [selectedCamera])

	useEffect(() => {
		if (client.current) client.current.changeAudioInput(selectedMic)
	}, [selectedMic])

	// useEffect(() => {
	// 	setMuteMainAudio(false)
	// 	if (loggedInAsParticipant && client.current) {
	// 		client.current.setProperty('selectedInterpreter', selectedInterpreter || '')
	// 	}
	// }, [selectedInterpreter])

	useWindowSize() // use this to trigger re-render when window size changes

	// -----------------------------------------------------------------------------------------------

	// -----------------------------------------------------------------------------------------------
	// When a participant is selected to be viewed full screen, we redisplay the current speaker after 5 seconds
	type Timeout = ReturnType<typeof setTimeout>
	const selectedSpeakerTimer = useRef<Timeout>()
	const onClickParticipant = (id: string) => {
		clearTimeout(selectedSpeakerTimer.current)
		selectedSpeakerTimer.current = setTimeout(() => setSelectedSpeakerId(null), 5000)
		setSelectedViewMode('SPEAKER')
		const context: CaptureContext = { user: { username: displayName } }
		Sentry.captureMessage('Switched to speaker view - click participant', context)
		setSelectedSpeakerId(id)
	}

	useInterval(() => {
		const participantWhoIsScreensharing =
			(localParticipant?.videoTrack?.videoType === 'desktop' && localParticipant) ||
			remoteParticipants.find(p => p.videoTrack?.videoType === 'desktop')

		if (participantWhoIsScreensharing !== participantScreensharing) {
			setParticipantScreensharing(participantWhoIsScreensharing)
		}
	}, 2000)

	// -----------------------------------------------------------------------------------------------
	// Determine the fullscreen speaker
	// Check if selected speaker is local participant or a remote participant (or nothing)

	let dominantSpeaker = localParticipant
	console.log('DominantSpeaker:', dominantSpeaker.displayName)

	if (selectedViewMode === 'SPEAKER') {
		dominantSpeaker = calculateDominantSpeaker({
			speakers,
			clientId,
			remoteParticipants,
			localParticipant,
			interpreters,
			selectedInterpreter,
			loggedInAsFacilitator,
			loggedInAsObserver,
			loggedInAsParticipant,
		})
	}

	const selectedSpeaker =
		(localParticipant?.participantId === selectedSpeakerId && localParticipant) ||
		remoteParticipants.find(p => p.participantId === selectedSpeakerId)

	const participantWhoIsScreensharing =
		(localParticipant?.videoTrack?.videoType === 'desktop' && localParticipant) ||
		remoteParticipants.find(p => p.videoTrack?.videoType === 'desktop')

	const fullScreenSpeaker = selectedSpeaker || participantWhoIsScreensharing || dominantSpeaker || localParticipant

	// -----------------------------------------------------------------------------------------------
	// Force 'SPEAKER' view mode if a participant is screensharing
	const viewMode = participantWhoIsScreensharing ? 'SPEAKER' : selectedViewMode
	// -----------------------------------------------------------------------------------------------

	/* If we have a fullscreen speaker, set them as the primary remote participant (i.e. request high res video stream) */
	useEffect(() => {
		if (fullScreenSpeaker?.participantId && viewMode === 'SPEAKER') {
			client.current.setPrimaryRemoteParticipant(fullScreenSpeaker.participantId)
		}
	}, [fullScreenSpeaker, fullScreenSpeaker?.videoTrack?.videoType])

	/* If switched to tile view, apply consistent height for all receiver video streams */
	// useEffect(() => {
	// 	if (viewMode === 'TILE') {
	// 		client.current.setMaxReceivedVideoHeight(Math.min(tileVideoSize.height, 480))
	// 	}
	// }, [viewMode, tileVideoSize])

	// -----------------------------------------------------------------------------------------------
	// Find selected interpreter details
	// const selectedInterpreterParticipantDetails =
	// 	selectedInterpreter && remoteParticipants.find(p => p?.properties?.clientId === selectedInterpreter)

	let tileVideoSize = { width: null, height: null, rows: null }

	if (viewMode === 'TILE' && buttonArea.current && mainArea.current) {
		// Calculate the height of the tile area even if it isn't rendered yet
		const containerHeight = mainArea.current.clientHeight - (buttonArea.current.clientHeight + 25)
		const containerWidth = mainArea.current.clientWidth - 14 // 7px margin
		const numRects = remoteParticipants.length + 1
		try {
			const { width, height, rows } = largestRect(containerWidth, containerHeight, numRects, 4, 3) // Aspect-ratio
			// Set window width/height to state
			tileVideoSize = { width, height, rows }
		} catch (err) {
			// Set window width/height to state
			tileVideoSize = { width: 400, height: 300, rows: Math.ceil(numRects / 3) }
		}
	}

	// -----------------------------------------------------------------------------------------------
	// Render all participants for tileview or filmstrip
	let participantsToDisplay = calculateVideoCallParticipantsToDisplay({
		selectedInterpreterId: selectedInterpreter,
		interpreterChannel: interpreterOnMainChannel ? '' : interpreterChannel,
		loggedInAsInterpreter: isInterpreter,
		loggedInAsParticipant,
		loggedInAsFacilitator,
		loggedInAsObserver,
		remoteParticipants,
		localParticipant,
		muteMainAudio,
		interpreters,
	})

	// Just for Agora - Exclude the fullscreen speaker from the filmstrip
	if (viewMode === 'SPEAKER' && fullScreenSpeaker) {
		participantsToDisplay = participantsToDisplay.filter(
			p => p.participant.participantId !== fullScreenSpeaker.participantId
		)
	}

	// If we are in TILE view, then we include the local participant in this list
	if (viewMode === 'TILE') {
		let subheading = null
		const interpreterDetails = loggedInAsObserver && interpreters.find(i => i.clientId === clientId)
		if (interpreterDetails) {
			const { channel } = interpreterDetails
			subheading = `Interpreter - ${channel}`
		} else if (loggedInAsObserver) {
			subheading = `Observer`
		} else if (loggedInAsFacilitator && localParticipant.displayName !== 'Facilitator') {
			subheading = 'Facilitator'
		}

		participantsToDisplay.push({
			participant: localParticipant,
			subheading,
			mute: true,
		})
	}

	// Create an array of the participant elements/videos
	const participantElems = participantsToDisplay.map(details => {
		const { participant, infoMessage, mute, subheading, volume } = details
		const { participantId } = participant
		const { width, height } = tileVideoSize
		// Get the V360G ID for this participant
		const pParticipantId = participant?.properties?.participantId
		const error = participant.isLocal ? localError : infoMessage
		const participantDetails = participants.find(
			p => p.id === (participant.isLocal ? v360ParticipantId : pParticipantId)
		)

		return (
			<VideoCallParticipantAgora
				key={participantId || 'local'}
				facilitatorId={facilitatorId}
				onClick={() => onClickParticipant(participantId)}
				uploadToImageCache={participant.isLocal}
				participant={participant}
				participantDetails={participantDetails}
				volume={mute ? 0 : volume}
				selectedAudioOut={selectedAudioOut}
				subheading={subheading}
				height={height}
				error={error}
				width={width}
				isDominantSpeaker={participantId === String(client.current?.dominantSpeaker)}
			/>
		)
	})
	// -----------------------------------------------------------------------------------------------

	// Controls displayed to interpreters for them to choose which channel they wish to broadcast to
	// const interpreterControls = isInterpreter && (
	// 	<InterpreterControls
	// 		onMainChannel={interpreterOnMainChannel}
	// 		channelName={interpreterDetails.channel}
	// 		onChangeChannel={interpreterChangeChannel}
	// 	/>
	// )

	// Interpreter element to be displayed to participants that have selected interpreter
	// let interpreterElem: JSX.Element = null
	// if (selectedInterpreterParticipantDetails) {
	// 	const interpreterDetails = interpreters.find(i => i.clientId === selectedInterpreter)
	// 	if (interpreterDetails) {
	// 		const { channel, onMainChannel } = interpreterDetails
	// 		const subheading = `Interpreter - ${channel}`
	// 		let infoMessage = ''
	// 		let interpreterVolume = loggedInAsFacilitator ? 0.1 : 1.0
	// 		if (onMainChannel) {
	// 			infoMessage = `Broadcasting on main channel`
	// 			interpreterVolume = 0
	// 		}

	// 		interpreterElem = (
	// 			<div className="video-call__interpreter">
	// 				<VideoCallParticipantAgora
	// 					selectedAudioOut={selectedAudioOut}
	// 					participant={selectedInterpreterParticipantDetails}
	// 					facilitatorId={facilitatorId}
	// 					volume={interpreterVolume}
	// 					subheading={subheading}
	// 					error={infoMessage}
	// 				/>

	// 				{!loggedInAsFacilitator ? (
	// 					<div className="video-call__interpreter-mute">
	// 						<label>Mute main audio</label>
	// 						<Switch value={muteMainAudio} onChange={checked => setMuteMainAudio(checked)} />
	// 					</div>
	// 				) : null}
	// 				<Button onClick={() => selectInterpreter(null)} text={CLOSE} />
	// 			</div>
	// 		)
	// 	}
	// }

	// Check if we have any interpreters available in the current video call
	// If so, we display a button to allow participants to select an interpreter
	// const interpretersInCall = interpreters.filter(i =>
	// 	remoteParticipants.find(p => p.properties && p.properties.clientId === i.clientId)
	// )
	// const interpreterAvailable = Boolean(
	/* 	!selectedInterpreterParticipantDetails && interpretersInCall.length && !isInterpreter*/
	// )
	// const interpreterAvailableButton = interpreterAvailable && (
	// 	<Button onClick={onClickSelectInterpreter} text="Interpreter available" primary />
	// )

	// const onClickSelectInterpreter = () => displayModalPopup('modal-select-interpreter')
	const onClickSettings = () => displayModalPopup('modal-configure-video')
	const onClickChat = () => setChatOpen(chatOpen => !chatOpen)
	const onClickChangeLayout = () => setSelectedViewMode(viewMode === 'TILE' ? 'SPEAKER' : 'TILE')
	// const onClickStatistics = () => client.current.showStatistics()

	function onClickEndCall() {
		client.current.unload()
		endMainCall()
	}

	const footer = (
		<div ref={buttonArea} className="video-call__buttons">
			{/* <div className="left">{interpreterControls || interpreterAvailableButton}</div> */}
			<div className="left" />
			<div className="middle">
				<VideoCallButtons
					client={client.current}
					onClickChangeLayout={onClickChangeLayout}
					onClickEndCall={onClickEndCall}
					onClickSettings={onClickSettings}
					onClickChat={onClickChat}
					showScreenShare={loggedInAsFacilitator || loggedInAsObserver || isVideoTest}
					showEndCall={loggedInAsFacilitator}
					showStatistics={false}
					showSettings={true}
					showChat={true}
					viewMode={selectedViewMode}
					allowChangeLayout={!participantWhoIsScreensharing}
				/>
			</div>
			<div className="right" />
		</div>
	)

	let mainContent: JSX.Element
	if (viewMode === 'TILE') {
		const { rows } = tileVideoSize
		const rowElems = []
		const vids = [...participantElems]
		const vidsPerRow = Math.ceil(participantElems.length / rows)
		for (let i = 0; i < rows; i++) {
			const participantsForRow = vids.splice(0, vidsPerRow)
			rowElems.push(
				<div key={i} style={{ display: 'flex' }}>
					{participantsForRow}
				</div>
			)
		}
		mainContent = (
			<div ref={tileview} className="video-call__tileview">
				<div className="video-call__tileview-inner">{rowElems}</div>
			</div>
		)
	} else if (fullScreenSpeaker) {
		mainContent = <FullScreenSpeaker speaker={fullScreenSpeaker} />
	}

	const onClickLocalParticipant = () => onClickParticipant(localParticipant.participantId)

	// Create filmstrip if in 'speaker' mode
	let filmstrip = null

	if (viewMode === 'SPEAKER') {
		const participantDetails = participants.find(p => p.id === v360ParticipantId)
		const showLocal = fullScreenSpeaker.participantId !== localParticipant.participantId
		filmstrip = (
			<div className="video-call__filmstrip" ref={strip}>
				<div className="video-call__participants">
					{participantElems}
					{/* Local Participant */}
					{showLocal && (
						<VideoCallParticipantAgora
							participant={localParticipant}
							facilitatorId={facilitatorId}
							onClick={onClickLocalParticipant}
							participantDetails={participantDetails}
							selectedAudioOut={selectedAudioOut}
							uploadToImageCache
							error={localError}
						/>
					)}
				</div>
			</div>
		)
	}

	const muted = client.current?.localParticipant?.isMuted
	const className = `video-call video-call--agora video-call--${viewMode.toLowerCase()}`

	if (systemError) {
		return (
			<div className={className}>
				<div className="video-call__container">
					<div className="video-call__error">
						<h2>An error occurred</h2>
						<p>
							It appears that we had a problem with this video call. <br /> Please click the button below to reload the
							page and try again.
						</p>
						<p>{systemError ? `Technical details: ${systemError}` : ''}</p>
						<Button onClick={() => window.location.reload()}>Reload</Button>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className={className} role="main">
			<div className="video-call__container">
				{chatOpen && <Chat client={client.current} />}
				<div ref={mainArea} className="video-call__main">
					{/* {interpreterElem} */}
					{mainContent}
					{footer}
				</div>
				{filmstrip}
			</div>
			{muted && <div className="video-call__mute-message">{MUTED_MESSAGE}</div>}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const settings = state.settings || ({} as Settings)

	const props: Partial<VideoCallAgoraProps> = {
		selectedAudioOut: state.videoconf.selectedAudioOut,
		selectedCamera: state.videoconf.selectedCamera,
		selectedMic: state.videoconf.selectedMic,
		interpreters: state.videoconf.interpreters || [],
		clientId: state.clientId,
		selectedInterpreter: state.videoconf.selectedInterpreter,
		participants: state.participants || [],
		loggedInAsParticipant: loggedInAsParticipantSelector(state) || loggedInAsGroupSelector(state),
		loggedInAsObserver: loggedInAsObserverSelector(state),
		loggedInAsFacilitator: state.tutor && state.tutor.loggedIn,
		facilitatorId: facilitatorIdSelector(state),
		participantId: state?.group?.participantId,
		videoCallParticipantsStartMuted: settings.videoCallParticipantsStartMuted,
		videoCallObserversStartMuted: settings.videoCallObserversStartMuted,
		videoCallFacilitatorsStartMuted: settings.videoCallFacilitatorsStartMuted,
	}

	const interpreter = props.loggedInAsObserver && props.interpreters.find(i => i.clientId === props.clientId)
	if (interpreter) {
		props.isInterpreter = true
		props.interpreterChannel = interpreter.channel
		props.interpreterOnMainChannel = interpreter.onMainChannel
		props.interpreterDetails = interpreter
	}

	return props
}
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	notifyMainCall: Actions.videoconf.notifyMainCall,
	endMainCall: Actions.videoconf.endMainCall,
	interpreterChangeChannel: Actions.videoconf.interpreterChangeChannel,
	selectInterpreter: Actions.videoconf.selectInterpreter,
	selectAudioOut: Actions.videoconf.selectAudioOut,
	selectCamera: Actions.videoconf.selectCamera,
	selectMic: Actions.videoconf.selectMic,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Pick<VideoCallAgoraProps, 'callId' | 'displayName' | 'testMode'>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(VideoCallAgora)

if (module?.hot?.data) {
	window.location.reload()
}
