import { connect } from 'react-redux'
import React, { useEffect, useState, FunctionComponent } from 'react'

import releasedItemsSelector from '../../selectors/releasedItems'
import sessionSelector from '../../selectors/session'
import getTime from '../../util/getTime'

import config from '../../../config'

import IconButton from '../../elements/IconButton'

const { INCOMING_MESSAGE, INCOMING_MESSAGE_TEXT, INCOMING_MEDIA, INCOMING_MEDIA_TEXT } = config.strings
const { INCOMING_UPDATE, INCOMING_UPDATE_TEXT } = config.strings

const Alert = ({ title, onClose, onClick, children }) => (
	<div className="new-media-alert__alert" onClick={onClick}>
		<div className="new-media-alert__title">{title}</div>
		<div>{children}</div>
		<IconButton iconName="close" onClick={onClose} />
	</div>
)

type NewMediaAlertProps = {
	releasedItems: ReleaseItem[]
	conversation: Message[]
	onClickAlert?: () => void
	active?: boolean
}

const NewMediaAlert: FunctionComponent<NewMediaAlertProps> = props => {
	const { releasedItems, conversation, onClickAlert, active = false } = props

	const [lastItemAlert, setLastItemAlert] = useState<number>(null)
	const [startTime] = useState<number>(getTime())

	const [newMessageReceived, setNewMessageReceived] = useState(false)
	const [newUpdateReceived, setNewUpdateReceived] = useState(false)
	const [newMediaReceived, setNewMediaReceived] = useState(false)

	useEffect(() => {
		if (!active) {
			setNewMessageReceived(false)
			setNewUpdateReceived(false)
			setNewMediaReceived(false)
			setLastItemAlert(getTime())
			return
		}
		const newItem = releasedItems.find(i => i.time > startTime && i.time > lastItemAlert)
		const newMessage = conversation.find(
			i => parseInt(i.timestamp) > startTime && parseInt(i.timestamp) > lastItemAlert
		)

		if (newMessage) {
			setNewMessageReceived(true)
			setLastItemAlert(parseInt(newMessage.timestamp))
		}

		if (newItem) {
			if (newItem.type === 'media') {
				setNewMediaReceived(true)
			} else {
				setNewUpdateReceived(true)
			}
			setLastItemAlert(newItem.time)
		}
	}, [releasedItems, conversation, active])

	if (!newMessageReceived && !newMediaReceived && !newUpdateReceived) return null

	return (
		<div className="new-media-alert">
			{newMediaReceived && (
				<Alert title={INCOMING_MEDIA} onClose={() => setNewMediaReceived(false)} onClick={onClickAlert}>
					{INCOMING_MEDIA_TEXT}
				</Alert>
			)}
			{newMessageReceived && (
				<Alert title={INCOMING_MESSAGE} onClose={() => setNewMessageReceived(false)} onClick={onClickAlert}>
					{INCOMING_MESSAGE_TEXT}
				</Alert>
			)}
			{newUpdateReceived && (
				<Alert title={INCOMING_UPDATE} onClose={() => setNewUpdateReceived(false)} onClick={onClickAlert}>
					{INCOMING_UPDATE_TEXT}
				</Alert>
			)}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const session = sessionSelector(state)
	const allGroupMessages: Message[] = session.allGroupMessages || []
	const conversation: Message[] = (state.groupData || {}).conversation || []
	const groupId = (state.group || {}).id
	const releases: Release[] = state.releases || []
	const releasedItems = releasedItemsSelector(session, releases, groupId)

	return { conversation: [...conversation, ...allGroupMessages], releasedItems }
}
const actions = {}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<NewMediaAlertProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(NewMediaAlert)
