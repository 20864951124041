/* Creating my own script because the mime-types package on npm is pretty big */

const types = {
	png: 'image/png',
	jpg: 'image/jpeg',
	jpeg: 'image/jpeg',
	mp4: 'video/mp4',
	m4a: 'audio/mp4',
	mp3: 'audio/mpeg',
	pdf: 'application/pdf',
	gif: 'image/gif',
	tif: 'image/tiff',
	tiff: 'image/tiff',
	bmp: 'image/bmp',
	wav: 'audio/wave',
	avi: 'video/x-msvideo',
	mov: 'video/quicktime',
}

function lookup(filename: string): string {
	const ext = filename.toLowerCase().split('.').pop()
	return types[ext] || 'application/octet-stream'
}

export default { lookup }
