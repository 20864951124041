import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import React from 'react'

import App from './web/pages/App'

import { store } from './web/store/store'

if (!('ontouchstart' in document)) {
	document.body.classList.add('no-touch')
}

if (process.env.FORCE_THEME) {
	document.body.classList.add(process.env.FORCE_THEME)
}

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
)
