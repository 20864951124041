const colourCodes = {
	charcoal: 'var(--team-colour-charcoal)',
	magenta: 'var(--team-colour-magenta)',
	crimson: 'var(--team-colour-crimson)',
	violet: 'var(--team-colour-violet)',
	maroon: 'var(--team-colour-maroon)',
	orange: 'var(--team-colour-orange)',
	cobalt: 'var(--team-colour-cobalt)',
	indigo: 'var(--team-colour-indigo)',
	purple: 'var(--team-colour-purple)',
	silver: 'var(--team-colour-silver)',
	coral: 'var(--team-colour-coral)',
	amber: 'var(--team-colour-amber)',
	olive: 'var(--team-colour-olive)',
	green: 'var(--team-colour-green)',
	pink: 'var(--team-colour-pink)',
	ruby: 'var(--team-colour-ruby)',
	gold: 'var(--team-colour-gold)',
	teal: 'var(--team-colour-teal)',
	cyan: 'var(--team-colour-cyan)',
	blue: 'var(--team-colour-blue)',
	navy: 'var(--team-colour-navy)',
	grey: 'var(--team-colour-grey)',
}

function convertColour(colour: string): string {
	return colourCodes[colour] || colour
}

export default convertColour
