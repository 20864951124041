import { Dispatch } from 'redux'
// import { v4 as uuid } from 'uuid'

import ConfirmationHelper from '../util/ConfirmationHelper'
import SessionHelpers from '../util/SessionHelpers'
import downloadBlob from '../util/downloadBlob'
import OnlineComms from '../core/OnlineComms'
import types from './types'
import getTime from '../util/getTime'
import action from '../util/action'
import config from '../../config'
import groupActions from './groups'
import miscActions from './misc'
import currentCaseSelector from '../selectors/currentCase'

const updateBuffer = {}
const updateRowBuffer = {}
let holdoffReleases = false

const _getSessionDetails = (_case: OpenCaseDetails, sessionId) => {
	// Get existing session details
	const sessions = _case.sessions || []
	const session = { ...sessions.find(s => s.id === sessionId) }
	session.schedule = (session.schedule || []).map(o => ({ ...o }))
	return session
}

const actions = {
	/* */
	addNewSession: (caseId: Case['id']) => (dispatch: Dispatch, getState: GetState): void => {
		const now = getTime()
		const id = now.toString()
		const state = getState()
		const index = (state?.openCase?.sessions?.length || 0) + 1 // Session indexes are 1-based

		const newSession: Session = {
			id,
			index,
			caseId,
			key: id,
			duration: 0,
			schedule: [],
			timestamp: now,
			initialUpdate: '',
		}

		OnlineComms.addNewSession(caseId, newSession).then(_ => {
			dispatch(action(types.SESSIONS_ADD_NEW_SUCCESS, newSession))
			dispatch(action(types.SESSION_SELECT, newSession.id))
		})
	},

	updateSession: (sessionDetails: Partial<Session>) => (dispatch: Dispatch, getState: GetState): void => {
		// Optimistically update the server details in state
		dispatch(action(types.SESSIONS_UPDATE_SUCCESS, sessionDetails))

		const state = getState()
		const caseId = state.openCase.key
		const sessionId = sessionDetails.key || sessionDetails.id
		const sessionWithoutSchedule = { ...sessionDetails, schedule: null }

		// Update session on server. Buffer this so we don't flood the server
		const update = () => OnlineComms.updateSession(caseId, sessionId, sessionWithoutSchedule)
		clearTimeout(updateBuffer[sessionId])
		updateBuffer[sessionId] = setTimeout(update, 1000)
	},

	updateReleaseScheduleRow: (row: ScheduleRow) => (dispatch: Dispatch, getState: GetState): void => {
		const state = getState()
		const sessionId = row.sessionId || row.parent.split('_')[1]
		const openCase = currentCaseSelector(state)
		const caseId = openCase.id

		if (!caseId) return

		// Dispatch an update of the session to be recorded in state
		const session = _getSessionDetails(openCase, sessionId)
		const rowIndex = session.schedule.findIndex(r => r.id === row.id)
		if (rowIndex >= 0) {
			session.schedule[rowIndex] = row
			dispatch(action(types.SESSIONS_UPDATE_SUCCESS, session))
		}

		// Update release row on server. We buffer this so as to not flood the server
		// when someone is typing in an update
		const updateOnServer = () => OnlineComms.updateReleaseRow(caseId, sessionId, row.id, row)
		const compoundKey = [caseId, sessionId, row.id].join()
		clearTimeout(updateRowBuffer[compoundKey])
		updateRowBuffer[compoundKey] = setTimeout(updateOnServer, 1000)
	},

	deleteSession: (id: string) => (dispatch: Dispatch, getState: GetState): void => {
		// Get current case ID
		const state = getState()
		const { id: caseId } = currentCaseSelector(state)
		OnlineComms.deleteSession(caseId, id).then(_ => dispatch(action(types.SESSIONS_DELETE, { timestamp: id })))
	},

	deletePreviousSession: (prevSessionId: string) => (dispatch: Dispatch, getState: GetState): void => {
		const state = getState()
		// Get current case ID
		const { id: caseId } = currentCaseSelector(state)
		ConfirmationHelper.confirm(config.strings.CONFIRM_DELETE_PREV_SESSION, () => {
			dispatch(action(types.SESSIONS_PREV_DELETED, prevSessionId))
			OnlineComms.deletePreviousSession(caseId, prevSessionId)
		})
	},

	startSession: (sessionId: string) => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		const state = getState()
		const currSess = state.currentSession
		const startTime = getTime()

		if (currSess && SessionHelpers.sessionIsActive(currSess)) return

		// Get current case ID
		const { id: caseId } = currentCaseSelector(state)

		const response = await OnlineComms.startSession(caseId, sessionId)
		if ('id' in response) {
			dispatch(action(types.SESSIONS_START, { id: response.id, startTime, sessionId }))
		}
		if ('message' in response) {
			ConfirmationHelper.alert('Error', response.message)
		}
	},

	endSession: (id: string) => (dispatch: Dispatch, getState: GetState): void => {
		const state = getState()
		// FYI: The server doesn't even need the current case ID. It just
		// gets the current session itself (based on facilitator ID) and
		// ends it.
		const caseId = state.openCase.id
		OnlineComms.endSession(caseId, id).then(_ => dispatch(action(types.SESSIONS_END)))
	},

	addReleaseRow: (sessionId: string, index: number) => (dispatch: Dispatch, getState: GetState): void => {
		// Get current case ID
		const state = getState()
		const { id: caseId } = currentCaseSelector(state)

		const timestamp = getTime().toString()
		const id = timestamp
		// const id = uuid() // This causes problems with current code
		const row = {
			id,
			index,
			timestamp,
			sessionId,
			type: 'media',
			release: 'manual',
		}

		// Be optimistic
		const payload = { sessionId, row }
		dispatch(action(types.SESSIONS_ADD_RELEASE_ROW_SUCCESS, payload))

		OnlineComms.addReleaseRow(caseId, sessionId, row)
	},

	removeReleaseRow: (sessionId: string, rowId: string) => (dispatch: Dispatch, getState: GetState): void => {
		// Get current case ID
		const state = getState()
		const { id: caseId } = currentCaseSelector(state)
		OnlineComms.removeReleaseRow(caseId, sessionId, rowId)
		// Dispatch without waiting from communication with server to complete
		dispatch(action(types.SESSIONS_REMOVE_RELEASE_ROW, { sessionId, rowId }))
	},

	releaseNow: (rowId: string) => async (dispatch: Dispatch): Promise<void> => {
		try {
			holdoffReleases = true
			dispatch(action(types.SESSION_RELEASE_NOW, { rowId, timeNow: getTime() }))
			await OnlineComms.releaseToAll(rowId)
		} catch (err) {
			console.error(err)
		}
		holdoffReleases = false
	},

	releaseToGroup: (groupId: string, rowId: string) => (dispatch: Dispatch): void => {
		ConfirmationHelper.confirm(config.strings.CONFIRM_RELEASE_TO_GROUP, async () => {
			try {
				holdoffReleases = true
				const payload = { groupId, rowId, timestamp: getTime() }
				dispatch(action(types.SESSION_RELEASE_TO_GROUP, payload))
				await OnlineComms.releaseToGroup(groupId, rowId)
			} catch (err) {
				console.error(err)
			}
			holdoffReleases = false
		})
	},

	pauseSession: () => (dispatch: Dispatch): void => {
		OnlineComms.pauseCurrentSession()
		dispatch(action(types.SESSIONS_PAUSE, {}))
	},

	resumeSession: () => (dispatch: Dispatch): void => {
		OnlineComms.resumeCurrentSession()
		dispatch(action(types.SESSIONS_RESUME, {}))
	},

	getCurrentSession: () => async (dispatch: Dispatch): Promise<void> => {
		const session = await OnlineComms.getCurrentSession()
		if (session) dispatch(action(types.SESSIONS_GOT_CURRENT_SESSION, session))
	},

	getOpeningsForGroup: (sessionId: string, groupId: string) => (dispatch: Dispatch): void => {
		if (!sessionId || !groupId) return
		OnlineComms.getOpenings(groupId, sessionId).then(data => {
			// Include the groupId and sessionId in the objects we're storing
			const _data = (data || [])
				.map(d => ({ ...d, groupId, sessionId }))
				.map(opening => {
					// If record key contains '_', it is scheduleRowId_participantId
					if (opening.key.includes('_')) {
						const split = opening.key.split('_')
						return { ...opening, key: split[0], participantId: split[1] }
					}
					return opening
				})
			dispatch(action(types.SESSIONS_GOT_OPENINGS, { data: _data }))
		})
	},

	getReleasesForSession: (sessionId: string) => (dispatch: Dispatch): void => {
		if (!sessionId) return
		OnlineComms.getReleasesForSession(sessionId).then(data => {
			if (holdoffReleases) return
			// Include the sessionId in the objects we're storing
			const _data = (data || []).map(d => ({ ...d, sessionId }))
			dispatch(action(types.SESSIONS_GOT_RELEASES, { sessionId, data: _data }))
		})
	},

	// ===============================================================================================

	getPreviousSessions: () => (dispatch: Dispatch, getState: GetState): void => {
		// Get current case ID
		const state = getState()
		const { id: caseId } = currentCaseSelector(state) || ({} as OpenCaseDetails)
		if (!caseId) return

		dispatch(action(types.SESSIONS_PREV_LOADING_LIST))
		OnlineComms.getPreviousSessions(caseId)
			.then(sessions => {
				dispatch(action(types.SESSIONS_PREV_RETRIEVED, sessions))
			})
			.catch(err => {
				console.error('Error fetching previous sessions: ', err)
				dispatch(action(types.SESSIONS_PREV_LOADING_LIST_FAILED))
			})
	},

	loadPreviousSession: (prevSessionId: string) => (dispatch: Dispatch, getState: GetState): void => {
		dispatch(action(types.SESSIONS_PREV_LOADING))

		// Get current case ID
		const state = getState()
		const { id: caseId } = currentCaseSelector(state) || ({} as OpenCaseDetails)
		if (!caseId) return

		OnlineComms.getPreviousSession(caseId, prevSessionId).then(session => {
			dispatch(action(types.SESSIONS_PREV_LOADED, session))
			// Fetch any translations if necessary
			groupActions.getDecisionLogTranslationsForGroup()(dispatch, getState)
		})
	},

	loadPreviousSessionForFacilitator: (prevSessionId: string) => (dispatch: Dispatch, getState: GetState): void => {
		dispatch(action(types.SESSIONS_PREV_LOADING))
		// Get current case ID
		const state = getState()
		const { id: caseId } = currentCaseSelector(state)
		OnlineComms.getPreviousSession(caseId, prevSessionId).then(session => {
			dispatch(action(types.SESSIONS_PREV_LOADED, session))
		})
	},

	returnToCurrentSession: () => (dispatch: Dispatch): void => {
		dispatch(action(types.SESSIONS_RETURN_TO_CURRENT))
	},

	exportSession: (filename: string, prevSessionId: string, translate?: boolean) => (
		dispatch: Dispatch,
		getState: GetState
	): void => {
		// Get current case ID
		const state = getState()
		const { id: caseId } = currentCaseSelector(state)

		OnlineComms.getPreviousSessionDocument(caseId, prevSessionId, translate).then(blob => {
			downloadBlob(
				blob,
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				'V360G Session.docx'
			)
		})
	},

	selectSession: (sessionId: string) => (dispatch: Dispatch): void => {
		dispatch(action(types.SESSION_SELECT, sessionId))
	},

	closeSessionInformation: () => (dispatch: Dispatch, getState: GetState): void => {
		miscActions.displayModalPopup('')(dispatch, getState) // Close modal popup
		dispatch(action(types.SESSION_INFO_CLOSED))
	},

	resendPhoneMessage: (scheduleRowId: string) => async (dispatch: Dispatch): Promise<void> => {
		holdoffReleases = true
		dispatch(action(types.PHONE_MESSAGE_RESENT, { scheduleRowId }))
		await OnlineComms.resendPhoneMessage(scheduleRowId)
		holdoffReleases = false
	},
}

export default actions
