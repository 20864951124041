/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react'
import getTextDirection from '../util/getTextDirection'

type TextareaProps = {
	onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
	id?: string
	className?: string
	placeholder?: string
	value?: string
	enabled?: boolean
	onFocus?: React.FocusEventHandler<HTMLTextAreaElement>
	onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
	// ref?: React.MutableRefObject<HTMLTextAreaElement>
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
	const { value, className = '', children, enabled = true, ...otherProps } = props
	const { onChange, onFocus, onBlur } = props
	return (
		<div className={`input textarea ${className}`}>
			<div className="textarea__resizer">{`${value}\n`}</div>
			<textarea
				ref={ref}
				disabled={!enabled}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value}
				style={{ direction: getTextDirection(value) }}
				aria-label={props.placeholder}
				{...otherProps}
				placeholder={props.placeholder}
			>
				{children}
			</textarea>
		</div>
	)
})

export default Textarea
