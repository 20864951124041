type Entity = {
	id?: string
	key?: string
	timestamp?: number | string
}

function equalObjects(a: Entity, b: Entity): boolean {
	try {
		if (!a && !b) return true
		if (!a || !b) return false
		if (a.id && b.id && String(a.id) === String(b.id)) return true
		if (a.key && b.key && String(a.key) === String(b.key)) return true
		if (a.timestamp && b.timestamp && String(a.timestamp) === String(b.timestamp)) return true
	} catch (e) {
		return false
	}
	return false
}

export default equalObjects
