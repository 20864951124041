/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { connect } from 'react-redux'
import React from 'react'

import config from '../../config'
import Actions from '../actions'

import { sessionIsActive } from '../util/SessionHelpers'

import BubbleHeading from '../elements/BubbleHeading'
import Session from '../components/Session'
import Button from '../elements/Button'
import TabsRow from '../elements/TabRow'
import TabsContainer from '../elements/TabsContainer'
import Tab from '../elements/Tab'

// =================================================================================================
type TutorSessionsProps = {
	currentSession: CurrentSession
	selectSession: (id: string) => void
	sessions: Session[]
	addNewSession: (caseId: string) => void
	openCase: OpenCaseDetails
	viewingPreviousSession: boolean
	selectedSession: string
}

class TutorSessions extends React.Component<TutorSessionsProps> {
	sessionCount: number
	element: HTMLDivElement

	constructor(props) {
		super(props)
		const { currentSession, selectSession } = props

		// If we have a current session, set that as the selected session prior to the first render
		if (currentSession && sessionIsActive(currentSession)) {
			selectSession(currentSession.sessionId)
		}
	}

	componentDidUpdate() {
		// After user adds a new session, scroll it into view
		const { sessions } = this.props
		if (this.sessionCount && sessions.length > this.sessionCount) {
			const _sessions = this.element.querySelectorAll('.session')
			_sessions[_sessions.length - 1].scrollIntoView()
			this.sessionCount = _sessions.length
		}
	}

	// ===============================================================================================

	_onClickNewSession() {
		const { sessions, addNewSession, openCase } = this.props
		this.sessionCount = sessions.length
		addNewSession(openCase.id)
	}

	// ===============================================================================================

	render() {
		const { CANNOT_EDIT_PREV_SESSIONS, NO_OPEN_CASE, NO_SESSIONS, SESSIONS, ADD_SESSION } = config.strings
		const { openCase, sessions, viewingPreviousSession, selectSession, selectedSession } = this.props

		let allowCreate = true
		let content = null
		if (viewingPreviousSession) {
			allowCreate = false
			content = <div className="info">{CANNOT_EDIT_PREV_SESSIONS}</div>
		} else if (!openCase || !openCase.id) {
			allowCreate = false
			content = <div className="info">{NO_OPEN_CASE}</div>
		} else if (sessions.length === 0) {
			content = <div className="info">{NO_SESSIONS}</div>
		} else {
			const sessionTabs = sessions.map((s, i) => (
				<Tab key={s.id} id={s.id} onClick={() => selectSession(s.id)}>
					{i + 1}
				</Tab>
			))
			content = (
				<>
					<TabsRow activeTab={selectedSession}>{sessionTabs}</TabsRow>
					<TabsContainer activeTab={selectedSession}>
						{sessions.map(s => (
							<Session key={s.id} id={s.id} data={s} />
						))}
					</TabsContainer>
				</>
			)
		}

		return (
			<div className="tutor-sessions" ref={ref => (this.element = ref)}>
				<div className="tutor-sessions__top">
					<BubbleHeading>{SESSIONS}</BubbleHeading>
					<Button text={ADD_SESSION} onClick={_ => this._onClickNewSession()} enabled={allowCreate} />
				</div>
				<div className="tutor-sessions__content">{content}</div>
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => {
	const sessions = state.openCase ? state.openCase.sessions || [] : []

	// Select the first session in list if no selected session or it doesn't exist anymore
	let { selectedSession } = state
	if (sessions.length && (!selectedSession || !sessions.find(s => s.id === selectedSession))) {
		selectedSession = sessions[0].id
	}

	return {
		viewingPreviousSession: state.viewingPreviousSession,
		currentSession: state.currentSession || {},
		previousSession: state.previousSession,
		openCase: state.openCase,
		selectedSession,
		sessions,
	}
}

const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	addNewSession: Actions.sessions.addNewSession,
	selectSession: Actions.sessions.selectSession,
}
export default connect(mapStateToProps, actions)(TutorSessions)
