import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useInterval } from 'react-use'

import config from '../../../config'
import Actions from '../../actions'
import DivWithInert from '../../elements/DivWithInert'
import calculateTranslationHash from '../../util/calculateTranslationHash'
import VirtualPhoneApps from './VirtualPhoneApps'

type WelcomeScreenProps = {
	welcomeMessage: string
	translations?: Record<string, string>
	facilitatorLanguage: string
	language: string
	translate: (text: string, toLang: string, fromLang?: string) => void
	onClickShowMessages: () => void
	active: boolean
}

const WelcomeScreen: FunctionComponent<WelcomeScreenProps> = props => {
	const { welcomeMessage, translations, translate, language, facilitatorLanguage, onClickShowMessages, active } = props
	const { SHOW_TRANSLATION, SHOW_ORIGINAL, DFT_WELCOME_MESSAGE } = config.strings

	const now = new Date()
	const [time, setTime] = useState(now.toTimeString().substring(0, 5))

	// Refresh the time displayed every few seconds
	useInterval(() => {
		setTime(new Date().toTimeString().substring(0, 5))
	}, 3000)

	// Display current date as a formatted string
	const dayName = now.toLocaleDateString(undefined, { weekday: 'long' })
	const monthName = now.toLocaleDateString(undefined, { month: 'long' })
	const date = `${dayName}, ${monthName} ${now.getDate()}`

	const message = welcomeMessage || DFT_WELCOME_MESSAGE

	// Check for translation
	const [showOriginal, setShowOriginal] = useState(false)
	const translationHash = calculateTranslationHash(message, facilitatorLanguage, language)
	useEffect(() => {
		if (!translations[translationHash]) {
			translate(date, language, facilitatorLanguage)
			translate(message, language, facilitatorLanguage)
		}
	}, [translationHash])

	// eslint-disable-next-line react/no-array-index-key
	const translation = translations[translationHash]
	const text = showOriginal ? message : translation || message

	const dateTranslationHash = calculateTranslationHash(date, facilitatorLanguage, language)
	const dateTranslation = translations[dateTranslationHash]
	const dateTranslated = showOriginal ? date : dateTranslation || date

	const onClickTranslate = () => setShowOriginal(!showOriginal)

	return (
		<DivWithInert className="welcome-screen" inert={!active}>
			<div className="welcome-screen__content">
				<div className="welcome-screen__datetime">
					<div className="welcome-screen__time">{time}</div>
					<div className="welcome-screen__date">{dateTranslated}</div>
				</div>
				<div className="welcome-screen__logo" />
				<div className="welcome-screen__message">{text}</div>
				{translation && (
					<div
						className="welcome-screen__translate"
						onClick={onClickTranslate}
						role="button"
						tabIndex={0}
						onKeyPress={onClickTranslate}
					>
						{showOriginal ? SHOW_TRANSLATION : SHOW_ORIGINAL}
					</div>
				)}
			</div>
			<VirtualPhoneApps showMessages={onClickShowMessages} />
		</DivWithInert>
	)
}

// export default WelcomeScreen

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	welcomeMessage: state?.settings?.waitingRoomMessage,
	language: state?.group?.language || 'EN',
	translations: state?.translations || {},
	facilitatorLanguage: 'EN',
})
const actions = {
	translate: Actions.translate.translate,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<WelcomeScreenProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(WelcomeScreen)
