/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useMount, useUnmount, useWindowSize } from 'react-use'
import { largestRect } from 'rect-scaler'
import { connect } from 'react-redux'

import Actions from '../../actions'
import config from '../../../config'

import JitsiClient, { JitsiClientEvents } from '../../util/JitsiClient'

import loggedInAsParticipantSelector from '../../selectors/loggedInAsParticipant'
import loggedInAsObserverSelector from '../../selectors/loggedInAsObserver'
import loggedInAsGroupSelector from '../../selectors/loggedInAsGroup'
import facilitatorIdSelector from '../../selectors/facilitatorId'

import calculateVideoCallParticipantsToDisplay from '../../util/calculateVideoCallParticipantsToDisplay'
import calculateDominantSpeaker from '../../util/calculateDominantSpeaker'

import InterpreterControls from './VideoCallInterpreterControls'
import VideoCallParticipant from './VideoCallParticipant'
import VideoCallButtons from './VideoCallButtons'
import Chat from './Chat'

import Button from '../../elements/Button'
import Switch from '../../elements/Switch'
import { IVideoClient } from './VideoTypes'

type VideoCallProps = {
	displayName: string
	domain: string
	callId: string

	selectedAudioOut?: string
	selectedCamera?: string
	selectedMic?: string
	selectAudioOut?: (deviceId: string) => void
	selectCamera?: typeof Actions.videoconf.selectCamera
	selectMic?: (deviceId: string) => void

	displayModalPopup?: typeof Actions.misc.displayModalPopup
	notifyMainCall?: (callId: string, participantId: string) => void
	endMainCall?: () => void

	loggedInAsObserver?: boolean
	loggedInAsParticipant?: boolean
	loggedInAsFacilitator?: boolean

	selectedInterpreter?: string
	selectInterpreter?: typeof Actions.videoconf.selectInterpreter
	isInterpreter?: boolean
	interpreterChannel?: string
	interpreterOnMainChannel?: boolean
	interpreterDetails?: InterpreterDetails

	interpreterChangeChannel?: (toMainChannel: boolean) => void

	clientId?: string
	facilitatorId?: string
	participantId?: string
	interpreters?: VideoConfDetails['interpreters']
	participants?: Participant[]

	videoCallParticipantsStartMuted: boolean
	videoCallObserversStartMuted: boolean
	videoCallFacilitatorsStartMuted: boolean
}

const globalVars = {
	interpreters: [],
}

function VideoCall(props: VideoCallProps): ReactElement<VideoCallProps> {
	const { domain, displayName, displayModalPopup, selectedCamera = null, selectedMic = null, clientId } = props
	const { interpreters, selectedInterpreter, callId } = props
	let { facilitatorId } = props
	if (!facilitatorId) facilitatorId = 'james@jessian.io'
	const { loggedInAsObserver, loggedInAsFacilitator, loggedInAsParticipant, selectInterpreter } = props
	const { interpreterOnMainChannel, isInterpreter, interpreterChangeChannel, interpreterDetails, participants } = props
	const { interpreterChannel } = props
	const { selectCamera, selectMic, selectAudioOut, selectedAudioOut } = props
	const {
		videoCallParticipantsStartMuted = true,
		videoCallObserversStartMuted = false,
		videoCallFacilitatorsStartMuted = false,
	} = props

	const { MUTED_MESSAGE, CLOSE, TUTOR, SUPERVISOR, INTERPRETER, MUTE_MAIN_AUDIO } = config.strings
	const { BROADCASTING_ON_X, BROADCASTING_ON_MAIN, INTERPRETER_AVAILABLE } = config.strings

	type Speakers = Record<string, Date>

	// eslint-disable-next-line react/destructuring-assignment
	const v360ParticipantId = props.participantId

	globalVars.interpreters = interpreters

	const { notifyMainCall, endMainCall } = props

	const client = useRef<JitsiClient>(null)
	const eventHandlerRefs = useRef<Array<number>>([])
	const [remoteParticipants, setRemoteParticipants] = useState<Array<JitsiParticipantDetails>>([])
	const [localError, setLocalError] = useState('')

	const [viewMode, setViewMode] = useState('TILE')
	const [selectedSpeakerId, setSelectedSpeakerId] = useState(null)
	const [muteMainAudio, setMuteMainAudio] = useState(false)
	const [chatOpen, setChatOpen] = useState(false)
	const [localParticipant, setLocalParticipant] = useState({} as JitsiParticipantDetails)
	const [speakers, setSpeakers] = useState<Speakers>({})

	const buttonArea = useRef<HTMLDivElement>()
	const tileview = useRef<HTMLDivElement>()
	const mainArea = useRef<HTMLDivElement>()
	const joined = useRef<boolean>(false)

	useMount(async () => {
		document.body.classList.add('in-call')

		// Initialise Jitsi client
		const jitsiClient = new JitsiClient({ domain, conferenceId: callId })
		client.current = jitsiClient
		window.JitsiClient = jitsiClient
		jitsiClient.setDisplayName(displayName)

		// Set some custom properties to be assigned to participants in the room (such as the observer ID for observers)
		if (loggedInAsObserver) jitsiClient.setProperty('clientId', clientId)
		else jitsiClient.setProperty('clientId', '')

		if (loggedInAsParticipant) jitsiClient.setProperty('participantId', v360ParticipantId)
		else jitsiClient.setProperty('participantId', '')

		if (loggedInAsFacilitator) client.current.setProperty('isFacilitator', 'true')

		if (loggedInAsParticipant) displayModalPopup('')

		const { REMOTE_PARTICIPANTS_CHANGED, LOCAL_PARTICIPANT_CHANGED, DOMINANT_SPEAKER_CHANGED } = JitsiClientEvents
		const { ROOM_JOINED } = JitsiClientEvents

		// Helper function for adding event listeners to the jitsi client and keeping an array of the
		// references we get back, which allows us to easily detach those event handlers later.
		const addEventListener = (type: JitsiClientEvents, callback: (...args: Array<unknown>) => void) => {
			eventHandlers.push(jitsiClient.on(type, callback))
		}

		const eventHandlers = eventHandlerRefs.current
		// Add event listeners
		addEventListener(LOCAL_PARTICIPANT_CHANGED, () => {
			const jitsiClient = client.current
			setLocalParticipant({ ...jitsiClient.localParticipant })

			const outputDevice = jitsiClient.getAudioOutputDevice()
			const { videoTrack, audioTrack } = jitsiClient.localParticipant
			if (audioTrack && !selectedMic) selectMic(audioTrack.deviceId)
			if (outputDevice && !selectedAudioOut) selectAudioOut(outputDevice)
			if (videoTrack && !selectedCamera && videoTrack.videoType !== 'desktop') selectCamera(videoTrack.deviceId)

			// If we are screensharing, switch to SPEAKER mode
			if (videoTrack && videoTrack.videoType === 'desktop') setViewMode('SPEAKER')

			if (jitsiClient.videoUnavailable) {
				setLocalError('Video device unavailable')
			} else {
				setLocalError('')
			}
		})

		addEventListener(REMOTE_PARTICIPANTS_CHANGED, () => {
			console.log('REMOTE_PARTICIPANTS_CHANGED', jitsiClient.remoteParticipants)
			setRemoteParticipants([...jitsiClient.remoteParticipants])

			// If someone is screensharing, switch to SPEAKER mode
			if (jitsiClient.remoteParticipants.find(p => p.videoTrack && p.videoTrack.videoType === 'desktop')) {
				setViewMode('SPEAKER')
			}
		})

		// Notify all users that new call has started
		addEventListener(ROOM_JOINED, () => {
			if (joined.current) return
			joined.current = true
			setLocalParticipant({ ...client.current.localParticipant })
			client.current.setMaxReceivedVideoHeight(480)

			if (loggedInAsFacilitator) notifyMainCall(callId, jitsiClient.participantId)
		})

		addEventListener(DOMINANT_SPEAKER_CHANGED, ({ participantId }) => {
			// Update the 'lastSpoke' time for this participant in our list of speakers.
			// It will help us determine if we want to show this participant full screen as the
			// dominant speaker or not. We might choose not to, for instance if they are an interpreter.
			setSpeakers(prevSpeakers => ({ ...prevSpeakers, [participantId]: new Date() }))
		})

		jitsiClient.connect({
			cameraDeviceId: selectedCamera,
			audioInputDeviceId: selectedMic,
			startAudioMuted:
				(loggedInAsFacilitator && videoCallFacilitatorsStartMuted) ||
				(loggedInAsParticipant && videoCallParticipantsStartMuted) ||
				(loggedInAsObserver && videoCallObserversStartMuted),
		})
	})

	useUnmount(async () => {
		document.body.classList.remove('in-call')
		if (!client.current) return
		// Detach/remove all event listeners from the active JitsiClient
		eventHandlerRefs.current.forEach(ref => client.current.off(ref))
		// Also "unload" the JitsiClient
		await client.current.unload()
	})

	// Switch camera/audio devices used by JitsiClient if they have changed in global state (i.e. in the user's settings)
	useEffect(() => {
		if (client.current) {
			client.current.changeVideoInput(selectedCamera).catch(err => console.error('Error switching video source', err))
		}
	}, [selectedCamera])

	useEffect(() => {
		if (client.current) {
			client.current.setAudioOutputDevice(selectedAudioOut)
		}
	}, [selectedAudioOut])

	useEffect(() => {
		if (client.current) {
			client.current.changeAudioInput(selectedMic)
		}
	}, [selectedMic])

	useEffect(() => {
		setMuteMainAudio(false)
		if (loggedInAsParticipant && client.current) {
			client.current.setProperty('selectedInterpreter', selectedInterpreter || '')
		}
	}, [selectedInterpreter])

	useWindowSize() // use this to trigger re-render when window size changes

	let tileVideoSize = { width: null, height: null, rows: null }

	if (viewMode === 'TILE' && buttonArea.current && mainArea.current) {
		// Calculate the height of the tile area even if it isn't rendered yet
		const containerHeight = mainArea.current.clientHeight - (buttonArea.current.clientHeight + 25)
		const containerWidth = mainArea.current.clientWidth - 14 // 7px margin
		const numRects = remoteParticipants.length + 1
		try {
			const { width, height, rows } = largestRect(containerWidth, containerHeight, numRects, 4, 3) // Aspect-ratio
			// Set window width/height to state
			tileVideoSize = { width, height, rows }
		} catch (err) {
			// Set window width/height to state
			tileVideoSize = { width: 400, height: 300, rows: Math.ceil(numRects / 3) }
		}
	}

	// -----------------------------------------------------------------------------------------------

	// -----------------------------------------------------------------------------------------------
	// When a participant is selected to be viewed full screen, we redisplay the current speaker after 5 seconds
	type Timeout = ReturnType<typeof setTimeout>
	const selectedSpeakerTimer = useRef<Timeout>()
	const onClickParticipant = (id: string) => {
		clearTimeout(selectedSpeakerTimer.current)
		selectedSpeakerTimer.current = setTimeout(() => setSelectedSpeakerId(null), 5000)
		setViewMode('SPEAKER')
		setSelectedSpeakerId(id)
	}

	// -----------------------------------------------------------------------------------------------
	// Determine the fullscreen speaker
	// Check if selected speaker is local participant or a remote participant (or nothing)

	let dominantSpeaker = localParticipant

	if (viewMode === 'SPEAKER') {
		dominantSpeaker = calculateDominantSpeaker({
			speakers,
			clientId,
			remoteParticipants,
			localParticipant,
			interpreters,
			selectedInterpreter,
			loggedInAsFacilitator,
			loggedInAsObserver,
			loggedInAsParticipant,
		})
	}

	const selectedSpeaker =
		(localParticipant?.participantId === selectedSpeakerId && localParticipant) ||
		remoteParticipants.find(p => p.participantId === selectedSpeakerId)

	const participantWhoIsScreensharing =
		(localParticipant?.videoTrack?.videoType === 'desktop' && localParticipant) ||
		remoteParticipants.find(p => p.videoTrack?.videoType === 'desktop')

	const fullScreenSpeaker = selectedSpeaker || participantWhoIsScreensharing || dominantSpeaker || localParticipant

	/* If we have a fullscreen speaker, set them as the primary remote participant (i.e. request high res video stream) */
	useEffect(() => {
		if (fullScreenSpeaker?.participantId && viewMode === 'SPEAKER') {
			client.current.setPrimaryRemoteParticipant(fullScreenSpeaker.participantId)
		}
	}, [fullScreenSpeaker])

	/* If switched to tile view, apply consistent height for all receiver video streams */
	useEffect(() => {
		if (viewMode === 'TILE') {
			client.current.setMaxReceivedVideoHeight(Math.min(tileVideoSize.height, 480))
		}
	}, [viewMode, tileVideoSize])

	// -----------------------------------------------------------------------------------------------
	// Find selected interpreter details
	const selectedInterpreterParticipantDetails =
		selectedInterpreter && remoteParticipants.find(p => p?.properties?.clientId === selectedInterpreter)

	// -----------------------------------------------------------------------------------------------
	// Render all participants for tileview or filmstrip
	const participantsToDisplay = calculateVideoCallParticipantsToDisplay({
		selectedInterpreterId: selectedInterpreter,
		interpreterChannel: interpreterOnMainChannel ? '' : interpreterChannel,
		loggedInAsInterpreter: isInterpreter,
		loggedInAsParticipant,
		loggedInAsFacilitator,
		loggedInAsObserver,
		remoteParticipants,
		localParticipant,
		muteMainAudio,
		interpreters,
	})

	// If we are in TILE view, then we include the local participant in this list
	if (viewMode === 'TILE') {
		let subheading = null
		const interpreterDetails = loggedInAsObserver && interpreters.find(i => i.clientId === clientId)
		if (interpreterDetails) {
			const { channel } = interpreterDetails
			subheading = `${INTERPRETER} - ${channel}`
		} else if (loggedInAsObserver) {
			subheading = SUPERVISOR
		} else if (loggedInAsFacilitator && localParticipant.displayName !== TUTOR) {
			subheading = TUTOR
		}

		participantsToDisplay.push({
			participant: localParticipant,
			subheading,
			mute: true,
		})
	}

	// Create an array of the participant elements/videos
	const participantElems = participantsToDisplay.map(details => {
		const { participant, infoMessage, mute, subheading, volume } = details
		const { participantId } = participant
		const { width, height } = tileVideoSize
		// Get the V360G ID for this participant
		const pParticipantId = participant?.properties?.participantId
		const error = participant.isLocal ? localError : infoMessage

		return (
			<VideoCallParticipant
				key={participantId || 'local'}
				facilitatorId={facilitatorId}
				onClick={() => onClickParticipant(participantId)}
				uploadToImageCache={participant.isLocal}
				participant={participant}
				participantDetails={participants.find(p => p.id === pParticipantId)}
				volume={mute ? 0 : volume}
				subheading={subheading}
				height={height}
				error={error}
				width={width}
			/>
		)
	})
	// -----------------------------------------------------------------------------------------------

	const onClickSelectInterpreter = () => displayModalPopup('modal-select-interpreter')
	const onClickSettings = () => displayModalPopup('modal-configure-video')
	const onClickChat = () => setChatOpen(chatOpen => !chatOpen)
	const onClickChangeLayout = () => setViewMode(viewMode === 'TILE' ? 'SPEAKER' : 'TILE')

	// Controls displayed to interpreters for them to choose which channel they wish to broadcast to
	const interpreterControls = isInterpreter && (
		<InterpreterControls
			onMainChannel={interpreterOnMainChannel}
			channelName={interpreterDetails.channel}
			onChangeChannel={interpreterChangeChannel}
		/>
	)

	// Interpreter element to be displayed to participants that have selected interpreter
	let interpreterElem: JSX.Element = null
	if (selectedInterpreterParticipantDetails) {
		const interpreterDetails = interpreters.find(i => i.clientId === selectedInterpreter)
		if (interpreterDetails) {
			const { channel, onMainChannel } = interpreterDetails
			const subheading = `${INTERPRETER} - ${channel}`
			let infoMessage = ''
			let interpreterVolume = loggedInAsFacilitator ? 0.1 : 1.0
			if (onMainChannel) {
				infoMessage = BROADCASTING_ON_MAIN
				interpreterVolume = 0
			}

			interpreterElem = (
				<div className="video-call__interpreter">
					<VideoCallParticipant
						participant={selectedInterpreterParticipantDetails}
						facilitatorId={facilitatorId}
						volume={interpreterVolume}
						subheading={subheading}
						error={infoMessage}
					/>

					{!loggedInAsFacilitator ? (
						<div className="video-call__interpreter-mute">
							<label>{MUTE_MAIN_AUDIO}</label>
							<Switch value={muteMainAudio} onChange={checked => setMuteMainAudio(checked)} />
						</div>
					) : null}
					<Button onClick={() => selectInterpreter(null)} text={CLOSE} />
				</div>
			)
		}
	}

	// Check if we have any interpreters available in the current video call
	// If so, we display a button to allow participants to select an interpreter
	const interpretersInCall = interpreters.filter(i =>
		remoteParticipants.find(p => p.properties && p.properties.clientId === i.clientId)
	)
	const interpreterAvailable = Boolean(
		!selectedInterpreterParticipantDetails && interpretersInCall.length && !isInterpreter
	)
	const interpreterAvailableButton = interpreterAvailable && (
		<Button onClick={onClickSelectInterpreter} text={INTERPRETER_AVAILABLE} primary />
	)

	const footer = (
		<div ref={buttonArea} className="video-call__buttons">
			<div className="left">{interpreterControls || interpreterAvailableButton}</div>
			<div className="middle">
				<VideoCallButtons
					client={(client.current as unknown) as IVideoClient}
					onClickChangeLayout={onClickChangeLayout}
					onClickEndCall={endMainCall}
					onClickSettings={onClickSettings}
					onClickChat={onClickChat}
					showScreenShare={loggedInAsFacilitator || loggedInAsObserver}
					showEndCall={loggedInAsFacilitator}
					viewMode={viewMode}
				/>
			</div>
			<div className="right" />
		</div>
	)

	let mainContent: JSX.Element
	if (viewMode === 'TILE') {
		const { rows } = tileVideoSize
		const rowElems = []
		const vids = [...participantElems]
		const vidsPerRow = Math.ceil(participantElems.length / rows)
		for (let i = 0; i < rows; i++) {
			const participantsForRow = vids.splice(0, vidsPerRow)
			rowElems.push(
				<div key={i} style={{ display: 'flex' }}>
					{participantsForRow}
				</div>
			)
		}
		mainContent = (
			<div ref={tileview} className="video-call__tileview">
				<div className="video-call__tileview-inner">{rowElems}</div>
			</div>
		)
	} else if (fullScreenSpeaker) {
		mainContent = (
			<VideoCallParticipant
				fullscreen
				volume={0}
				participant={fullScreenSpeaker}
				error={fullScreenSpeaker === localParticipant ? localError : ''}
			/>
		)
	}

	const onClickLocalParticipant = () => onClickParticipant(localParticipant.participantId)

	// Create filmstrip if in 'speaker' mode
	const filmstrip = viewMode === 'SPEAKER' && (
		<div className="video-call__filmstrip">
			<div className="video-call__participants">{participantElems}</div>
			{/* Local Participant */}
			<VideoCallParticipant
				participant={localParticipant}
				facilitatorId={facilitatorId}
				onClick={onClickLocalParticipant}
				error={localError}
				uploadToImageCache
			/>
		</div>
	)

	const muted = client.current?.localParticipant.isMuted
	const className = `video-call video-call--${viewMode.toLowerCase()}`

	return (
		<div className={className} role="main">
			<div className="video-call__container">
				{chatOpen && <Chat client={client.current} />}
				<div ref={mainArea} className="video-call__main">
					{interpreterElem}
					{mainContent}
					{footer}
				</div>
				{filmstrip}
			</div>
			{muted && <div className="video-call__mute-message">{MUTED_MESSAGE}</div>}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const settings = state.settings || ({} as Settings)

	const props: Partial<VideoCallProps> = {
		selectedAudioOut: state.videoconf.selectedAudioOut,
		selectedCamera: state.videoconf.selectedCamera,
		selectedMic: state.videoconf.selectedMic,
		interpreters: state.videoconf.interpreters || [],
		clientId: state.clientId,
		selectedInterpreter: state.videoconf.selectedInterpreter,
		participants: state.participants || [],
		loggedInAsParticipant: loggedInAsParticipantSelector(state) || loggedInAsGroupSelector(state),
		loggedInAsObserver: loggedInAsObserverSelector(state),
		loggedInAsFacilitator: state.tutor && state.tutor.loggedIn,
		facilitatorId: facilitatorIdSelector(state),
		participantId: state?.group?.participantId,
		videoCallParticipantsStartMuted: settings.videoCallParticipantsStartMuted,
		videoCallObserversStartMuted: settings.videoCallObserversStartMuted,
		videoCallFacilitatorsStartMuted: settings.videoCallFacilitatorsStartMuted,
	}

	const interpreter = props.loggedInAsObserver && props.interpreters.find(i => i.clientId === props.clientId)
	if (interpreter) {
		props.isInterpreter = true
		props.interpreterChannel = interpreter.channel
		props.interpreterOnMainChannel = interpreter.onMainChannel
		props.interpreterDetails = interpreter
	}

	return props
}
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	notifyMainCall: Actions.videoconf.notifyMainCall,
	endMainCall: Actions.videoconf.endMainCall,
	interpreterChangeChannel: Actions.videoconf.interpreterChangeChannel,
	selectInterpreter: Actions.videoconf.selectInterpreter,
	selectAudioOut: Actions.videoconf.selectAudioOut,
	selectCamera: Actions.videoconf.selectCamera,
	selectMic: Actions.videoconf.selectMic,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Pick<VideoCallProps, 'callId' | 'displayName' | 'domain'>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(VideoCall)
