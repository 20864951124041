import ConfirmationHelper from '../util/ConfirmationHelper'
import OnlineComms from '../core/OnlineComms'
import getTime from '../util/getTime'
import action from '../util/action'
import equal from '../util/equal'
import config from '../../config'
import miscActions from './misc'
import types from './types'

// =================================================================================================

const addNewCase: ActionCreator<string, string> = (title, description) => async (dispatch, getState) => {
	const { ERROR_TITLE_BLANK, ERROR_DESCRIPTION_BLANK, ERROR_TITLE_ALREADY_EXISTS } = config.strings

	// Validate
	if (!title) {
		dispatch(action(types.CASES_ADD_NEW_FAIL, ERROR_TITLE_BLANK))
		return
	}
	if (!description) {
		dispatch(action(types.CASES_ADD_NEW_FAIL, ERROR_DESCRIPTION_BLANK))
		return
	}
	const existingCases = getState().caseList || []
	if (existingCases.findIndex(c => c.title === title) >= 0) {
		dispatch(action(types.CASES_ADD_NEW_FAIL, ERROR_TITLE_ALREADY_EXISTS))
		return
	}

	const timestamp = getTime()
	const id = String(timestamp)
	try {
		dispatch(action(types.CASES_ADDING_NEW))
		await OnlineComms.addNewCase(id, title, description, timestamp)
		const payload = { id, title, description, timestamp }
		dispatch(action(types.CASES_ADD_NEW_SUCCESS, payload))
		// Close modal popup
		miscActions.displayModalPopup('', {})(dispatch, getState)
	} catch (err) {
		console.error(err)
	}
}

// =================================================================================================

const deleteCase = (id: string) => (dispatch: Dispatch, getState: GetState): void => {
	ConfirmationHelper.confirm(config.strings.CONFIRM_DELETE_CASE, async () => {
		if (String(getState()?.openCase?.id) === id) {
			await OnlineComms.setOpenCase(null)
			dispatch(action(types.CASES_LOAD_CASE, null))
		}
		await OnlineComms.deleteCase(id)
		dispatch(action(types.CASES_DELETE_CASE, id))
	})
}

// =================================================================================================

const loadCaseList = () => async (dispatch: Dispatch): Promise<void> => {
	dispatch(action(types.CASES_START_LOAD_CASELIST))

	try {
		const cases = await OnlineComms.getCaseList()
		dispatch(action(types.CASES_LOAD_CASELIST, cases))
	} catch (err) {
		console.error(err)
		dispatch(action(types.CASES_LOAD_CASELIST_ERROR, err))
	}
}

// =================================================================================================

const loadCase: ActionCreator<string> = caseId => (dispatch, getState): void => {
	// While we are here, set the current case ID for when we log back in.
	OnlineComms.setOpenCase(caseId)

	const state = getState()
	const _case = (state.caseList || []).find(c => equal(c, { id: caseId }))
	// Firstly load the case from our caselist as a starter
	dispatch(action(types.CASES_LOAD_CASE, _case))
	// This gives an immediate UI update
	// Then fetch the real, entire case from the server (incl. media and sessions)
	fetchCase(caseId)(dispatch, getState)
}

// =================================================================================================

const getOpenCase: ActionCreator = () => async (dispatch, getState): Promise<unknown> => {
	const initialState = getState()
	const openCase = initialState.openCase || ({} as OpenCaseDetails)
	const _case = (await OnlineComms.getOpenCase()) as OpenCaseDetails

	dispatch(action(types.CASES_GOT_OPEN_CASE, _case))

	const caseId = _case?.id
	if (caseId && caseId !== openCase.id) {
		return fetchCase(_case.id)(dispatch, getState)
	}
}

// =================================================================================================

const fetchCase: ActionCreator<string> = caseId => async dispatch => {
	try {
		const caseDetails = (await OnlineComms.loadCase(caseId)) as Case
		if (!caseDetails?.id) return
		dispatch(action(types.CASES_LOAD_CASE, caseDetails))
	} catch (err) {
		console.error(err)
	}
}
// =================================================================================================

const updateCase: ActionCreator<string, SimpleObject> = (caseId, details) => async (dispatch, getState) => {
	const { title, description } = details
	const { ERROR_TITLE_BLANK, ERROR_DESCRIPTION_BLANK } = config.strings
	if (!title) {
		dispatch(action(types.CASES_ADD_NEW_FAIL, ERROR_TITLE_BLANK))
		return
	}
	if (!description) {
		dispatch(action(types.CASES_ADD_NEW_FAIL, ERROR_DESCRIPTION_BLANK))
		return
	}

	try {
		dispatch(action(types.CASES_UPDATING_CASE, { id: caseId, ...details }))
		OnlineComms.updateCase(caseId, details)
		dispatch(action(types.CASES_UPDATED_CASE, { id: caseId, ...details }))
		miscActions.displayModalPopup('', {})(dispatch, getState)
	} catch (err) {
		console.error(err)
		dispatch(action(types.CASES_UPDATE_FAIL, { id: caseId, ...details }))
	}
}

// =================================================================================================

const actions = {
	loadCaseList,
	getOpenCase,
	updateCase,
	addNewCase,
	deleteCase,
	loadCase,
}

export default actions
