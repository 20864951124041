// import React from 'react'
import { FunctionComponent } from 'react'
import { useInterval } from 'react-use'
import { connect } from 'react-redux'

import config from '../../config'
import Actions from '../actions'

import sessionSelector from '../selectors/session'
import loggedInAsParticipantSelector from '../selectors/loggedInAsParticipant'

const { GROUP_FETCH_FREQUENCY, GROUP_FETCH_DECISION_LOG_FREQUENCY } = config

type SyncManagerGroupProps = {
	getOpenings: (sessionId: string, groupId: string) => void
	getReleasesForSession: (sessionId: string) => void
	getConversation: () => void
	fetchDecisionLog: () => void
	getServerStatus: () => void
	getParticipants: () => void
	fetchGroupData: () => void
	getServerTime: () => void
	fetchSettings: () => void
	session: CurrentSession | PreviousSession
	currentSession: CurrentSession
	loggedInAsParticipant: boolean
	viewingPreviousSession: boolean
	editingDecisionLog: boolean
	groupId: string
}

const SyncManagerGroup: FunctionComponent<SyncManagerGroupProps> = props => {
	const { fetchGroupData, getConversation, getParticipants, fetchSettings } = props
	const { getServerStatus, getReleasesForSession, getOpenings, fetchDecisionLog } = props

	const { viewingPreviousSession, editingDecisionLog } = props
	const { session, loggedInAsParticipant, groupId } = props

	// Fetch data from the server every few seconds
	useInterval(() => {
		fetchSettings()
		fetchGroupData()
		getServerStatus()
		getConversation()
		if (session) getReleasesForSession(session.id)
		if (session) getOpenings(session.id, groupId)
		if (loggedInAsParticipant) getParticipants()
	}, GROUP_FETCH_FREQUENCY)

	// Fetch decision log a bit more regularly, unless it is unnecessary
	useInterval(() => {
		if (viewingPreviousSession || editingDecisionLog || !loggedInAsParticipant) return
		fetchDecisionLog()
	}, GROUP_FETCH_DECISION_LOG_FREQUENCY)

	// this.initialised = true
	return null
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<SyncManagerGroupProps> => {
	const groupId = state?.group?.id
	const session = sessionSelector(state)
	const currentSession = state?.groupData?.currentSession
	const loggedInAsParticipant = loggedInAsParticipantSelector(state)
	const viewingPreviousSession = state?.viewingPreviousSession
	const editingDecisionLog = state?.editingDecisionLog

	return { loggedInAsParticipant, session, groupId, currentSession, viewingPreviousSession, editingDecisionLog }
}
// Map actions to component properties by just passing an object of those actions to connect()
const actions: Partial<SyncManagerGroupProps> = {
	getServerTime: Actions.login.getServerTime,
	fetchGroupData: Actions.groups.fetchGroupData,
	fetchSettings: Actions.settings.fetchSettings,
	fetchDecisionLog: Actions.groups.fetchDecisionLog,
	getOpenings: Actions.sessions.getOpeningsForGroup,
	getConversation: Actions.messages.getConversation,
	getServerStatus: Actions.videoconf.getServerStatus,
	getParticipants: Actions.participants.getParticipants,
	getReleasesForSession: Actions.sessions.getReleasesForSession,
}
export default connect(mapStateToProps, actions)(SyncManagerGroup)
