const GREEN = '#23a12f'
const BLUE = '#1b80f1'
// const DARKBLUE = '#35398e'
// const DARKBLUE = '#35398e'
// const PURPLE = '#6f237d'

const ORANGE = '#ff6600'
const TEAL = '#009ba4'
// const YELLOW = '#ffad33'

const PURPLE = '#671cbf'
const YELLOW = '#ff9922'
const DARKBLUE = '#0d3dcb'
const DARKTEAL = '#264653'
const MAGENTA = '#cc0066'

const mediaIconList = [
	{ value: 'document', icon: 'file-lines', label: 'Document', color: BLUE },
	{ value: 'phone', icon: 'phone-alt', label: 'Phone', color: GREEN },
	{ value: 'audio', icon: 'microphone-alt', label: 'Audio', color: DARKBLUE },
	{ value: 'video', icon: 'video-alt', label: 'Video', color: PURPLE },
	{ value: 'image', icon: 'camera-alt', label: 'Image', color: YELLOW },
	{ value: 'mail', icon: 'envelope-alt', label: 'Mail', color: BLUE },
	{ value: 'map', icon: 'map-marker', label: 'Map', color: GREEN },
	{ value: 'newspaper', icon: 'newspaper', label: 'News', color: DARKBLUE },
	{ value: 'web', icon: 'chrome', label: 'Web', color: GREEN },
	/* For the following we are using brand colours: */
	{ value: 'facebook', icon: 'facebook-f', label: 'Facebook', color: '#0866FF' },
	{ value: 'instagram', icon: 'instagram', label: 'Instagram', color: '#e80071' },
	{ value: 'whatsapp', icon: 'whatsapp', label: 'WhatsApp', color: '#25D366' },
	{ value: 'skype', icon: 'skype', label: 'Skype', color: '#0078D7' },
	{ value: 'linkedin', icon: 'linkedin', label: 'LinkedIn', color: '#2867B2' },
	{ value: 'twitter', icon: 'twitter', label: 'Twitter', color: '#1DA1F2' },
	{ value: 'viber', icon: 'viber', label: 'Viber', color: '#AE56A4' },
	{ value: 'youtube', icon: 'youtube', label: 'YouTube', color: '#FF0000' },
	{ value: 'teams', icon: 'microsoft-teams', label: 'MS Teams', color: '#464db7' },
	{ value: 'x', icon: 'x', label: 'Post', color: '#000000' },
	{ value: 'outlook', icon: 'microsoftoutlook', label: 'Outlook Email', color: '#0072c6' },
	{ value: 'edge', icon: 'microsoftedge', label: 'Edge Browser', color: '#0078d7' },
	{ value: 'tiktok', icon: 'tiktok', label: 'TikTok', color: '#69c9d0' },
	{ value: 'zoom', icon: 'zoom', label: 'Zoom', color: '#2d8cff' },
	{ value: 'gmail', icon: 'gmail', label: 'Gmail', color: '#d14836' },
]

/*
const list = [
	{ value: 'document', icon: 'file-lines', label: 'Document', color: '#660066' },
	{ value: 'video', icon: 'video-alt', label: 'Video', color: '#FFAD33' },
	{ value: 'map', icon: 'map-marker', label: 'Map', color: '#66CC66' },
	{ value: 'phone', icon: 'phone-alt', label: 'Phone', color: '#6633FF' },
	{ value: 'audio', icon: 'microphone-alt', label: 'Audio', color: '#1e79f9' },
	{ value: 'image', icon: 'camera-alt', label: 'Image', color: '#FF6600' },
	{ value: 'mail', icon: 'envelope-alt', label: 'Mail', color: '#009BA4' },
	{ value: 'newspaper', icon: 'newspaper', label: 'News', color: '#cc3399' },
	{ value: 'web', icon: 'globe', label: 'Web', color: '#35398e' },
	{ value: 'facebook', icon: 'facebook-f', label: 'Facebook', color: '#1877F2' },
	{ value: 'instagram', icon: 'instagram', label: 'Instagram', color: '#8134af' },
	{ value: 'whatsapp', icon: 'whatsapp', label: 'WhatsApp', color: '#25d366' },
	{ value: 'skype', icon: 'skype', label: 'Skype', color: '#02AFF0' },
	{ value: 'linkedin', icon: 'linkedin', label: 'LinkedIn', color: '#007BB5' },
	{ value: 'twitter', icon: 'twitter', label: 'Twitter', color: '#1DA1F2' },
	{ value: 'viber', icon: 'viber', label: 'Viber', color: '#AE56A4' },
	{ value: 'youtube', icon: 'youtube', label: 'YouTube', color: '#FF0000' },
]
*/
export default mediaIconList
