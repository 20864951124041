export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'Anuloj',
	TUTOR: 'Lehtësues',
	CURRENT_SESSION: 'Seanca Aktuale',
	TEAM: 'Ekip',
	SIGNOUT: 'Shkyç ',
	INITIAL_UPDATE: 'Informacioni i sesionit',
	ADVICE: 'Vendim',
	RATIONALE: 'Arsyetim',
	MEDIA_FEED: 'Burim Mediatik',
	MESSAGES: 'Mesazhet',
	PREVSESSIONS: 'Seancat e mëparshme',
	RETURN_TO_CURRENT: 'Kthehuni në seancën aktuale',
	SESSION_IS_PAUSED: 'Seanca aktualisht është Pauzuar',
	ATTRIB_CURR_AUTHOR: 'Vendimmarrës',
	SAVE: 'Ruaj',
	CHANGE: 'Ndrysho',
	NO_AUTHOR: 'E pavendosur',
	NO_INITIAL_INFO: 'Ju lutemi prisni që të fillojë seanca tjetër',
	DECISION_MAKER_SELECT: 'Ju lutemi zgjidhni një vendimmarrës',
	SESSION_COMPLETE: 'Seanca e mëparshme ka përfunduar',
	OPENED_AT: 'Hapur në',
	SEND_MESSAGE_PLACEHOLDER: 'Shkruaj një mesazh',
	SEND: 'Dërgoni',
	TO_ALL_GROUPS: 'Për të gjitha grupet',
	SHOW_TRANSLATION: 'Shfaq përkthimin',
	SHOW_ORIGINAL: 'Trego origjinalin',
	CREDENTIALS_EXPIRED: 'Kodi juaj i kalimit ka skaduar. Ju lutemi identifikohuni përsëri',
	INCOMING_MESSAGE: 'Mesazhi hyrës',
	INCOMING_MESSAGE_TEXT: 'Një mesazh i ri është marrë',
	INCOMING_MEDIA: 'Artikulli i ardhshëm i medias',
	INCOMING_MEDIA_TEXT: 'Një artikull i ri mediatik është marrë',
	INCOMING_UPDATE: 'Azhurnimi i ardhshëm',
	INCOMING_UPDATE_TEXT: 'Një azhurnim është marrë',
	CONFIRM_LOGOUT: 'Je i sigurt që dëshiron të dalësh?',
	MUTED_MESSAGE: 'Mikrofoni juaj është i fikur',
	NEWSTYPE_UPDATE: 'Përditësimi u mor',
	WARNING_TIME_MSG: 'Kanë mbetur edhe 5 minuta',
	NOTEPAD: 'Shënime',
	DECISION_LOG: 'Regjistri i vendimeve',
	VIEW_INITIAL_UPDATE: 'Shiko informacionin e seancës',
	NOTETAKER: 'Shënuesi',
	DFT_MESSAGES_TEXT: 'Një mesazh i ri është marrë',
	POPUP_TITLE_CONFIGUREVIDEO: 'Konfiguro video',
	CAMERA: 'Kamera',
	MICROPHONE: 'Mikrofoni',
	AUDIO_OUTPUT_DEVICE: 'Përzgjidhni pajisjen e daljes audio',
	SELECT_CAMERA: 'Përzgjidhni kamerën',
	TO: 'Për',
	EVERYONE: 'Të gjithë',
	ADD_DECISION_LOG: 'Shto në regjistrin e vendimeve',
	ENTER_ADVICE: 'Shkruani vendimin tuaj',
	ENTER_RATIONALE: 'Shkruani arsyen tuaj',
	DFT_NOTIFICATIONS_TEXT:
		'Media do të shfaqet këtu. Mbani një sy në këtë pajisje për media dhe përditësime të reja. Informacioni i sesionit fillestar mund të arrihet nga butoni në krye të ekranit tuaj. Komunikoni me lehtësuesin duke përdorur aplikacionin Messages në këndin e poshtëm majtas të kësaj pajisjeje.',
	DECISION_LOG_HELP_TEXT:
		'Përgjigjet dhe arsyetimi i ekipit tuaj do të shfaqen këtu. Ju do të jeni në gjendje të modifikoni përgjigjet dhe arsyetimin tuaj duke klikuar mbi to.',
}
