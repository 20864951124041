/**
 * Represents the MessagesApp component.
 * @component
 * @param {Object[]} messages - The array of message details.
 * @param {string} groupId - The ID of the group.
 * @param {string} participantId - The ID of the participant.
 * @param {string} groupColour - The color of the group.
 * @param {string} participantName - The name of the participant.
 * @param {boolean} [viewingPreviousSession] - Indicates if the user is viewing a previous session.
 * @param {boolean} [active] - Indicates if the component is active.
 * @param {Function} markConversationAsReadForGroup - The function to mark the conversation as read for the group.
 * @param {Object} [session] - The current session object.
 * @param {Function} [onClose] - The function to close the component.
 */
import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import config from '../../../config'
import Actions from '../../actions'

import ConfirmationHelper from '../../util/ConfirmationHelper'
import messagesForGroupSelector, { MessageDetails } from '../../selectors/messagesForGroup'
import currentSessionSelector from '../../selectors/currentSession'
import teamNameFromColour from '../../util/teamNameFromColour'
import useAutoScroll from '../../util/useAutoScroll'
import getTime from '../../util/getTime'

import IconButton from '../../elements/IconButton'
import MessageEntry from '../MessageEntry'
import MessageElem from '../MessageElem'
import Row from '../../elements/Row'
import DivWithInert from '../../elements/DivWithInert'

type MessagesAppProps = {
	messages: MessageDetails[]
	groupId: string
	participantId: string
	groupColour: string
	participantName: string
	viewingPreviousSession?: boolean
	active?: boolean
	markConversationAsReadForGroup: () => void
	session?: CurrentSession
	onClose?: () => void
}

const sortAscending = (a, b) => a.timestamp - b.timestamp

const MessagesApp: FunctionComponent<MessagesAppProps> = props => {
	const [prevMessages, setPrevMessages] = useState([])
	const { messages, groupId, participantId, groupColour, participantName, onClose, active } = props
	const { markConversationAsReadForGroup } = props

	const { SEND_MESSAGE_PLACEHOLDER, MESSAGES, DFT_MESSAGES_TEXT } = config.strings

	const [pendingMessages, setPendingMessages] = useState<MessageDetails[]>([])

	const onSendMessage = msg => {
		const timestamp = getTime()
		const from = teamNameFromColour(groupColour)
		const msgDetails: MessageDetails = { ...msg, participantName, participantId, groupId, timestamp, from }
		setPendingMessages([...pendingMessages, msgDetails])
	}

	const messagesWithPending = [...messages, ...pendingMessages].sort(sortAscending)

	// Ensure that we scroll to the bottom if a new message is added.
	const scroller = useAutoScroll(messagesWithPending.length)

	useEffect(() => {
		if (messages.length > prevMessages.length) {
			const newMessages = messages.filter((m, i) => i >= prevMessages.length)
			newMessages.forEach(m => {
				if (m.tutor) {
					ConfirmationHelper.alert('Incoming Message', '', 'OK', '')
				}
			})
		}
		setPrevMessages(messages)

		const filtered = pendingMessages.filter(pm => !messages.find(m => m.id === pm.id))

		setPendingMessages(filtered)
	}, [messages, prevMessages, setPrevMessages])

	const showInfo = messagesWithPending.length === 0

	const onCloseClick = () => {
		markConversationAsReadForGroup()
		onClose()
	}

	const messageElems = messagesWithPending.map(m => (
		<MessageElem
			key={m.timestamp}
			from={m.from}
			message={m.message}
			timestamp={m.timestamp}
			to={m.to}
			tutor={m.tutor}
			groupId={m.groupId}
			observerId={m.observerId}
			participantId={m.participantId}
			participantName={m.participantName}
			translation={m.translated && m.translation !== m.message && m.translation}
		/>
	))

	return (
		<DivWithInert className="messages-app" inert={!active}>
			<div className="messages-app__overlay" onClick={onCloseClick} aria-hidden />
			<Row>
				<h2>{MESSAGES}</h2>
				<IconButton iconName="close" onClick={onCloseClick} />
			</Row>
			<div className="messages-app__content">
				{showInfo && <p>{DFT_MESSAGES_TEXT}</p>}
				<div ref={scroller} className="messages-app__scroller">
					{messageElems}
				</div>
				{active && (
					<MessageEntry
						toTutor
						groupId={groupId}
						onSendMessage={onSendMessage}
						placeholder={SEND_MESSAGE_PLACEHOLDER}
					/>
				)}
			</div>
		</DivWithInert>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const messages = messagesForGroupSelector(state)

	const group = state?.group || ({} as GroupDetails)
	const { viewingPreviousSession } = state
	const session = currentSessionSelector(state)
	const groupId = group.id
	const groupColour = group.colour
	const { participantId } = group
	const participantName = participantId && group.name
	return { messages, groupId, groupColour, participantName, viewingPreviousSession, session, participantId }
}

const actions = {
	markConversationAsReadForGroup: Actions.messages.markConversationAsReadForGroup,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<MessagesAppProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(MessagesApp)
