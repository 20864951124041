/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */

import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import config from '../../config'

import Actions from '../actions'

import MediaLibrary from '../core/MediaLibrary'
import mime from '../util/mime-types'

import IncomingItemToast from './IncomingItemToast'
import IconButton from '../elements/IconButton'
import Preloader from '../elements/Preloader'
// import PDFViewer from './PDFViewer'
import API from '../core/OnlineComms'

const { IMAGE_TYPES, VIDEO_TYPES, AUDIO_TYPES } = config

// =================================================================================================

const Video: FunctionComponent<{ src: string }> = ({ src }) => (
	<video autoPlay controls disablePictureInPicture controlsList="nodownload nofullscreen noremoteplayback">
		<source src={src} />
	</video>
)

// =================================================================================================

const Audio: FunctionComponent<{ src: string }> = ({ src }) => (
	<audio controls controlsList="nodownload">
		<source src={src} />
	</audio>
)

// =================================================================================================

const PDF: FunctionComponent<{ src: string }> = ({ src }) => (
	<iframe
		className="pdf-iframe"
		title="Document"
		// src={`data:application/pdf;base64,${_arrayBufferToBase64(src)}#toolbar=0`}
		src={`${src}#toolbar=0 `}
	/>
)

const MemoizedPDF = React.memo(PDF)

// =================================================================================================

type MediaModalProps = {
	media: MediaItem
	markMediaAsSeen: (id: string) => void
	getMediaData: (id: string) => Promise<Blob>
	displayModalPopup: (popupId: string, parms?: SimpleObject | string) => void
}

type MediaModalState = {
	mediaUrl?: string
	loading?: boolean
	error?: string
}

function getMediaUrlForData(data, extension) {
	let mediaUrl
	// data = blob or arrayBuffer
	if (extension.toLowerCase() === '.pdf' && data instanceof ArrayBuffer) {
		mediaUrl = data
	} else {
		// data = blob
		const type = mime.lookup(`file${extension}`)
		const blob = new Blob([data], { type })
		const urlCreator = window.URL || window.webkitURL
		mediaUrl = urlCreator.createObjectURL(blob)
	}
	return mediaUrl
}
class MediaModal extends React.Component<MediaModalProps, MediaModalState> {
	constructor(props) {
		super(props)
		this.state = { loading: true }
	}

	componentDidMount() {
		const { media = {} as MediaItem, markMediaAsSeen, getMediaData } = this.props
		if (!media.id) return

		markMediaAsSeen(media.id)

		if (media.extension === '.pdf') {
			API.getMediaUrl('', media.id).then(response => {
				this.setState({ mediaUrl: response.url, loading: false })
			})
		} else {
			// If we already have this media cached, get the data URL for it
			const mediaFromLibrary = MediaLibrary.get(media.id)
			if (mediaFromLibrary) {
				this.setState({ mediaUrl: getMediaUrlForData(mediaFromLibrary.data, media.extension), loading: false })
				return
			}

			// We need to get the media data/URL and then update this in state so we can re-render
			getMediaData(media.id)
				.then(data => {
					this.setState({ mediaUrl: getMediaUrlForData(data, media.extension), loading: false })
				})
				.catch(err => {
					console.error(err)
					this.setState({ mediaUrl: null, loading: false, error: 'Media could not be loaded' })
				})
		}
	}

	renderMedia(mediaUrl, extension) {
		const ext = extension.toLowerCase()
		// if (ext === '.pdf') return <PDFViewer src={mediaUrl} />
		if (ext === '.pdf') return <MemoizedPDF src={mediaUrl} />
		if (IMAGE_TYPES.includes(ext)) return <img src={mediaUrl} alt="" />
		if (VIDEO_TYPES.includes(ext)) return <Video src={mediaUrl} />
		if (AUDIO_TYPES.includes(ext)) return <Audio src={mediaUrl} />

		// Unknown
		return <div>{config.strings.CONTENT_FORMAT_NOT_RECOGNISED}</div>
	}

	render() {
		const { displayModalPopup, media } = this.props
		const { mediaUrl, error, loading } = this.state
		const close = () => displayModalPopup('')

		const onClickMain = e => {
			if (e.target === e.currentTarget) close()
			// If media is an image, allow clicking on the image to close it
			if (IMAGE_TYPES.includes(media.extension)) close()
		}

		return (
			<div className="media-modal">
				<IncomingItemToast />
				<IconButton iconName="close" onClick={close} />
				<div className="media-modal__main" onClick={onClickMain}>
					{loading ? <Preloader /> : null}
					{error ? <div>{error}</div> : null}
					{!loading && !error ? this.renderMedia(mediaUrl, media.extension) : null}
				</div>
				<div className="media-modal__bottom">
					<h2>{media.name}</h2>
				</div>
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	media: state.modalParms,
})
const actions = {
	markMediaAsSeen: Actions.media.markMediaAsSeen,
	displayModalPopup: Actions.misc.displayModalPopup,
	getMediaData: Actions.media.getMediaData,
}
export default connect(mapStateToProps, actions)(MediaModal)
