import React, { FunctionComponent, useEffect, useRef } from 'react'

type DivWithInertProps = {
	inert?: boolean
} & React.HTMLAttributes<HTMLDivElement>

// =================================================================================================
// React doesn't yet support the 'inert' attribute, so we have created our own <div> component that
// does support it.
// (see: https://github.com/facebook/react/pull/24730)
// =================================================================================================

const DivWithInert: FunctionComponent<DivWithInertProps> = props => {
	const { inert, children, ...rest } = props

	// Set element 'inert' attribute based on 'inert' prop.
	const rootRef = useRef(null)
	useEffect(() => {
		rootRef.current.inert = inert
	}, [inert])

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div ref={rootRef} {...rest}>
			{children}
		</div>
	)
}

export default DivWithInert
