import React, { FunctionComponent } from 'react'

const VirtualPhoneFrame: FunctionComponent = props => (
	<svg
		className="virtual-phone-frame"
		preserveAspectRatio="xMaxYMid slice"
		viewBox="0 0 170 340"
		width="100%"
		height="100%"
	>
		<g transform="translate(-274.95 -56.6)">
			<path
				className="virtual-phone-frame__bezel"
				fill="#000000"
				d="M433.75 65.7q-6.958-6.953-16.8-6.95h-114q-9.842-.003-16.8 6.95-6.953 6.958-6.95 16.8v288q-.003 9.842 6.95 16.8 6.958 6.953 16.8 6.95h114q9.842.003 16.8-6.95 6.953-6.958 6.95-16.8v-288q.003-9.842-6.95-16.8m-3.9 3.9q5.347 5.342 5.35 12.9v288q-.003 7.558-5.35 12.9-5.342 5.347-12.9 5.35h-114q-7.558-.003-12.9-5.35-5.347-5.342-5.35-12.9v-288q.003-7.558 5.35-12.9 5.342-5.347 12.9-5.35h114q7.558.003 12.9 5.35z"
			/>
		</g>
		<path
			className="virtual-phone-frame__buttons"
			stroke="#000000"
			strokeWidth="4"
			strokeLinejoin="round"
			strokeLinecap="round"
			fill="none"
			d="M165.45 73.65v28.25M4.45 73v22.6m0 12.3v22.6"
		/>
		{/* Notch:
		<path
			fill="#2C353C"
			d="M125.55 11.85v-4.2H44.5v4.2q.1 3.2 2.4 5.5 2.4 2.4 5.8 2.4h64.65q3.4 0 5.8-2.4 2.3-2.3 2.4-5.5z"
		/>
		<path
			fill="#3C4652"
			d="M99.7 13.45q0-.9-.75-1.65-.75-.75-1.65-.75-1 0-1.7.75t-.7 1.65q0 1 .7 1.7.7.75 1.7.75.9 0 1.65-.75.75-.7.75-1.7z"
		/> 
		<path
			stroke="#3C4652"
			strokeWidth="4"
			strokeLinejoin="round"
			strokeLinecap="round"
			fill="none"
			d="M73.35 13.45h15.1"
		/> */}
	</svg>
)

export default VirtualPhoneFrame
