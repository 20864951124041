export type NotificationData = ScheduleRow & {
	time: number
	rowId: string
}

type SessionInstance = CurrentSession | PreviousSession

function mediaFeedSelector(groupId: string, session: SessionInstance, releases: Release[]): NotificationData[] {
	const schedule = session.schedule || []

	// Helper function - for any particular row, returns the timestamp it was
	// released to the current group (if it has been released)
	const getTimeReleased = (row: ScheduleRow) => {
		const _groupId = String(groupId)
		const releasesForRow = releases.filter(release => release.rowId === row.id)
		const releaseToGroup = releasesForRow.find(r => r.groupId === _groupId || r.groupId === 'ALL')
		return releaseToGroup && releaseToGroup.timestamp
	}

	const newsfeed = schedule.map(r => ({ ...r, time: getTimeReleased(r), rowId: r.id }))
	return newsfeed.filter(r => Boolean(r.time))
}

export default mediaFeedSelector
