import React from 'react'

type TabProps = {
	onClick?: () => void
	id: string
	className?: string
	active?: boolean
	children: React.ReactNode
}

export default function Tab(props: TabProps): JSX.Element {
	const { onClick, className = '', children, active } = props
	const _className = `tab ${active ? 'tab--active' : 'tab--inactive'} ${className}`
	return (
		<div className={_className} onClick={onClick}>
			<h3>{children}</h3>
		</div>
	)
}
