const MEDIA_ADD_NEW_SUCCESS: Reducer = (state, action) => {
	const openCase = { ...state.openCase }
	openCase.media = [...(openCase.media || []), action.payload]
	const newState = { ...state, openCase }
	return newState
}

const MEDIA_MARK_AS_SEEN: Reducer = (state, action) => ({
	...state,
	mediaSeen: [...(state.mediaSeen || []), action.payload],
})

const MEDIA_DELETE: Reducer = (state, action) => {
	const mediaId = action.payload
	const openCase = { ...state.openCase }
	openCase.media = openCase.media.filter(m => m.id !== mediaId).map(m => ({ ...m }))
	return { ...state, openCase }
}

const MEDIA_LOADING_ITEMS: Reducer = state => {
	const openCase = { ...state.openCase }
	const newState = { ...state, loadingMedia: true, openCase }
	return newState
}

const MEDIA_LOADED_ITEMS: Reducer = (state, action) => {
	const openCase = { ...state.openCase }
	openCase.media = action.payload as MediaItem[]
	const newState = { ...state, loadingMedia: null, openCase }
	return newState
}

const MEDIA_CLOSE: Reducer = state => {
	const newState = { ...state, mediaExpanded: false }
	delete newState.selectedScheduleRowId
	return newState
}

const MEDIA_UPDATED: Reducer = (state, action) => {
	const { mediaId, data } = action.payload as { mediaId: string; data: Partial<MediaItem> }
	const openCase = { ...state.openCase }
	openCase.media = [...(openCase.media || [])].map(m => ({ ...m }))
	const index = openCase.media.findIndex(m => m.id === mediaId)
	if (index >= 0) {
		openCase.media[index] = { ...openCase.media[index], ...data }
	}
	return { ...state, openCase }
}

const MEDIA_EXPANDED: Reducer = (state, { payload }) => ({ ...state, mediaExpanded: payload ?? true })

// =================================================================================================

const reducers = {
	MEDIA_ADD_NEW_SUCCESS,
	MEDIA_LOADING_ITEMS,
	MEDIA_MARK_AS_SEEN,
	MEDIA_LOADED_ITEMS,
	MEDIA_EXPANDED,
	MEDIA_UPDATED,
	MEDIA_DELETE,
	MEDIA_CLOSE,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
