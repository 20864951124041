import { connect } from 'react-redux'
import React from 'react'

import actions from '../actions'
import OnlineComms from '../core/OnlineComms'
import MediaLibrary from '../core/MediaLibrary'
import sessionSelector from '../selectors/session'

// At this stage the MediaLibrary is just an array of objects with a maximum length.
// Any items added to the list once it has reached it's maximum size will result
// in the oldest items being dropped.

const QUEUE_CHECK_INTERVAL = 2000

type MediaCacheProps = {
	session: Session
	media: Array<MediaItem>
}

class MediaCache extends React.Component<MediaCacheProps> {
	public cache: Array<MediaItem>
	private queue: Array<string>
	private interval: number
	private busy: boolean

	constructor(props: MediaCacheProps) {
		super(props)
		this.cache = []
		this.queue = []
		this.interval = window.setInterval(this._onTick.bind(this), QUEUE_CHECK_INTERVAL)
	}

	componentWillUnmount() {
		window.clearInterval(this.interval)
		this.cache = []
		this.queue = []
	}

	_onTick() {
		if (this.busy || this.queue.length === 0) return
		const mediaId = this.queue.shift()

		if (MediaLibrary.get(mediaId)) return

		this.busy = true

		OnlineComms.getMediaData(mediaId)
			.then(dataToStore => {
				const mediaItem = { id: mediaId, data: dataToStore as Blob }
				MediaLibrary.push(mediaItem)
			})
			.catch(err => {
				console.error(err)
			})
			.then(_ => {
				this.busy = false
			})
	}

	render() {
		// Handle new props in here
		const { session, media } = this.props

		// This will give us an array which is just media item IDs as strings
		const mediaInCurrentSession = (session.schedule || []).filter(r => r.mediaitem).map(r => r.mediaitem)

		const mediaInCurrentCase = (media || []).map(m => m.id || m.key)

		mediaInCurrentSession.concat(mediaInCurrentCase).forEach(mediaId => {
			if (!MediaLibrary.get(mediaId)) {
				this.queue.push(mediaId)
			}
		})
		return null // nothing rendered
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<MediaCacheProps> => {
	const session = sessionSelector(state)
	const groupData = state.groupData || {}
	const currentCase: OpenCaseDetails = groupData.currentCase || state.openCase || ({} as OpenCaseDetails)
	const media = currentCase.media || []

	return { session, media }
}
export default connect(mapStateToProps, actions)(MediaCache)
