import ConfirmationHelper from '../util/ConfirmationHelper'
import OnlineComms from '../core/OnlineComms'
import action from '../util/action'
import config from '../../config'
import types from './types'

const { PASSCODE_DELETED_MSG, PASSCODE_DELETED_TITLE, CONFIRM_DELETE_PASSWORD } = config.strings
const {
	OBSERVER_PASSWORD_UPDATED,
	OBSERVER_PASSWORDS_LOADING,
	OBSERVER_PASSWORDS_LOADED,
	OBSERVER_PASSWORD_CREATING,
	OBSERVER_PASSWORD_CREATED,
	OBSERVER_PASSWORD_DELETED,
} = types

let holdoff = false

const getPasswords: ActionCreator = () => dispatch => {
	if (holdoff) return
	dispatch(action(OBSERVER_PASSWORDS_LOADING))
	OnlineComms.getLinkPasswords()
		.then(passwords => {
			if (holdoff) return
			dispatch(action(OBSERVER_PASSWORDS_LOADED, passwords))
		})
		.catch(err => {
			console.error(err)
			dispatch(action(OBSERVER_PASSWORDS_LOADED, []))
		})
}

const generatePassword: ActionCreator = () => dispatch => {
	dispatch(action(OBSERVER_PASSWORD_CREATING))
	OnlineComms.addNewLinkPassword()
		.then(password => dispatch(action(OBSERVER_PASSWORD_CREATED, password)))
		.catch(err => {
			console.error(err)
			ConfirmationHelper.alert('Could not create new passcode. Please try again.')
		})
}

const deletePassword: ActionCreator<string> = key => dispatch => {
	ConfirmationHelper.confirm(CONFIRM_DELETE_PASSWORD, async () => {
		// Optimism
		dispatch(action(OBSERVER_PASSWORD_DELETED, key))
		holdoff = true
		try {
			await OnlineComms.deleteLinkLinkPassword(key)
			ConfirmationHelper.successMessage(PASSCODE_DELETED_TITLE, PASSCODE_DELETED_MSG)
		} catch (err) {
			console.error('Error deleting linked access passcode: ', err)
			ConfirmationHelper.alert('Passcode could not be deleted. Please try again.')
			holdoff = false
			getPasswords()
		}
		holdoff = false
	})
}

const updatePassword: ActionCreator<string, SimpleObject> = (key, data) => async dispatch => {
	holdoff = true
	dispatch(action(OBSERVER_PASSWORD_UPDATED, { key, data }))
	try {
		await OnlineComms.updateLinkPassword(key, data)
	} catch (err) {
		console.error(err)
	}
	holdoff = false
}

const actions = {
	getPasswords,
	generatePassword,
	deletePassword,
	updatePassword,
}

export default actions
