import React from 'react'

type TabsRowProps = {
	activeTab: string | number
	children: React.ReactNode
}

export default function TabsRow(props: TabsRowProps): JSX.Element {
	const { children, activeTab } = props
	const _children = (children || []).map((c, i) => {
		const { id } = c.props
		const isActive = typeof activeTab === 'number' ? activeTab === i : activeTab === id
		return React.cloneElement(c, { active: isActive, key: id })
	})
	return <div className="tab__row">{_children}</div>
}
