/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import md5 from 'md5'

import config from '../../config'
import Actions from '../actions'

import onChangeDecisionLog, { UpdateDecisionLog } from '../util/onChangeDecisionLog'
import DecisionLogEntryRow from '../elements/DecisionLogEntryRow'
import { sessionIsActive } from '../util/SessionHelpers'
import useAutoScrollBottomParticipant from '../util/useAutoScrollBottomParticipant'

type DecisionLogListProps = {
	editingDecisionLog: boolean

	participantId: string
	currAuthorId: string
	currAuthor: string
	language: string

	participants: string[]
	advice: string[]
	rationale: string[]
	languages: string[]
	authors: string[]
	times: number[]

	decisionLogTranslations: Translation[]

	updateDecisionLog: UpdateDecisionLog
	removeDecisionLog: (index) => void

	sessionActive: boolean
	sessionPaused: boolean
}

const DecisionLogList: FunctionComponent<DecisionLogListProps> = props => {
	const { editingDecisionLog, participantId, removeDecisionLog, updateDecisionLog, currAuthor } = props
	const { sessionActive, sessionPaused, currAuthorId, language } = props
	const { advice, rationale, times, authors, participants, languages } = props
	const { decisionLogTranslations } = props

	const { DECISION_LOG_HELP_TEXT, NO_DECISION_LOGS, ADVICE, RATIONALE } = config.strings

	// const translatedDecisionLog = props.translatedDecisionLog || {}
	// const translatedAdvice = translatedDecisionLog.advice || []
	// const translatedRationale = translatedDecisionLog.rationale || []

	const numRows = Math.max(advice.length, rationale.length)

	let indexToHide = -1
	if (editingDecisionLog) {
		indexToHide = Math.max(...participants.map((p, i) => (p === participantId ? i : -1)))
	}

	// Use autoScroll to scroll to the bottom if a new row is added
	const scroller = useAutoScrollBottomParticipant(numRows)

	// Map the advice and rationale to rows of text areas
	const rows = []
	for (let r = 0; r < numRows; r++) {
		if (sessionActive && r === indexToHide) continue

		let enabled = sessionActive && !sessionPaused
		// If you are not the current author, you cannot edit anything
		if (currAuthorId && currAuthorId !== participantId) enabled = false
		// If there is no selected author (decision maker) then no one can edit anything
		if (!currAuthorId && !currAuthor) enabled = false
		// If you are not the author of the current row, you cannot edit it
		if (participantId && participantId !== participants[r]) enabled = false
		if (!currAuthorId && currAuthor !== authors[r]) enabled = false

		const pId = participantId

		const updDecLog = updateDecisionLog
		const onChangeAdvice = e => onChangeDecisionLog(props, updDecLog, currAuthor, pId, language, 'advice', r, e)
		const onChangeRationale = e => onChangeDecisionLog(props, updDecLog, currAuthor, pId, language, 'rationale', r, e)

		const translationKeyAdvice = md5(languages[r] + advice[r])
		const translatedAdvice = decisionLogTranslations.find(t => t.key === translationKeyAdvice)?.text
		const translationKeyRationale = md5(languages[r] + rationale[r])
		const translatedRationale = decisionLogTranslations.find(t => t.key === translationKeyRationale)?.text

		rows.push(
			<DecisionLogEntryRow
				key={r}
				index={r}
				time={times[r]}
				advice={advice[r]}
				author={authors[r]}
				rationale={rationale[r]}
				onChangeAdvice={onChangeAdvice}
				onChangeRationale={onChangeRationale}
				onRemove={() => removeDecisionLog(r)}
				translatedAdvice={translatedAdvice}
				translatedRationale={translatedRationale}
				enabled={enabled}
			/>
		)
	}

	// <p>Your team’s decisions and rationale will appear here.</p>
	// <p>You will be able to edit your own decisions and rationale by clicking on them.</p>

	if (sessionActive && rows.length === 0) {
		return (
			<div className="decision-log">
				{DECISION_LOG_HELP_TEXT.split('. ').map(str => (
					<p key={str}>{str}</p>
				))}
			</div>
		)
	}

	if (rows.length === 0) {
		return (
			<div className="decision-log">
				<p>{NO_DECISION_LOGS}</p>
			</div>
		)
	}

	return (
		<div className="decision-log">
			<div className="decision-log__header">
				<h3>{ADVICE}</h3>
				<h3>{RATIONALE}</h3>
			</div>
			<div ref={scroller} className="decision-log__scroller">
				<div className="decision-log__inner">{rows}</div>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<DecisionLogListProps> => {
	const { viewingPreviousSession, editingDecisionLog, decisionLogTranslations = [] } = state

	const currentSession = state?.groupData?.currentSession || state?.currentSession

	const groupId = state?.group?.id
	const previousSession = (viewingPreviousSession && state.previousSession) || ({} as PreviousSession)
	const prevSessGroups = previousSession.groups || []
	const groupInPrevSession = prevSessGroups.find(g => g.id === groupId)

	const sessionActive = !viewingPreviousSession && sessionIsActive(currentSession)
	const sessionPaused = !viewingPreviousSession && state?.groupData?.currentSession?.paused

	const group = groupInPrevSession || state.group || ({} as GroupDetails)
	const { currAuthor, currAuthorId, participantId, language } = group

	return {
		participants: group.participants || [],
		rationale: group.rationale || [],
		languages: group.languages || [],
		authors: group.authors || [],
		advice: group.advice || [],
		times: group.times || [],
		decisionLogTranslations,
		editingDecisionLog,
		participantId,
		sessionPaused,
		sessionActive,
		currAuthorId,
		currAuthor,
		language,
	}
}
const actions = {
	updateDecisionLog: Actions.groups.updateDecisionLog,
	removeDecisionLog: Actions.groups.removeDecisionLog,
}

export default connect(mapStateToProps, actions)(DecisionLogList)
