const BASE_DATA_URL = 'https://view360-speedtest-data.s3.eu-west-2.amazonaws.com'
let active = false
const performSpeedTest = () => async () => {
	if (active) return
	active = true
	console.log('Performing speed test...')
	const results = []
	// Initial pings - Do not include these in results
	for (let i = 0; i < 3; i++) await ping()
	// Perform 20 pings of the server
	for (let i = 0; i < 20; i++) results.push(await ping())
	// Calculate average
	const averagePing = Math.round(results.reduce((acc, val) => acc + val, 0) / results.length)

	console.log(`performSpeedTest -> averagePing`, averagePing)

	const dataResults = []

	async function datafetch(size) {
		const start = Date.now()
		const headers = { 'Accept-Encoding': '' } // disable gzip
		await fetch(`${BASE_DATA_URL}/${size}.txt`, { headers }).then(res => res.text())
		const end = Date.now()

		const duration = end - start - averagePing
		return duration
	}

	// We need to attempt to retrieve as much data from the server as we can in a reasonable amount
	// of time. We can do this by downloading a small file, and then incrementing the size of the
	// next file to retrieve based on how long we think it's going to download, based on the
	// performance so far.

	// Fetch 100k file
	const duration100k = await datafetch(100000)
	dataResults.push({ size: 100000, duration: duration100k })
	console.log(`performSpeedTest -> duration100k`, duration100k)
	if (duration100k < 2000) {
		// i.e. > 400kbps
		const duration1M = await datafetch(1000000)
		console.log(`performSpeedTest -> duration1M`, duration1M)
		dataResults.push({ size: 1000000, duration: duration1M })
		if (duration1M < 4000) {
			// i.e. > 2Mbps
			// Simultaneous download of 1 * 10M
			const size = 1000000 // 1M
			const resultsArr = await Promise.all([datafetch(size), datafetch(size), datafetch(size)])
			dataResults.push({ size: size * resultsArr.length, duration: Math.max(...resultsArr) })
		}
		if (duration1M < 500) {
			// i.e. > 16Mbps
			// Simultaneous download of 3 * 10M
			const size = 10000000 // 10M
			const resultsArr = await Promise.all([datafetch(size), datafetch(size), datafetch(size)])
			dataResults.push({ size: size * resultsArr.length, duration: Math.max(...resultsArr) })
		} else if (duration1M < 2000) {
			// i.e. > 4Mbps
			const duration10M = await datafetch(10000000)
			console.log(`performSpeedTest -> duration10M`, duration10M)
			dataResults.push({ size: 10000000, duration: duration10M })
		}
	} else if (duration100k < 5000) {
		// Try 100k again
		dataResults.push({ size: 100000, duration: await datafetch(100000) })
	}

	// Calculate total size and duration of downloads
	const downloadResult = dataResults.reduce(
		(acc, val) => ({ size: acc.size + val.size, duration: acc.duration + val.duration }),
		{ size: 0, duration: 0 }
	)
	// Calculate bits per second
	const downloadBitsPerSecond = Math.round((downloadResult.size * 8) / (downloadResult.duration / 1000))
	// Convert to Mbps
	const mbps = Math.round(downloadBitsPerSecond / 10000) / 100

	console.log(`Download speed: ${mbps}mbps`)
	active = false
}

async function ping() {
	const start = Date.now()
	await fetch(`${BASE_DATA_URL}/0.txt`, { method: 'head' })
	const end = Date.now()
	const duration = end - start
	return duration
}

export default { performSpeedTest }
