/* eslint-disable prefer-destructuring */
// =================================================================================================
// Config
// =================================================================================================
import EN from './strings-en'

import AR from './strings-ar' // Arabic
import BG from './strings-bg' // Bulgarian
import BS from './strings-bs' // Bosnian
import CY from './strings-cy' // Welsh
import FR from './strings-fr' // French
import HR from './strings-hr' // Croatian
import MK from './strings-mk' // Macedonian
import MS from './strings-ms' // Malaysian
import NL from './strings-nl' // Dutch
import RO from './strings-ro' // Romanian
import RU from './strings-ru' // Russian
import SQ from './strings-sq' // Albanian
import SR from './strings-sr' // Serbian
import SW from './strings-sw' // Swahili
import UK from './strings-uk' // Ukrainian
import VI from './strings-vi' // Vietnamese
import ZHCN from './strings-zh-cn' // Chinese - Simplified
import ZHTW from './strings-zh-tw' // Chinese - Traditional
import FA from './strings-fa' // Farsi

/** 
 * Helper
 @param {string} str String to be checked
 @returns boolean True if `str` is "true", otherwise false.
*/
const isTrue = str => str === 'true' || false

// NOTE: For Vite, change 'process.env' to 'import.meta.env'
const SERVER_URL = process.env.SERVER_URL
const SERVER_URL_AUTH = process.env.SERVER_URL_AUTH
const SERVER_URL_CASES = process.env.SERVER_URL_CASES
const SERVER_URL_MEDIA = process.env.SERVER_URL_MEDIA
const SERVER_URL_NOTES = process.env.SERVER_URL_NOTES
const SERVER_URL_RELEASES = process.env.SERVER_URL_RELEASES
const SERVER_URL_SESSIONS = process.env.SERVER_URL_SESSIONS
const SERVER_URL_MESSAGING = process.env.SERVER_URL_MESSAGING
const SERVER_URL_PASSWORDS = process.env.SERVER_URL_PASSWORDS
const SERVER_URL_VIDEOCONF = process.env.SERVER_URL_VIDEOCONF
const SERVER_URL_PARTICIPANTS = process.env.SERVER_URL_PARTICIPANTS
const SERVER_URL_PREVSESSIONS = process.env.SERVER_URL_PREVSESSIONS

const REGION = process.env.REGION
const SHOW_IP = process.env.SHOW_IP
const DESKTOP = process.env.DESKTOP
const SENTRY_ID = process.env.SENTRY_ID
const MEDIA_ACL = process.env.MEDIA_ACL
const SENTRY_ENV = process.env.SENTRY_ENV
const UPLOAD_TYPE = process.env.UPLOAD_TYPE
const AGORA_APP_ID = process.env.AGORA_APP_ID
const MEDIA_BUCKET = process.env.MEDIA_BUCKET
const LOGROCKET_ID = process.env.LOGROCKET_ID
const SOCKET_SERVER = process.env.SOCKET_SERVER
const SOCKET_SERVER_URL = process.env.SOCKET_SERVER_URL
const VIDEO_TEST_DOMAIN = process.env.VIDEO_TEST_DOMAIN
const VIDEO__NEW_COMPONENTS = process.env.VIDEO__NEW_COMPONENTS
const MEDIA_UPLOAD_ACCESS_KEY = process.env.MEDIA_UPLOAD_ACCESS_KEY
const SIGNED_URLS_FOR_DOWNLOADS = process.env.SIGNED_URLS_FOR_DOWNLOADS
const SHOW_INFO_IN_WINDOW_TITLE = process.env.SHOW_INFO_IN_WINDOW_TITLE

const NOTEPAD_ENABLED = isTrue(process.env.NOTEPAD_ENABLED)
const PHONE_MESSAGES_ENABLED = isTrue(process.env.PHONE_MESSAGES_ENABLED)
const AGORA_ENABLED = isTrue(process.env.AGORA_ENABLED)
const TWILIO_ENABLED = isTrue(process.env.TWILIO_ENABLED)

const NODE_ENV = process.env.NODE_ENV

const STAGING = process.env.STAGING

// The ability to change the audio output (for video conferencing) is only possible if your
// browser supports the `setSinkId` method on the `HTMLAudioElement` prototype. So we'll create an
// audio element briefly to check if it's available.
const audioElement = document.createElement('audio')
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CHANGE_AUDIO_OUTPUT_SUPPORTED = typeof audioElement.setSinkId === 'function'
audioElement.remove()

const config = {
	// App
	GROUP_FETCH_FREQUENCY: 3000, // Milliseconds
	GROUP_FETCH_DECISION_LOG_FREQUENCY: 1800, // Milliseconds
	MIN_SESSION_DURATION: 1,
	MAX_SESSION_DURATION: 120,

	strings: EN,

	LANGUAGE_PACKS: { EN, AR, BG, BS, CY, FR, HR, MK, MS, NL, RO, RU, SQ, SR, SW, UK, VI, ZHCN, ZHTW, FA },

	//prettier-ignore
	TEAM_COLOURS: [ 'violet', 'pink', 'magenta', 'maroon', 'crimson', 'ruby', 'coral', 'orange', 'amber', 'gold', 'olive', 'green', 'teal', 'cyan', 'cobalt', 'blue', 'navy', 'indigo', 'purple', 'charcoal', 'grey', 'silver' ],

	// ===============================================================================================
	// Bring in environment variables. They need to be specified explicitly. This will not work:
	//...import.meta.env,
	// ===============================================================================================

	SERVER_URL,
	SOCKET_SERVER_URL,

	SERVER_URL_AUTH: SERVER_URL_AUTH || SERVER_URL,
	SERVER_URL_CASES: SERVER_URL_CASES || SERVER_URL,
	SERVER_URL_MEDIA: SERVER_URL_MEDIA || SERVER_URL,
	SERVER_URL_NOTES: SERVER_URL_NOTES || SERVER_URL,
	SERVER_URL_RELEASES: SERVER_URL_RELEASES || SERVER_URL,
	SERVER_URL_SESSIONS: SERVER_URL_SESSIONS || SERVER_URL,
	SERVER_URL_MESSAGING: SERVER_URL_MESSAGING || SERVER_URL,
	SERVER_URL_PASSWORDS: SERVER_URL_PASSWORDS || SERVER_URL,
	SERVER_URL_VIDEOCONF: SERVER_URL_VIDEOCONF || SERVER_URL,
	SERVER_URL_PARTICIPANTS: SERVER_URL_PARTICIPANTS || SERVER_URL,
	SERVER_URL_PREVSESSIONS: SERVER_URL_PREVSESSIONS || SERVER_URL,

	STAGING,

	REGION,
	NODE_ENV,
	SENTRY_ID,
	MEDIA_ACL,
	SENTRY_ENV,
	AGORA_APP_ID,
	LOGROCKET_ID,
	MEDIA_BUCKET,
	VIDEO_TEST_DOMAIN,
	MEDIA_UPLOAD_ACCESS_KEY,

	UPLOAD_TYPE: UPLOAD_TYPE || 's3',

	IMAGE_TYPES: ['.png', '.jpg', '.jpeg', '.gif', '.tif'],
	VIDEO_TYPES: ['.mp4'],
	AUDIO_TYPES: ['.m4a', '.mp3'],

	UPDATE_ITEM_ICON_TYPE: 'mail',

	SHOW_IP: isTrue(SHOW_IP),
	DESKTOP: isTrue(DESKTOP),
	SOCKET_SERVER: isTrue(SOCKET_SERVER),
	SHOW_INFO_IN_WINDOW_TITLE: isTrue(SHOW_INFO_IN_WINDOW_TITLE),
	SIGNED_URLS_FOR_DOWNLOADS: isTrue(SIGNED_URLS_FOR_DOWNLOADS),

	VIDEO__NEW_COMPONENTS: isTrue(VIDEO__NEW_COMPONENTS),
	CHANGE_AUDIO_OUTPUT_SUPPORTED,

	PHONE_MESSAGES_ENABLED,
	NOTEPAD_ENABLED,
	TWILIO_ENABLED,
	AGORA_ENABLED,
}

export default config
