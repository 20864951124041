import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import config from '../../config'

import { sessionIsActive } from '../util/SessionHelpers'
import DecisionLogList from './DecisionLogList'
import AddDecisionLog from './AddDecisionLog'

type ParticipantDecisionLogProps = {
	sessionActive?: boolean
	showTitle?: boolean
	id?: string
}

const ParticipantDecisionLog: FunctionComponent<ParticipantDecisionLogProps> = props => {
	const { sessionActive, showTitle = true } = props
	const { DECISION_LOG } = config.strings

	return (
		<div className="participant-decision-log" aria-label={DECISION_LOG}>
			<div className="participant-decision-log__top">
				{showTitle && <h2>{DECISION_LOG}</h2>}
				<DecisionLogList />
			</div>
			{sessionActive ? <AddDecisionLog /> : null}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const { viewingPreviousSession } = state

	const currentSession = state?.groupData?.currentSession || state?.currentSession
	const sessionActive = !viewingPreviousSession && sessionIsActive(currentSession)

	return { sessionActive }
}
const actions = {}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<ParticipantDecisionLogProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(ParticipantDecisionLog)
