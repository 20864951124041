import React, { FunctionComponent } from 'react'

import config from '../../config'
import InitialInformation from '../components/InitialInformation'

import BubbleHeading from '../elements/BubbleHeading'

const InitialInformationPage: FunctionComponent = () => {
	const { INITIALINFORMATIONPAGE } = config.strings
	return (
		<div className="initial-information-page">
			<div className="initial-information-page__top">
				<BubbleHeading>{INITIALINFORMATIONPAGE}</BubbleHeading>
			</div>
			<div className="initial-information-page__content">
				<InitialInformation />
			</div>
		</div>
	)
}

export default InitialInformationPage
