import React, { FunctionComponent, KeyboardEvent, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import config from '../../config'
import Button from './Button'
import getTextDirection from '../util/getTextDirection'

type MessageEntryElemProps = {
	enabled?: boolean
	placeholder?: string
	initialValue?: string
	onKeyPress?: () => unknown
	onSubmit?: (message: string) => unknown
}

const MessageEntryElem: FunctionComponent<MessageEntryElemProps> = props => {
	const { enabled, placeholder, initialValue, onSubmit } = props
	let className = 'message-entry'
	if (!enabled) className += ' message-entry--disabled'

	const [message, setMessage] = useState(initialValue)

	const onClickSubmit = () => {
		if (onSubmit) {
			onSubmit(message)
			setMessage('')
		}
	}

	const onKeyPress = (e: KeyboardEvent) => {
		if (onSubmit && e.key === 'Enter' && !e.shiftKey) {
			onClickSubmit()
			e.preventDefault()
		}
	}

	return (
		<div className={className} aria-disabled={!enabled}>
			<div className="message-entry__wrapper">
				<TextareaAutosize
					onChange={e => setMessage((e.target as HTMLTextAreaElement).value)}
					style={{ direction: getTextDirection(message) }}
					onKeyPress={onKeyPress}
					placeholder={placeholder}
					aria-label={placeholder}
					disabled={!enabled}
					value={message}
					maxRows={message ? 7 : 1} // Ensure height is one line when empty
				/>
			</div>
			<Button onClick={onClickSubmit} text={config.strings.SEND} enabled={enabled} />
		</div>
	)
}

MessageEntryElem.defaultProps = { enabled: true }

export default MessageEntryElem
