import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import VideoCallParticipantAgora from './VideoCallParticipantAgora'
import facilitatorIdSelector from '../../selectors/facilitatorId'
import { ParticipantDetails } from './VideoTypes'

import config from '../../../config'

type FullScreenSpeakerProps = {
	speaker: ParticipantDetails
	participants: Participant[]
	participantId: string
	facilitatorId: string
	interpreters?: VideoConfDetails['interpreters']
}

const FullScreenSpeaker: FunctionComponent<FullScreenSpeakerProps> = props => {
	const { speaker, participants, participantId, interpreters, facilitatorId } = props
	const { TUTOR, SUPERVISOR, INTERPRETER } = config.strings

	const pParticipantId = speaker?.properties?.participantId
	const participantDetails = participants.find(p => p.id === (speaker.isLocal ? participantId : pParticipantId))

	const pClientId = speaker?.properties?.clientId
	const interpreterDetails = interpreters.find(i => i.clientId === pClientId)
	const pIsObserver = Boolean(pClientId)
	const pIsInterpreter = Boolean(pIsObserver && interpreterDetails)
	const pIsFacilitator = speaker?.properties?.isFacilitator === 'true'
	let subheading = ''
	// Sort out subheading
	if (!pIsInterpreter && pIsObserver) {
		subheading = SUPERVISOR
	}
	if (pIsFacilitator && speaker.displayName !== TUTOR) {
		subheading = TUTOR
	}
	if (pIsInterpreter) {
		const { channel } = interpreterDetails
		subheading = `${INTERPRETER} - ${channel}`
	}
	return (
		<VideoCallParticipantAgora
			fullscreen
			volume={0}
			subheading={subheading}
			facilitatorId={facilitatorId}
			participant={speaker}
			participantDetails={participantDetails}
			key={speaker.videoTrack?.videoType} // ensure fullscreen video re-renders if videoType changes
			// error={speaker === localParticipant ? localError : ''}
		/>
	)
}

const mapStateToProps = (state: StateTree) => ({
	participants: state.participants || [],
	participantId: state?.group?.participantId,
	interpreters: state.videoconf.interpreters || [],
	facilitatorId: facilitatorIdSelector(state),
})

const actions = {}

export default connect(mapStateToProps, actions)(FullScreenSpeaker)
