import { connect } from 'react-redux'
import React from 'react'

import InputFieldManager from './InputFieldManager'
import Preloader from '../elements/Preloader'
import Button from '../elements/Button'
import Input from '../elements/Input'
import config from '../../config'
import Actions from '../actions'

type NewCaseModalProps = {
	addNewCase: (title: string, description: string) => void
	clearErrors: () => void
	savingCase?: boolean
	errors?: string[]
}

class NewCaseModal extends React.Component<NewCaseModalProps> {
	constructor(props: NewCaseModalProps) {
		super(props)
		this._onClickSave = this._onClickSave.bind(this)
		InputFieldManager.clearValue('new-case-title')
		InputFieldManager.clearValue('new-case-description')
		props.clearErrors()
	}

	componentWillUnmount() {
		const { clearErrors } = this.props
		clearErrors()
	}

	_onClickSave() {
		const { addNewCase } = this.props
		const title = InputFieldManager.getValue('new-case-title')
		const description = InputFieldManager.getValue('new-case-description')
		addNewCase(title, description)
	}

	_errorBit() {
		const { errors } = this.props
		return (errors || []).map(e => (
			<div key={e} className="error">
				{e}
			</div>
		))
	}

	render() {
		const { CASE_TITLE, CASE_DESCRIPTION, SAVE } = config.strings
		const { savingCase } = this.props
		return (
			<div className="new-case">
				<Input name="new-case-title" onEnter={this._onClickSave} label={CASE_TITLE} />
				<Input name="new-case-description" onEnter={this._onClickSave} label={CASE_DESCRIPTION} />
				{this._errorBit()}
				<div className="modal-footer">
					<Button text={SAVE} onClick={this._onClickSave} />
				</div>
				{savingCase && (
					<div className="new-case__overlay">
						<Preloader />
					</div>
				)}
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<NewCaseModalProps> => ({
	savingCase: state.savingCase,
	errors: state.errors,
})
const actions: Partial<NewCaseModalProps> = {
	addNewCase: Actions.cases.addNewCase,
	clearErrors: Actions.misc.clearErrors,
}
export default connect(mapStateToProps, actions)(NewCaseModal)
