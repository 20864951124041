/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */

// import '../style/components/_video-call-participant.scss'

import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useInterval, useUnmount } from 'react-use'

import { ParticipantDetails, ParticipantTrack } from './VideoTypes'
import { subscribe, unsubscribe } from '../../core/VideoImageCache'
import VideoElementAgora from '../../elements/VideoElementAgora'
import teamNameFromColour from '../../util/teamNameFromColour'
import convertColour from '../../util/convertColour'
import Preloader from '../../elements/Preloader'
import Icon from '../../elements/Icon'

type VideoCallParticipantAgoraProps = {
	participant: ParticipantDetails
	facilitatorId?: string
	error?: string
	audioLevel?: number
	width?: number
	height?: number
	onClick?: () => void
	volume?: number
	showName?: boolean
	displayName?: string
	subheading?: string
	fullscreen?: boolean
	uploadToImageCache?: boolean
	participantDetails?: { colour: string }
	showVolumeStat?: boolean
	selectedAudioOut?: string
	isDominantSpeaker?: boolean
}

function setOutputDevice(audioElement: HTMLAudioElement, deviceId: string) {
	// Typescript will complain that HTMLAudioElement does not support setSinkId, but the selected
	// audio output device can only be changed in browsers that support setSinkId.

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (deviceId && audioElement && audioElement.setSinkId) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		audioElement.setSinkId(deviceId)
	}
}

function VideoCallParticipantAgora(
	props: VideoCallParticipantAgoraProps
): ReactElement<VideoCallParticipantAgoraProps> {
	const { participant, width, height, onClick, showName = true, subheading, fullscreen = false } = props
	const { volume = 1, displayName, uploadToImageCache, facilitatorId, error } = props
	const { participantDetails, isDominantSpeaker, selectedAudioOut } = props

	const { videoTrack, audioTrack, isMuted, isCameraMuted, isLocal, participantId, status, stats } = participant

	const inner = useRef<HTMLDivElement>(null)
	const audio = useRef<HTMLAudioElement>(null)
	const [track, setTrack] = useState<ParticipantTrack>(null)

	// Fetch images for this participant from image cache service
	useEffect(() => {
		const innerDiv = inner.current
		if (!participant || isLocal || !facilitatorId || isCameraMuted || uploadToImageCache) return

		// Only use cached images if the participant has one of the following statuses
		if (!['inactive', 'restoring'].includes(status)) return

		// Set the image as the background image for the 'inner' div
		const onImageReceived = (base64: string) => {
			if (base64 && base64 !== '{}') {
				innerDiv.style.backgroundImage = `url(data:image/webp;base64,${base64})`
			}
		}
		const ref = subscribe(participant.participantId, facilitatorId, onImageReceived)
		return () => {
			unsubscribe(ref)
		}
	}, [participant, facilitatorId, isCameraMuted, uploadToImageCache, status])

	// Regularly update the shadow/border for the participant based on their audio level
	useInterval(() => {
		if (!audioTrack || !participant.getVolume || !inner.current) return

		const audioLevel = participant?.getVolume() || 0

		if (inner.current && audioLevel > 0.1) {
			const blur = (audioLevel - 0.1) * 50
			const spread = (audioLevel - 0.1) * 4
			inner.current.style.boxShadow = `0px 0px ${blur}px ${spread}px var(--accent-colour-2)`
			inner.current.style.borderColor = 'var(--accent-colour-2)'
		} else {
			inner.current.style.boxShadow = null
			inner.current.style.borderColor = null
		}
	}, 100)

	// Attach the audioTrack to the audio element when we have one
	useEffect(() => {
		if (audioTrack?.deviceId === track?.deviceId) return

		setTrack(audioTrack)
		if (audioTrack && audio.current) {
			audioTrack.attach(audio.current)
			setOutputDevice(audio.current, selectedAudioOut)
		}
	}, [audioTrack])

	// If the selected audio output device is changed, update the audio element
	useEffect(() => {
		setOutputDevice(audio.current, selectedAudioOut)
	}, [selectedAudioOut])

	// Update volume of audio track according to props
	useEffect(() => {
		if (audio.current) {
			const newVolume = isMuted ? 0 : volume
			console.log(`Changing volume of audio element to ${newVolume}`)
			audio.current.volume = newVolume
		}
	}, [volume, isMuted])

	useUnmount(() => {
		if (audio.current) {
			audio.current.removeAttribute('src')
			audio.current.srcObject = null
			audio.current.remove()
		}
	})

	// const loading = !isCameraMuted && !participant.videoTrack
	const loading = false

	// Determine CSS classname based on participant status
	const baseClass = 'video-call-participant'
	let className = baseClass
	const novideo = isCameraMuted || !videoTrack || status === 'inactive'
	if (novideo) className += ` ${baseClass}--novideo`
	if (isMuted) className += ` ${baseClass}--noaudio`
	if (isCameraMuted) className += ` ${baseClass}--cameraoff`
	if (fullscreen) className += ` ${baseClass}--fullscreen`
	if (isDominantSpeaker) className += ` ${baseClass}--dominant`
	if (error) className += ` ${baseClass}--with-error`
	if (status) className += ` ${baseClass}--${status}`
	if (videoTrack?.videoType === 'desktop') className += ` ${baseClass}--screenshare`

	// Apply hardcoded height/width if provided
	const style: React.CSSProperties = {}
	if (width && height) {
		style.width = `${width}px`
		style.height = `${height}px`
	}

	let _displayName = displayName || participant.displayName
	if (isLocal) _displayName += ' (you)'

	const colour = participantDetails?.colour
	const _subheading = subheading || teamNameFromColour(colour)
	let groupColourDot = null
	if (colour) {
		groupColourDot = <div className="video-call-participant__dot" style={{ backgroundColor: convertColour(colour) }} />
	}

	// const currentVolume = audio.current ? 100 * audio.current.volume : 0
	// let volumeText = 'Low'
	// if (!currentVolume) volumeText = 'Muted'
	// else if (currentVolume > 30) volumeText = 'High'
	// let volumeText = currentVolume ? 'Low' : 'Muted'
	// volumeText = currentVolume > 30 ? 'High' : volumeText

	const { connectionQuality, downloadRate, uploadRate, sendingResolution, receivingResolution } = stats || {}

	// If this is our own camera, the resolution will be for the video we are *sending*
	// If this is a remote participant, the resolution will be for the video we are *receiving*
	// const resolutionLabel = isLocal ? 'Sending resolution' : 'Receiving resolution'
	const resolutionLabel = 'Resolution'
	const resolutionToShow = sendingResolution || receivingResolution
	const resolutionFormatted =
		resolutionToShow && resolutionToShow.width ? `${resolutionToShow.width} x ${resolutionToShow.height}` : null

	// 'Connection quality': `${stats?.connectionQuality}%`,
	// 'Download rate': `${stats?.bitrate?.download} kbps`,
	// 'Upload rate': `${stats?.bitrate?.upload} kbps`,
	// 'Packet loss': stats?.packetLoss?.total,

	const StatRow = ({ label, value, unit = '' }) => (
		<tr>
			<td>
				<label>{label}: </label>
			</td>
			<td>{value + unit}</td>
		</tr>
	)

	const notNull = val => val !== null && val !== undefined && val >= 0
	const videoTypes = { video: 'camera', desktop: 'screenshare' }
	const videoType = novideo ? 'inactive' : videoTypes[videoTrack?.videoType] || 'inactive'
	const videoKey = !novideo && `${participant.participantId}_${videoType}_${videoTrack?.deviceId}`

	return (
		<div className={className} style={style} onClick={onClick}>
			<div ref={inner} className="video-call-participant__inner">
				{loading && <Preloader />}
				{!novideo && (
					<VideoElementAgora
						key={videoKey}
						track={videoTrack}
						uploadToImageCache={uploadToImageCache}
						participantId={participantId}
						facilitatorId={facilitatorId}
					/>
				)}
				{!isLocal && <audio ref={audio} autoPlay />}
				<div className="video-call-participant__centre">
					{error && <div className="video-call-participant__error">{error}</div>}
					<div className="video-call-participant__icons">
						{isMuted && <Icon name="microphone-slash" />}
						{(isCameraMuted || !videoTrack) && <Icon name="video-slash" />}
					</div>
				</div>
				<div className="video-call-participant__titles">
					{showName && _displayName && (
						<div className="video-call-participant__display-name" title={_displayName}>
							{/* {_displayName + (audio.current ? ` - ${audio.current.volume}` : '')} */}
							{_displayName}
						</div>
					)}
					{_subheading && (
						<div className="video-call-participant__subheading" title={_subheading}>
							{groupColourDot}
							{_subheading}
						</div>
					)}
				</div>
				<table className="video-call-participant__statistics">
					<tbody>
						{notNull(connectionQuality) ? <StatRow label="Connection" value={connectionQuality} unit="%" /> : null}
						{notNull(downloadRate) ? <StatRow label="Download rate" value={downloadRate} unit=" kbps" /> : null}
						{notNull(uploadRate) ? <StatRow label="Upload rate" value={uploadRate} unit=" kbps" /> : null}
						{resolutionFormatted ? <StatRow label={resolutionLabel} value={resolutionFormatted} /> : null}
						{status ? <StatRow label="Status" value={status.toUpperCase()} /> : null}
						{videoType ? <StatRow label="Video type" value={videoType.toUpperCase()} /> : null}
						{/* {!isLocal && showVolumeStat ? <StatRow label="Volume" value={volumeText} unit="" /> : null} */}
						{!stats ? (
							<tr>
								<td colSpan={2}>
									<em>Please wait</em>
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
			</div>
		</div>
	)
}

// export default VideoCallParticipantAgora
export default React.memo(VideoCallParticipantAgora)
