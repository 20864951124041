export type ParticipantTrack = {
	getType: () => 'video' | 'audio'
	attach: (elem: HTMLElement) => void
	detach: (elem: HTMLElement) => void
	isLocal: () => boolean
	getParticipantId: () => string
	isMuted: () => boolean
	dispose: () => void
	unmute: () => void
	mute: () => void
	deviceId: string
	videoType: 'video' | 'desktop'
	containers: Array<HTMLMediaElement>
}

export type ParticipantDetails = {
	participantId?: string
	displayName?: string
	audioTrack?: ParticipantTrack
	videoTrack?: ParticipantTrack
	isMuted?: boolean
	isLocal?: boolean
	isCameraMuted?: boolean
	properties?: SimpleObject
	status?: string
	stats?: ParticipantStats
	videoError?: string
	getVolume?: () => number
}

export type ClientParams = {
	domain?: string
	conferenceId: string
	displayName: string
	cameraDeviceId?: string
	logging?: boolean
}

export type ParticipantStats = {
	downloadRate: number
	uploadRate: number
	connectionQuality: number
	sendingResolution: { width: number; height: number }
	receivingResolution: { width: number; height: number }
}

export type ConnectParams = {
	cameraDeviceId?: string
	audioInputDeviceId?: string
	startAudioMuted?: boolean
	noVideo?: boolean
	noAudio?: boolean
	mode?: 'presentation' | 'grid'
}

export type EventCallback = (...any) => void

export interface IVideoClient {
	localParticipant: ParticipantDetails
	remoteParticipants: ParticipantDetails[]
	on: (event: VideoClientEvent, callback: EventCallback) => number
	off: (ref: number) => void
	toggleMuteAudio: () => Promise<boolean>
	toggleMuteCamera(): Promise<boolean>
	toggleScreenShare(): Promise<void>
	isScreenshareActive(): boolean
	setPrimaryRemoteParticipant(participantId: string): Promise<void>
	setProperty(name: string, value: unknown): void
	sendChatMessage(message: string, participantId: string): Promise<void>
	chat: VideoChatMessage[]
	displayName: string
	participantId: string
}

export type VideoChatMessage = {
	participantId: string
	to?: string
	message: string
	timestamp: number
	privateMsg?: boolean
}

// eslint-disable-next-line no-shadow
export enum VideoClientEvents {
	LOCAL_PARTICIPANT_CHANGED,
	REMOTE_PARTICIPANTS_CHANGED,
	DOMINANT_SPEAKER_CHANGED,
	REMOTE_PARTICIPANT_STATISTICS_RECEIVED,
	LOCAL_STATISTICS_RECEIVED,
	VIDEO_AVAILABLE_CHANGED,
	AUDIO_AVAILABLE_CHANGED,
	MESSAGE_RECEIVED,
	ROOM_JOINED,
}

export type VideoClientEvent = VideoClientEvents
