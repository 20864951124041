import React, { FunctionComponent } from 'react'

type InfoSectionProps = {
	warning?: boolean
	className?: string
}

const InfoSection: FunctionComponent<InfoSectionProps> = props => {
	const { children, className = ``, warning, ...otherProps } = props

	let _className = `info`
	if (warning) _className += ` info--warning`
	if (className) _className += ` ${className}`

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={_className} {...otherProps}>
			{children}
		</div>
	)
}

export default InfoSection
