export default {
	/* Team view */
	OK: 'بلی',
	CANCEL: 'لغو',
	TUTOR: 'تسهیل کننده',
	CURRENT_SESSION: 'جلسه جاری:',
	TEAM: 'تیم',
	SIGNOUT: 'خروج',
	INITIAL_UPDATE: 'معلومات راجع به جلسه',
	ADVICE: 'تصمیم',
	RATIONALE: 'استدلال منطقی',
	MEDIA_FEED: 'اطلاع مطبوعاتی',
	MESSAGES: 'پیام ها',
	PREVSESSIONS: 'جلسه قبلی',
	RETURN_TO_CURRENT: 'بازگشت به جلسه جاری',
	SESSION_IS_PAUSED: 'در حال حاضر جلسه متوقف شده است ',
	ATTRIB_CURR_AUTHOR: 'تصمیم گیرنده',
	SAVE: 'حفظ یا ذخیره',
	CHANGE: 'تغییر ',
	NO_AUTHOR: 'عدم تنظیم ',
	NO_INITIAL_INFO: 'لطفآ انتظار داشته باشید تا جلسه بعدی آغاز گردد',
	DECISION_MAKER_SELECT: 'لطفآ تصمیم گیرنده را انتخاب نمایید...',
	SESSION_COMPLETE: 'جلسه قبلی خاتمه یافته است',
	OPENED_AT: 'در ... باز می باشد',
	SEND_MESSAGE_PLACEHOLDER: 'یک پیام بنویسید',
	SEND: 'ارسال ',
	TO_ALL_GROUPS: 'برای تمام گروه ها',
	SHOW_TRANSLATION: 'ترجمه را به نمایش بگذارید',
	SHOW_ORIGINAL: 'نسخه اصلی را به نمایش بگذارید',
	CREDENTIALS_EXPIRED: 'زمان استفاده از آی دی شما منقضی گردید. لطفآ دوباره وارد شوید.',
	INCOMING_MESSAGE: 'پیام وارده',
	INCOMING_MESSAGE_TEXT: 'یک پیام جدید دریافت شده است',
	INCOMING_MEDIA: 'مورد رسانه یی وارده',
	INCOMING_MEDIA_TEXT: 'یک مورد جدید رسانه یی دریافت شده است',
	INCOMING_UPDATE: 'معلومات تازه وارده',
	INCOMING_UPDATE_TEXT: 'معلومات تازه دریافت شده است',
	CONFIRM_LOGOUT: 'آیا مطمئن هستید که خارج شوید؟',
	MUTED_MESSAGE: 'شما در حال حاضر خاموش هستید',
	NEWSTYPE_UPDATE: 'معلومات تازه دریافت شده اند',
	WARNING_TIME_MSG: 'صرف 5 دقیقه باقی مانده است',
	VIEWING_PREVIOUS: `نمایش جلسه $$ از روی تاریخ`,
	DECISION_LOG: 'دفترچه تصمیمات',
	NOTEPAD: 'دفترچه یادداشت',
	VIEW_INITIAL_UPDATE: 'معلومات راجع به جلسه را مشاهده نمایید',
	NO_DECISION_LOGS: 'دفترچه تصمیمات خالی می باشد',
	DFT_MESSAGES_TEXT: 'این برنامه به شما امکان می‌دهد در طول یک جلسه پیام‌هایی را به/از تسهیلگر ارسال و دریافت کنید',
	DFT_NOTIFICATIONS_TEXT:
		'این برنامه به شما امکان می‌دهد در طول یک جلسه پیام‌هایی را به/از تسهیلگر ارسال و دریافت کنید',
	NOTETAKER: 'دفترچه یادداشت',
	TO: 'به',
	EVERYONE: 'همه',
	MESSAGE: 'پیام',
	SESSION_HAS_STARTED: 'جلسه آغاز شده است',
	SESSION_INFO_RECEIVED: 'معلومات جلسه دریافت شده است',
	CLICK_TO_OPEN: 'برای باز کردن کلیک کنید',
	CONTINUE: 'ادامه',
	DECISION_LOG_HELP_TEXT: 'دفترچه تصمیمات به شما امکان می‌دهد تصمیمات خود را ثبت کنید',
	SCROLL_TO_BOTTOM: 'به پایین صفحه بروید',
	ADD_DECISION_LOG: 'دفترچه تصمیمات را اضافه کنید',
	ENTER_ADVICE: 'تصمیم خود را وارد کنید',
	ENTER_RATIONALE: 'استدلال منطقی خود را وارد کنید',
}
