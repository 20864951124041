import React, { FunctionComponent, ChangeEvent, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useInterval, useMount } from 'react-use'

import config from '../../config'
import Actions from '../actions'

import isLoggedInAsParticipant from '../selectors/loggedInAsParticipant'
import DecisionMakerDropdown from '../elements/DecisionMakerDropdown'
import Preloader from '../elements/Preloader'
import Textarea from '../elements/Textarea'
import Row from '../elements/Row'

type NotepadProps = {
	toggleNotepad: (show: boolean) => void
	updateNoteTaker: (id: string) => void
	viewingPreviousSession: boolean
	loggedInAsParticipant: boolean
	updateNotes: (string) => void
	participants: Participant[]
	getNoteTaker: () => void
	getNotes: () => Note[]
	participantId: string
	notetaker: string
	sessionId: string
	notes: Note[]
	id: string
}

const Notepad: FunctionComponent<NotepadProps> = props => {
	const { participants, updateNotes, getNotes, notetaker, updateNoteTaker, notes } = props
	const { participantId, loggedInAsParticipant, getNoteTaker, viewingPreviousSession, sessionId } = props

	const [loading, setLoading] = useState(true)

	const { NOTETAKER } = config.strings

	const onChangeParticipant = (e: ChangeEvent<HTMLSelectElement>) => updateNoteTaker(e.target.value)

	useInterval(() => {
		getNoteTaker()
		if (loggedInAsParticipant && notetaker !== participantId && !viewingPreviousSession) {
			getNotes()
		}
	}, 3000)

	const reload = async () => {
		setLoading(true)
		await getNotes()
		await getNoteTaker()
		setLoading(false)
	}

	useMount(() => reload())

	// If any of these things change, reload the notes
	useEffect(() => {
		reload()
	}, [viewingPreviousSession, sessionId])

	// useEffect(() => {
	// 	getNotes()
	// }, [notetaker])

	const notesText = notes
		.sort((a, b) => a.index - b.index)
		.map(n => n.text)
		.join('\n')

	const enabled = (!loggedInAsParticipant || notetaker === participantId) && !viewingPreviousSession
	console.log('enabled', enabled)
	const onChangeNotes = e => updateNotes(e.target.value)

	let content = null
	if (loading) {
		content = (
			<Row>
				<Preloader />
			</Row>
		)
	} else {
		content = <Textarea value={notesText} onChange={onChangeNotes} enabled={enabled} />
	}

	console.log('notetaker', notetaker)

	return (
		<div className="notepad">
			{viewingPreviousSession ? null : (
				<Row>
					{participants.length > 0 && (
						<DecisionMakerDropdown
							onChange={onChangeParticipant}
							participants={participants}
							label={NOTETAKER}
							value={notetaker}
						/>
					)}
				</Row>
			)}
			{content}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	loggedInAsParticipant: isLoggedInAsParticipant(state),
	participants: (state.participants || []).filter(p => p?.colour === state?.group?.colour),
	notetaker: state.notetaker || '',
	notes: state.notes || [],
	participantId: state.group.participantId,
	viewingPreviousSession: state.viewingPreviousSession,
	sessionId: state.viewingPreviousSession ? state.previousSession?.id : state.groupData?.currentSession?.id,
})
const actions = {
	updateNoteTaker: Actions.notes.updateNoteTaker,
	toggleNotepad: Actions.misc.toggleNotepad,
	getNoteTaker: Actions.notes.getNoteTaker,
	updateNotes: Actions.notes.updateNotes,
	getNotes: Actions.notes.getNotes,
}

export default connect(mapStateToProps, actions)(Notepad)
