export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'Batal',
	TUTOR: 'Fasilitator',
	CURRENT_SESSION: 'Sesi semasa',
	TEAM: 'Pasukan',
	SIGNOUT: 'Log keluar',
	INITIAL_UPDATE: 'Maklumat sesi',
	ADVICE: 'Keputusan',
	RATIONALE: 'Rasional',
	MEDIA_FEED: 'Suapan Media',
	MESSAGES: 'Mesej',
	PREVSESSIONS: 'Sesi sebelumnya',
	RETURN_TO_CURRENT: 'Kembali ke sesi semasa',
	SESSION_IS_PAUSED: 'Sesi dihentikan buat seketika pada masa ini',
	ATTRIB_CURR_AUTHOR: 'Pembuat keputusan',
	SAVE: 'Simpan',
	CHANGE: 'Ubah',
	NO_AUTHOR: 'Tidak ditetapkan',
	NO_INITIAL_INFO: 'Sila tunggu sesi seterusnya bermula',
	DECISION_MAKER_SELECT: 'Sila pilih pembuat keputusan',
	SESSION_COMPLETE: 'Sesi sebelumnya telah pun selesai',
	OPENED_AT: 'Dibuka pada',
	SEND_MESSAGE_PLACEHOLDER: 'Tulis mesej',
	SEND: 'Hantar',
	TO_ALL_GROUPS: 'Kepada semua kumpulan',
	SHOW_TRANSLATION: 'Tunjukkan terjemahan',
	SHOW_ORIGINAL: 'Paparan asal',
	CREDENTIALS_EXPIRED: 'Kod laluan anda telah tamat tempoh. Sila log masuk semula',
	INCOMING_MESSAGE: 'Mesej masuk',
	INCOMING_MESSAGE_TEXT: 'Mesej baru telah diterima',
	INCOMING_MEDIA: 'Item media masuk',
	INCOMING_MEDIA_TEXT: 'Item media baru telah diterima',
	INCOMING_UPDATE: 'Kemaskini masuk',
	INCOMING_UPDATE_TEXT: 'Kemaskini telah di terima',
	CONFIRM_LOGOUT: 'Adakah anda pasti mahu log keluar?',
	MUTED_MESSAGE: 'Mikrofon anda diredam',
	NEWSTYPE_UPDATE: 'Kemas kini diterima',
	WARNING_TIME_MSG: 'Tinggal 5 minit lagi',
	DECISION_LOG: 'Log keputusan',
	NOTEPAD: 'Notepad',
	NOTETAKER: 'Pencatat nota',
	VIEW_INITIAL_UPDATE: 'Lihat maklumat sesi',
	DFT_MESSAGES_TEXT: 'Aplikasi ini membolehkan anda menghantar dan menerima mesej kepada fasilitator semasa sesi.',
	DFT_NOTIFICATIONS_TEXT:
		'Media akan dipaparkan di sini. Sila pantau peranti ini untuk media dan kemas kini baru. Maklumat sesi asal boleh diakses melalui butang di bahagian atas skrin anda. Berkomunikasi dengan fasilitator menggunakan aplikasi Mesej di sudut kiri bawah peranti ini.',
	TO: 'Kepada',
	EVERYONE: 'Semua orang',
	MESSAGE: 'Mesej',
	POPUP_TITLE_CONFIGUREVIDEO: 'Konfigurasi audio/video',
	CAMERA: 'Kamera',
	MICROPHONE: 'Mikrofon',
	AUDIO_OUTPUT_DEVICE: 'Peranti output audio',
	SELECT_AUDIO_OUTPUT_DEVICE: 'Pilih peranti output audio',
	NO_DECISION_LOGS: 'Tiada log keputusan',
	ENTER_ADVICE: 'Masukkan keputusan anda',
	ENTER_RATIONALE: 'Masukkan rasional anda',
	DECISION_LOG_HELP_TEXT: 'Log keputusan membolehkan anda merekodkan keputusan anda',
	ADD_DECISION_LOG: 'Tambah log keputusan',
}
