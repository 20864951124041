import uniq from 'lodash.uniq'
import md5 from 'md5'

import types from './types'

import ConfirmationHelper from '../util/ConfirmationHelper'
import groupsSelector from '../selectors/groups'
import OnlineComms from '../core/OnlineComms'
import { translate } from './translate'
import action from '../util/action'
import getTime from '../util/getTime'
import config from '../../config'

const { GROUPS_FETCH_DATA_SUCCESS } = types

let holdoff = false
/**
 * Fetch data for the group view screen from the facilitator server
 **/
const fetchGroupData: ActionCreator = () => async (dispatch, getState) => {
	const state = getState()

	try {
		const groupData = (await OnlineComms.getCurrentSession()) as GroupData
		if (!groupData) return

		if (groupData.currentCase) dispatch(action(GROUPS_FETCH_DATA_SUCCESS, groupData))

		const oldCurrentSessionId = state.groupData?.currentSession?.id
		const newCurrentSessionId = groupData?.currentSession?.id

		const currentSessionHasChanged = oldCurrentSessionId !== newCurrentSessionId
		if (currentSessionHasChanged) {
			dispatch(action(types.CURRENT_SESSION_CHANGED))
			fetchDecisionLog()(dispatch, getState)
		}

		const initialUpdate = groupData?.currentSession?.initialUpdate
		if (initialUpdate) {
			translate(initialUpdate, state?.group?.language || 'EN', 'XX')(dispatch, getState)
		}

		return groupData
	} catch (err) {
		console.error('Error fetching current session: ', err)
		// We may receive error code 403 (access denied) when a group's passcode has
		// been deleted and they no longer have access.
		if (err.code === 403) {
			dispatch(action(types.GROUP_KICKED))
		}
	}
}

let lastDecisionLogUpdate = 0
let updateDecisionLogTimeout

const updateDecisionLog: ActionCreator<string[], string[], string[], number[], string[], string[]> = (
	advice,
	rationale,
	authors,
	times,
	participants,
	languages
) => (dispatch, getState) => {
	holdoff = true

	// Get group ID from state
	const state = getState()
	const groupId = state.group.participantId ? state.group.colour : state.group.id

	const details: Partial<GroupDetails> = {
		advice,
		rationale,
		authors,
		times,
		participants,
		languages,
	} as Partial<GroupDetails>

	// Update in state
	dispatch(action(types.GROUP_STORE_DECISIONLOG, details))

	// Buffer the calls to the server so that we only send a max of one update per second, whilst
	// the user is typing in their decision logs.
	let wait = 1
	const timeSinceLastUpdate = Date.now() - lastDecisionLogUpdate
	if (timeSinceLastUpdate < 1000) {
		wait = 1000 - timeSinceLastUpdate
	}

	clearTimeout(updateDecisionLogTimeout)
	updateDecisionLogTimeout = setTimeout(async () => {
		lastDecisionLogUpdate = Date.now()
		const group = state.group || ({} as GroupDetails)
		const currAuthor = group.currAuthor || ''
		const currAuthorId = group.currAuthorId || ''
		if (currAuthor || currAuthorId) {
			details.currAuthor = currAuthor
			details.currAuthorId = currAuthorId
		}
		details.clientTimezoneOffset = new Date().getTimezoneOffset()
		const sessionId = state.groupData.currentSession.id
		await OnlineComms.updateDecisionLog(groupId, sessionId, details).catch(console.error)
		holdoff = false
	}, wait)
}

const removeDecisionLog: ActionCreator<number> = index => async (dispatch, getState) => {
	const { CONFIRM_DELETE_DECISIONLOG } = config.strings

	const state = getState()
	const { group, groupData } = state
	const { advice, rationale, authors, times, participants, languages } = group
	const groupId = group.participantId ? group.colour : group.id
	const sessionId = groupData?.currentSession?.id

	if (!sessionId || !groupId) return

	const details: Partial<GroupDetails> = {
		advice: advice.filter((x, i) => i !== index),
		rationale: rationale.filter((x, i) => i !== index),
		authors: authors.filter((x, i) => i !== index),
		times: times.filter((x, i) => i !== index),
		participants: participants.filter((x, i) => i !== index),
		languages: languages.filter((x, i) => i !== index),
	} as Partial<GroupDetails>

	ConfirmationHelper.confirm(CONFIRM_DELETE_DECISIONLOG, async () => {
		// Update in state
		dispatch(action(types.GROUP_STORE_DECISIONLOG, details))
		await OnlineComms.updateDecisionLog(groupId, sessionId, details).catch(console.error)
	})
}

/* Used by facilitator */
const getDecisionLogTranslationsForGroup: ActionCreator = () => async (dispatch, getState) => {
	try {
		const state = getState()

		// Get user's participant ID and language
		const { participantId, language = 'EN' } = state.group || {}
		if (!participantId) return

		// Get team/group details from previous session (if viewing) or state.group
		const previousSession = state.previousSession || ({} as PreviousSession)
		const prevSessionGroup = (previousSession.groups || [])[0]
		const group = prevSessionGroup || state.group || ({} as GroupDetails)

		// Get list of all participants in session
		const participants = previousSession.participants || group.participants || []

		// Get decision log
		const advice = [...(group.advice || [])]
		const rationale = [...(group.rationale || [])]
		const rows = Math.max(advice.length, rationale.length)
		const languages = group.languages || []

		// Get current list of known translations
		const decisionLogTranslations = state.decisionLogTranslations || []

		const textToTranslate = []
		for (let i = 0; i < rows; i++) {
			if (!advice[i] && !rationale[i]) continue
			const isDifferentParticipant = participants[i] && participants[i] !== participantId
			const isDifferentLanguage = languages[i] && languages[i] !== language
			if (!isDifferentParticipant || !isDifferentLanguage) continue

			const checkAndAdd = (text: string) => {
				if (!text) return
				const translationKey = md5(languages[i] + text)
				const translatedAlready = Boolean(decisionLogTranslations.find(t => t.key === translationKey))
				if (translatedAlready) return
				textToTranslate.push({ key: translationKey, text, fromLang: languages[i], toLang: language })
			}
			checkAndAdd(advice[i])
			checkAndAdd(rationale[i])
		}

		if (!textToTranslate.length) return

		// Translate the text for each language separately
		const langs = uniq(textToTranslate.map(t => t.fromLang))
		langs.forEach(lang => {
			const textArr = textToTranslate.filter(t => t.fromLang === lang).map(t => t.text)
			if (!textArr.length) return
			OnlineComms.translate(lang, language, textArr).then(translations => {
				textToTranslate.forEach((t, i) => {
					dispatch(action(types.GROUP_GOT_LOG_TRANSLATION, { key: t.key, text: translations[i] }))
				})
			})
		})
	} catch (err) {
		console.error('Error getting translations:', err)
	}
}

const fetchDecisionLog: ActionCreator = _ => async (dispatch, getState) => {
	if (holdoff) return

	const state = getState()
	const group = state.group || ({} as GroupDetails)
	const groupData = state.groupData || ({} as GroupData)

	const groupId = group.participantId ? group.colour : group.id
	const currentSessionId = (groupData.currentSession || {}).id

	try {
		if (groupId && currentSessionId) {
			const log = await OnlineComms.getDecisionLog(groupId, currentSessionId)
			if (holdoff) return
			dispatch(action(types.GROUPS_GOT_DECISION_LOG, log))
		}
		if (group.participantId) {
			getDecisionLogTranslationsForGroup()(dispatch, getState)
		}
	} catch (err) {
		console.error(err)
	}
}

/* Used by facilitator */
// eslint-disable-next-line no-shadow
const getDecisionLog: ActionCreator<string, string, boolean> = (
	groupId: string,
	sessionId: string,
	translateLog = true
) => (dispatch, getState) => {
	dispatch(action(types.GROUPS_FETCHING_DECISION_LOG))
	OnlineComms.getDecisionLog(groupId, sessionId).then(data => {
		const decisionLog = { ...data }

		dispatch(action(types.GROUPS_FETCHED_DECISION_LOG, { ...decisionLog, groupId: decisionLog.groupid }))

		if (translateLog) {
			getDecisionLogTranslations()(dispatch, getState)
		}
	})
}

const getDecisionLogTranslations: ActionCreator = () => async (dispatch: Dispatch, getState: GetState) => {
	try {
		const state = getState()
		// Get decision log from state
		const { groupId, advice = [], rationale = [], authors = [] } = state.decisionLog || {}
		if (!groupId) return

		// Get group language from state
		const groups = groupsSelector(state)
		const language = (groups.find(g => g.id === groupId) || {}).language || 'EN'
		const toLang = 'EN'
		if (language === toLang) return

		// Collate the text to translate
		const textToTranslate = [...advice, ...rationale, ...authors]
		if (!textToTranslate.length) return

		// Translate it
		const translated = await OnlineComms.translate(language, toLang, textToTranslate)
		// Slice the results into the appropriate parts - [...advice, ...rationale, ...authors]
		const translatedAdvice = translated.slice(0, advice.length)
		const translatedRationale = translated.slice(advice.length, advice.length + rationale.length)
		const translatedAuthors = translated.slice(advice.length + rationale.length)

		// Dispatch the translated text
		const payload = {
			groupId,
			language,
			advice: translatedAdvice,
			rationale: translatedRationale,
			authors: translatedAuthors,
		}
		dispatch(action(types.GROUPS_TRANSLATED_DECISION_LOG, payload))
	} catch (err) {
		console.error('Error getting translations:', err)
	}
}

const loadGroupList: ActionCreator = () => async dispatch => {
	const groups = await OnlineComms.getGroupsList()
	dispatch(action(types.GROUPS_LIST_LOADED, groups))
}

const switchLanguage: ActionCreator = (language: string) => dispatch => {
	localStorage.setItem('language', language)
	dispatch(action(types.GROUPS_CHANGED_LANGUAGE, language))
}

const openItem: ActionCreator = (rowId: string, sessionId: string) => (dispatch, getState) => {
	const state = getState()
	const { viewingPreviousSession, group = {} as GroupDetails } = state
	const { participantId, colour, id } = group
	const groupId = participantId ? colour : id
	const lastUpdate = getTime()
	let markAsOpened = false
	if (!viewingPreviousSession) {
		// Mark item as opened
		OnlineComms.openItem(rowId, sessionId)
		markAsOpened = true
	}
	const payload = { rowId, groupId, participantId, sessionId, lastUpdate, markAsOpened }
	dispatch(action(types.GROUP_OPENED_ITEM, payload))
}

const updateCurrentAuthor: ActionCreator = (newAuthIdOrName: string) => async (dispatch, getState) => {
	const state = getState()
	const groupId = state.group.participantId ? state.group.colour : state.group.id
	const sessionId = state.groupData.currentSession.id
	const participants = state.participants || []

	// Check if we've been given the ID of a participant or a name
	const participant = participants.find(p => p.id === newAuthIdOrName) || ({} as Participant)
	const participantName = participant.name
	const payload = participantName ? { id: newAuthIdOrName, name: participantName } : { name: newAuthIdOrName }

	holdoff = true
	dispatch(action(types.SESSION_CURR_AUTHOR_CHANGED, payload))
	const update = { currAuthor: payload.name, currAuthorId: payload.id }
	await OnlineComms.updateDecisionLog(groupId, sessionId, update).catch(console.error)
	holdoff = false
}

const setEditingDecisionLog: ActionCreator<boolean> = value => async dispatch => {
	dispatch(action(types.SET_EDITING_DECISION_LOG, value))
}

const actions = {
	getDecisionLogTranslationsForGroup,
	getDecisionLogTranslations,
	setEditingDecisionLog,
	updateCurrentAuthor,
	updateDecisionLog,
	removeDecisionLog,
	fetchDecisionLog,
	fetchGroupData,
	getDecisionLog,
	switchLanguage,
	loadGroupList,
	openItem,
}

export default actions
