import React, { FunctionComponent } from 'react'

import IconButton from '../../elements/IconButton'
import VideoCallMuteButton from './VideoCallMuteButton'
import VideoCallCameraButton from './VideoCallCameraButton'
import VideoCallScreenshareButton from './VideoCallScreenshareButton'
import VideoCallChatButton from './VideoCallChatButton'
import { IVideoClient } from './VideoTypes'

type VideoCallButtonsProps = {
	onClickChangeLayout: () => unknown
	onClickSettings: () => unknown
	onClickEndCall: () => unknown
	onClickChat: () => unknown
	showScreenShare: boolean
	showEndCall: boolean
	client: IVideoClient
	viewMode?: string
}

const VideoCallButtons: FunctionComponent<VideoCallButtonsProps> = props => {
	const { onClickChangeLayout, onClickSettings, onClickEndCall, onClickChat } = props
	const { showEndCall, showScreenShare, viewMode, client } = props

	const changeViewIcon = viewMode === 'TILE' ? 'speaker-view-light' : 'grid'
	const hangUpClass = 'video-call-buttons__hang-up'

	return (
		<div className="video-call-buttons">
			<VideoCallChatButton client={client} onClick={onClickChat} />
			{showScreenShare && <VideoCallScreenshareButton client={client} />}
			<IconButton iconName={changeViewIcon} onClick={onClickChangeLayout} title="Change layout" />
			{showEndCall && <IconButton className={hangUpClass} iconName="phone" onClick={onClickEndCall} title="End call" />}
			<VideoCallMuteButton client={client} />
			<VideoCallCameraButton client={client} />
			<IconButton iconName="cog" onClick={onClickSettings} title="Settings" />
		</div>
	)
}

export default VideoCallButtons
