import React, { FunctionComponent } from 'react'

import IconButton from './IconButton'

type SidebarButtonProps = {
	name?: string
	label?: string
	iconName?: string
	selected?: boolean
	onClick?: () => void
	showBadge?: boolean
}

const SidebarButton: FunctionComponent<SidebarButtonProps> = props => {
	const { showBadge, selected, onClick, name, iconName, label } = props

	const className = `sidebar__button ${name.toLowerCase()}`

	return (
		<div key={name} className={className}>
			<div className="sidebar__label">{label}</div>
			<IconButton
				key={name}
				iconName={iconName}
				selected={selected}
				showBadge={showBadge}
				onClick={onClick}
				title={label}
				showTooltip={false}
			/>
		</div>
	)
}

export default SidebarButton
