/* eslint-disable jsx-a11y/media-has-caption */

import React, { FunctionComponent, useEffect, useRef } from 'react'
import { ParticipantTrack } from '../util/TwilioClient'
import { endBroadcast, startBroadcast } from '../core/VideoImageCache'

type VideoElementAgoraProps = {
	track: ParticipantTrack
	uploadToImageCache: boolean
	participantId: string
	facilitatorId: string
}

const VideoElementAgora: FunctionComponent<VideoElementAgoraProps> = props => {
	const elem = useRef<HTMLDivElement>(null)
	const { track, uploadToImageCache, participantId, facilitatorId } = props
	const id = useRef<number>(Math.floor(Math.random() * 10000))

	useEffect(() => {
		console.log('Attaching video track to video element', elem.current)
		track.attach(elem.current)

		return () => {
			console.log('Detaching track from video element', elem.current)
			// I think this code is not reached on dismount because elem.current is null on dismount
			// It is the same with useUnmount
			if (elem.current) {
				track.detach(elem.current)
			}
		}
	}, [])

	// Upload camera image to video image cache service if required
	useEffect(() => {
		if (!uploadToImageCache || !participantId || !elem.current) return
		const videoElem = (elem.current as HTMLDivElement).querySelector('video')
		if (!videoElem) return
		const ref = startBroadcast(participantId, facilitatorId, videoElem)
		return () => endBroadcast(ref)
	}, [uploadToImageCache, participantId, facilitatorId])

	return <div className="video-element__container" id={`vid${id.current}`} ref={elem} />
}

export default React.memo(VideoElementAgora)
