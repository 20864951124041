import * as Sentry from '@sentry/browser'

interface Window {
	JitsiMeetJS: unknown
}
declare const window: Window

export function jitsiLibLoaded(): boolean {
	return Boolean(window.JitsiMeetJS)
}

let loading = false

export async function loadJitsiMeetLib(domain: string): Promise<void> {
	if (loading || !domain || jitsiLibLoaded()) return

	loading = true
	try {
		// Fetch lib-jitsi-meet script as text
		// Cache at most for one hour
		const ONE_HOUR = 60 * 60 * 1000
		const HOURS_SINCE_EPOCH = Math.floor(Date.now() / ONE_HOUR)
		const jitsiLibUrl = `https://${domain}/libs/lib-jitsi-meet.min.js?t=${HOURS_SINCE_EPOCH}`
		const response = await fetch(jitsiLibUrl)
		const text = await response.text()

		// Add script to page
		const fileref = document.createElement('script')
		fileref.setAttribute('type', 'text/javascript')
		fileref.innerHTML = text
		document.body.appendChild(fileref)
	} catch (err) {
		console.error(err)
		Sentry.captureException(err)
	}
	loading = false
}

export default loadJitsiMeetLib
