export default {
	/* Team view */
	OK: "D'ACCORD",
	CANCEL: 'Annuler',
	TUTOR: 'Facilitateur',
	CURRENT_SESSION: 'Session actuelle',
	TEAM: 'Equipe',
	SIGNOUT: 'Déconnecter',
	INITIAL_UPDATE: 'Session d’information',
	ADVICE: 'Décision',
	RATIONALE: 'Justification',
	MEDIA_FEED: 'Flux de médias',
	MESSAGES: 'Messages',
	PREVSESSIONS: 'Sessions précédentes',
	RETURN_TO_CURRENT: 'Revenir à la session actuelle',
	SESSION_IS_PAUSED: 'La session est actuellement EN PAUSE',
	ATTRIB_CURR_AUTHOR: 'Décideur',
	SAVE: 'Sauvegarder',
	CHANGE: 'Changer',
	NO_AUTHOR: 'Non installé',
	NO_INITIAL_INFO: 'Veuillez attendre que la prochaine session commence.',
	DECISION_MAKER_SELECT: 'Veuillez choisir un décideur',
	SESSION_COMPLETE: 'La session précédente est terminée',
	OPENED_AT: 'Ouvert à',
	SEND_MESSAGE_PLACEHOLDER: 'Rédiger un message',
	SEND: 'Envoyer',
	TO_ALL_GROUPS: 'À tous les groupes',
	SHOW_TRANSLATION: 'Montrer la traduction',
	SHOW_ORIGINAL: 'Montrer l’original',
	CREDENTIALS_EXPIRED: 'Votre mot de passe est expiré. Veuillez vous reconnecter à nouveau',
	INCOMING_MESSAGE: 'Message entrant',
	INCOMING_MESSAGE_TEXT: 'Vous avez un nouveau message',
	INCOMING_MEDIA: 'Média entrant',
	INCOMING_MEDIA_TEXT: 'Vous avez un nouveau media',
	INCOMING_UPDATE: 'Mise-à-jour entrante',
	INCOMING_UPDATE_TEXT: 'Vous avez une mise-à-jour',
	CONFIRM_LOGOUT: 'Êtes-vous sûr de vouloir vous déconnecter?',
	MUTED_MESSAGE: 'Votre microphone est éteint',
	NEWSTYPE_UPDATE: 'Mise à jour reçue',
	WARNING_TIME_MSG: 'Il reste 5 minutes',
	VIEW_INITIAL_UPDATE: 'Afficher session d’information',
	REMOVE: 'Supprimer',
	DECISION_LOG: 'Journal de décision',
	NOTEPAD: 'Bloc-notes',
	NO_DECISION_LOGS: 'Aucun journal de décision',
	DFT_MESSAGES_TEXT:
		'Cette application vous permet d’envoyer et de recevoir des messages au facilitateur pendant la session.',
	NOTETAKER: 'Preneur de notes',
	TO: 'À',
	EVERYONE: 'Tout le monde',
	MESSAGE: 'Message',
	POPUP_TITLE_CONFIGUREVIDEO: "Configurer l'audio/vidéo",
	DFT_NOTIFICATIONS_TEXT:
		"Les médias apparaîtront ici. Gardez un œil sur cet appareil pour les nouveaux médias et mises à jour. Les informations sur la session initiale sont accessibles à partir du bouton en haut de votre écran. Communiquez avec l'animateur en utilisant l'application Messages dans le coin inférieur gauche de cet appareil.",
	CAMERA: 'Caméra',
	MICROPHONE: 'Microphone',
	AUDIO_OUTPUT_DEVICE: 'Sortie audio',
	ADD_DECISION_LOG: 'Ajouter au journal de décision',
	ENTER_ADVICE: 'Entrez votre décision',
	ENTER_RATIONALE: 'Entrez votre justification',
}
