import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import config from '../../../config'

import mediaFeedSelector, { NotificationData } from '../../selectors/mediaFeed'
import releasesSelector from '../../selectors/releases'
import sessionSelector from '../../selectors/session'

import useAutoScroll from '../../util/useAutoScroll'

import Notification from './Notification'

// =================================================================================================

type NotificationsProps = {
	groupId: string
	notifications: NotificationData[]
}

const Notifications: FunctionComponent<NotificationsProps> = props => {
	const { groupId, notifications } = props
	const { DFT_NOTIFICATIONS_TEXT } = config.strings

	// Ensure that the list scrolls to the bottom if a new item is received.
	const scroller = useAutoScroll(notifications.length)

	// Map each newsfeed item to a Notification component
	let content: React.ReactNode = notifications
		.sort((a, b) => a.time - b.time) // Oldest item first
		.map(item => <Notification key={item.id} item={item} groupId={groupId} />)

	if (notifications.length === 0) {
		content = (
			<>
				{DFT_NOTIFICATIONS_TEXT.split('\n').map((text, i) => (
					// eslint-disable-next-line react/no-array-index-key
					<p key={i}>{text}</p>
				))}
			</>
		)
	}
	return (
		<div className="notifications" ref={scroller}>
			<div className="notifications__content">{content}</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree, props): Partial<NotificationsProps> => {
	const releases = releasesSelector(state)
	const session = sessionSelector(state)
	const { groupId } = props
	const notifications = groupId ? mediaFeedSelector(groupId, session, releases) : []
	return { notifications }
}
const actions = {}

type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Pick<NotificationsProps, 'groupId'>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(Notifications)
