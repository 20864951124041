import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import { useInterval } from 'react-use'

import config from '../../../config'

import getTime from '../../util/getTime'
import BubbleHeading from '../../elements/BubbleHeading'

type VideoCallWaitMessageProps = {
	mainCallId: string
	callStarted?: string
	warningTitle?: string
	warningMessage?: string
	warningTime?: number
	callWarningEnabled?: boolean
}

const VideoCallWaitMessage: FunctionComponent<VideoCallWaitMessageProps> = props => {
	const { mainCallId, callStarted, warningTitle, warningMessage, warningTime, callWarningEnabled } = props

	if (!callWarningEnabled) return null

	const { DEFAULT_VIDEO_WARNING_MESSSAGE, DEFAULT_VIDEO_WARNING_TITLE } = config.strings

	// Check if main call is soon to start =========================================================
	const now = getTime()
	const started = new Date(callStarted)
	const callWillStart = new Date(started.getTime() + warningTime * 1000).getTime()
	const mainCallSoonToStart = mainCallId && now <= callWillStart
	// =============================================================================================

	// Calculate how many seconds to display in the countdown, based on how soon the call is going to
	// start. Then, recalculate this number every second and store the value in state to trigger
	// a re-render.
	const calculateSecondsRemaining = () => {
		if (!mainCallSoonToStart) return 0
		const millisecondsLeft = callWillStart - getTime()
		return Math.ceil(millisecondsLeft / 1000)
	}
	const [countdown, setCountdown] = useState(calculateSecondsRemaining())
	useInterval(() => setCountdown(calculateSecondsRemaining()), 1000)

	// If we have no impending call, or countdown has reached zero/NaN, then don't display
	const _warningMessage = warningMessage || DEFAULT_VIDEO_WARNING_MESSSAGE
	const _warningTitle = warningTitle || DEFAULT_VIDEO_WARNING_TITLE
	if (!(countdown > 0) || !mainCallSoonToStart) return null

	return (
		<div className="wait-message-overlay">
			<div className="wait-message">
				<div className="wait-message__inner">
					{_warningTitle && <BubbleHeading>{_warningTitle}</BubbleHeading>}
					{_warningMessage && <p>{_warningMessage}</p>}
					<h3>{countdown}</h3>
				</div>
			</div>
		</div>
	)
}

//==================================================================================================
const mapStateToProps = (state: StateTree) => ({
	callWarningEnabled: state.videoconf?.mainCallWarningEnabled,
	warningMessage: state?.settings?.videoCallWarningMessage,
	warningTitle: state?.settings?.videoCallWarningTitle,
	warningTime: state.settings?.videoCallWarningTime,
	callStarted: state.videoconf?.mainCallStarted,
	mainCallId: state.videoconf?.mainCallId,
})
const actions = {}

export default connect(mapStateToProps, actions)(VideoCallWaitMessage)
