import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import config from '../../../config'
import Actions from '../../actions'

import InputFieldManager from '../InputFieldManager'

import LanguageSelector from '../../elements/LanguageSelector'
import Button from '../../elements/Button'
import Input from '../../elements/Input'
import Error from '../../elements/Error'
import Icon from '../../elements/Icon'

type LoginParticipantProps = {
	errors?: string[]
	language?: string
	clearErrors?: () => void
	onClickBack?: () => void
	switchGroupLanguage?: (lang: string) => void
	loginAsParticipant?: (passcode: string, name: string) => void
}

const LoginParticipant: FunctionComponent<LoginParticipantProps> = props => {
	const { switchGroupLanguage, language, onClickBack, errors = [], clearErrors, loginAsParticipant } = props
	const { NAME, PASSCODE, BACK, OK } = config.strings

	const onClickOk = () => {
		clearErrors()
		loginAsParticipant(InputFieldManager.getValue('passcode'), InputFieldManager.getValue('name'))
	}

	return (
		<div className="login-group">
			<div className="login-group__main">
				<h2>Please enter your log in details</h2>
				<div className="login__input-row">
					<Icon name="user" />
					<Input name="name" focus onEnter={onClickOk} placeholder={NAME} />
				</div>
				<div className="login__input-row">
					<Icon name="key" />
					<Input name="passcode" onEnter={onClickOk} placeholder={PASSCODE} />
				</div>
				<div className="login__input-row">
					<Icon name="globe" />
					<LanguageSelector onChange={value => switchGroupLanguage(value)} value={language} />
				</div>
			</div>
			<div className="login__error-space">
				{errors.map(error => (
					<Error key={error}>{error}</Error>
				))}
			</div>
			<div className="login__otherbuttons">
				<Button text={OK} onClick={onClickOk} />
				<Button level="secondary" text={BACK} onClick={onClickBack} />
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const group = state.group || ({} as GroupDetails)
	return {
		language: group.language || 'EN',
		errors: state.errors || [],
	}
}
const actions = {
	loginAsParticipant: Actions.login.loginAsParticipant,
	switchGroupLanguage: Actions.groups.switchLanguage,
	clearErrors: Actions.misc.clearErrors,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<LoginParticipantProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(LoginParticipant)
