import React, { FunctionComponent, useState } from 'react'

import 'intl-tel-input/build/css/intlTelInput.css'
import ReactIntlTelInput from 'react-intl-tel-input-v2'
import { parsePhoneNumber } from 'libphonenumber-js'

import InputFieldManager from '../components/InputFieldManager'

type PhoneNumberInputProps = {
	onEnter?: (e: React.KeyboardEvent) => void
	name?: string
	// onChange?: (e: React.ChangeEvent) => void
	placeholder?: string
	// value?: string
	// enabled?: boolean
	// label?: string
	// focus?: boolean
}

const PhoneNumberInput: FunctionComponent<PhoneNumberInputProps> = props => {
	const { name } = props

	const initialValue = InputFieldManager.getValue(name) || ''
	let phoneNumber
	try {
		phoneNumber = parsePhoneNumber(initialValue)
	} catch (err) {
		phoneNumber = {}
	}
	const iso2 = phoneNumber.country || 'GB'
	const dialCode = phoneNumber.countryCallingCode || '44'
	const phone = phoneNumber.nationalNumber || ''

	const [value, setValue] = useState({ iso2, dialCode, phone })

	const onChange = val => {
		console.log(val)
		setValue(val)
		// eslint-disable-next-line no-shadow
		const { dialCode, phone, country } = val
		let formattedPhoneNumber = dialCode + phone
		try {
			// eslint-disable-next-line no-shadow
			const phoneNumber = parsePhoneNumber(`${dialCode} ${phone}`, country || 'GB')
			formattedPhoneNumber = phoneNumber.formatInternational().split(' ').join('')
		} catch (err) {
			// Ignore
		}
		InputFieldManager.setValue(name, formattedPhoneNumber)
	}

	return (
		<div className="input phone-number-input">
			<ReactIntlTelInput
				value={value}
				intlTelOpts={{ separateDialCode: true }}
				onChange={onChange}
				onKeyUp={e => console.log('onKeyUp', e)}
				onKey={e => console.log('onKey', e)}
			/>
		</div>
	)
}

export default PhoneNumberInput
