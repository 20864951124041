/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */

import { connect } from 'react-redux'
import React, { FunctionComponent, useEffect, useState } from 'react'

import config from '../../../config'
import Actions from '../../actions'

import PhonePreloader from '../../elements/PhonePreloader'
import Row from '../../elements/Row'
import IconButton from '../../elements/IconButton'
import Icon from '../../elements/Icon'
import mediaIconList from '../../../config/mediaIconList'
import AudioPlayer from './AudioPlayer'
import selectedMediaSelector from '../../selectors/selectedMedia'
import DivWithInert from '../../elements/DivWithInert'

// =================================================================================================

type VoiceMessageScreenProps = {
	resendPhoneMessage: (scheduleRowId: string) => void
	markMediaAsSeen: (id: string) => void
	// getMediaData: (id: string) => Promise<Blob>
	releases: StateTree['releases']
	selectedRowId: string
	active?: boolean
	participantName?: string
	onRedirectToAudioPlayer?: (rowId: string) => void
	closeMedia: () => void
	mediaItem: MediaItem
}

const phoneLogStatusMap = {
	queued: 'pending',
	initiated: 'pending',
	ringing: 'pending',
	'in-progress': 'pending',
	completed: 'sent',
	'completed-human': 'sent',
	'completed-unknown': 'sent',
	busy: 'failed',
	'no-answer': 'failed',
	cancelled: 'failed',
	failed: 'failed',
	'completed-fax': 'failed',
	'completed-machine-start': 'voicemail',
}

const phoneLogStatusTitleMap = {
	queued: 'Incoming Call',
	initiated: 'Incoming Call',
	ringing: 'Incoming Call',
	'in-progress': 'Call in Progress',
	completed: 'Phone Message Sent',
	'completed-human': 'Phone Message Sent',
	'completed-unknown': 'Phone Message Sent',
	busy: 'Call Failed',
	'no-answer': 'No Answer',
	cancelled: 'Call Failed',
	failed: 'Call Failed',
	'completed-fax': 'Call Failed',
	'completed-machine-start': 'Call Failed',
}

const VoiceMessageScreen: FunctionComponent<VoiceMessageScreenProps> = props => {
	const { releases, selectedRowId, resendPhoneMessage, participantName } = props
	const { closeMedia, mediaItem, active } = props

	const { CLICK_TO_RESEND, VOICE_MESSAGE_SENT, WENT_TO_VOICEMAIL, CALL_FAILED_TEXT, CHECK_PHONE, AT } = config.strings
	const { INCOMING_CALL, CALLING, RESEND, PLAY_HERE } = config.strings

	const SENT_MESSAGE = to => [VOICE_MESSAGE_SENT.replace('%%%', to), CLICK_TO_RESEND]
	const VOICEMAIL_MESSAGE = to => [WENT_TO_VOICEMAIL.replace('%%%', to), CLICK_TO_RESEND]
	const FAILED_MESSAGE = to => [CALL_FAILED_TEXT.replace('%%%', to), CLICK_TO_RESEND]

	const PENDING_MESSAGE = [CHECK_PHONE, ' ']

	const [playLocally, setPlayLocally] = useState(false)

	useEffect(() => {
		setPlayLocally(false)
	}, [selectedRowId])

	// Find phone log record
	const release = releases.find(r => r.rowId === selectedRowId)
	const { phonelog } = release || {}
	if (!phonelog) {
		return (
			<div className="voice-message-screen voice-message-screen--inactive">
				<div className="virtual-phone__bg2" />
				{/* <PhonePreloader idle /> */}
			</div>
		)
	}

	const date = new Date(phonelog?.statusTime)
	const time = Number.isNaN(date.getTime()) ? null : date.toLocaleTimeString()
	const toString = phonelog.to + (time ? ` ${AT} ${time}` : '')

	let message = PENDING_MESSAGE
	let shortStatus = phoneLogStatusMap[phonelog?.status] // default: 'pending'
	if (shortStatus === 'sent') message = SENT_MESSAGE(toString)
	if (shortStatus === 'failed') message = FAILED_MESSAGE(toString)
	if (shortStatus === 'voicemail') message = VOICEMAIL_MESSAGE(toString)
	if (playLocally) shortStatus = 'local'

	const resendEnabled = shortStatus !== 'pending'
	const title = phoneLogStatusTitleMap[phonelog?.status] || 'Please wait'
	const phoneIcon = mediaIconList.find(i => i.value === 'phone').icon
	const phoneColour = mediaIconList.find(i => i.value === 'phone').color

	let className = `voice-message-screen voice-message-screen--${shortStatus}`
	if (!active) {
		className += ' voice-message-screen--inactive'
	}

	return (
		<DivWithInert className={className} inert={!active}>
			<div className="virtual-phone__bg2" />
			<Row className="voice-message-screen__top-row">
				<IconButton className="voice-message-screen__close-button" iconName="close" onClick={closeMedia} />
			</Row>
			<div className="voice-message-screen__header">
				<h2>{INCOMING_CALL}</h2>
				<h3>{playLocally ? mediaItem?.name : `${CALLING} ${participantName}...`}</h3>
				<div className="voice-message-screen__phone-icon" style={{ background: phoneColour }}>
					<Icon name={phoneIcon} />
				</div>
			</div>
			<div className="voice-message-screen__content">
				{!playLocally && (
					<>
						<PhonePreloader idle={shortStatus !== 'pending'} />
						{title ? <h3>{title}</h3> : null}
						{message.map(m => (
							<p>{m}</p>
						))}
					</>
				)}
				{playLocally && <AudioPlayer closeButton closeButtonIcon="phone-xmark" onClickClose={closeMedia} />}
			</div>
			{!playLocally && (
				<Row className="voice-message-screen__footer">
					<IconButton
						text={RESEND}
						enabled={resendEnabled}
						iconName="phone-arrow-up-right"
						onClick={() => resendPhoneMessage(selectedRowId)}
					/>
					<IconButton iconName="volume" text={PLAY_HERE} onClick={() => setPlayLocally(true)} />
				</Row>
			)}
		</DivWithInert>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const { mediaItem } = selectedMediaSelector(state)
	return {
		releases: state.releases || [],
		selectedRowId: state.selectedScheduleRowId,
		participantName: state.group?.name,
		mediaItem,
	}
}
const actions = {
	resendPhoneMessage: Actions.sessions.resendPhoneMessage,
	markMediaAsSeen: Actions.media.markMediaAsSeen,
	closeMedia: Actions.media.closeMedia,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<VoiceMessageScreenProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(VoiceMessageScreen)
