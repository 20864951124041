/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, useState } from 'react'
import getTextDirection from '../util/getTextDirection'
import config from '../../config'

type MessageElemProps = {
	icon?: ReactElement

	timestamp?: string
	message?: string
	from?: string
	participantName?: string
	translation?: string
	privateMsg?: boolean
	tutor?: boolean
	to?: string
	groupId?: string
	observerId?: string
	participantId?: string
}

/*
    width: 100%;
    background: #ff0099;
    color: white;
    border-radius: 99px;
    font-size: 14px;
    line-height: 24px;
    padding: 0 11px;
    margin-top: -9px;
	*/

export const MessageElem = (props: MessageElemProps): ReactElement => {
	const { timestamp, message, icon, from, participantName, translation, privateMsg, to } = props
	const { participantId, tutor, observerId, groupId } = props

	const { SHOW_TRANSLATION, SHOW_ORIGINAL, PRIVATE_MESSAGE, TO } = config.strings

	const [showOriginal, setShowOriginal] = useState(false)

	const tooltip = from + (participantName ? ` ${participantName}` : ``)

	let className = 'message'
	if (translation && !showOriginal) className += ' message--translated'
	if (privateMsg) className += ' message--private'
	if (participantId) className += ' message--participant'
	if (!tutor && !observerId && groupId) className += ' message--group'

	const text = translation && !showOriginal ? translation : message

	const time = new Date(parseInt(timestamp, 10)).toTimeString().substr(0, 5)

	return (
		<div className={className} key={timestamp}>
			{privateMsg && (
				<div className="message__private-details">
					{PRIVATE_MESSAGE} {to ? `${TO.toLowerCase()} ${to}` : ''}
				</div>
			)}
			<div className="message__left">{icon}</div>
			<div className="message__right">
				<div className="message__top">
					<div className="message__from" title={tooltip}>
						{from}
						{participantName && <span className="message__name">{participantName}</span>}
					</div>
					<div className="message__at">{time}</div>
				</div>
				<div className="message__text" style={{ direction: getTextDirection(text) }}>
					{text}
				</div>
				{translation && (
					<div className="message__translate" onClick={() => setShowOriginal(!showOriginal)} role="button">
						{showOriginal ? SHOW_TRANSLATION : SHOW_ORIGINAL}
					</div>
				)}
			</div>
		</div>
	)
}

export default MessageElem
