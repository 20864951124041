import { connect } from 'react-redux'
import React from 'react'
import md5 from 'md5'

import config from '../../../config'

import SideCall from './SideCall'

type ObserverBreakoutRoomProps = {
	domain: string
	instanceId: string
	facilitatorId: string
	displayName: string
}

// For an observer breakout room, we open up the SideCall component and use a call ID which is:
// md5(facilitatorId + server instance ID)
function ObserverBreakoutRoom(props: ObserverBreakoutRoomProps) {
	const { domain, instanceId, facilitatorId, displayName } = props
	const callId = md5(facilitatorId + instanceId)

	return <SideCall callId={callId} domain={domain} displayName={displayName} />
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): ObserverBreakoutRoomProps => ({
	facilitatorId: state.linkedAccess?.facilitatorId || state.tutor.loggedInAs,
	displayName: state.linkedAccess?.name || config.strings.TUTOR,
	instanceId: state.videoconf?.instanceId,
	domain: state.videoconf?.domain,
})
const actions = {}
export default connect(mapStateToProps, actions)(ObserverBreakoutRoom)
