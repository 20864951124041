/* eslint-disable no-unused-vars */

const VIDEOCONF_GOT_SERVER_STATUS: Reducer = (state, action) => {
	const details: VideoConfDetails = action.payload || ({} as VideoConfDetails)
	const videoConf = state.videoconf || {}
	// const serverStatus = details.status || ''
	let { serverId } = videoConf
	if (details.status === 'online' || details.status === 'starting') {
		serverId = details.serverId
	}
	const videoconf = { ...videoConf, ...details, serverId }
	return { ...state, videoconf }
}

const VIDEOCONF_GOT_SERVER_REGIONS: Reducer = (state, action) => {
	const regions = action.payload || []
	const videoconf = { ...(state.videoconf || {}), regions }
	return { ...state, videoconf }
}

const VIDEOCONF_SERVER_STARTING: Reducer = state => {
	const newState: StateTree = { ...state, videoconf: { ...(state.videoconf || {}), status: 'starting' } }
	return newState
}

const VIDEOCONF_SELECT_REGION: Reducer = (state, action) => {
	const region = action.payload
	const videoconf: VideoConfDetails = { ...(state.videoconf || {}), serverId: region, status: 'offline' }
	const newState = { ...state, videoconf }
	return newState
}

const VIDEOCONF_SERVER_STOPPING: Reducer = state => {
	const newState: StateTree = { ...state, videoconf: { ...(state.videoconf || {}), status: 'stopping' } }
	return newState
}

const VIDEOCONF_JOINED_BREAKOUT: Reducer = (state, action) => {
	const groupId = action.payload
	const newState = { ...state, joinedBreakout: groupId }
	return newState
}

const VIDEOCONF_LEFT_BREAKOUT: Reducer = state => {
	const newState = { ...state }
	delete newState.joinedBreakout
	return newState
}

const VIDEOCONF_CHANGED_CAMERA: Reducer = (state, action) => {
	const newState = { ...state, videoconf: { ...(state.videoconf || {}), selectedCamera: action.payload } }
	localStorage.setItem('video-in', action.payload)
	return newState
}

const VIDEOCONF_CHANGED_MIC: Reducer = (state, action) => {
	const newState = { ...state, videoconf: { ...(state.videoconf || {}), selectedMic: action.payload } }
	localStorage.setItem('audio-in', action.payload)
	return newState
}
const VIDEOCONF_CHANGED_AUDIO_OUT: Reducer = (state, action) => {
	const newState = { ...state, videoconf: { ...(state.videoconf || {}), selectedAudioOut: action.payload } }
	localStorage.setItem('audio-out', action.payload)
	return newState
}

const VIDEOCONF_CHANGED_INTERPRETER: Reducer = (state, action) => {
	const newState = { ...state, videoconf: { ...(state.videoconf || {}), selectedInterpreter: action.payload } }
	return newState
}

const VIDEOCONF_INTERPRETER_CHANGED_CHANNEL: Reducer = (state, action) => {
	const { clientId, onMainChannel } = action.payload
	const newState = { ...state, videoconf: { ...(state.videoconf || {}) } }
	// Update interpreter in list of interpreters
	newState.videoconf.interpreters = (newState.videoconf.interpreters || []).map(i => ({ ...i }))
	const interpreter = newState.videoconf.interpreters.find(i => i.clientId === clientId)
	if (interpreter) interpreter.onMainChannel = onMainChannel
	return newState
}

const VIDEOCONF_CHAT_TOGGLED: Reducer = (state, action) => {
	const enabled = action.payload
	const newState = { ...state, videoconf: { ...(state.videoconf || {}), chatEnabled: enabled } }
	return newState
}

//==================================================================================================

const reducers = {
	VIDEOCONF_CHANGED_INTERPRETER,
	VIDEOCONF_GOT_SERVER_REGIONS,
	VIDEOCONF_GOT_SERVER_STATUS,
	VIDEOCONF_SERVER_STOPPING,
	VIDEOCONF_SERVER_STARTING,
	VIDEOCONF_SELECT_REGION,
	VIDEOCONF_LEFT_BREAKOUT,
	VIDEOCONF_JOINED_BREAKOUT,
	VIDEOCONF_CHANGED_CAMERA,
	VIDEOCONF_CHANGED_MIC,
	VIDEOCONF_CHAT_TOGGLED,
	VIDEOCONF_CHANGED_AUDIO_OUT,
	VIDEOCONF_INTERPRETER_CHANGED_CHANNEL,
}

//==================================================================================================

const handler: Reducer = (state, action) => {
	let newState = state
	if (!newState.videoconf) {
		newState = { ...state }
		newState.videoconf = {
			selectedCamera: localStorage.getItem('video-in') || null,
			selectedMic: localStorage.getItem('audio-in') || null,
			selectedAudioOut: localStorage.getItem('audio-out') || null,
			interpreters: [],
		}
	}
	if (reducers[action.type]) {
		newState = reducers[action.type](newState, action)
	}
	return newState
}

export default handler
