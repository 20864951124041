import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import config from '../../config'

import calculateTranslationHash from '../util/calculateTranslationHash'
import currentCaseSelector from '../selectors/currentCase'
import currentSessionSelector from '../selectors/currentSession'
import GroupColourDot from './GroupColourDot'
import ParticipantTopButtons from './ParticipantTopButtons'
import teamNameFromColour from '../util/teamNameFromColour'
import Row from '../elements/Row'
import Actions from '../actions'

type ParticipantHeaderBarProps = {
	openCase: Case
	currentSession: CurrentSession
	colour: string
	viewingPreviousSession: boolean
	translations?: Record<string, string>
	facilitatorLanguage: string
	language: string
	translate: (text: string, toLang: string, fromLang?: string) => void
}

const ParticipantHeaderBar: FunctionComponent<ParticipantHeaderBarProps> = props => {
	const { currentSession, openCase, colour } = props
	const { translations, facilitatorLanguage, language, translate } = props
	const { SHOW_TRANSLATION, SHOW_ORIGINAL } = config.strings
	const caseName = openCase?.title

	// Check for translation
	const [showOriginal, setShowOriginal] = useState(false)
	const translationHash = calculateTranslationHash(caseName, facilitatorLanguage, language)
	useEffect(() => {
		if (!translations[translationHash]) {
			translate(caseName, language, facilitatorLanguage)
		}
	}, [translationHash])

	// eslint-disable-next-line react/no-array-index-key
	const translation = translations[translationHash]
	const title = showOriginal ? caseName : translation || caseName

	const onClickTranslate = () => setShowOriginal(!showOriginal)

	const sessionIndex = currentSession?.index
	const teamName = teamNameFromColour(colour)
	const { SESSION } = config.strings
	return (
		<div className="participant-header-bar" role="banner">
			<div className="participant-header-bar__logo" />
			<div className="participant-header-bar__titles">
				<Row>
					<h1>{title || 'View360 Global'}</h1>

					{translation && (
						<div
							className="participant-header-bar__translate"
							onClick={onClickTranslate}
							role="button"
							tabIndex={0}
							onKeyPress={onClickTranslate}
						>
							{showOriginal ? SHOW_TRANSLATION : SHOW_ORIGINAL}
						</div>
					)}
				</Row>
				<Row>
					{sessionIndex ? (
						<h2>
							{SESSION} {sessionIndex}
						</h2>
					) : null}
					{teamName ? (
						<div className="participant-header-bar__team-name">
							<GroupColourDot /> {teamName}
						</div>
					) : null}
				</Row>
			</div>
			<div className="spacer" />
			<ParticipantTopButtons />
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<ParticipantHeaderBarProps> => {
	const openCase = currentCaseSelector(state)
	const currentSession = currentSessionSelector(state)
	const { viewingPreviousSession } = state
	return {
		openCase,
		currentSession,
		viewingPreviousSession,
		colour: state?.group?.colour,
		language: state?.group?.language || 'EN',
		translations: state?.translations || {},
		facilitatorLanguage: 'EN',
	}
}
const actions = {
	translate: Actions.translate.translate,
}

export default connect(mapStateToProps, actions)(ParticipantHeaderBar)
