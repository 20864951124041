export const GOT_SERVER_TIME_DIFF: Reducer = (state, action) => {
	console.info(`Server time difference: ${action.payload}ms`)
	const serverTimeDiff = action.payload as number
	return { ...state, serverTimeDiff }
}

export const STARTED_PARTICIPANT_LOGIN: Reducer = (state, action) => {
	const { ipAddress } = action.payload as { ipAddress: string }
	return {
		...state,
		groupIpAddress: ipAddress,
		group: { ...state.group, loggingIn: true, ipAddress },
	}
}

export const LOGGED_IN_FAIL_PARTICIPANT: Reducer = (state, action) => {
	const loginError = action.payload as string
	const { group } = state
	return {
		...state,
		errors: [loginError],
		group: { ...group, loginError, loggingIn: false },
	}
}

export const LOGGED_IN_SUCCESS_PARTICIPANT: Reducer = (state, action) => {
	const groupDetails = action.payload as Partial<GroupDetails>
	const { colour } = groupDetails
	const group = { ...state.group, ...groupDetails, loggedIn: true, loggingIn: false, id: colour }
	return { ...state, group }
}

// =================================================================================================

const reducers = {
	LOGGED_IN_SUCCESS_PARTICIPANT,
	LOGGED_IN_FAIL_PARTICIPANT,
	STARTED_PARTICIPANT_LOGIN,
	GOT_SERVER_TIME_DIFF,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	if (reducers[action.type]) {
		return reducers[action.type](state, action)
	}
	return state
}

export default switchcase
