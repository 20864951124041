import { connect } from 'react-redux'
import React from 'react'
import { useMount } from 'react-use'

import ContentLoadingWrapper from '../components/ContentLoadingWrapper'
import BubbleHeading from '../elements/BubbleHeading'
import MediaItem from '../components/MediaItem'
import Button from '../elements/Button'
import config from '../../config'
import Actions from '../actions'

type TutorMediaPropsFromState = {
	media: Array<MediaItem>
	openCase: SimpleObject
	loadingMedia: boolean
	isObserver: boolean
}

type TutorMediaActions = {
	getMediaItems: () => unknown
	displayModalPopup: (location, parms?) => unknown
}

type TutorMediaProps = TutorMediaPropsFromState & TutorMediaActions

function TutorMedia(props: TutorMediaProps): React.ReactElement<TutorMediaProps> {
	const { media, openCase, loadingMedia, isObserver } = props
	const { getMediaItems, displayModalPopup } = props
	const { MEDIA, ADD_MEDIA, NO_OPEN_CASE } = config.strings

	// Load media items on mount
	useMount(() => getMediaItems())

	// Check if we have an open case - If no open case then we display a message to indicate this and
	// prevent new items from being added to the list.
	const noOpenCase = !openCase || !openCase.id
	const allowCreate = !noOpenCase

	const onClickMediaItem = item => () => displayModalPopup('modal-media', item)

	// const sortByLastUpdateAscending = (a, b) => (a.lastUpdate ? a.lastUpdate - b.lastUpdate : 0)
	const sortByName = (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)

	// Create list of media items for display
	const items = media
		.sort(sortByName)
		.map(item => <MediaItem key={item.id} data={item} onClick={onClickMediaItem(item)} />)

	const noOpenCaseMessage = <span className="tutor-media__message">{NO_OPEN_CASE}</span>
	const itemsList = <ContentLoadingWrapper loading={loadingMedia}>{items}</ContentLoadingWrapper>

	const content = noOpenCase ? noOpenCaseMessage : itemsList
	const onClickAddMedia = () => displayModalPopup('modal-media-upload')
	const addMediaButton = <Button text={ADD_MEDIA} onClick={onClickAddMedia} enabled={allowCreate} />

	return (
		<div className="tutor-media">
			<div className="tutor-media__top">
				<BubbleHeading>{MEDIA}</BubbleHeading>
				{!isObserver && addMediaButton}
			</div>
			<div className="tutor-media__content">{content}</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): TutorMediaPropsFromState => ({
	isObserver: state.linkedAccess && state.linkedAccess.connected,
	openCase: state.openCase,
	media: state?.openCase?.media || [],
	loadingMedia: state.loadingMedia || false,
})
const actions: TutorMediaActions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	getMediaItems: Actions.media.getMediaItems,
}
export default connect(mapStateToProps, actions)(TutorMedia)
