class InputFieldManager {
	private data: SimpleObject

	constructor() {
		this.data = {}
	}

	getValue(name: string): string {
		return this.data[name] as string
	}

	setValue(name: string, value: unknown): void {
		this.data[name] = value
	}

	clearValue(name: string) {
		this.data[name] = ''
	}
}

const singleton = new InputFieldManager()
export default singleton

// class InputFieldManager {
// 	private static _instance: InputFieldManager
// 	private data: Record<string, string>

// 	private constructor() {
// 		this.data = {}
// 	}

// 	getValue(name: string): string {
// 		return this.data[name] as string
// 	}

// 	setValue(name: string, value: string): void {
// 		this.data[name] = value as string
// 	}

// 	clearValue(name: string) {
// 		this.data[name] = ''
// 	}

// 	public static get Instance() {
// 		// Do you need arguments? Make it a regular static method instead.
// 		return this._instance || (this._instance = new this())
// 	}
// }

// const myClassInstance = InputFieldManager.Instance
// export default myClassInstance
