import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import config from '../../../config'
import Actions from '../../actions'

import selectedMediaSelector from '../../selectors/selectedMedia'
import sessionSelector from '../../selectors/session'
import getTime from '../../util/getTime'

import VirtualPhoneFrame from './VirtualPhoneFrameSVG'
import VirtualPhoneClock from './VirtualPhoneClock'
import WelcomeScreen from './WelcomeScreen'
import UpdateScreen from './UpdateScreen'
import AudioPlayerScreen from './AudioPlayerScreen'
import MessagesApp from './MessagesApp'
import NewSessionScreen from './NewSessionScreen'
import VideoPlayer from './VideoPlayer'
import HomeScreen from './HomeScreen'
import VoiceMessageScreen from './VoiceMessageScreen'
import releasesSelector from '../../selectors/releases'
import mediaFeedSelector, { NotificationData } from '../../selectors/mediaFeed'

const { AUDIO_TYPES, VIDEO_TYPES } = config

type VirtualPhoneProps = {
	groupId: string
	session: CurrentSession | PreviousSession
	viewingPreviousSession: boolean
	sessionInfoClosed: boolean
	mediaExpanded: boolean
	selectedMediaItem: MediaItem
	selectedScheduleRow: ScheduleRow
	phoneNumber?: string
	notifications: NotificationData[]
	closeMedia: () => void
	conversation: Message[]
}

const VirtualPhone: FunctionComponent<VirtualPhoneProps> = props => {
	const { groupId, session, viewingPreviousSession, sessionInfoClosed, phoneNumber } = props
	const { selectedMediaItem, selectedScheduleRow, mediaExpanded, closeMedia } = props
	const { notifications, conversation } = props

	const [showMessages, setShowMessages] = useState(false)

	let status = 'home'
	if (!session?.id) status = 'welcome'

	const sessionJustStarted = !viewingPreviousSession && session.startTime > getTime() - 600 * 1000 && !sessionInfoClosed
	if (sessionJustStarted) status = 'start'

	// Check whether we should be displaying a selected media item/update in an "app"
	if (status === 'home' && AUDIO_TYPES.includes(selectedMediaItem?.extension)) {
		status = 'audioplayer'
	}
	if (status === 'home' && VIDEO_TYPES.includes(selectedMediaItem?.extension) && !mediaExpanded) {
		status = 'videoplayer'
	}
	if (status === 'home' && selectedScheduleRow?.type === 'update') {
		status = 'update'
	}
	if (status === 'audioplayer' && selectedMediaItem?.phoneEnabled && phoneNumber) {
		status = 'voicemessage'
	}

	// -----------------------------------------------------------------------------------------------
	// We need to ensure that if a user is viewing media for longer than 30 seconds, and a new media
	// item is received, then we close the media so that they are aware of it
	const idleCheckScreens = ['audioplayer', 'videoplayer', 'update', 'voicemessage']
	const IDLE_TIMEOUT = 10 * 1000

	const idleTimeout = useRef(null)
	const timeEnteredScreen = useRef(null)
	const prevLength = useRef(notifications.length)
	const prevConvoLength = useRef(conversation.length)

	useEffect(() => {
		if (
			(notifications.length > prevLength.current || conversation.length > prevConvoLength.current) &&
			(idleCheckScreens.includes(status) || showMessages)
		) {
			const timeSinceEnteredScreen = Date.now() - timeEnteredScreen.current
			if (timeSinceEnteredScreen > IDLE_TIMEOUT) {
				closeMedia()
				setShowMessages(false)
			} else if (!idleTimeout.current) {
				idleTimeout.current = setTimeout(() => {
					closeMedia()
					setShowMessages(false)
					idleTimeout.current = null
				}, IDLE_TIMEOUT - timeSinceEnteredScreen)
			}
		}
		prevLength.current = notifications.length
		prevConvoLength.current = conversation.length
	}, [notifications])

	useEffect(() => {
		if ((idleCheckScreens.includes(status) || showMessages) && !timeEnteredScreen.current) {
			timeEnteredScreen.current = Date.now()
		} else {
			timeEnteredScreen.current = 0
			clearTimeout(idleTimeout.current)
			idleTimeout.current = null
		}
	}, [status, showMessages])

	// -----------------------------------------------------------------------------------------------

	let className = 'virtual-phone'
	if (status) className += ` virtual-phone--${status}`
	if (showMessages) className += ` virtual-phone--messagesopen`

	const toggleMessages = () => setShowMessages(!showMessages)

	return (
		<div className="virtual-phone__container" role="complementary" aria-label="Your virtual phone">
			<div className={className}>
				<div className="virtual-phone__content">
					<div className="virtual-phone__bg1" />
					<div className="virtual-phone__bg2" />
					<VirtualPhoneClock />

					<NewSessionScreen active={status === 'start'} />
					<WelcomeScreen active={status === 'welcome'} onClickShowMessages={toggleMessages} />
					<HomeScreen active={status === 'home'} groupId={groupId} onClickShowMessages={toggleMessages} />
					<MessagesApp active={showMessages} onClose={toggleMessages} />
					<AudioPlayerScreen active={status === 'audioplayer'} />
					<VideoPlayer active={status === 'videoplayer'} />
					<UpdateScreen active={status === 'update'} />
					<VoiceMessageScreen active={status === 'voicemessage'} />
				</div>
				<VirtualPhoneFrame />
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const { selectedRow: scheduleRow, mediaItem } = selectedMediaSelector(state)
	const group = state.group || ({} as GroupDetails)

	const releases = releasesSelector(state)
	const session = sessionSelector(state)
	const groupId = state?.group?.id
	const notifications = groupId ? mediaFeedSelector(groupId, session, releases) : []

	const allGroupMessages: Message[] = session.allGroupMessages || []
	const conversation: Message[] = (state.groupData || {}).conversation || []
	const fullConversation = [...conversation, ...allGroupMessages]

	return {
		viewingPreviousSession: state?.viewingPreviousSession,
		session: sessionSelector(state),
		selectedScheduleRow: scheduleRow,
		sessionInfoClosed: state?.sessionInfoClosed,
		selectedMediaItem: mediaItem,
		mediaExpanded: state?.mediaExpanded,
		phoneNumber: group?.phoneNumber,
		conversation: fullConversation,
		notifications,
		groupId,
	}
}

const actions = {
	closeMedia: Actions.media.closeMedia,
}

export default connect(mapStateToProps, actions)(VirtualPhone)
