/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent, useRef, useState } from 'react'
import interpolate from 'color-interpolate'
import { useInterval } from 'react-use'
import { connect } from 'react-redux'

import { sessionIsActive, sessionSecondsLeft } from '../../util/SessionHelpers'
import currentSessionSelector from '../../selectors/currentSession'
import ConfirmationHelper from '../../util/ConfirmationHelper'
import config from '../../../config'

const WARNING_TIME_SECS = 300

type VirtualPhoneClockProps = {
	session: CurrentSession | PreviousSession
}

const convertDegToRad = deg => deg * (Math.PI / 180)

const colormap = interpolate(['#EF2F6F', '#5151EB'])

// =================================================================================================

const VirtualPhoneClock: FunctionComponent<VirtualPhoneClockProps> = props => {
	const { session } = props
	const secsLeft = sessionSecondsLeft(session)
	const duration = (session?.duration || 0) * 60
	const { WARNING_TIME_MSG } = config.strings

	const [timeRemaining, setTimeRemaining] = useState(secsLeft || 0)

	// Every second update the number of seconds remaining
	const lastSecs = useRef(0)
	useInterval(() => {
		const secs = sessionSecondsLeft(session)
		setTimeRemaining(secs || 0)
		if (secs !== lastSecs.current && secs === WARNING_TIME_SECS) {
			ConfirmationHelper.warning(WARNING_TIME_MSG)
		}
		lastSecs.current = secs
	}, 500)

	const hands = 31
	const innerRadius = 100
	const outerRadius = 140
	const strokeWidth = 5

	// Calculate the position of each "hand" (line) starting with the one at 3'o'clock
	const handPositions = []
	const xOffset = outerRadius
	const yOffset = 2
	for (let i = 0; i < hands; i++) {
		const angle = (180 / (hands - 1)) * i
		const radAngle = convertDegToRad(angle)
		const y1 = Math.sin(radAngle) * innerRadius + yOffset
		const x1 = Math.cos(radAngle) * innerRadius + xOffset
		const y2 = Math.sin(radAngle) * outerRadius + yOffset
		const x2 = Math.cos(radAngle) * outerRadius + xOffset
		handPositions.push({ x1, y1, x2, y2 })
	}

	// Reverse these, so the first one is at 9'o'clock
	// We will then display the first n of these, where n is a decreasing number as the session progresses
	handPositions.reverse()
	const numHandsToShow = timeRemaining ? Math.ceil((hands / duration) * timeRemaining) : 0
	const colouredHandsToShow = handPositions.slice(0, numHandsToShow)

	// Calculate the colours for the hands
	const colours = colouredHandsToShow.map((p, i) => colormap((1 / hands) * i))
	const styles = []

	// Add a glow to the last hand
	// const lastColour = colours[colouredHandsToShow.length - 1]
	// styles[colouredHandsToShow.length - 1] = {
	// 	filter: `
	// 	  drop-shadow(0px 0px 120px ${lastColour})
	// 	  drop-shadow(0px 0px 100px ${lastColour})
	// 		drop-shadow(0px 0px 60px ${lastColour})
	// 		drop-shadow(0px 0px 44px ${lastColour})
	// 		drop-shadow(0px 0px 38px ${lastColour})
	// 		drop-shadow(0px 0px 32px ${lastColour})
	// 	`,
	// }

	// Ensure that the length of the the last hand is relative to how much time is
	// left for that segment
	const segmentTime = duration / hands
	const timeRemainingInCurrentSegment = timeRemaining - (colouredHandsToShow.length - 1) * segmentTime
	const timeRemainingInCurrentSegmentAsRatio = timeRemainingInCurrentSegment / segmentTime // number from 0 - 1
	const lastIndex = colouredHandsToShow.length - 1
	colouredHandsToShow[lastIndex] = { ...colouredHandsToShow[lastIndex] }
	const lastHand = colouredHandsToShow[lastIndex]
	lastHand.x2 = lastHand.x1 + (lastHand.x2 - lastHand.x1) * timeRemainingInCurrentSegmentAsRatio
	lastHand.y2 = lastHand.y1 + (lastHand.y2 - lastHand.y1) * timeRemainingInCurrentSegmentAsRatio

	// Format the time
	const mins = String(Math.floor(timeRemaining / 60)).padStart(2, '0')
	const secs = String(timeRemaining % 60).padStart(2, '0')
	const timeFormatted = `${mins}:${secs}`

	let className = 'virtual-phone-clock'
	// if (session?.paused) className += ' virtual-phone-clock--paused'

	if (!sessionIsActive(session)) {
		className += ' virtual-phone-clock--zero'
	} else if (session.paused) {
		className += ' virtual-phone-clock--paused'
	} else if (timeRemaining >= 295 && timeRemaining <= 300) {
		className += ' virtual-phone-clock--nearzero'
	} else if (timeRemaining >= 55 && timeRemaining <= 60) {
		className += ' virtual-phone-clock--nearzero'
	} else if (!timeRemaining) {
		className += ' virtual-phone-clock--zero'
	}

	return (
		<div className={className}>
			<div className="virtual-phone-clock__time">{timeFormatted}</div>
			<svg
				preserveAspectRatio="xMinYMin slice"
				fill="#FFFFFF"
				viewBox={`0 0 ${outerRadius * 2} ${outerRadius}`}
				version="1.1"
			>
				{/* Draw background lines */}
				{handPositions.map(({ x1, y1, x2, y2 }, i) => (
					<line
						key={i}
						x1={x1}
						y1={y1}
						x2={x2}
						y2={y2}
						stroke="white"
						strokeWidth={strokeWidth}
						strokeLinecap="butt"
						style={styles[i]}
					/>
				))}
				{/* Draw coloured lines */}
				{colouredHandsToShow.map(({ x1, y1, x2, y2 }, i) => (
					<line
						key={i}
						x1={x1}
						y1={y1}
						x2={x2}
						y2={y2}
						stroke={colours[i]}
						strokeWidth={strokeWidth}
						strokeLinecap="butt"
					/>
				))}
			</svg>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<VirtualPhoneClockProps> => {
	const currentSession = currentSessionSelector(state)
	return { session: currentSession }
}
const actions = {}

export default connect(mapStateToProps, actions)(VirtualPhoneClock)
