import React, { ChangeEventHandler, FunctionComponent } from 'react'

import config from '../../config'

type DecisionMakerDropdownProps = {
	participants: StateTree['participants']
	onChange: ChangeEventHandler<HTMLSelectElement>
	className?: string
	disabled?: boolean
	error?: boolean
	value?: string
	label?: string
}

const DecisionMakerDropdown: FunctionComponent<DecisionMakerDropdownProps> = props => {
	const { participants = [], className, disabled, onChange, value, error, label } = props
	const { ATTRIB_CURR_AUTHOR, DECISION_MAKER_SELECT, OFFLINE } = config.strings

	// Placeholder option
	const options = [
		<option key="" value="" disabled>
			{DECISION_MAKER_SELECT}
		</option>,
	]

	// Add an <option> for each participant. The 'value' is the ID of the participant
	// Show the option as disabled if the participant has gone offline
	options.push(
		...participants.map(participant => {
			const { online } = participant
			return (
				<option key={participant.id} value={participant.id} disabled={!online}>
					{participant.name} {online ? '' : `(${OFFLINE})`}
				</option>
			)
		})
	)

	let _className = 'decision-maker-dropdown'
	if (className) _className += ` ${className}`
	let inputClassname = 'input'
	if (error) inputClassname += ' input--error'
	if (disabled) inputClassname += ' input--disabled'

	const _label = label || ATTRIB_CURR_AUTHOR

	return (
		<div className={_className}>
			<label htmlFor="decisionMaker">{_label}:</label>
			<div className={inputClassname}>
				<select name="decisionMaker" value={value} onChange={onChange} disabled={disabled}>
					{options}
				</select>
			</div>
		</div>
	)
}
export default DecisionMakerDropdown
