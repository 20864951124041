/* eslint-disable @typescript-eslint/no-unused-vars */
import { v4 as uuid } from 'uuid'
import md5 from 'md5'

import facilitatorIdSelector from '../selectors/facilitatorId'
import OnlineComms from '../core/OnlineComms'
import SocketComms from '../core/SocketComms'
import action from '../util/action'
import types from './types'

import loggedInAsParticipantSelector from '../selectors/loggedInAsParticipant'
import loadJitsiMeetLib, { jitsiLibLoaded } from '../util/loadJitsiMeetLib'
import { ParticipantDetails } from '../util/TwilioClient'

const {
	VIDEOCONF_INTERPRETER_CHANGED_CHANNEL,
	VIDEOCONF_CHANGED_INTERPRETER,
	VIDEOCONF_GOT_SERVER_REGIONS,
	VIDEOCONF_GOT_SERVER_STATUS,
	VIDEOCONF_CHANGED_AUDIO_OUT,
	VIDEOCONF_SERVER_STARTING,
	VIDEOCONF_SERVER_STOPPING,
	VIDEOCONF_JOINED_BREAKOUT,
	VIDEOCONF_CHANGED_CAMERA,
	VIDEOCONF_SELECT_REGION,
	VIDEOCONF_LEFT_BREAKOUT,
	VIDEOCONF_CHAT_TOGGLED,
	VIDEOCONF_CHANGED_MIC,
	SOCKET_OBSERVER_BREAKOUT_DEACTIVATED,
	SOCKET_OBSERVER_BREAKOUT_ACTIVATED,
	SOCKET_BREAKOUT_ROOMS_DEACTIVATED,
	SOCKET_BREAKOUT_ROOMS_ACTIVATED,
	SOCKET_MAIN_CALL_STARTED,
	SOCKET_MAIN_CALL_ENDED,
	TEXT_TRANSLATED,
} = types

let holdoff = false
let holdoffTimeout = null

function pauseServerStatusUpdates(ms = 3000) {
	holdoff = true
	clearTimeout(holdoffTimeout)
	holdoffTimeout = setTimeout(() => (holdoff = false), ms)
}

const isolateFromUpdates = callback => async (...args) => {
	pauseServerStatusUpdates()
	let error = null
	let result = null
	try {
		result = await callback(...args)
	} catch (err) {
		error = err
	}
	pauseServerStatusUpdates()

	if (error) throw error
	return result
}

// =================================================================================================

const getServerStatus: ActionCreator = () => async dispatch => {
	if (holdoff) return

	const status = await OnlineComms.getServerStatus()

	if (holdoff || !status) return

	// At this point, lazy load the Jitsi Meet library that we will use for videoconferencing
	// functionality from the server that we will be connecting to.
	// It is best if we use the same version of this library as the version of the server,
	// so we retrieve the version that comes with the server software.
	if (!jitsiLibLoaded()) {
		loadJitsiMeetLib(status.domain)

		// Only set the server status to 'online' if the lib-jitsi-meet library has been successfully
		// downloaded and loaded, otherwise it may take the user into a video call and crash because
		// the jitsi library hasn't yet been initialised.
		// It is currently 684KB.
		if (status.status === 'online') status.status = 'starting'
	}

	dispatch(action(VIDEOCONF_GOT_SERVER_STATUS, status))
}

const getServerRegions: ActionCreator = () => async dispatch => {
	const regions = await OnlineComms.getServerRegions()
	if (regions) dispatch(action(VIDEOCONF_GOT_SERVER_REGIONS, regions))
}

const startServer: ActionCreator<string> = region =>
	isolateFromUpdates(async dispatch => {
		if (!region) return
		dispatch(action(VIDEOCONF_SERVER_STARTING))
		await OnlineComms.startServer(region).catch(err => console.error(err))
	})

const stopServer: ActionCreator = () =>
	isolateFromUpdates(async (dispatch, getState) => {
		dispatch(action(VIDEOCONF_SERVER_STOPPING))
		actions.deactivateBreakoutRooms()(dispatch, getState)
		actions.deactivateObserverBreakoutRoom()(dispatch, getState)
		await OnlineComms.stopServer().catch(err => console.error(err))
	})

// const updateSelectedRegion: ActionCreator<string> = region => async (dispatch, getState) => {
// 	dispatch(action(VIDEOCONF_SELECT_REGION, region))
// 	await OnlineComms.updateSelectedServerId(region)
// }

const updateSelectedRegion: ActionCreator<string> = region =>
	isolateFromUpdates(async (dispatch, getState) => {
		dispatch(action(VIDEOCONF_SELECT_REGION, region))
		await OnlineComms.updateSelectedServerId(region)
	})

const joinBreakoutRoom: ActionCreator<string> = groupId =>
	isolateFromUpdates((dispatch, getState) => {
		if (!groupId) return
		dispatch(action(VIDEOCONF_JOINED_BREAKOUT, groupId))
	})

const leaveBreakoutRoom: ActionCreator = () =>
	isolateFromUpdates((dispatch, getState) => {
		dispatch(action(VIDEOCONF_LEFT_BREAKOUT))
	})

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
const startMainCall: ActionCreator<boolean> = (mainCallWarningEnabled = false) => (dispatch, getState) => {
	const facilitatorId = facilitatorIdSelector(getState())
	if (!facilitatorId) return

	// Pause server status updates until the video call component has started, a conference is
	// initialised and we've sent a notification message out to the participants
	pauseServerStatusUpdates(10000)

	// Generate a unique ID for this conference
	const mainCallId = uuid()
	const payload = { mainCallId, mainCallWarningEnabled, mainCallStarted: null }
	dispatch(action(SOCKET_MAIN_CALL_STARTED, payload))
}

const startMainCallWithWarning: ActionCreator = () => (dispatch, getState) => {
	startMainCall(true)(dispatch, getState)
}

const notifyMainCall: ActionCreator<string, string> = (callId, modId) =>
	isolateFromUpdates(async (dispatch, getState) => {
		const state = getState()
		const { mainCallWarningEnabled } = state.videoconf
		await OnlineComms.startMainCall(callId, modId, mainCallWarningEnabled)
	})

const endMainCall: ActionCreator = () =>
	isolateFromUpdates(async (dispatch, getState) => {
		dispatch(action(SOCKET_MAIN_CALL_ENDED, {}))
		await OnlineComms.endMainCall()
	})

const activateBreakoutRooms: ActionCreator = () =>
	isolateFromUpdates((dispatch, getState: () => StateTree) => {
		const state = getState()
		const serverId = state?.videoconf?.serverId
		const facilitatorId = facilitatorIdSelector(getState())
		// Call ID = MD5 of facilitator ID + date/time (hh:mm) (+ group ID)
		const callId = md5(facilitatorId + new Date().toISOString().substr(0, 16))
		dispatch(action(SOCKET_BREAKOUT_ROOMS_ACTIVATED, { callId }))
		SocketComms.activateBreakoutRooms(facilitatorId, callId, serverId)
	})

const deactivateBreakoutRooms: ActionCreator = () =>
	isolateFromUpdates((dispatch, getState) => {
		const facilitatorId = facilitatorIdSelector(getState())
		dispatch(action(SOCKET_BREAKOUT_ROOMS_DEACTIVATED, {}))
		SocketComms.deactivateBreakoutRooms(facilitatorId)
	})

const activateObserverBreakoutRoom: ActionCreator = () =>
	isolateFromUpdates((dispatch, getState) => {
		const facilitatorId = facilitatorIdSelector(getState())
		const callId = md5(facilitatorId + new Date().toISOString().substr(0, 16))
		dispatch(action(SOCKET_OBSERVER_BREAKOUT_ACTIVATED, { callId }))
		SocketComms.activateObserverBreakoutRoom(facilitatorId, callId)
	})

const deactivateObserverBreakoutRoom: ActionCreator = () =>
	isolateFromUpdates((dispatch, getState) => {
		const facilitatorId = facilitatorIdSelector(getState())
		dispatch(action(SOCKET_OBSERVER_BREAKOUT_DEACTIVATED, {}))
		SocketComms.deactivateObserverBreakoutRoom(facilitatorId)
	})

const selectCamera: ActionCreator<string> = cameraId => (dispatch, getState) => {
	const { selectedCamera } = getState()?.videoconf || {}
	if (cameraId !== selectedCamera) {
		dispatch(action(VIDEOCONF_CHANGED_CAMERA, cameraId))
	}
}

const selectInterpreter: ActionCreator<string> = interpreterId => dispatch => {
	dispatch(action(VIDEOCONF_CHANGED_INTERPRETER, interpreterId))
}

const selectMic: ActionCreator<string> = micId => dispatch => {
	dispatch(action(VIDEOCONF_CHANGED_MIC, micId))
}

const selectAudioOut: ActionCreator<string> = (micId: string) => dispatch => {
	dispatch(action(VIDEOCONF_CHANGED_AUDIO_OUT, micId))
}

const interpreterChangeChannel: ActionCreator<boolean> = onMainChannel => async (dispatch, getState) => {
	const state = getState()
	const facilitatorId = facilitatorIdSelector(state)
	const { clientId } = state
	dispatch(action(VIDEOCONF_INTERPRETER_CHANGED_CHANNEL, { clientId, onMainChannel }))
	await SocketComms.interpreterChangeChannel(facilitatorId, clientId, onMainChannel)
}

const toggleChat: ActionCreator<boolean> = enabled => async dispatch => {
	dispatch(action(VIDEOCONF_CHAT_TOGGLED, enabled))
}

const checkChatMessagesForTranslation: ActionCreator<ChatMessage[], ParticipantDetails[]> = (
	chat: ChatMessage[],
	remoteParticipants: ParticipantDetails[]
) => async (dispatch, getState) => {
	if (!chat || !chat.length) return

	const state = getState()
	const { participants } = state

	const loggedInAsParticipant = loggedInAsParticipantSelector(state)

	let ourLanguage = 'EN'
	if (loggedInAsParticipant && state?.group?.language) ourLanguage = state.group.language

	for (let i = 0; i < chat.length; i++) {
		// Get the chat message text
		const chatMessage = chat[i]
		const text = chatMessage.message
		if (!text) continue

		// Get the view360 participant ID for the participant who sent this message
		// NOTE: This is different from the Jitsi participant ID, so we need to look them up in the remote participants list
		const remoteParticipant = remoteParticipants.find(p => p.participantId === chatMessage.participantId)
		if (!remoteParticipant) continue

		let language = 'EN'
		const participantId = remoteParticipant?.properties?.participantId
		if (participantId) {
			// Get the participant details
			const participant = participants.find(p => p.id === participantId)
			if (!participant) continue
			// Check their language. If it is the same as ours then no translation needed
			language = participant.language || 'EN'
		}

		if (language === ourLanguage) continue

		// Now call the API to translate this message and store the result
		const translationResult = await OnlineComms.translate(language, ourLanguage, [text])
		const payload = { fromLang: language, toLang: ourLanguage, text, translation: translationResult[0] }
		dispatch(action(TEXT_TRANSLATED, payload))
	}
}

const actions = {
	checkChatMessagesForTranslation,
	deactivateObserverBreakoutRoom,
	activateObserverBreakoutRoom,
	interpreterChangeChannel,
	startMainCallWithWarning,
	deactivateBreakoutRooms,
	activateBreakoutRooms,
	updateSelectedRegion,
	leaveBreakoutRoom,
	selectInterpreter,
	getServerRegions,
	joinBreakoutRoom,
	getServerStatus,
	notifyMainCall,
	selectAudioOut,
	startMainCall,
	selectCamera,
	startServer,
	endMainCall,
	stopServer,
	toggleChat,
	selectMic,
}

export default actions
