import React, { FunctionComponent } from 'react'

type RowProps = {
	className?: string
}
const Row: FunctionComponent<RowProps> = ({ children, className = '' }) => (
	<div className={`row ${className}`}>{children}</div>
)

export default Row
