import $ from 'jquery'
import * as Sentry from '@sentry/browser'
import { IVideoClient } from '../components/videoconf/VideoTypes'

// lib-jitsi-meet.min.js is copied into the public directly and added to index.html as a script tag.
// JitsiMeetJS is then available globally at window.JitsiMeetJS
// We use this method to avoid any complications from bundling the library using our package bundler.

type CreateLocalTracksParams = {
	devices: string[]
	micDeviceId?: string
	cameraDeviceId?: string
}
type JitsiConnectionParams = {
	bosh: string
	hosts: { domain: string; muc: string }
}
type JitsiConnection = {
	addEventListener: (eventType: string, callback: () => void) => void
	connect: () => void
	disconnect: () => void
	initJitsiConference: (confId: string, options: ConferenceOptions) => JitsiRoom
}
type ConferenceOptions = typeof CONFERENCE_OPTIONS
type JitsiMeetJSType = {
	setLogLevel: (logLevel: string) => void
	logLevels: Record<string, string>
	events: Record<string, Record<string, string>>
	createLocalTracks: (params: CreateLocalTracksParams) => JitsiTrack[]
	JitsiConnection: (a: unknown, b: unknown, params: JitsiConnectionParams) => void
	mediaDevices: MediaDevices
	init: (options: JitsiInitOptions) => void
}
type JitsiInitOptions = {
	enableAnalyticsLogging: boolean
}
type MediaDevices = {
	enumerateDevices: (callback: (devices: MediaDeviceInfo[]) => void) => void
	isDeviceChangeAvailable: (type: string) => boolean
	setAudioOutputDevice: (deviceId: string) => void
	getAudioOutputDevice: () => string
}
interface Window {
	JitsiMeetJS: JitsiMeetJSType
	$: unknown
}
declare const window: Window

// const { JitsiMeetJS } = window
// Add jquery to global namespace, which is needed for new JitsiMeetJS.JitsiConnection
window.$ = $

// -----------------------------------------------------------------
// https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-ljm-api
// -----------------------------------------------------------------

const CONFERENCE_OPTIONS = {
	openBridgeChannel: true,
	channelLastN: 4,
	applicationName: 'view360',
	useNewBandwidthAllocationStrategy: true,
	callStatsID: '577928691',
	callStatsSecret: 'i8xO2ZJG5c2m:yMGngJ2uX5JPEArqslADWxoVdIcLRtF6196upqLZOVk=',
	statisticsDisplayName: null, // to be set later
	enableUnifiedOnChrome: true,
	// p2p: { enabled: false },
	p2p: {
		enabled: false,
		enableUnifiedOnChrome: true,
	},
}

const DEFAULT_DISPLAY_NAME = 'Participant'

type EventCallback = (...any) => void

type JitsiClientParams = {
	domain: string
	conferenceId: string
	displayName?: string
	cameraDeviceId?: string
}

type ConnectParams = {
	cameraDeviceId?: string
	audioInputDeviceId?: string
	audioOutputDeviceId?: string
	startAudioMuted?: boolean
}

class JitsiClient implements IVideoClient {
	domain: string
	conferenceId: string
	cameraDeviceId: string
	audioInputDeviceId: string
	audioOutputDeviceId: string
	isJoined: boolean
	videoUnavailable: boolean
	audioUnavailable: boolean
	startAudioMuted: boolean
	participantId: string
	properties: SimpleObject
	chat: Array<JitsiChatMessage>

	room: JitsiRoom
	connection: JitsiConnection
	localTracks: Array<JitsiTrack> = []
	remoteParticipants: Array<JitsiParticipantDetails>
	localParticipant: JitsiParticipantDetails
	primaryId: string

	// Event handlers is a map of functions that map to a key, being any one of the JitsiClientEvents
	eventHandlers: PartialRecord<JitsiClientEvent, Record<string, EventCallback>>

	// eslint thinks this is the standard React component displayName property
	// eslint-disable-next-line react/static-property-placement
	displayName: string

	constructor({ domain, displayName, conferenceId, cameraDeviceId }: JitsiClientParams) {
		this.displayName = displayName || DEFAULT_DISPLAY_NAME
		this.conferenceId = conferenceId
		this.domain = domain
		this.isJoined = false
		this.eventHandlers = {}
		this.remoteParticipants = []
		this.cameraDeviceId = cameraDeviceId
		this.localTracks = []
		this.properties = {}
		this.localParticipant = { isLocal: true, properties: {} }
		this.chat = []
		this.primaryId = null

		CONFERENCE_OPTIONS.statisticsDisplayName = this.displayName

		this.toggleMuteAudio = this.toggleMuteAudio.bind(this)
		this.toggleMuteCamera = this.toggleMuteCamera.bind(this)
		this.videoUnavailable = false
		this.audioUnavailable = false

		// Only allow ERROR log messages in console

		const { JitsiMeetJS } = window
		JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR)
		// Configure the JitsiMeet API
		// https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-ljm-api#jitsimeetjs
		const initOptions: JitsiInitOptions = { enableAnalyticsLogging: false }
		JitsiMeetJS.init(initOptions)
	}

	async connect(params: ConnectParams): Promise<void> {
		const { cameraDeviceId, audioInputDeviceId, audioOutputDeviceId, startAudioMuted } = params
		this.cameraDeviceId = cameraDeviceId || this.cameraDeviceId || null
		this.audioInputDeviceId = audioInputDeviceId || this.audioInputDeviceId || null
		this.audioOutputDeviceId = audioOutputDeviceId || this.audioOutputDeviceId
		this.startAudioMuted = startAudioMuted

		const { domain } = this
		const connectionOptions = {
			bosh: `https://${domain}/http-bind`,
			hosts: { domain, muc: `conference.${domain}` },
		}
		const { JitsiMeetJS } = window
		const conn = new JitsiMeetJS.JitsiConnection(null, null, connectionOptions)

		conn.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess.bind(this))
		conn.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed.bind(this))
		conn.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.onDisconnect.bind(this))
		conn.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DROPPED_ERROR, this.onConnectionDropped.bind(this))

		conn.connect()

		this.connection = conn

		// Create local audio and video tracks using default devices.
		// Create audio track first
		try {
			const tracks = await JitsiMeetJS.createLocalTracks({ devices: ['audio'], micDeviceId: this.audioInputDeviceId })
			this.onLocalTracks(tracks)
			if (startAudioMuted) {
				this.toggleMuteAudio()
			}
		} catch (error) {
			console.error('Error creating local track:', error)
			Sentry.captureException(error)
			// const errorMessage = error?.message || error?.gum?.error?.message || error?.gum?.error?.name
			this.audioUnavailable = true
			this.localParticipant.isMuted = true
			this.localParticipant.audioTrack = null
		}

		// Create video track
		try {
			const tracks = await JitsiMeetJS.createLocalTracks({ devices: ['video'], cameraDeviceId: this.cameraDeviceId })
			this.onLocalTracks(tracks)
		} catch (error) {
			console.error('Error creating local track:', error)
			Sentry.captureException(error)
			// const errorMessage = error?.message || error?.gum?.error?.message || error?.gum?.error?.name
			this.videoUnavailable = true
			this.localParticipant.isCameraMuted = true
			this.localParticipant.videoTrack = null
		}
		this.dispatch(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED)
	}

	async unload(): Promise<void> {
		const localTracks = this.localTracks || []
		for (let i = 0; i < localTracks.length; i++) {
			await this.localTracks[i].dispose()
		}
		if (this.room) await this.room.leave()
		if (this.connection) await this.connection.disconnect()
	}

	setDisplayName(name: string): void {
		this.displayName = name
		this.localParticipant.displayName = name
		CONFERENCE_OPTIONS.statisticsDisplayName = this.displayName
		if (this.isJoined) this.room.setDisplayName(name)
	}

	setProperty(name: string, value: unknown): void {
		this.properties[name] = value
		if (this.isJoined) this.room.setLocalParticipantProperty(name, value)
		this.localParticipant.properties = this.localParticipant.properties || {}
		this.localParticipant.properties[name] = value
	}

	private onRemoteStatsUpdated(participantId, statistics) {
		const remoteParticipant = this.remoteParticipants.find(p => p.participantId === participantId)
		if (!remoteParticipant) return
		remoteParticipant.stats = statistics
		this.dispatch(JitsiClientEvents.REMOTE_PARTICIPANT_STATISTICS_RECEIVED, { participantId, statistics })
	}

	private onLocalStatsUpdated(statistics) {
		this.localParticipant.stats = statistics
		this.dispatch(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED, this.localParticipant.videoTrack)
		this.dispatch(JitsiClientEvents.LOCAL_STATISTICS_RECEIVED, statistics)
	}

	private onConnectionFailed(): void {
		console.log('JitsiClient: onConnectionFailed')
	}

	private onConnectionDropped(e: Error): void {
		console.log('JitsiClient: onConnectionDropped', e)
		Sentry.captureException(e)
	}

	private onDisconnect(): void {
		console.log('JitsiClient: onDisconnect')
	}

	async toggleMuteAudio(): Promise<boolean> {
		this.localParticipant.isMuted = await this.toggleTrackMute('audio')
		this.dispatch(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED, this.localParticipant.audioTrack)
		return this.localParticipant.isMuted
	}

	async toggleMuteCamera(): Promise<boolean> {
		const { JitsiMeetJS } = window
		if (this.videoUnavailable) {
			try {
				const cameraDeviceId = this.cameraDeviceId || null
				const tracks = await JitsiMeetJS.createLocalTracks({ devices: ['video'], cameraDeviceId })
				this.onLocalTracks(tracks)
				this.videoUnavailable = false
				this.localParticipant.isCameraMuted = false
			} catch (err) {
				console.error(err)
				this.videoUnavailable = true
				this.localParticipant.isCameraMuted = true
			}
		} else {
			try {
				this.localParticipant.isCameraMuted = await this.toggleTrackMute('video')
			} catch (err) {
				console.error('Caught error when toggling mute of camera', err)
				this.videoUnavailable = true
				this.localParticipant.isCameraMuted = true
			}
		}
		const videoTrack = this.localTracks.find(t => t.getType() === 'video')
		this.dispatch(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED, videoTrack)
		return this.localParticipant.isCameraMuted
	}

	async toggleTrackMute(type: string): Promise<boolean> {
		const track = this.localTracks.find(t => t.getType() === type)
		if (!track) return false
		if (track.isMuted()) {
			await track.unmute()
		} else {
			await track.mute()
		}
		return track.isMuted()
	}

	private onConnectionSuccess(): void {
		console.log(`JitsiClient: onConnectionSuccess`)

		const conferenceOptions = { ...CONFERENCE_OPTIONS, startAudioMuted: this.startAudioMuted }
		const room = this.connection.initJitsiConference(this.conferenceId, conferenceOptions)

		this.room = room
		this.participantId = room.myUserId()
		this.localParticipant.participantId = this.participantId

		const { JitsiMeetJS } = window
		room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined.bind(this))
		room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onTrackAddedToRoom.bind(this))
		room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, this.onTrackRemovedFromRoom.bind(this))
		room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoinedRoom.bind(this))
		room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeftRoom.bind(this))
		room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, this.onTrackMuteChanged.bind(this))
		room.on(JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED, this.onDisplayNameChanged.bind(this))
		room.on(JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED, this.onDominantSpeakerChanged.bind(this))
		room.on(JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED, this.onParticipantPropertyChanged.bind(this))
		room.on(JitsiMeetJS.events.conference.PARTICIPANT_CONN_STATUS_CHANGED, this.onUserStatusChanged.bind(this))
		room.on(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED, this.onLocalStatsUpdated.bind(this))
		room.on(JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED, this.onRemoteStatsUpdated.bind(this))
		room.on(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, this.onMessageReceived.bind(this))
		room.on(JitsiMeetJS.events.conference.PRIVATE_MESSAGE_RECEIVED, this.onPrivateMessageReceived.bind(this))
		room.on(JitsiMeetJS.events.conference.LAST_N_ENDPOINTS_CHANGED, this.onLastNEndpointsChanged.bind(this))
		// room.on(JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED, (a, b) => console.log('ENDPOINT_MESSAGE_RECEIVED', a, b) )

		room.join()
	}

	on(eventType: JitsiClientEvent, callback: EventCallback): number {
		if (!this.eventHandlers[eventType]) this.eventHandlers[eventType] = {}
		const id = Math.floor(Math.random() * 100000000)
		this.eventHandlers[eventType][id] = callback
		return id
	}

	dispatch(eventType: JitsiClientEvent, data?: unknown): void {
		const handlers = this.eventHandlers[eventType]
		if (!handlers) return
		Object.keys(handlers).forEach(ref => {
			const callback = handlers[ref]
			callback(data)
		})
	}

	off(refOrCallback: number | EventCallback): void {
		if (typeof refOrCallback === 'number' || typeof refOrCallback === 'string') {
			const handlerRef = refOrCallback
			// Find event handler that matches ref
			Object.keys(this.eventHandlers).forEach(eventType => {
				const handlers = this.eventHandlers[eventType]
				if (handlers[handlerRef]) delete handlers[handlerRef]
			})
		} else {
			const callback = refOrCallback
			// Find event handler that matches this callback
			Object.keys(this.eventHandlers).forEach(eventType => {
				const handlers = this.eventHandlers[eventType]
				const ref = Object.keys(handlers).find(key => handlers[key] === callback)
				if (ref) delete handlers[ref]
			})
		}
	}

	private onScreenSharingCancelled(): Promise<void> {
		console.log('JitsiClient: onScreenSharingCancelled')
		Sentry.captureMessage('JitsiClient: onScreenSharingCancelled')
		// Revert to camera video device
		console.log('this.cameraDeviceId', this.cameraDeviceId)
		return this.changeVideoInput(this.cameraDeviceId, true)
	}

	private onMessageReceived(fromId: string, message: string, ts: number): void {
		console.log(`JitsiClient: MESSAGE_RECEIVED fromId, message, timestamp`, fromId, message, ts)
		// Message to all participants received
		const timestamp = ts || Date.now()
		const chatMsg: JitsiChatMessage = { participantId: fromId, message, timestamp }
		this.chat.push(chatMsg)
		this.dispatch(JitsiClientEvents.MESSAGE_RECEIVED, chatMsg)
	}

	private onPrivateMessageReceived(fromId: string, message: string, ts: number): void {
		console.log(`JitsiClient: PRIVATE_MESSAGE_RECEIVED fromId, message, timestamp`, fromId, message, ts)
		// Direct message received
		const timestamp = ts || Date.now()
		const chatMsg: JitsiChatMessage = { participantId: fromId, message, timestamp, privateMsg: true }
		this.chat.push(chatMsg)
		this.dispatch(JitsiClientEvents.MESSAGE_RECEIVED, chatMsg)
	}

	sendChatMessage(message: string, participantId?: string): void {
		if (!message || !this.isJoined) return

		// Is message a direct message?
		if (participantId) {
			const recipient = this.remoteParticipants.find(p => p.participantId === participantId)
			if (!recipient) return

			this.room.sendPrivateTextMessage(participantId, message)

			// Private messages won't come back to us in an event, so we need to add it to our local
			// chat state now.
			const chatMsg: JitsiChatMessage = {
				participantId: this.localParticipant.participantId,
				to: recipient.displayName,
				timestamp: Date.now(),
				privateMsg: true,
				message,
			}
			this.chat.push(chatMsg)
			this.dispatch(JitsiClientEvents.MESSAGE_RECEIVED, chatMsg)
		} else {
			this.room.sendTextMessage(message)
		}
	}

	private onLastNEndpointsChanged(leavingIds: Array<string>, enteringIds: Array<string>): void {
		console.log('JitsiClient: LAST_N_ENDPOINTS_CHANGED', leavingIds, enteringIds)
		const allIds = [...leavingIds, ...enteringIds]

		// Check if the 'status' for any remote participants has changed
		let changed = false
		allIds.forEach(participantId => {
			const participant = this.remoteParticipants.find(p => p.participantId === participantId)
			const details = this.room.getParticipantById(participantId)
			const status = details && details.getConnectionStatus()
			if (!participant || !details || !status) return
			if (participant.status !== status) {
				participant.status = status
				changed = true
			}
		})
		if (changed) this.dispatch(JitsiClientEvents.REMOTE_PARTICIPANTS_CHANGED)
	}

	/**
	 * Handles local tracks after they have been created with JitsiMeetJS.createLocalTracks
	 * @param tracks Array with JitsiTrack objects
	 */
	private async onLocalTracks(tracks: Array<JitsiTrack>): Promise<void> {
		console.log(`JitsiClient: localTracks`, tracks)
		const { JitsiMeetJS } = window

		for (let i = 0; i < tracks.length; i++) {
			const track = tracks[i]

			if (!this.localTracks.includes(track)) this.localTracks.push(track)

			// TODO: Only need to do this if track type = audio?
			track.on(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, (level: number) =>
				this.onAudioLevelChanged(this.participantId, level)
			)

			if (track.getType() === 'audio') {
				this.localParticipant.audioTrack = track
				this.localParticipant.isMuted = track.isMuted()
				if (!this.audioInputDeviceId) this.audioInputDeviceId = track.deviceId
			} else {
				this.localParticipant.videoTrack = track
				this.localParticipant.isCameraMuted = track.isMuted()
				if (!this.cameraDeviceId) this.cameraDeviceId = track.deviceId
			}
			this.dispatch(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED, track)

			// Add track to conference if we are currently in a room
			if (this.isJoined && this.room) {
				console.log('JitsiClient: Adding track to room:', track)
				this.room.addTrack(track)
			}
		}
	}

	private onConferenceJoined(): void {
		console.log(`JitsiClient: onConferenceJoined`)
		Sentry.captureMessage(`JitsiClient: onConferenceJoined`)

		this.isJoined = true
		this.room.setDisplayName(this.displayName)
		Object.keys(this.properties).forEach(key => {
			if (this.properties[key]) this.room.setLocalParticipantProperty(key, this.properties[key])
		})

		for (let i = 0; i < this.localTracks.length; i++) {
			if (!this.localTracks[i]?.disposed) {
				this.room.addTrack(this.localTracks[i])
			}
		}

		this.dispatch(JitsiClientEvents.ROOM_JOINED)

		const participants = this.room.getParticipants()
		participants.forEach(participant => {
			const participantId = participant.getId()
			console.log('Remote participant in this room:', participant)
			let rp = this.remoteParticipants.find(p => p.participantId === participantId)
			if (!rp) {
				rp = { participantId }
				this.remoteParticipants.push(rp)
			}
			rp.displayName = participant.getDisplayName()
		})
		this.dispatch(JitsiClientEvents.REMOTE_PARTICIPANTS_CHANGED)
	}

	private onTrackAddedToRoom(track: JitsiTrack): void {
		if (track.isLocal()) return

		const { JitsiMeetJS } = window

		const participantId = track.getParticipantId()

		console.log(`JitsiClient: onTrackAddedToRoom`, track, participantId)
		// Sentry.captureMessage(`JitsiClient: onTrackAddedToRoom`)

		track.on(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, (level: number) => {
			this.onAudioLevelChanged(participantId, level)
		})
		track.on(JitsiMeetJS.events.track.TRACK_VIDEOTYPE_CHANGED, videoType => {
			console.log('videoType: ', videoType)
			this.dispatch(JitsiClientEvents.REMOTE_PARTICIPANTS_CHANGED)
		})

		let participant = this.remoteParticipants.find(p => p.participantId === participantId)
		if (!participant) {
			participant = { participantId }
			this.remoteParticipants.push(participant)
		}

		if (track.getType() === 'video') {
			participant.videoTrack = track
			participant.isCameraMuted = track.isMuted()
		} else {
			participant.audioTrack = track
			participant.isMuted = track.isMuted()
		}

		this.dispatch(JitsiClientEvents.REMOTE_PARTICIPANTS_CHANGED)
	}

	private onTrackRemovedFromRoom(track: JitsiTrack): void {
		console.log('onTrackRemovedFromRoom', track)
		const remoteParticipant = this.remoteParticipants.find(p => p.audioTrack === track || p.videoTrack === track)
		if (!remoteParticipant) return
		if (remoteParticipant.videoTrack === track) remoteParticipant.videoTrack = null
		if (remoteParticipant.audioTrack === track) remoteParticipant.audioTrack = null
		this.dispatch(JitsiClientEvents.REMOTE_PARTICIPANTS_CHANGED)
	}

	private onTrackMuteChanged(track: JitsiTrack): void {
		console.log(`JitsiClient: onTrackMuteChanged ${track.getType()} - ${track.isMuted()}`)

		// Check if participant is local or remote
		if (this.localTracks.includes(track)) {
			if (track.getType() === 'video') this.localParticipant.isCameraMuted = track.isMuted()
			if (track.getType() === 'audio') this.localParticipant.isMuted = track.isMuted()
			this.dispatch(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED, track)
			return
		}
		const remoteParticipant = this.remoteParticipants.find(p => p.videoTrack === track || p.audioTrack === track)
		if (remoteParticipant) {
			if (track.getType() === 'video') remoteParticipant.isCameraMuted = track.isMuted()
			if (track.getType() === 'audio') remoteParticipant.isMuted = track.isMuted()
			this.dispatch(JitsiClientEvents.REMOTE_PARTICIPANTS_CHANGED)
		}
	}

	private onDominantSpeakerChanged(participantId: string): void {
		console.log(`JitsiClient: onDominantSpeakerChanged`, participantId)
		this.dispatch(JitsiClientEvents.DOMINANT_SPEAKER_CHANGED, { participantId })
	}

	private onParticipantPropertyChanged(participant: JitsiInternalParticipantDetails, property: string): void {
		console.log('JitsiClient: onParticipantPropertyChanged', participant, property)
		const participantId = participant.getId()
		const remoteParticipant = this.remoteParticipants.find(p => p.participantId === participantId)
		if (remoteParticipant) {
			remoteParticipant.properties = participant._properties
			this.dispatch(JitsiClientEvents.REMOTE_PARTICIPANTS_CHANGED)
		}
		// const value = participant.getProperty(property)
		// this.dispatch(JitsiClientEvents.PARTICIPANT_PROPERTY_CHANGED, { participantId, property, value })
		// - Until we find a reason for this, just dispatch a REMOTE_PARTICIPANTS_CHANGED instead
	}

	private onAudioLevelChanged(participantId: string, level: number): void {
		this.dispatch(JitsiClientEvents.AUDIO_LEVEL_CHANGED, { participantId, level })
	}

	private onUserJoinedRoom(participantId: string): void {
		console.log(`JitsiClient: onUserJoinedRoom`, participantId)
		Sentry.captureMessage(`JitsiClient: onUserJoinedRoom`)
		if (!this.remoteParticipants.find(p => p.participantId === participantId)) {
			const user = this.room.getParticipantById(participantId)
			if (!user) return
			const participant: JitsiParticipantDetails = {
				participantId,
				displayName: user.getDisplayName(),
				status: user.getConnectionStatus(),
			}
			this.remoteParticipants.push(participant)
		}
	}

	async toggleScreenShare(): Promise<void> {
		if (!this.isJoined) return

		const { JitsiMeetJS } = window

		if (this.localTracks.find(t => t.videoType === 'desktop')) {
			return this.onScreenSharingCancelled()
		}

		let desktopTrack
		try {
			// Attempt to create new local 'desktop' track
			const tracks = await JitsiMeetJS.createLocalTracks({ devices: ['desktop'] })
			desktopTrack = tracks.find(t => t.getType() === 'video')
		} catch (error) {
			console.log('JitsiClient: User chose to cancel screensharing or it was not allowed/available')
			console.log(error)
			return
		}

		// Dispose of previous video track
		const oldTrack = this.localTracks.find(t => t.getType() === 'video')
		if (oldTrack) {
			await this.room.replaceTrack(oldTrack, desktopTrack)
			if (!oldTrack.disposed) await oldTrack.dispose()
			this.localTracks.splice(this.localTracks.indexOf(oldTrack), 1)
		} else {
			await this.room.addTrack(desktopTrack)
		}
		this.localTracks.push(desktopTrack)
		this.localParticipant.videoTrack = desktopTrack
		this.videoUnavailable = false
		this.localParticipant.isCameraMuted = false
		this.dispatch(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED, desktopTrack)
		desktopTrack.on(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, this.onScreenSharingCancelled.bind(this))
	}

	isScreenshareActive(): boolean {
		return this?.localParticipant?.videoTrack?.videoType === 'desktop'
	}

	private onUserLeftRoom(participantId: string): void {
		const index = this.remoteParticipants.findIndex(p => p.participantId === participantId)
		if (index === -1) return

		this.remoteParticipants.splice(index, 1)
		this.dispatch(JitsiClientEvents.REMOTE_PARTICIPANTS_CHANGED)
	}

	private onDisplayNameChanged(participantId: string, displayName: string): void {
		let participant = this.remoteParticipants.find(p => p.participantId === participantId)
		if (!participant) {
			participant = { participantId }
			this.remoteParticipants.push(participant)
		}
		participant.displayName = displayName
		this.dispatch(JitsiClientEvents.REMOTE_PARTICIPANTS_CHANGED)
	}

	private onUserStatusChanged(id: string, status: string) {
		console.log('Jitsi: onUserStatusChanged', id, status)

		const participant = this.remoteParticipants.find(p => p.participantId === id)
		if (!participant) return
		participant.status = status
		this.dispatch(JitsiClientEvents.REMOTE_PARTICIPANTS_CHANGED)
	}

	getDeviceList(kind?: string): Promise<Array<MediaDeviceInfo>> {
		const { JitsiMeetJS } = window
		return new Promise(resolve => {
			JitsiMeetJS.mediaDevices.enumerateDevices(devices => {
				resolve(devices.filter(d => !kind || d.kind === kind))
			})
		})
	}

	getAudioOutputDevice(): string {
		const { JitsiMeetJS } = window
		return JitsiMeetJS.mediaDevices.getAudioOutputDevice()
	}

	setAudioOutputDevice(deviceId: string): void {
		const { JitsiMeetJS } = window
		if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output')) {
			JitsiMeetJS.mediaDevices.setAudioOutputDevice(deviceId)
		}
	}

	async changeVideoInput(deviceId: string, force = false): Promise<void> {
		const { JitsiMeetJS } = window

		// Don't allow video to be changed if it doesn't need to be switched to this new device
		if (this.cameraDeviceId === deviceId && !this.videoUnavailable && !force) return
		// Don't allow video to be changed unless we have connected to a room
		if (!this.isJoined) return

		this.cameraDeviceId = deviceId

		// Get the current video track. If it is already using the correct device, we can leave now
		const oldTrack = this.localTracks.find(t => t.getType() === 'video')
		if (oldTrack && oldTrack.deviceId === deviceId) return

		// if (this.localParticipant.isCameraMuted) return

		try {
			// try {
			// 	console.log('oldTrack', oldTrack)
			// 	console.log('oldTrack.disposed', oldTrack.disposed)
			// 	console.log('oldTrack.track.readyState', oldTrack.track.readyState)
			// 	console.log('oldTrack.track.enabled', oldTrack.track.enabled)
			// } catch (err) {
			// 	console.error(err)
			// }

			const tracks = await JitsiMeetJS.createLocalTracks({ devices: ['video'], cameraDeviceId: deviceId })
			const newTrack = tracks[0]
			if (oldTrack) {
				await this.room.replaceTrack(oldTrack, newTrack)
				// Jitsi should have disposed the previous video track, but we will check
				if (!oldTrack.disposed) await oldTrack.dispose()
				this.localTracks.splice(this.localTracks.indexOf(oldTrack), 1)
			} else {
				this.room.addTrack(newTrack)
			}
			this.localTracks.push(newTrack)
			this.localParticipant.videoTrack = newTrack

			newTrack.on(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, (level: number) =>
				this.onAudioLevelChanged(this.participantId, level)
			)
			this.videoUnavailable = false
			this.localParticipant.isCameraMuted = false
			this.dispatch(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED, newTrack)
		} catch (err) {
			Sentry.captureException(err)
			this.videoUnavailable = true
			this.localParticipant.isCameraMuted = true
			this.localParticipant.videoTrack = null
			if (oldTrack) {
				this.room.removeTrack(oldTrack)
				this.localTracks.splice(this.localTracks.indexOf(oldTrack), 1)
				await oldTrack.dispose()
			}
			this.dispatch(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED)
		}
	}

	async changeAudioInput(deviceId: string): Promise<void> {
		if (this.audioInputDeviceId === deviceId && !this.audioUnavailable) return
		if (!this.localTracks) return
		if (!this.isJoined) return

		const { JitsiMeetJS } = window

		// If we have an active audio track, remove it
		const currentAudioTrack = this.localTracks.find(t => t.getType() === 'audio')
		if (currentAudioTrack) {
			if (currentAudioTrack.deviceId === deviceId) return
			if (this.isJoined) await this.room.removeTrack(currentAudioTrack).catch(e => console.error(e))
			await currentAudioTrack.dispose()
			this.localTracks.splice(this.localTracks.indexOf(currentAudioTrack), 1)
		}

		this.audioInputDeviceId = deviceId

		// Create new audio track
		try {
			const tracks = await JitsiMeetJS.createLocalTracks({ devices: ['audio'], micDeviceId: deviceId })
			const newTrack = tracks[0]
			this.localTracks.push(newTrack)
			if (this.isJoined) this.room.addTrack(newTrack)
			this.audioUnavailable = false
			if (this.localParticipant?.isMuted) this.toggleMuteAudio()
			this.dispatch(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED, newTrack)
		} catch (err) {
			this.audioUnavailable = true
			this.localParticipant.isMuted = true
		}
	}

	setPrimaryRemoteParticipant(participantId: string): void {
		if (!this.room) return
		if (this.primaryId === participantId) return
		this.primaryId = participantId

		try {
			// this.room.selectParticipant(participantId)
			const constraints = {
				lastN: CONFERENCE_OPTIONS.channelLastN,
				selectedEndpoints: [participantId],
				onStageEndpoints: [participantId],
				defaultConstraints: { maxHeight: 180 },
				constraints: { [participantId]: { maxHeight: 720 } },
			}
			this.room.setReceiverConstraints(constraints)
			console.log('setReceiverConstraints', constraints)
		} catch (err) {
			console.error('Could not select participant for primary viewing:', err)
		}
	}

	maxReceivedVideoHeight?: number

	/* Applies a consistent maxHeight for all received video streams */
	setMaxReceivedVideoHeight(height: number): void {
		if (!this.room) return

		if (this.maxReceivedVideoHeight === height) return
		this.maxReceivedVideoHeight = height

		this.primaryId = null
		try {
			const constraints = {
				lastN: CONFERENCE_OPTIONS.channelLastN,
				selectedEndpoints: [],
				onStageEndpoints: [],
				defaultConstraints: { maxHeight: Math.floor(height) },
				constraints: {},
			}
			this.room.setReceiverConstraints(constraints)
			console.log('setReceiverConstraints', constraints)
		} catch (err) {
			console.error('Could not set receiver constraints:', err)
		}
	}
}

export default JitsiClient

// eslint-disable-next-line no-shadow
export enum JitsiClientEvents {
	LOCAL_PARTICIPANT_CHANGED,
	REMOTE_PARTICIPANTS_CHANGED,
	DOMINANT_SPEAKER_CHANGED,
	REMOTE_PARTICIPANT_STATISTICS_RECEIVED,
	LOCAL_STATISTICS_RECEIVED,
	AUDIO_LEVEL_CHANGED,
	PARTICIPANT_PROPERTY_CHANGED,
	ROOM_JOINED,
	MESSAGE_RECEIVED,
}

type JitsiClientEvent = JitsiClientEvents
