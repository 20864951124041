import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import InitialInformationModal from './InitialInformationModal'
import SelectInterpreterModal from './SelectInterpreterModal'
import PreviousSessionsModal from './PreviousSessionsModal'
import VideoParticipantModal from './videoconf/VideoParticipantModal'
import ConfigureVideoModal from './ConfigureVideoModal'
import MediaOpeningsModal from './MediaOpeningsModal'
import GroupReleaseModal from './GroupReleaseModal'
import MediaUploadModal from './MediaUploadModal'
import EditCaseModal from './EditCaseModal'
import NewCaseModal from './NewCaseModal'
import UpdateModal from './UpdateModal'
import MediaModal from './MediaModal'

import IconButton from '../elements/IconButton'

import config from '../../config'
import Actions from '../actions'
import EditMediaItemModal from './EditMediaItemModal'
import MediaForPhoneModal from './MediaForPhoneModal'

type ModalProps = {
	location: string
	parms?: unknown
	displayModalPopup: (popupId: string, parms?: SimpleObject | string) => void
}

const Modal: FunctionComponent<ModalProps> = props => {
	const { location, parms } = props

	if (!location || location.indexOf('modal-') !== 0) return <div />

	if (location === 'modal-media') return <MediaModal />
	if (location === 'modal-update') return <UpdateModal update={parms} />
	if (location === 'modal-initial-information') return <InitialInformationModal update={parms} />

	let content = null

	const modalName = location.substr(6).toUpperCase().split('-').join('')
	const titleKey = `POPUP_TITLE_${modalName}`
	let titleString = config.strings[titleKey] || config.strings[modalName]

	if (location === 'modal-newcase') content = <NewCaseModal />
	if (location === 'modal-edit-case') content = <EditCaseModal data={parms as Case} />
	if (location === 'modal-edit-media') content = <EditMediaItemModal data={parms as MediaItem} />
	if (location === 'modal-grouprelease') content = <GroupReleaseModal scheduleRowId={parms as string} />
	if (location === 'modal-media-upload') content = <MediaUploadModal />
	if (location === 'modal-prevsessions') content = <PreviousSessionsModal />
	if (location === 'modal-configure-video') content = <ConfigureVideoModal />
	if (location === 'modal-video-participant') content = <VideoParticipantModal participantId={parms as string} />
	if (location === 'modal-select-interpreter') content = <SelectInterpreterModal />

	if (location === 'modal-media-for-phone') {
		const { mediaitem, rowId } = parms as { mediaitem: MediaItem; rowId: string }
		console.log('mediaitem', mediaitem)
		content = <MediaForPhoneModal media={mediaitem} rowId={rowId} />
		titleString = mediaitem.name
	}

	if (location === 'modal-media-openings') {
		const { rowId, groupId, title } = parms as { rowId: string; groupId: string; title: string }
		titleString = title
		content = <MediaOpeningsModal rowId={rowId} groupId={groupId} />
	}

	console.log('location', location)
	const className = `modal modal-${location.substr(6)}`

	// Close modal by setting modal location to blank
	const close = () => props.displayModalPopup('')

	return (
		<div className="modal__container">
			<div className={className}>
				<div className="modal__header">
					<div className="modal__title">{titleString}</div>
					<IconButton iconName="close" onClick={close} title="Close popup" />
				</div>
				<div className="modal__content">{content}</div>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<ModalProps> => ({
	location: state.navigatingToModal || '',
	parms: state.modalParms || {},
})
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
}
export default connect(mapStateToProps, actions)(Modal)
