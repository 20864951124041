import AgoraRTC from 'agora-rtc-sdk-ng'

// import AgoraRTC from '../lib/AgoraRTC_N-4.12.1'
// import AgoraRTM from 'agora-rtm-sdk'

import AgoraRTM from '../lib/agora-rtm-sdk'

// RTC: https://docs.agora.io/en/Video/API%20Reference/web_ng/index.html
// RTM: https://docs.agora.io/en/Real-time-Messaging/API%20Reference/RTM_web/index.html

import OnlineComms from '../core/OnlineComms'
import config from '../../config'

import {
	AgoraLocalVideoStats,
	AgoraParticipant,
	AgoraRemoteVideoStats,
	AgoraNetworkQuality,
	AgoraTrack,
	AgoraWindow,
	RTMChannel,
	RTMClient,
	CameraVideoTrackInitConfig,
	MicrophoneAudioTrackInitConfig,
} from './AgoraClientTypes'

import {
	ClientParams,
	ConnectParams,
	EventCallback,
	IVideoClient,
	ParticipantDetails,
	ParticipantStats,
	ParticipantTrack,
	VideoChatMessage,
	VideoClientEvent,
	VideoClientEvents,
} from '../components/videoconf/VideoTypes'

const { AGORA_APP_ID } = config

const LOW_THRESHOLD = 3 // Number of users to display at high quality
const HIGH_THRESHOLD = 11 // Number of users to display at low quality

const LOW_STREAM = 1
const HIGH_STREAM = 0
const LOW_VOL_THRESHOLD = 0.4

declare const window: AgoraWindow

const SCREENSHARE_TRACK_PARAMS = {
	// Set the encoder configurations. See: https://docs.agora.io/en/Video/video_profile_web_ng?platform=Web
	encoderConfig: '1080p_1',
	// Set the video transmission optimization mode as prioritizing video quality.
	optimizationMode: 'detail',
}

const AGORA_CLIENT_PARAMS = { mode: 'rtc', codec: 'vp8' }

class AgoraClient implements IVideoClient {
	// eslint-disable-next-line react/static-property-placement
	displayName: string
	client: ReturnType<typeof AgoraRTC.createClient>
	joined: boolean
	participantId: string
	localParticipant: ParticipantDetails
	remoteParticipants: ParticipantDetails[] = []
	chat: VideoChatMessage[] = []
	conferenceId: string
	localVideoTrack: AgoraTrack
	localAudioTrack: AgoraTrack
	videoDeviceId: string
	token: string
	remoteVideoStats: AgoraRemoteVideoStats = {}
	rtmClient: RTMClient
	rtmChannel: RTMChannel
	screenShareActive = false
	remoteScreenshareParticipant?: string
	recentSpeakers: number[] = [] // UIDs
	spotlitParticipantId: string
	displayNames: Record<number, string> = {}
	networkQuality: Record<number, number> = {}
	localVideoStats: AgoraLocalVideoStats = {} as AgoraLocalVideoStats
	properties: Record<string, string> = {}
	intervals: number[] = []

	constructor({ displayName, conferenceId }: ClientParams) {
		this.conferenceId = conferenceId
		this.displayName = displayName

		// Create AgoraRTC client
		this.client = AgoraRTC.createClient(AGORA_CLIENT_PARAMS)
		window.AgoraRTCClient = this.client
		window.AgoraClient = this

		// Enable dual stream. This means that we will broadcast two video streams (LOW + HIGH quality) and clients can
		// use setRemoteVideoStreamType to switch between the two.
		this.client
			.enableDualStream()
			.then(() => console.log('Dual stream enabled'))
			.catch(err => console.log(err))

		// Set up event listeners for remote users publishing or unpublishing tracks

		this.client.enableAudioVolumeIndicator()
		// this.client.on('volume-indicator', this.onVolumesChange.bind(this))

		this.client.on('user-left', this.onUserLeft.bind(this))
		this.client.on('user-published', this.onUserPublished.bind(this))
		this.client.on('network-quality', this.onNetworkQuality.bind(this))
		this.client.on('user-unpublished', this.onUserUnpublished.bind(this))
		this.client.on('user-info-updated', (msg, info) => console.log(`user-info-updated - msg: ${msg} info: ${info}`))

		AgoraRTC.onCameraChanged = info => {
			console.log('camera changed!', info.state, info.device)
		}

		this.rtmClient = AgoraRTM.createInstance(AGORA_APP_ID)
	}

	async connect(params: ConnectParams = {}): Promise<void> {
		const { startAudioMuted, cameraDeviceId, audioInputDeviceId } = params

		try {
			const devices = (await AgoraRTC.getDevices()) || []
			const cams = devices.filter(d => d.kind === 'videoinput')
			const mics = devices.filter(d => d.kind === 'audioinput')

			// Initialise webcam
			if (cams.length) {
				const deviceParams: CameraVideoTrackInitConfig = {}
				if (cameraDeviceId && cams.find(d => d.deviceId === cameraDeviceId)) {
					deviceParams.cameraId = cameraDeviceId
					this.videoDeviceId = cameraDeviceId
				}
				this.localVideoTrack = (await AgoraRTC.createCameraVideoTrack(deviceParams)) as unknown as AgoraTrack
			}

			// Initialise microphone
			if (mics.length) {
				const deviceParams: MicrophoneAudioTrackInitConfig = {}
				if (audioInputDeviceId && mics.find(d => d.deviceId === audioInputDeviceId)) {
					deviceParams.microphoneId = audioInputDeviceId
				}
				this.localAudioTrack = (await AgoraRTC.createMicrophoneAudioTrack(deviceParams)) as unknown as AgoraTrack
			}

			// Mute microphone if necessary
			if (startAudioMuted && this.localAudioTrack && !this.localAudioTrack.muted) {
				await this.localAudioTrack.setMuted(true)
			}

			// Get Agora auth token
			const { token } = await OnlineComms.getAgoraToken(this.conferenceId)
			this.token = token

			// Connect to Agora
			const uid = await this.client.join(AGORA_APP_ID, this.conferenceId, this.token, null)
			this.joined = true
			this.participantId = String(uid)

			// Connect to RTM service for signalling/messaging
			await this.connectToRtmChannel()

			// Publish the tracks that we created
			await this.client.publish([this.localAudioTrack, this.localVideoTrack].filter(Boolean))

			// Update client
			this.updateLocalParticipantDetails()

			this.dispatch(VideoClientEvents.ROOM_JOINED)

			this.client.remoteUsers.forEach(p => this.onRemoteParticipantConnected(p))

			// Get first instance of local statistics now, and then again after 2 seconds
			this.onLocalVideoStats(this.client.getLocalVideoStats())
			setTimeout(() => this.onLocalVideoStats(this.client.getLocalVideoStats()), 2000)

			// Get remote/local video stats on a regular basis
			this.intervals.push(window.setInterval(() => this.onLocalVideoStats(this.client.getLocalVideoStats()), 5000))
			this.intervals.push(window.setInterval(() => this.onRemoteVideoStats(this.client.getRemoteVideoStats()), 5000))
			this.intervals.push(window.setInterval(() => this.sendNetworkQuality(), 5000))

			this.intervals.push(window.setInterval(() => this.localVolumeCheck(), 100))
			// Send local participant metadata to all other participants on a regular basis, in case something has been missed
			this.intervals.push(window.setInterval(() => this.sendPropertiesToParticipants(), 5000))
		} catch (err) {
			console.error(err)
			throw err
		}
	}

	async toggleMuteAudio(): Promise<boolean> {
		const newVal = this.localAudioTrack && this.localAudioTrack.enabled ? !this.localAudioTrack.muted : true
		if (this.localAudioTrack) {
			await this.localAudioTrack.setMuted(newVal)
		}
		this.localParticipant.isMuted = newVal
		this.dispatch(VideoClientEvents.LOCAL_PARTICIPANT_CHANGED)
		return newVal
	}

	async toggleMuteCamera(): Promise<boolean> {
		const newVal = this.localVideoTrack ? !this.localVideoTrack.enabled : true
		if (this.localVideoTrack) {
			await this.localVideoTrack.setEnabled(newVal)
		}
		this.localParticipant.isCameraMuted = !newVal
		this.dispatch(VideoClientEvents.LOCAL_PARTICIPANT_CHANGED)
		return newVal
	}

	async changeVideoInput(deviceId: string): Promise<void> {
		const currentDeviceId = this.localVideoTrack && this.localVideoTrack.getMediaStreamTrackSettings().deviceId
		if (!this.joined || !this.localVideoTrack || !(deviceId === '' || deviceId !== currentDeviceId)) {
			return
		}
		// Unpublish existing video track
		if (this.localVideoTrack) {
			this.localVideoTrack.close()
			await this.client.unpublish(this.localVideoTrack)
		}
		await this.enableCamera(deviceId)
	}

	async changeAudioInput(deviceId: string): Promise<void> {
		const currentDeviceId = this.localAudioTrack && this.localAudioTrack.getMediaStreamTrackSettings().deviceId
		if (!this.joined || !this.localAudioTrack || !(deviceId === '' || deviceId !== currentDeviceId)) {
			return
		}
		// Unpublish existing video track
		if (this.localAudioTrack) {
			this.localAudioTrack.close()
			await this.client.unpublish(this.localAudioTrack)
		}
		await this.enableMicrophone(deviceId)
	}

	async enableCamera(deviceId?: string): Promise<void> {
		const params: CameraVideoTrackInitConfig = {}
		const _deviceId = deviceId || this.videoDeviceId
		console.log('enableCamera', _deviceId)
		if (_deviceId) {
			params.cameraId = _deviceId
			this.videoDeviceId = _deviceId
		}
		this.localVideoTrack = (await AgoraRTC.createCameraVideoTrack(params)) as unknown as AgoraTrack
		await this.client.publish(this.localVideoTrack)
		this.updateLocalParticipantDetails()
	}

	async enableMicrophone(deviceId?: string): Promise<void> {
		const params: MicrophoneAudioTrackInitConfig = {}
		if (deviceId) params.microphoneId = deviceId
		this.localAudioTrack = (await AgoraRTC.createMicrophoneAudioTrack(params)) as unknown as AgoraTrack
		await this.client.publish(this.localAudioTrack)
		this.updateLocalParticipantDetails()
	}

	async toggleScreenShare(): Promise<void> {
		if (this.screenShareActive) {
			await this.endScreenShare()
		} else {
			await this.startScreenShare()
		}
	}

	async startScreenShare(): Promise<void> {
		try {
			// Create a screen share track
			const localScreenTrack = (await AgoraRTC.createScreenVideoTrack(SCREENSHARE_TRACK_PARAMS)) as unknown

			// Unpublish existing video track
			if (this.localVideoTrack) {
				this.localVideoTrack.close()
				await this.client.unpublish(this.localVideoTrack)
			}
			this.localVideoTrack = localScreenTrack as AgoraTrack
			await this.client.publish(this.localVideoTrack)
			this.localVideoTrack.on('track-ended', () => this.onTrackEnded(this.localVideoTrack))
			await this.rtmChannel.sendMessage({ text: `screenshare-enabled` })
			this.screenShareActive = true
			this.updateLocalParticipantDetails()
		} catch (err) {
			console.log(err)
		}
	}

	async endScreenShare(): Promise<void> {
		// Unpublish existing video track
		if (this.localVideoTrack) {
			this.localVideoTrack.close()
			await this.client.unpublish(this.localVideoTrack)
		}
		this.screenShareActive = false
		await this.rtmChannel.sendMessage({ text: `screenshare-disabled` })
		await this.enableCamera()
		this.updateLocalParticipantDetails()
	}

	isScreenshareActive(): boolean {
		return this.screenShareActive
	}

	async setPrimaryRemoteParticipant(participantId: string): Promise<void> {
		this.setSpotlitParticipantId(participantId)
	}

	setSpotlitParticipantId(participantId: string): void {
		if (this.spotlitParticipantId === participantId) return
		this.spotlitParticipantId = participantId
		this.updateVideoStreamPriorities()
	}

	streamTypeCache: { [uid: number]: number } = {}

	updateVideoStreamPriorities(): void {
		const speakers = this.recentSpeakers.filter(uid => String(uid) !== this.localParticipant?.participantId)

		let updatedStatus = false
		speakers.forEach((uid, i) => {
			const isWithinLowThreshold = i < LOW_THRESHOLD
			const isSpotlit = uid === parseInt(this.spotlitParticipantId)
			const isInactive = i >= HIGH_THRESHOLD && !isSpotlit
			const participantIndex = this.remoteParticipants.findIndex(p => p.participantId === String(uid))
			const participant = this.remoteParticipants[participantIndex]

			const newStreamType = isWithinLowThreshold || isSpotlit ? HIGH_STREAM : LOW_STREAM
			if (this.streamTypeCache[uid] !== newStreamType) {
				this.client.setRemoteVideoStreamType(uid, newStreamType)
				this.streamTypeCache[uid] = newStreamType
			}

			const newStatus = isInactive ? 'inactive' : 'active'
			if (participant && participant.status !== newStatus) {
				this.remoteParticipants[participantIndex] = { ...participant, status: newStatus }
				updatedStatus = true
			}
		})

		// If I am outside of the high threshold, set my client role to 'audience'

		// NOTE: This can only be used in a
		// const myIndex = speakers.indexOf(this.client.uid)
		// if(myIndex >= HIGH_THRESHOLD && this.client.getClientRole() !== 'audience') { }

		if (updatedStatus) {
			this.dispatch(VideoClientEvents.REMOTE_PARTICIPANTS_CHANGED)
		}
	}

	private updateRemoteParticipantDetails(participant: AgoraParticipant): void {
		const id = participant.uid
		const participantId = String(id)
		let details: ParticipantDetails = this.remoteParticipants.find(p => p.participantId === participantId)
		if (!details) {
			details = { participantId }
			this.remoteParticipants.push(details)
		}
		details.displayName = this.displayNames[participantId]
		details.isMuted = !participant.hasAudio
		details.isCameraMuted = !participant.hasVideo
		if (participant.hasVideo && participant.videoTrack) {
			details.videoTrack = this.agoraVideoTrackToParticipantTrack(participant.videoTrack)
			details.videoTrack.videoType = this.remoteScreenshareParticipant === participantId ? 'desktop' : 'video'
		} else {
			details.videoTrack = null
		}

		if (participant.hasAudio && participant.audioTrack) {
			details.audioTrack = this.agoraVideoTrackToParticipantTrack(participant.audioTrack, 'audio')
			details.getVolume = () => participant.audioTrack?.getVolumeLevel() || 0
		} else {
			details.audioTrack = null
			details.getVolume = () => 0
		}

		if (this.remoteVideoStats[id]) {
			details.stats = details.stats || ({} as ParticipantStats)
			details.stats.receivingResolution = {
				height: this.remoteVideoStats[id].receiveResolutionHeight,
				width: this.remoteVideoStats[id].receiveResolutionWidth,
			}
			details.stats.downloadRate = Math.round(this.remoteVideoStats[id].receiveBitrate / 1024)
			details.stats.connectionQuality = this.networkQuality[participantId]
		}
		this.dispatch(VideoClientEvents.REMOTE_PARTICIPANTS_CHANGED)
	}

	private updateLocalParticipantDetails() {
		const details = this.localParticipant || ({} as ParticipantDetails)
		details.participantId = this.participantId
		details.displayName = this.displayName
		details.isLocal = true
		details.isMuted = !this.localAudioTrack || !this.localAudioTrack.enabled || this.localAudioTrack.muted
		details.isCameraMuted = !this.localVideoTrack || !this.localVideoTrack.enabled || this.localVideoTrack.muted
		details.videoTrack = this.agoraVideoTrackToParticipantTrack(this.localVideoTrack)
		details.isMuted = !this.localAudioTrack || this.localAudioTrack.muted
		details.videoTrack.videoType = this.screenShareActive ? 'desktop' : 'video'

		if (this.localVideoStats) {
			details.stats = details.stats || ({} as ParticipantStats)
			details.stats.sendingResolution = {
				height: this.localVideoStats.sendResolutionHeight,
				width: this.localVideoStats.sendResolutionWidth,
			}
			details.stats.uploadRate = Math.round(this.localVideoStats.sendBitrate / 1024)
			// details.stats.connectionQuality =
		}

		if (this.localAudioTrack) {
			details.audioTrack = this.agoraVideoTrackToParticipantTrack(this.localAudioTrack, 'audio')
			details.getVolume = () => this.localAudioTrack.getVolumeLevel() || 0
		} else {
			details.audioTrack = null
			details.getVolume = () => 0
		}

		this.localParticipant = details
		this.dispatch(VideoClientEvents.LOCAL_PARTICIPANT_CHANGED)
	}

	async unload(): Promise<void> {
		// this.client.unpublish() // stops sending audio & video to agora
		if (this.localVideoTrack) this.localVideoTrack.stop() // stops video track and removes the player from DOM
		if (this.localVideoTrack) this.localVideoTrack.close() // Releases the resource
		if (this.localAudioTrack) this.localAudioTrack.stop() // stops audio track
		if (this.localAudioTrack) this.localAudioTrack.close() // Releases the resource
		this.client.remoteUsers.forEach(user => {
			this.client.unsubscribe(user) // unsubscribe from the user
		})
		this.intervals.forEach(interval => clearInterval(interval))
		this.intervals = []
		this.client.removeAllListeners() // Clean up the client object to avoid memory leaks
		await this.client.leave()
	}

	setProperty(name: string, value: string): void {
		this.properties[name] = value
		this.sendPropertiesToParticipants()
	}

	// ===============================================================================================
	// Real-time messaging client functions

	async connectToRtmChannel(): Promise<void> {
		try {
			const { token: rtmToken } = await OnlineComms.getAgoraRtmToken(this.participantId)
			await this.rtmClient.login({ uid: this.participantId, token: rtmToken })

			this.rtmClient.on('MessageFromPeer', (message, memberId) => {
				this.onMessage(message as { text: string; messageType: string }, memberId, true)
			})

			this.rtmChannel = await this.rtmClient.createChannel(this.conferenceId)
			await this.rtmChannel.join()
			this.rtmChannel.on('ChannelMessage', (message, memberId) =>
				this.onMessage(message as { text: string; messageType: string }, memberId)
			)

			this.rtmChannel.on('MemberJoined', (participantId: string) => {
				this.sendPropertiesToParticipants(participantId)
			})

			this.sendPropertiesToParticipants()

			window.RTMClient = this.rtmClient
			window.RTMChannel = this.rtmChannel
		} catch (err) {
			console.error('RTMERROR:', err)
		}
	}

	sendPropertiesTimeout: number | null = null

	/* Sends all local participant metadata to other participants (or one specific participant) */
	async sendPropertiesToParticipants(participantId?: string): Promise<void> {
		if (!this.rtmChannel) return

		window.clearTimeout(this.sendPropertiesTimeout)
		this.sendPropertiesTimeout = window.setTimeout(async () => {
			const sendMessage = participantId
				? msg => this.rtmClient.sendMessageToPeer(msg, participantId)
				: msg => this.rtmChannel.sendMessage(msg)
			sendMessage({ text: `displayname:${this.displayName}` })
			sendMessage({ text: `screenshare-${this.screenShareActive ? 'enabled' : 'disabled'}` })
			const keys = Object.keys(this.properties)
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i]
				const keyEncoded = encodeURIComponent(key)
				const valueEncoded = encodeURIComponent(this.properties[key])
				await sendMessage({ text: `property:${keyEncoded}:${valueEncoded}` })
			}
		}, 1000)
	}

	async sendChatMessage(message: string, participantId: string): Promise<void> {
		if (participantId) {
			await this.rtmClient.sendMessageToPeer({ text: message }, participantId)
		} else {
			await this.rtmChannel.sendMessage({ text: message })
		}
		const data: VideoChatMessage = {
			message,
			to: participantId,
			timestamp: Date.now(),
			privateMsg: Boolean(participantId),
			participantId: this.localParticipant.participantId,
		}

		// Get recipients actual display name
		if (participantId) {
			const participant = this.remoteParticipants.find(p => p.participantId === participantId)
			if (participant) {
				data.to = participant.displayName
			}
		}
		this.chat.push(data)
		this.dispatch(VideoClientEvents.MESSAGE_RECEIVED, data)
	}

	// ===============================================================================================
	// Dominant speaker detection

	dominantSpeaker: number = null
	dominantSpeakerDebuff = 0
	dominantSpeakerChangeTime = 0
	dominantSpeakerDebuffTime = 1000

	talking = false
	async localVolumeCheck(): Promise<void> {
		// This function is periodically called to check if the local participants speaking volume is
		// higher than that of the dominant speaker. If so, the dominant speaker is updated.
		const localVolume: number = this.client?.localTracks?.find(t => t.trackMediaType === 'audio')?.getVolumeLevel() || 0

		// Ignore local participant volume if it is below a certain threshold
		if (localVolume < LOW_VOL_THRESHOLD) {
			if (this.talking) {
				console.log('not talking')
				this.talking = false
			}
			return
		}

		if (!this.talking) {
			console.log('talking')
			this.talking = true
		}

		// Get the current volume of the "dominant speaker"
		let dominantSpeakerVolume = 0
		if (this.dominantSpeaker) {
			const remoteUser = this.client?.remoteUsers?.find(t => t.uid === this.dominantSpeaker)
			dominantSpeakerVolume = remoteUser?.audioTrack?.getVolumeLevel() || 0
		}

		if (localVolume > dominantSpeakerVolume) {
			this.onNewDominantSpeaker(parseInt(this.participantId))
			// Inform other users
			this.rtmChannel.sendMessage({ text: `dominant-speaker:${this.participantId}` })
		}
	}

	async onNewDominantSpeaker(uid: number): Promise<void> {
		if (this.dominantSpeaker === uid) return

		this.recentSpeakers = [uid, ...this.recentSpeakers.filter(id => id !== uid)]
		this.dominantSpeaker = uid

		// Prevent switching the dominant speaker more than once every second.
		// If the last time we changed the dominant speaker was over a second ago,
		// then we can change the dominant speaker immediately. Otherwise, delay the next update
		// until a second has passed.
		if (this.dominantSpeakerChangeTime < Date.now() - this.dominantSpeakerDebuffTime) {
			this.switchDominantSpeaker(uid)
		} else {
			window.clearTimeout(this.dominantSpeakerDebuff)
			this.dominantSpeakerDebuff = window.setTimeout(
				() => this.switchDominantSpeaker(uid),
				this.dominantSpeakerDebuffTime
			)
		}
	}

	async switchDominantSpeaker(uid: number): Promise<void> {
		console.log('newDominantSpeaker', uid)
		this.dominantSpeakerChangeTime = Date.now()
		this.dispatch(VideoClientEvents.DOMINANT_SPEAKER_CHANGED, { participantId: String(uid) })
		this.updateVideoStreamPriorities()
	}

	// ===============================================================================================
	// Event handlers

	// onVolumesChange(volumes: { level: number; uid: number }[]): void {
	// 	let updated = false
	// 	// Check if we have a new top speaker
	// 	const currTop = this.recentSpeakers[0]
	// 	const currTopVol = (volumes.find(v => v.uid === currTop) || {}).level || 0
	// 	const newTopUid = (volumes.find(v => v.level > currTopVol) || {}).uid
	// 	if (newTopUid && newTopUid !== currTop) {
	// 		this.recentSpeakers = [newTopUid, ...this.recentSpeakers.filter(uid => uid !== newTopUid)]
	// 		this.dominantSpeaker = newTopUid
	// 		updated = true
	// 		this.dispatch(VideoClientEvents.DOMINANT_SPEAKER_CHANGED, { participantId: String(newTopUid) })
	// 	}

	// 	// Append any UIDs that are not in the recent speakers list
	// 	volumes.forEach(v => {
	// 		if (!this.recentSpeakers.includes(v.uid)) {
	// 			updated = true
	// 			this.recentSpeakers.push(v.uid)
	// 		}
	// 	})

	// 	if (updated) this.updateVideoStreamPriorities()
	// }

	async onUserPublished(user: AgoraParticipant, mediaType: string): Promise<void> {
		await this.client.subscribe(user, mediaType)
		this.updateRemoteParticipantDetails(user)
		if (!this.recentSpeakers.includes(user.uid)) {
			this.recentSpeakers.push(user.uid)
			this.updateVideoStreamPriorities()
		}
	}

	onUserUnpublished(user: AgoraParticipant): void {
		this.updateRemoteParticipantDetails(user)
		this.updateVideoStreamPriorities()
	}

	onUserLeft(user: AgoraParticipant): void {
		this.remoteParticipants = this.remoteParticipants.filter(p => p.participantId !== String(user.uid))
		this.recentSpeakers = this.recentSpeakers.filter(p => p !== user.uid)
		this.updateVideoStreamPriorities()
		this.dispatch(VideoClientEvents.REMOTE_PARTICIPANTS_CHANGED)
	}

	async onTrackEnded(track: AgoraTrack): Promise<void> {
		// If currently screensharing, the screensharing must have been ended, so restore the default local video (camera) track
		if (this.screenShareActive) {
			await this.endScreenShare()
		} else {
			console.log('track-ended', track)
		}
	}

	onMessage(message: { text: string; messageType: string }, participantId: string, direct = false): void {
		const { text, messageType } = message
		if (messageType !== 'TEXT') return

		const participant = this.client.remoteUsers.find(p => p.uid === parseInt(participantId))
		if (!participant) return

		if (text === 'screenshare-enabled') {
			this.remoteScreenshareParticipant = participantId
			this.updateRemoteParticipantDetails(participant)
			return
		}
		if (text.startsWith('dominant-speaker:')) {
			const [, id] = text.split(':')
			this.onNewDominantSpeaker(parseInt(id))
			return
		}
		if (text === 'screenshare-disabled') {
			if (this.remoteScreenshareParticipant === participantId) {
				this.remoteScreenshareParticipant = null
				this.updateRemoteParticipantDetails(participant)
			}
			return
		}
		if (text.startsWith('displayname:')) {
			const [, displayName] = text.split(':')
			this.displayNames[participantId] = displayName
			this.updateRemoteParticipantDetails(participant)
			return
		}
		if (text.startsWith('network-quality:')) {
			const networkQuality = parseInt(text.split(':')[1])
			this.networkQuality[participantId] = networkQuality
			this.updateRemoteParticipantDetails(participant)
			return
		}
		if (text.startsWith('property:')) {
			const [, keyEncoded, valueEncoded] = text.split(':')
			const key = decodeURIComponent(keyEncoded)
			const value = decodeURIComponent(valueEncoded)
			this.onPropertyReceived(key, value, participantId)
			return
		}
		if (text.startsWith('check:')) {
			this.rtmChannel.sendMessage({ text: `recent: ${JSON.stringify(this.recentSpeakers)}` })
			return
		}

		// if (text.startsWith('recent:')) {
		// 	const speakers = JSON.parse(text.split(':')[1])
		// 	console.log(
		// 		'~ speakers',
		// 		speakers.map(s => (this.remoteParticipants.find(p => p.participantId === String(s)) || {}).displayName || s)
		// 	)
		// 	return
		// }

		// In any other case, treat as a chat message
		const chatMessage: VideoChatMessage = { participantId, message: text, timestamp: Date.now() }
		if (direct) chatMessage.privateMsg = true
		this.chat.push(chatMessage)
		this.dispatch(VideoClientEvents.MESSAGE_RECEIVED, message)
	}

	onRemoteVideoStats(stats: AgoraRemoteVideoStats): void {
		this.remoteVideoStats = stats
		this.client.remoteUsers.forEach(user => {
			this.updateRemoteParticipantDetails(user)
		})
	}

	onLocalVideoStats(stats: AgoraLocalVideoStats): void {
		this.localVideoStats = stats
		this.updateLocalParticipantDetails()
	}

	onRemoteParticipantConnected(participant: AgoraParticipant): void {
		this.updateRemoteParticipantDetails(participant)
	}

	onNetworkQuality(networkQualityStats: AgoraNetworkQuality): void {
		this.localParticipant.stats = this.localParticipant.stats || ({} as ParticipantStats)
		const down = networkQualityStats.downlinkNetworkQuality
		// 1 = 100%, 6 = 0%
		const connectionQuality = down > 0 ? Math.round(100 - (down - 1) * (100 / 5)) : null
		this.localParticipant.stats.connectionQuality = connectionQuality
	}

	onPropertyReceived(key: string, value: string, participantId: string): void {
		let details: ParticipantDetails = this.remoteParticipants.find(p => p.participantId === participantId)
		if (!details) {
			details = { participantId }
			this.remoteParticipants.push(details)
		}
		details.properties = details.properties || {}
		details.properties[key] = value
		this.dispatch(VideoClientEvents.REMOTE_PARTICIPANTS_CHANGED)
	}

	sendNetworkQualityPrevVal = null
	sendNetworkQualityTimeSent = 0
	sendNetworkQuality(): void {
		const val = this.localParticipant?.stats?.connectionQuality
		if (this.sendNetworkQualityPrevVal !== val || this.sendNetworkQualityTimeSent < Date.now() - 5000) {
			this.sendNetworkQualityTimeSent = Date.now()
			if (this.rtmChannel) this.rtmChannel.sendMessage({ text: `network-quality:${val}` })
			this.sendNetworkQualityPrevVal = val
		}
	}

	// ===============================================================================================

	agoraVideoTrackToParticipantTrack(track: AgoraTrack, type: 'audio' | 'video' = 'video'): ParticipantTrack {
		return {
			attach: HTMLVideoElement => {
				track.stop()
				track.play(HTMLVideoElement.id)
				console.log('track', track)
			},
			detach: () => console.info('Method not implemented: localVideoTrack.detach'),
			isLocal: () => true,
			getType: () => type,
			getParticipantId: () => track.getUserId().toString(),
			isMuted: () => false,
			mute: () => console.info('Method not implemented: localVideoTrack.mute'),
			unmute: () => console.info('Method not implemented: localVideoTrack.unmute'),
			dispose: () => console.info('Method not implemented: localVideoTrack.dispose'),
			deviceId: track.getMediaStreamTrackSettings().deviceId,
			containers: [],
			videoType: null,
		}
	}

	// Event handlers is a map of functions that map to a key, being any one of the TwilioClientEvents
	// eventHandlers: PartialRecord<TwilioClientEvent, EventCallback> = {}
	eventHandlers: PartialRecord<VideoClientEvent, Record<string, EventCallback>> = {}

	on(eventType: VideoClientEvent, callback: EventCallback): number {
		if (!this.eventHandlers[eventType]) this.eventHandlers[eventType] = {}
		const id = Math.floor(Math.random() * 100000000)
		this.eventHandlers[eventType][id] = callback
		return id
	}

	off(ref: number): void {
		// Find event handler that matches ref
		Object.keys(this.eventHandlers).forEach(eventType => {
			const handlers = this.eventHandlers[eventType]
			if (handlers[ref]) delete handlers[ref]
		})
	}

	dispatch(eventType: VideoClientEvent, data?: unknown): void {
		const handlers = this.eventHandlers[eventType]
		if (!handlers) return
		Object.keys(handlers).forEach(ref => {
			const callback = handlers[ref]
			callback(data)
		})
	}

	// ===============================================================================================
}

export default AgoraClient
