import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import config from '../../config'
import Actions from '../actions'

import Button from '../elements/Button'
import sessionSelector from '../selectors/session'

type ParticipantTopButtonsProps = {
	session: CurrentSession | PreviousSession
	viewingPreviousSession: boolean
	groupSignOut: () => void
	displayModalPopup: (name: string) => void
	returnToCurrent: () => void
	toggleNotepad: (show?: boolean) => void
}

const ParticipantTopButtons: FunctionComponent<ParticipantTopButtonsProps> = props => {
	const { displayModalPopup, groupSignOut, returnToCurrent, viewingPreviousSession, session, toggleNotepad } = props
	const { VIEW_INITIAL_UPDATE, PREVSESSIONS, SIGNOUT, RETURN_TO_CURRENT } = config.strings

	return (
		<div className="participant-top-buttons">
			{/* {session?.id ? ( */}
			{/* <Button primary icon="memo" onClick={() => toggleNotepad()}>
				{NOTEPAD}
			</Button> */}
			{/* // ) : null} */}
			{session?.id ? (
				<Button primary icon="circle-info" onClick={() => displayModalPopup('modal-initial-information')}>
					{VIEW_INITIAL_UPDATE}
				</Button>
			) : null}
			{viewingPreviousSession && (
				<Button primary icon="clock-rotate-left" onClick={returnToCurrent}>
					{RETURN_TO_CURRENT}
				</Button>
			)}
			{!viewingPreviousSession && (
				<Button icon="clock-rotate-left" onClick={() => displayModalPopup('modal-prevsessions')}>
					{PREVSESSIONS}
				</Button>
			)}
			<Button icon="arrow-right-from-bracket" onClick={groupSignOut}>
				{SIGNOUT}
			</Button>
		</div>
	)
}
// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<ParticipantTopButtonsProps> => {
	const session = sessionSelector(state)
	const { viewingPreviousSession } = state
	return { viewingPreviousSession, session }
}
const actions = {
	groupSignOut: Actions.login.signOut,
	displayModalPopup: Actions.misc.displayModalPopup,
	returnToCurrent: Actions.sessions.returnToCurrentSession,
	toggleNotepad: Actions.misc.toggleNotepad,
}

export default connect(mapStateToProps, actions)(ParticipantTopButtons)
