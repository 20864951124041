import config from '../../config'
import miscActions from './misc'
import types from './types'

import MediaLibrary from '../core/MediaLibrary'
import OnlineComms from '../core/OnlineComms'

import ConfirmationHelper from '../util/ConfirmationHelper'
import downloadBlob from '../util/downloadBlob'
import getTime from '../util/getTime'
import mime from '../util/mime-types'
import action from '../util/action'

const { MEDIA_DELETED_MSG, MEDIA_DELETED_TITLE, CONFIRM_DELETE_MEDIA } = config.strings

const addUploadedMediaToCase: ActionCreator<string, string> = (fileName, fileId) => (dispatch, getState) => {
	const state = getState()
	const fileNameSplit = fileName.split('.')
	const extension = `.${fileNameSplit.pop()}`
	const baseName = fileNameSplit.join('.')
	const obj: Partial<MediaItem> = {
		fileName,
		extension,
		id: fileId,
		lastUpdate: getTime(),
		name: decodeURI(baseName),
	}
	dispatch(action(types.MEDIA_ADD_NEW_SUCCESS, obj))

	const currentCase = state.openCase || ({} as OpenCaseDetails)
	OnlineComms.addNewMedia(currentCase.id, obj)
}

const markMediaAsSeen: ActionCreator<string> = mediaId => dispatch => {
	dispatch(action(types.MEDIA_MARK_AS_SEEN, mediaId))
}

const deleteMedia: ActionCreator<string> = mediaId => (dispatch, getState) => {
	const state = getState()
	const _case = state.openCase || ({} as OpenCaseDetails)
	const caseId = _case.id
	ConfirmationHelper.confirm(CONFIRM_DELETE_MEDIA, () => {
		OnlineComms.deleteMedia(caseId, mediaId)
		dispatch(action(types.MEDIA_DELETE, mediaId))
		ConfirmationHelper.successMessage(MEDIA_DELETED_TITLE, MEDIA_DELETED_MSG)
	})
}

const getMediaItems: ActionCreator = () => (dispatch, getState) => {
	dispatch(action(types.MEDIA_LOADING_ITEMS))
	const state = getState()
	const _case = state.openCase || ({} as OpenCaseDetails)
	const caseId = _case.id
	if (caseId) {
		OnlineComms.getMediaItems(caseId).then(arr => {
			arr.sort((a, b) => a.lastUpdate - b.lastUpdate)
			dispatch(action(types.MEDIA_LOADED_ITEMS, arr))
		})
	}
}

const updateMedia: ActionCreator<string, Partial<MediaItem>> = (mediaId, data) => (dispatch, getState) => {
	const state = getState()
	const _case = state.openCase || ({} as OpenCaseDetails)
	const caseId = _case.id
	dispatch(action(types.MEDIA_UPDATED, { mediaId, data }))
	miscActions.displayModalPopup('')(dispatch, getState)
	OnlineComms.updateMedia(caseId, mediaId, data)
}

// eslint-disable-next-line arrow-body-style
const getMediaData: ActionCreator<string> = mediaId => () => {
	// Note: media data is not stored in global state as it is large, so it is just returned to the
	// component directly
	return OnlineComms.getMediaData(mediaId)
}

const downloadMediaItem: ActionCreator<string> = mediaId => async (dispatch, getState) => {
	const state = getState()
	const mediaItem = (state?.openCase?.media || []).find(m => m.id === mediaId)
	if (!mediaItem) return
	try {
		// We download the media (or get it from MediaCache) as a Blob and then force it to be downloaded.
		// This works better than getting a URL and auto-clicking a <a href={url} download /> link as
		// that method will open up in a new tab (unless perhaps it is the same origin as the page).
		const mimeType = mime.lookup(mediaItem.fileName)
		const cachedItem = MediaLibrary.get(mediaItem.id)
		const data = cachedItem?.data || ((await OnlineComms.getMediaData(mediaId)) as Blob)
		downloadBlob(data, mimeType, mediaItem.fileName)
	} catch (err) {
		console.error(err)
	}
}

const closeMedia: ActionCreator = () => dispatch => {
	dispatch(action(types.MEDIA_CLOSE))
}

const expandMedia: ActionCreator<boolean> = (expanded = true) => dispatch => {
	console.log(expanded)
	dispatch(action(types.MEDIA_EXPANDED, expanded))
}

const actions = {
	addUploadedMediaToCase,
	downloadMediaItem,
	markMediaAsSeen,
	getMediaItems,
	getMediaData,
	deleteMedia,
	updateMedia,
	expandMedia,
	closeMedia,
}

export default actions
