/* eslint-disable camelcase */
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import React, { useRef, useState } from 'react'
import { parse } from 'query-string'

import InputFieldManager from '../InputFieldManager'
import Button from '../../elements/Button'
import Input from '../../elements/Input'
import Icon from '../../elements/Icon'

import history from '../../core/history'
import config from '../../../config'
import Actions from '../../actions'

type LoginTutorProps = {
	error?: string
	loginAsTutor: (username: string, password: string) => void
	loginForOpenId: (username: string, password: string, openIdParams: SimpleObject, form: HTMLFormElement) => void
	onClickBack: () => void
}

function LoginTutor(props: LoginTutorProps) {
	const { error, onClickBack, loginAsTutor, loginForOpenId } = props
	const { EMAIL, PASSWORD, OK, BACK } = config.strings

	const location = useLocation()
	const query = parse(location.search)

	const [openIdParams] = useState(query)
	const openIdForm = useRef(null)

	const { redirect_uri, scope, state } = query

	const onClickOK = () => {
		const username = InputFieldManager.getValue('username')
		const password = InputFieldManager.getValue('password')
		if (redirect_uri) {
			loginForOpenId(username, password, openIdParams, openIdForm.current)
		} else {
			loginAsTutor(username, password)
		}
	}

	const onClickBackButton = () => {
		if (redirect_uri) history.go(-2)
		else onClickBack()
	}

	return (
		<div className="login__tutor">
			<div className="login__main">
				<h2>Please enter your log in details</h2>
				<div className="login__input-row">
					<Icon name="at" />
					<Input name="username" type="username" onEnter={onClickOK} focus placeholder={EMAIL} />
				</div>
				<div className="login__input-row">
					<Icon name="key" />
					<Input name="password" type="password" onEnter={onClickOK} placeholder={PASSWORD} />
				</div>
			</div>

			<div className="login__error-space">{error && <div className="error">{error}</div>}</div>

			<div className="login__otherbuttons">
				<Button text={OK} onClick={onClickOK} />
				<Button level="secondary" text={BACK} onClick={onClickBackButton} />
			</div>

			<form ref={openIdForm} method="get" style={{ display: 'none' }}>
				<input type="hidden" name="state" value={state} />
				<input type="hidden" name="scope" value={scope} />
				<input type="hidden" name="code" />
				<button type="submit">Submit</button>
			</form>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	error: state.tutor.loginError,
})
const actions = {
	loginAsTutor: Actions.login.loginAsTutor,
	loginForOpenId: Actions.login.loginForOpenId,
}
export default connect(mapStateToProps, actions)(LoginTutor)
