import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import config from '../../../config'

import currentSessionSelector from '../../selectors/currentSession'
import currentCaseSelector from '../../selectors/currentCase'
import NewSessionNotification from './NewSessionNotification'
import DivWithInert from '../../elements/DivWithInert'

type NewSessionScreenProps = {
	currentCase: OpenCaseDetails
	currentSession: CurrentSession
	active?: boolean
}

const NewSessionScreen: FunctionComponent<NewSessionScreenProps> = ({ currentSession, currentCase, active }) => {
	const { SESSION_HAS_STARTED, CLICK_TO_OPEN, SESSION } = config.strings
	const caseTitle = currentCase.title
	const sessionTitle = `${SESSION} ${currentSession.index}`

	return (
		<DivWithInert className="start-screen" inert={!active}>
			<div className="start-screen__titles">
				<h2>{caseTitle}</h2>
				<h3>{sessionTitle}</h3>
				{currentSession.endTime !== null ? null : <p>{SESSION_HAS_STARTED}</p>}
			</div>
			<div className="start-screen__content">
				<NewSessionNotification />
				<p>{CLICK_TO_OPEN}</p>
			</div>
		</DivWithInert>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const currentCase = currentCaseSelector(state) || ({} as OpenCaseDetails)
	const currentSession = currentSessionSelector(state) || ({} as CurrentSession)

	return {
		currentCase,
		currentSession,
	}
}
const actions = {}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<NewSessionScreenProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(NewSessionScreen)
