/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useInterval } from 'react-use'

import Actions from '../../actions'
import config from '../../../config'

import getMediaUrlForData from '../../util/getMediaUrlForData'

import getMediaFeed, { NotificationData } from '../../selectors/mediaFeed'
import selectedMediaSelector from '../../selectors/selectedMedia'
import releasesSelector from '../../selectors/releases'
import sessionSelector from '../../selectors/session'

import MediaLibrary from '../../core/MediaLibrary'

import IconButton from '../../elements/IconButton'
import Preloader from '../../elements/Preloader'
import AudioVisualiser from './AudioVisualiser'
import Row from '../../elements/Row'

const { AUDIO_TYPES } = config

type AudioPlayerProps = {
	notification: NotificationData
	mediaItem: MediaItem
	getMediaData: (id: string) => Promise<Blob>
	markMediaAsSeen: (id: string) => void
	closeButton?: boolean
	closeButtonIcon?: string
	onClickClose?: () => void
}

const formatAudioTime = secs => {
	if (!secs) return '00:00'
	const minsStr = String(Math.floor(secs / 60)).padStart(2, '0')
	const secsStr = String(Math.floor(secs % 60)).padStart(2, '0')
	return `${minsStr}:${secsStr}`
}

function formatNotificationTime(time: number | string): string {
	return new Date(time).toTimeString().substr(0, 5)
}

const AudioPlayer: FunctionComponent<AudioPlayerProps> = props => {
	const { notification, mediaItem, getMediaData, markMediaAsSeen } = props
	const { onClickClose, closeButton, closeButtonIcon } = props

	const { RECEIVED_AT } = config.strings

	const [mediaId, setMediaId] = useState(null)
	const [mediaUrl, setMediaUrl] = useState(null)
	const [playing, setPlaying] = useState(false)
	const [loading, setLoading] = useState(true)
	const [duration, setDuration] = useState(0)
	const [position, setPosition] = useState(0)
	const [error, setError] = useState('')
	const audioPlayer = useRef<HTMLAudioElement>(null)

	useInterval(() => {
		if (audioPlayer.current) setPosition(audioPlayer.current.currentTime)
	}, 500)

	useEffect(() => {
		const { id, extension } = mediaItem || {}
		if (!id) return
		if (!AUDIO_TYPES.includes(extension)) return

		if (mediaId === id) return
		setMediaId(id)

		// If we already have this media cached, get the data URL for it
		const mediaFromLibrary = MediaLibrary.get(id)

		if (mediaFromLibrary) {
			setMediaUrl(getMediaUrlForData(mediaFromLibrary.data, extension))
			setLoading(false)
			markMediaAsSeen(id)
			return
		}

		// We need to get the media data/URL and then update this in state so we can re-render
		getMediaData(id)
			.then(data => {
				setLoading(false)
				setMediaUrl(getMediaUrlForData(data, extension))
				markMediaAsSeen(id)
			})
			.catch(err => {
				console.error(err)
				setLoading(false)
				setMediaUrl(null)
				setError('Media could not be loaded')
			})
	}, [mediaItem])

	// If the media URL for our audio player changes then we need to ensure that it is loaded
	// https://stackoverflow.com/a/43578104/757237
	useEffect(() => {
		console.log('mediaUrl changed')
		if (!audioPlayer.current) return
		audioPlayer.current.pause()
		audioPlayer.current.load()
		// Autoplay the audio when it is loaded
		audioPlayer.current.play()
	}, [mediaUrl])

	const onClickPlay = () => {
		const audioElement = audioPlayer.current
		if (!audioElement) return
		if (audioElement.paused) {
			audioElement.play()
		} else {
			audioElement.pause()
		}
	}

	const onAudioStateChanged = () => {
		console.log('onAudioStateChanged')
		const audioElement = audioPlayer.current
		setPlaying(!audioElement.paused)
	}

	const playButtonIcon = playing ? 'pause' : 'play'

	let content: ReactElement = null

	if (loading) {
		content = <Preloader />
	} else if (error) {
		content = <p>{error}</p>
	} else {
		content = (
			<>
				<h3>{mediaItem?.name || 'Unknown'}</h3>
				<p>
					{RECEIVED_AT} {formatNotificationTime(notification.time)}
				</p>
				<AudioVisualiser audioElement={audioPlayer.current} />
				<audio
					ref={audioPlayer}
					controls
					controlsList="nodownload"
					onDurationChange={() => setDuration(audioPlayer.current.duration)}
					onEnded={onAudioStateChanged}
					onPause={onAudioStateChanged}
					onPlay={onAudioStateChanged}
				>
					<source src={mediaUrl} />
				</audio>
				<div className="audio-player__clock">
					{formatAudioTime(position)} / {formatAudioTime(duration)}
				</div>
				<Row className="audio-player__button-row">
					<IconButton className="audio-player__play-button" iconName={playButtonIcon} onClick={onClickPlay} />
					{closeButton && (
						<IconButton className="audio-player__close-button" iconName={closeButtonIcon} onClick={onClickClose} />
					)}
				</Row>
			</>
		)
	}
	return <div className="audio-player">{content}</div>
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const { selectedScheduleRowId } = state
	const { mediaItem } = selectedMediaSelector(state)

	const groupId = state?.group?.id
	const releases = releasesSelector(state)
	const session = sessionSelector(state)
	const notifications = groupId ? getMediaFeed(groupId, session, releases) : []
	const notification = notifications.find(n => n.rowId === selectedScheduleRowId) || ({} as NotificationData)

	return { notification, mediaItem }
}
const actions = {
	markMediaAsSeen: Actions.media.markMediaAsSeen,
	getMediaData: Actions.media.getMediaData,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<AudioPlayerProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(AudioPlayer)
