// =============================================================================
// Components > Button
// =============================================================================

import React, { ReactNode, ReactElement, ReactEventHandler } from 'react'
import Icon from './Icon'

type ButtonProps = {
	text?: string
	primary?: boolean
	secondary?: boolean
	level?: string
	enabled?: boolean
	children?: ReactNode
	onClick?: ReactEventHandler
	icon?: string
}

function Button(props: ButtonProps): ReactElement<ButtonProps> {
	const { text, level, children, onClick, icon, primary = false, enabled = true, secondary = false } = props

	let className = 'button'
	if (level === 'secondary') className += ' button--secondary'
	if (enabled === false) className += ' button--disabled'
	if (primary) className += ' button--primary'
	if (secondary) className += ' button--secondary'

	const tabIndex = enabled ? 0 : -1

	const _onClick = (enabled && onClick) || null

	let label: string = text || ''
	if (!label && typeof children === 'string') {
		label = children as string
	}

	return (
		<div className={className}>
			<button type="button" onClick={_onClick} aria-label={label} tabIndex={tabIndex} disabled={enabled === false}>
				{icon ? <Icon name={icon} /> : null}
				{children}
				<span className="button__text">{text}</span>
			</button>
		</div>
	)
}

export default Button
