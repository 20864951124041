import { Switch, Route, match as Match } from 'react-router-dom'
import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'
import { Location } from 'history'

import config from '../../config'

import TutorObserverPasswords from './TutorObserverPasswords'
import TutorParticipants from './TutorParticipants'
import TutorPrevSessions from './TutorPrevSessions'
import TutorPasswords from './TutorPasswords'
import TutorVideoConf from './TutorVideoConf'
import TutorSessions from './TutorSessions'
import TutorSettings from './TutorSettings'
import TutorGroups from './TutorGroups'
import TutorMedia from './TutorMedia'
import TutorCases from './TutorCases'

import TeamBreakoutRoomJoined from '../components/videoconf/TeamBreakoutRoomJoined'
import ObserverBreakoutRoom from '../components/videoconf/ObserverBreakoutRoom'
import VideoCallWaitMessage from '../components/videoconf/VideoCallWaitMessage'
import MediaCacheComponent from '../components/MediaCacheComponent'
import SyncManager from '../components/SyncManager'
import StatusBar from '../components/StatusBar'
import VideoCall from '../components/videoconf/VideoCall'
import Sidebar from '../components/Sidebar'

import inCallSelector from '../selectors/inCall'
import InitialInformationPage from './InitialInformationPage'
import Modal from '../components/Modal'
import VideoCallTwilio from '../components/videoconf/VideoCallTwilio'
import VideoCallAgora from '../components/videoconf/VideoCallAgora'

type TutorViewProps = {
	observerBreakoutRoomsId: string
	joinedBreakoutGroupId: string
	selectedRegion: string
	showMainCall: boolean
	serverStatus: string
	displayName: string
	mainCallId: string
	domain: string
	location: Location
	match: Match
}

const TutorView: FunctionComponent<TutorViewProps> = props => {
	const { mainCallId, domain, joinedBreakoutGroupId, serverStatus, observerBreakoutRoomsId } = props
	const { displayName, showMainCall, selectedRegion } = props
	// react-router props
	const { location, match } = props

	// Check if we need to display a full screen video call ("main call") or breakout room.
	let mainCall = null
	let sideCall = null

	// Firstly, is the video conferencing server online?
	const cloudVideo = selectedRegion === 'twilio' || selectedRegion === 'agora'
	const vcAvailable = (domain && serverStatus === 'online') || cloudVideo

	if (vcAvailable && showMainCall) {
		if (selectedRegion === 'twilio') {
			mainCall = <VideoCallTwilio callId={mainCallId} domain={domain} displayName={displayName} />
		} else if (selectedRegion === 'agora') {
			mainCall = <VideoCallAgora callId={mainCallId} displayName={displayName} />
		} else {
			mainCall = <VideoCall callId={mainCallId} domain={domain} displayName={displayName} />
		}
	}

	const showJoinedBreakoutRoom = vcAvailable && !showMainCall && joinedBreakoutGroupId
	if (showJoinedBreakoutRoom) sideCall = <TeamBreakoutRoomJoined />

	const showObserverBreakoutRoom = vcAvailable && !showMainCall && !showJoinedBreakoutRoom && observerBreakoutRoomsId
	if (showObserverBreakoutRoom) sideCall = <ObserverBreakoutRoom />

	// Show the selected facilitator page unless it is the videoconf page and we are in a call
	const showContent = location.pathname !== `${match.url}/videoconf` || !showMainCall

	return (
		<div className="tutor-view">
			<MediaCacheComponent />
			<SyncManager />
			<StatusBar />
			<div className="tutor-view__main">
				<Sidebar />
				<div className="tutor-view__content">
					{showContent && (
						<>
							<Switch>
								<Route path={`${match.url}/cases`} component={TutorCases} />
								<Route path={`${match.url}/media`} component={TutorMedia} />
								<Route path={`${match.url}/groups`} component={TutorGroups} />
								<Route path={`${match.url}/settings`} component={TutorSettings} />
								<Route path={`${match.url}/sessions`} component={TutorSessions} />
								<Route path={`${match.url}/passcodes`} component={TutorPasswords} />
								<Route path={`${match.url}/videoconf`} component={TutorVideoConf} />
								<Route path={`${match.url}/prevsessions`} component={TutorPrevSessions} />
								<Route path={`${match.url}/participants`} component={TutorParticipants} />
								<Route path={`${match.url}/observers`} component={TutorObserverPasswords} />
								<Route path={`${match.url}/info`} component={InitialInformationPage} />
								<Route path={`${match.url}/`} component={TutorCases} />
							</Switch>
							<div className="tutor-view__content-overlay" />
						</>
					)}
					{mainCall}
					<VideoCallWaitMessage />
				</div>
				{sideCall}
			</div>
			<Modal />
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => {
	const loggedInWithLinkedAccess = state.linkedAccess && state.linkedAccess.connected
	const videoconf = state.videoconf || {}
	const showMainCall = inCallSelector(state)
	return {
		displayName: state.linkedAccess?.name || state?.tutor?.name || config.strings.TUTOR,
		observerBreakoutRoomsId: videoconf.observerBreakoutRoomsId,
		loggedIn: state.tutor.loggedIn || loggedInWithLinkedAccess,
		joinedBreakoutGroupId: state.joinedBreakout,
		selectedRegion: videoconf.serverId || '',
		linkedAccess: loggedInWithLinkedAccess,
		mainCallId: videoconf.mainCallId,
		serverStatus: videoconf.status,
		domain: videoconf.domain,
		showMainCall,
	}
}
const actions = {}

export default connect(mapStateToProps, actions)(TutorView)
