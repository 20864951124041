import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import StatusBarMessage from './StatusBarMessage'
import Countdown from '../elements/Countdown'

import config from '../../config'

type StatusBarProps = {
	currentCase: Case
	currentSession: CurrentSession | PreviousSession
	participants: Participant[]
	groups: GroupDetails[]
}

const StatusBar: FunctionComponent<StatusBarProps> = props => {
	const { currentSession, currentCase, participants, groups } = props
	const { SESSION, PARTICIPANTS_ONLINE, TEAMS_ONLINE } = config.strings
	// eslint-disable-next-line react/destructuring-assignment
	const sessionIndex = currentSession.index

	const THIRTY_SECONDS = 30 * 1000

	const participantsOnline = participants.filter(p => p.online).length
	const groupsOnline = groups.filter(g => g.lastAction > Date.now() - THIRTY_SECONDS).length

	return (
		<div className="status-bar">
			<div className="status-bar__top">
				<div className="status-bar__logo" />
				<div className="status-bar__case-title">
					{currentCase.title || 'View 360 Global'}
					<div className="status-bar__session-title">{sessionIndex && <div>{`${SESSION} ${sessionIndex}`}</div>}</div>
				</div>
				<div className="status-bar__stats">
					<div className="status-bar__stat-participants">
						<span className="status-bar__stat-label">{PARTICIPANTS_ONLINE}:</span> {participantsOnline}
					</div>
					<div className="status-bar__stat-groups">
						<span className="status-bar__stat-label">{TEAMS_ONLINE}:</span> {groupsOnline}
					</div>
				</div>
				<Countdown session={currentSession} />
			</div>
			<StatusBarMessage />
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const groupData = state.groupData || {}
	const currentCase = state.openCase || groupData.currentCase || {}
	const currentSession = state.currentSession || groupData.currentSession || {}
	const participants = state.participants || []
	const groups = state.groups || []
	// const participants = state.participants || []
	return { currentCase, currentSession, participants, groups }
}
const actions = {}
export default connect(mapStateToProps, actions)(StatusBar)
