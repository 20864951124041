/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
// import { useMount, useUnmount } from 'react-use'

import config from '../../../config'
import Actions from '../../actions'

import { ParticipantDetails, VideoClientEvents } from './VideoTypes'
import TwilioClient from '../../util/TwilioClient'
import VideoCallParticipantTwilio from './VideoCallParticipantTwilio'
import facilitatorIdSelector from '../../selectors/facilitatorId'
// import VideoCallParticipant from './VideoCallParticipant'
import teamNameFromColour from '../../util/teamNameFromColour'
import VideoCallCameraButton from './VideoCallCameraButton'
import VideoCallMuteButton from './VideoCallMuteButton'
import convertColour from '../../util/convertColour'
import IconButton from '../../elements/IconButton'

type SideCallTwilioProps = {
	displayModalPopup: (popupId: string, parms?: SimpleObject | string) => void
	displayName: string
	domain: string
	callId: string
	facilitatorId: string
	selectedCamera?: string
	selectedMic?: string
	selectedAudioOut?: string
	selectAudioOut?: typeof Actions.videoconf.selectAudioOut
	selectCamera?: typeof Actions.videoconf.selectCamera
	selectMic?: typeof Actions.videoconf.selectMic
	groups?: GroupDetails[]
	joinedBreakout: string
}

function SideCallTwilio(props: SideCallTwilioProps): ReactElement<SideCallTwilioProps> {
	// const { domain, callId, displayName, displayModalPopup, selectedCamera, selectedMic, selectedAudioOut } = props
	const { domain, callId, displayName, displayModalPopup, selectedCamera, selectedMic } = props
	// const { selectCamera, selectMic, selectAudioOut, facilitatorId } = props
	const { facilitatorId } = props
	const { groups, joinedBreakout } = props

	const client = useRef<TwilioClient>(null)
	const [remoteParticipants, setRemoteParticipants] = useState<ParticipantDetails[]>([])
	const [localParticipant, setLocalParticipant] = useState({} as ParticipantDetails)
	// const [localError, setLocalError] = useState('')

	const eventHandlerRefs = useRef<number[]>([])

	useEffect(() => {
		// Initialise Twilio client
		client.current = new TwilioClient({ domain, conferenceId: callId, displayName })
		// client.current.setDisplayName(displayName)

		window.VideoClient = client.current

		// Helper function for adding event listeners to the video client and keeping an array of the
		// references we get back, which allows us to easily detach those event handlers later.
		const addEventListener = (type: VideoClientEvents, callback: (...args: Array<unknown>) => void) => {
			eventHandlerRefs.current.push(client.current.on(type, callback))
		}

		// Add event listeners
		addEventListener(VideoClientEvents.LOCAL_PARTICIPANT_CHANGED, () => {
			const twilioClient = client.current
			setLocalParticipant({ ...twilioClient.localParticipant })

			// Save the video/audio devices that are being used into global state if we don't already have
			// user-selected devices.
			// const outputDevice = twilioClient.getAudioOutputDevice()

			// const { videoTrack, audioTrack } = twilioClient.localParticipant
			// const selectedMicDifferent = !selectedMic || selectedMic !== audioTrack?.deviceId
			// if (audioTrack && selectedMicDifferent) selectMic(audioTrack.deviceId)
			// const selectedCameraDifferent = !selectedCamera || selectedCamera !== videoTrack?.deviceId
			// if (videoTrack && selectedCameraDifferent && videoTrack.videoType !== 'desktop') selectCamera(videoTrack.deviceId)

			// if (outputDevice && !selectedAudioOut) selectAudioOut(outputDevice)

			// if (client.current.videoUnavailable) {
			// 	setLocalError('Video device unavailable')
			// } else {
			// 	setLocalError('')
			// }
		})

		addEventListener(VideoClientEvents.REMOTE_PARTICIPANTS_CHANGED, () => {
			setRemoteParticipants([...client.current.remoteParticipants])
		})

		client.current.connect({
			cameraDeviceId: selectedCamera || null,
			audioInputDeviceId: selectedMic || null,
			// audioOutputDeviceId: selectedAudioOut || null,
		})

		return () => {
			// Detach/remove all event listeners from the active TwilioClient
			eventHandlerRefs.current.forEach(ref => client.current.off(ref))
			setRemoteParticipants([])
			client.current.unload()
			// window.TwilioClient = null
		}
	}, [callId])

	// Switch camera/audio devices used by TwilioClient if they have changed in global state (i.e. in the user's settings)
	useEffect(() => {
		if (client.current) client.current.changeVideoInput(selectedCamera)
	}, [selectedCamera])

	useEffect(() => {
		if (client.current) client.current.changeAudioInput(selectedMic)
	}, [selectedMic])

	// useEffect(() => {
	// 	client.current.setAudioOutputDevice(selectedAudioOut)
	// }, [selectedAudioOut])

	const onClickParticipant = (participant: ParticipantDetails) => {
		config.strings.POPUP_TITLE_VIDEOPARTICIPANT = participant.displayName
		console.log('participant.participantId', participant.participantId)
		displayModalPopup('modal-video-participant', participant.participantId || 'local')
	}

	const participants = remoteParticipants.map(participant => {
		// eslint-disable-next-line no-shadow
		const { participantId } = participant
		return (
			<VideoCallParticipantTwilio
				key={participantId}
				participant={participant}
				facilitatorId={facilitatorId}
				onClick={() => onClickParticipant(participant)}
			/>
		)
	})

	let message = null
	if (joinedBreakout) {
		const group = groups.find(g => g.id === joinedBreakout)
		if (group) {
			message = (
				<>
					<div className="dot" style={{ backgroundColor: convertColour(group.colour) }} />
					{teamNameFromColour(group.colour)}
				</>
			)
		}
	}

	return (
		<div className="side-call">
			{message && <div className="side-call__message">{message}</div>}
			<div className="side-call__participants">{participants}</div>
			{/* Local participant video */}
			<VideoCallParticipantTwilio
				participant={localParticipant}
				facilitatorId={facilitatorId}
				// onClick={() => onClickParticipant(localParticipant)}
				// error={localError}
				uploadToImageCache
			/>
			<div className="video-call-buttons">
				<VideoCallMuteButton client={client.current} />
				<VideoCallCameraButton client={client.current} />
				<IconButton iconName="cog" onClick={() => displayModalPopup('modal-configure-video')} />
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	facilitatorId: facilitatorIdSelector(state),
	selectedAudioOut: state.videoconf.selectedAudioOut,
	selectedCamera: state.videoconf.selectedCamera,
	selectedMic: state.videoconf.selectedMic,
	joinedBreakout: state.joinedBreakout,
	groups: state.groups || [],
})
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	selectAudioOut: Actions.videoconf.selectAudioOut,
	selectCamera: Actions.videoconf.selectCamera,
	selectMic: Actions.videoconf.selectMic,
}
export default connect(mapStateToProps, actions)(SideCallTwilio)
