/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ReactElement } from 'react'
import Button from '../../elements/Button'

type InterpreterControlsProps = {
	onChangeChannel?: (boolean) => void
	onMainChannel?: boolean
	channelName: string
}

const InterpreterControls = (props: InterpreterControlsProps): ReactElement => {
	const { onChangeChannel, onMainChannel, channelName } = props
	return (
		<div className="video-call__interpreter-controls">
			<label>Select your channel</label>
			<div className="row">
				<Button onClick={_ => onChangeChannel(true)} text="Main" primary={onMainChannel} secondary={!onMainChannel} />
				<Button
					onClick={_ => onChangeChannel(false)}
					text={channelName}
					primary={!onMainChannel}
					secondary={onMainChannel}
				/>
			</div>
			<div className="smalltext">
				You are currently broadcasting to the {onMainChannel ? 'Main' : channelName} channel
			</div>
		</div>
	)
}

export default InterpreterControls
