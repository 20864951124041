import config from '../../config'

const { SERVER_URL_VIDEOCONF } = config

// const TOKEN_ENDPOINT = 'https://dv3pyjet04.execute-api.eu-west-2.amazonaws.com/dev/token'

export type DeviceInfo = {
	audioInputDevices: MediaDeviceInfo[]
	videoInputDevices: MediaDeviceInfo[]
	audioOutputDevices: MediaDeviceInfo[]
	hasAudioInputDevices: boolean
	hasVideoInputDevices: boolean
}

export async function getDeviceInfo(): Promise<DeviceInfo> {
	const devices = await navigator.mediaDevices.enumerateDevices()

	return {
		audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
		videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
		audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
		hasAudioInputDevices: devices.some(device => device.kind === 'audioinput'),
		hasVideoInputDevices: devices.some(device => device.kind === 'videoinput'),
	}
}

export async function fetchToken(roomName: string, displayName: string): Promise<string> {
	const tokenRequestBody = {
		user_identity: displayName,
		room_name: roomName,
		create_conversation: false,
	}
	const res = await fetch(`${SERVER_URL_VIDEOCONF}/videoconf/twiliotoken`, {
		method: 'POST',
		body: JSON.stringify(tokenRequestBody),
	})
	const { token } = await res.json()
	return token
}
