import copyToClipboard from 'copy-html-to-clipboard'

import ConfirmationHelper from '../util/ConfirmationHelper'
import OnlineComms from '../core/OnlineComms'
import action from '../util/action'
import config from '../../config'
import types from './types'

const {
	PASSCODE_DELETED_TITLE,
	PASSCODE_DELETED_MSG,
	PASSCODE_COPIED_MSG,
	PASSCODE_COPIED_TITLE,
	CONFIRM_DELETE_PASSWORD,
} = config.strings

let holdoff = false

const getPasswords: ActionCreator = () => dispatch => {
	if (holdoff) return
	dispatch(action(types.PASSWORDS_LOADING))
	OnlineComms.getPasswords().then(passwords => {
		if (holdoff) return
		dispatch(action(types.PASSWORDS_LOADED, passwords))
	})
}

const createPassword: ActionCreator = () => async dispatch => {
	holdoff = true

	const expiryHours = 96 // Default expiry: 4 days
	const expires = new Date()
	expires.setHours(expires.getHours() + (expiryHours || 96))

	const lastUpdate = new Date().toISOString()

	// Here we generate a password client side. The server will either accept this or generate a
	// different one if that suggestion wasn't acceptable for whatever reason
	const key = generatePassword(7)

	try {
		dispatch(action(types.PASSWORD_CREATED, { key, lastUpdate, expires }))
		await OnlineComms.addNewPassword(key)
	} catch (err) {
		console.error(err)
		dispatch(action(types.PASSWORD_DELETED, key))
	}

	holdoff = false
}

const deletePassword: ActionCreator<string> = key => dispatch => {
	ConfirmationHelper.confirm(CONFIRM_DELETE_PASSWORD, () => {
		holdoff = true
		// Remove passcode from state (optimistically)
		dispatch(action(types.PASSWORD_DELETED, key))
		ConfirmationHelper.successMessage(PASSCODE_DELETED_TITLE, PASSCODE_DELETED_MSG)
		OnlineComms.deletePassword(key)
			.catch(console.error)
			.then(_ => {
				holdoff = false
			})
	})
}

const copyPasswordToClipboard: ActionCreator = key => () => {
	copyToClipboard(key)
	ConfirmationHelper.successMessage(PASSCODE_COPIED_TITLE, PASSCODE_COPIED_MSG)
}

function generatePassword(length) {
	const possible = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'
	let text = ''
	for (let i = 0; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length))
	}
	return text
}

const actions = {
	getPasswords,
	createPassword,
	deletePassword,
	copyPasswordToClipboard,
}

export default actions
