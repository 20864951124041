import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useUnmount } from 'react-use'

import { IVideoClient, VideoClientEvents } from './VideoTypes'
import IconButton from '../../elements/IconButton'

type VideoCallChatButtonProps = {
	client: IVideoClient
	onClick: () => void
}

function VideoCallChatButton(props: VideoCallChatButtonProps): ReactElement<VideoCallChatButtonProps> {
	const { client, onClick } = props
	const eventHandlerRefs = useRef<Array<number>>([])
	const [isChatEnabled, setIsChatEnabled] = useState(false)
	const [chat, setChat] = useState<Array<ChatMessage>>([])
	const [lastSeenMessage, setLastSeenMessage] = useState<number>(null)

	useEffect(() => {
		if (!client) return
		setChat([...(client.chat || [])])
		const eventHanderRef = client.on(VideoClientEvents.MESSAGE_RECEIVED, () => {
			if (isChatEnabled) setLastSeenMessage(Math.max(...(client.chat || []).map(m => m.timestamp)))
			setChat([...(client.chat || [])])
		})

		// On dismount, remove event handlers
		return () => {
			if (eventHanderRef) client.off(eventHanderRef)
		}
	}, [client])

	useEffect(() => {
		if (isChatEnabled) setLastSeenMessage(Math.max(...chat.map(m => m.timestamp)))
	}, [chat, isChatEnabled])

	useUnmount(() => {
		// Detach event handler callbacks from Jitsi client
		eventHandlerRefs.current.forEach(ref => client.off(ref))
	})

	// Toggle microphone mute when button is clicked
	const onClickButton = async () => {
		if (onClick) onClick()
		const enabled = !isChatEnabled
		// if (enabled) setLastSeenMessage(Math.max(...chat.map(m => m.timestamp)))
		setIsChatEnabled(enabled)
	}

	const unseenMessage = Math.max(...chat.map(m => m.timestamp)) > lastSeenMessage

	return (
		<IconButton
			iconName="comments-alt"
			onClick={onClickButton}
			selected={isChatEnabled}
			title="Chat"
			showBadge={unseenMessage}
		/>
	)
}

export default VideoCallChatButton
