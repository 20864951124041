import React from 'react'

type TabsContainerProps = {
	activeTab: string | number
	children: React.ReactNode
}

export default function TabsContainer(props: TabsContainerProps): JSX.Element {
	const { children, activeTab } = props
	let child = null
	if (typeof activeTab === 'number') {
		child = children.length ? children[activeTab] : children
	} else {
		// Set class of each child according to whether tab is active or not
		// activeTab === id prop of the active child
		child = (children || []).map(c => {
			const { id } = c.props
			const className = id === activeTab ? 'tab__content--active' : 'tab__content--inactive'
			return React.cloneElement(c, { className: `${c.props.className || ''} ${className}` })
		})
	}
	return <div className="tabs-container">{child}</div>
}
