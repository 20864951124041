import { connect } from 'react-redux'
import React, { FunctionComponent, useState } from 'react'

import { useMount } from 'react-use'

import config from '../../config'
import Actions from '../actions'

import Preloader from '../elements/Preloader'
import Button from '../elements/Button'
import Switch from '../elements/Switch'

function errorBit(errors: Array<string>) {
	return (errors || []).map(e => (
		<div key={e} className="error">
			{e}
		</div>
	))
}

type EditMediaItemModalProps = {
	data: MediaItem
	updateMedia: (id: string, data: Partial<MediaItem>) => void
	savingDetails?: boolean
	errors: Array<string>
	clearErrors: () => void
	displayModalPopup: (name: string) => void
}

const EditMediaItemModal: FunctionComponent<EditMediaItemModalProps> = props => {
	const { MEDIA_ITEM_NAME, MEDIA_PHONE_ENABLED, SAVE, CANCEL } = config.strings

	const { data = {} as MediaItem, updateMedia, savingDetails, errors, clearErrors, displayModalPopup } = props
	console.log('data', data)

	const [name, setName] = useState(data.name || '')
	const [phoneEnabled, setPhoneEnabled] = useState(Boolean(data.phoneEnabled))

	useMount(() => clearErrors())

	// Handlers
	const onClickSave = async () => updateMedia(data.id, { name, phoneEnabled })

	let enableForPhoneRow = null
	if (data.extension === '.mp3') {
		enableForPhoneRow = (
			<>
				<div className="input edit-media-modal__enabled-row">
					<label>{MEDIA_PHONE_ENABLED}</label>
					<Switch value={phoneEnabled} onChange={val => setPhoneEnabled(val)} />
				</div>
				<p>
					If enabled, this media item will be sent to the participant&apos;s mobile phone number. If the participant has
					not provided a phone number, the media will be presented as a standard audio file.
				</p>
			</>
		)
	} else {
		enableForPhoneRow = (
			<>
				<div className="input edit-media-modal__enabled-row edit-media-modal__enabled-row--disabled">
					<label>{MEDIA_PHONE_ENABLED}</label>
					<Switch value={false} disabled />
				</div>
				<p>This option is only available for MP3 files.</p>
			</>
		)
	}

	return (
		<div className="edit-media-modal">
			<div className="input">
				<label>{MEDIA_ITEM_NAME}</label>
				<input value={name} onChange={e => setName(e.target.value)} required />
			</div>
			{enableForPhoneRow}
			{errorBit(errors)}
			<div className="modal-footer">
				<Button primary text={SAVE} onClick={onClickSave} enabled={name?.length > 0} />
				<Button secondary text={CANCEL} onClick={() => displayModalPopup('')} />
			</div>
			{savingDetails && <Preloader />}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	errors: state.errors,
	savingDetails: state.savingMediaItem,
})
const actions = {
	updateMedia: Actions.media.updateMedia,
	clearErrors: Actions.misc.clearErrors,
	displayModalPopup: Actions.misc.displayModalPopup,
}
export default connect(mapStateToProps, actions)(EditMediaItemModal)
