import loggedInAsGroup from './loggedInAsGroup'
import loggedInAsParticipant from './loggedInAsParticipant'

/**
 * This selector will return true if there is an active video call in progress. This does not include
 * breakout rooms.
 */
function inCall(state: StateTree): boolean {
	const settings = state.settings || {}
	const videoconf = state.videoconf || {}
	const { domain, status, mainCallId, mainCallStarted, mainCallWarningEnabled, serverId } = videoconf

	const cloudVideo = serverId === 'twilio' || serverId === 'agora'
	const serverOnline = status === 'online' || cloudVideo
	if (!serverOnline) return false

	let mainCallHasStarted = true

	// If user is logged in as a team or participant, check if we need to display a call warning message
	// before the video call is displayed
	if (loggedInAsParticipant(state) || loggedInAsGroup(state)) {
		const mainCallWarningTime = (mainCallWarningEnabled && settings.videoCallWarningTime) || 0
		const now = new Date()
		const callInitiatedByTutor = new Date(mainCallStarted)
		const callWillStart = new Date(callInitiatedByTutor.getTime() + mainCallWarningTime * 1000)
		mainCallHasStarted = Boolean(mainCallId && now >= callWillStart)
	}

	if (cloudVideo) return Boolean(mainCallId && mainCallHasStarted)

	return Boolean(mainCallId && serverOnline && domain && mainCallHasStarted)
}

export default inCall
