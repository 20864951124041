import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'
import { useMount } from 'react-use'
import Actions from '../actions'

import Preloader from '../elements/Preloader'
import NoRecords from './NoRecords'

type PreviousSessionsModalProps = {
	displayModalPopup: (popupId: string, parms?: SimpleObject | string) => void
	loadPreviousSession: (key: string) => void
	loadingPreviousSessions: boolean
	prevSessions: PreviousSession[]
	getPreviousSessions: () => void
}

const PreviousSessionsModal: FunctionComponent<PreviousSessionsModalProps> = props => {
	const { prevSessions, loadingPreviousSessions, displayModalPopup, loadPreviousSession, getPreviousSessions } = props

	useMount(() => getPreviousSessions())

	let content: JSX.Element | JSX.Element[] = <Preloader />

	if (!loadingPreviousSessions && !prevSessions.length) {
		content = <NoRecords />
	}

	if (!loadingPreviousSessions && prevSessions.length) {
		const onClickSession = session => {
			loadPreviousSession(session.key)
			displayModalPopup('')
		}

		content = prevSessions.map(session => {
			const formattedTime = new Date(session.timestamp).toString().substr(0, 25)
			const onClick = () => onClickSession(session)
			return (
				<div
					key={session.id}
					className="prev-session"
					onClick={onClick}
					role="button"
					aria-label={`View previous session ${session.index || 0} - ${formattedTime}`}
					tabIndex={0}
					onKeyPress={onClick}
				>
					<h2>Session {session.index || 0}</h2>
					<span className="prev-session__time">{formattedTime}</span>
				</div>
			)
		})
	}

	return <div className="prev-sessions-modal">{content}</div>
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<PreviousSessionsModalProps> => ({
	loadingPreviousSessions: Boolean(state.loadingPreviousSessions),
	prevSessions: state.prevSessions || [],
})
const actions: Partial<PreviousSessionsModalProps> = {
	getPreviousSessions: Actions.sessions.getPreviousSessions,
	loadPreviousSession: Actions.sessions.loadPreviousSession,
	displayModalPopup: Actions.misc.displayModalPopup,
}
export default connect(mapStateToProps, actions)(PreviousSessionsModal)
