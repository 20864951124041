/* eslint-disable react/jsx-props-no-spreading */

import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useMount } from 'react-use'
import { Location } from 'history'

import history from '../core/history'

import packageJson from '../../../package.json'

import Actions from '../actions'
import config from '../../config'

import LoginParticipantPhone from '../components/login/LoginParticipantPhone'
import LoginParticipant from '../components/login/LoginParticipant'
import LoginObserver from '../components/login/LoginObserver'
import LoginTutor from '../components/login/LoginTutor'
import LoginMain from '../components/LoginMain'
import Transition from '../elements/Transition'
import OnlineComms from '../core/OnlineComms'
import Preloader from '../elements/Preloader'

// =====================================================================================================================
// Transition wrappers

type TransitionProps = {
	in: boolean
}
const TransitionInFromRight: FunctionComponent<TransitionProps> = props => (
	<Transition className="login__slideinfromright" {...props} />
)
const TransitionInFromLeft: FunctionComponent<TransitionProps> = props => (
	<Transition className="login__slideinfromleft" {...props} />
)
const TransitionFadeIn: FunctionComponent<TransitionProps> = props => (
	<Transition className="login__fadeIn" {...props} />
)
// =====================================================================================================================

type LoginProps = {
	loggingIn?: boolean
	promptParticipantPhone?: boolean
	phoneNumber?: string
	navigatingTo?: string
	location?: Location
	checkLogin?: () => void
}

const Login: FunctionComponent<LoginProps> = props => {
	const { loggingIn, location, checkLogin } = props

	// Display "STAGING" or "DEVELOPMENT" in the version number if we're not in production
	let { VERSION } = config.strings
	const { NODE_ENV } = config
	if (NODE_ENV.toUpperCase() !== 'PRODUCTION') VERSION = `${NODE_ENV.toUpperCase()} - ${VERSION}`

	const routeMap = {
		PARTICIPANT: '/login/participant',
		OBSERVER: '/login/observer',
		TUTOR: '/login/tutor',
		PHONE: '/login/phone',
		MAIN: '/login',
	}

	// If the user has managed to get back to the login page while they still have an auth token,
	// then check if they are still logged in
	useMount(() => {
		if (OnlineComms.authToken) checkLogin()
	})

	const onClickBack = () => history.push('/login')

	return (
		<div className="login">
			<div className="login__inner">
				{/* Angled white section */}
				<svg className="login__white-bit" preserveAspectRatio="none" viewBox="0 0 1000 1000">
					<g>
						<path fill="#FFFFFF" stroke="none" d="M 500 0 L 0 1000 1000 1000 1000 0 500 0 Z" />
					</g>
				</svg>
				<div className="login__left">
					<div className="login__main-logo" />
				</div>
				<div className="login__right">
					<h1>Welcome</h1>
					<div className="login__content">
						{/* Login front page */}
						<TransitionInFromLeft in={location.pathname === '/login' || location.pathname === '/'}>
							<LoginMain
								onClickTutor={() => history.push(routeMap.TUTOR)}
								onClickParticipant={() => history.push(routeMap.PARTICIPANT)}
								onClickLinkedAccess={() => history.push(routeMap.OBSERVER)}
							/>
						</TransitionInFromLeft>

						{/* Facilitator login */}
						<TransitionInFromRight in={location.pathname === routeMap.TUTOR}>
							<LoginTutor onClickBack={onClickBack} />
						</TransitionInFromRight>

						{/* Observer login */}
						<TransitionInFromRight in={location.pathname === routeMap.OBSERVER}>
							<LoginObserver onClickBack={onClickBack} />
						</TransitionInFromRight>

						{/* Participant login */}
						<TransitionInFromRight in={location.pathname === routeMap.PARTICIPANT}>
							<LoginParticipant onClickBack={onClickBack} />
						</TransitionInFromRight>

						{/* Participant - phone number entry */}
						<TransitionInFromRight in={location.pathname === routeMap.PHONE}>
							<LoginParticipantPhone onClickBack={() => history.push(routeMap.PARTICIPANT)} />
						</TransitionInFromRight>

						{/* Overlay/preloader for when we're logging in */}
						<TransitionFadeIn in={loggingIn}>
							<div className="login__overlay">
								<Preloader />
							</div>
						</TransitionFadeIn>
					</div>
				</div>
				<span className="login__version">
					{VERSION} {packageJson.version}
				</span>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<LoginProps> => ({
	loggingIn: Boolean(state?.group?.loggingIn || state?.tutor?.loggingIn || state?.linkedAccess?.connecting),
	promptParticipantPhone: state.settings.promptParticipantPhone,
	phoneNumber: state.group.phoneNumber,
	navigatingTo: state.navigatingTo,
})
const actions = {
	loginAsTutor: Actions.login.loginAsTutor,
	clearErrors: Actions.misc.clearErrors,
	checkLogin: Actions.login.checkLogin,
}
export default connect(mapStateToProps, actions)(Login)
