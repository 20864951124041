import { useInterval, useMount } from 'react-use'
import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import config from '../../config'
import Actions from '../actions'

import loggedInAsObserverSelector from '../selectors/loggedInAsObserver'

import ContentLoadingWrapper from '../components/ContentLoadingWrapper'
import BubbleHeading from '../elements/BubbleHeading'
import Passcode from '../elements/Passcode'
import Button from '../elements/Button'
import loggedInAsFacilitator from '../selectors/loggedInAsFacilitator'

type TutorPasswordsProps = {
	passwords?: Passcode[]
	loadingPasswords?: boolean
	participants?: Participant[]
	copyPasswordToClipboard?: (password: string) => void
	getPasswords?: () => void
	createPassword?: () => void
	deletePassword?: (password: string) => void
	loggedInAsObserver?: boolean
	createPasswordAllowed?: boolean
}

const TutorPasswords: FunctionComponent<TutorPasswordsProps> = props => {
	const { passwords, loadingPasswords, participants, loggedInAsObserver, createPasswordAllowed } = props
	const { copyPasswordToClipboard, getPasswords, createPassword } = props
	useMount(() => getPasswords())
	useInterval(() => getPasswords(), 3000)

	const passwordsArray = [...(passwords || [])]

	// eslint-disable-next-line react/destructuring-assignment
	const deletePassword = !loggedInAsObserver && props.deletePassword

	const content = passwordsArray
		.sort((a, b) => {
			if (a.colour && !b.colour) return 1
			if (b.colour && !a.colour) return -1
			if (a.colour && b.colour && a.colour > b.colour) return 1
			if (a.colour && b.colour && a.colour < b.colour) return -1
			return a.lastUpdate > b.lastUpdate ? -1 : 1
		})
		.map(password => {
			// Check if we have a participant name that this passcode is allocated to
			const participant = password.participantId && participants.find(p => p.id === password.participantId)
			return (
				<Passcode
					key={password.key}
					password={password}
					participant={participant}
					deletePassword={deletePassword}
					copyPasswordToClipboard={copyPasswordToClipboard}
				/>
			)
		})

	return (
		<div className="tutor-passwords">
			<div className="tutor-passwords__top">
				<BubbleHeading>{config.strings.GROUP_PASSWORDS}</BubbleHeading>
				{createPasswordAllowed && <Button text={config.strings.GENERATE_NEW_PASSWORD} onClick={createPassword} />}
			</div>
			<div className="tutor-passwords__content">
				<ContentLoadingWrapper loading={loadingPasswords}>{content}</ContentLoadingWrapper>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<TutorPasswordsProps> => ({
	passwords: state.passwords || [],
	participants: state.participants || [],
	loadingPasswords: state.loadingPasswords || false,
	loggedInAsObserver: loggedInAsObserverSelector(state),
	createPasswordAllowed: loggedInAsFacilitator(state) || state?.settings?.observerNewPasscodesAllowed,
})
const actions = {
	getPasswords: Actions.passwords.getPasswords,
	deletePassword: Actions.passwords.deletePassword,
	createPassword: Actions.passwords.createPassword,
	copyPasswordToClipboard: Actions.passwords.copyPasswordToClipboard,
}
export default connect(mapStateToProps, actions)(TutorPasswords)
