import config from '../../config'
import teamNameFromColour from '../util/teamNameFromColour'

export type MessageDetails = {
	id?: string
	to?: string
	from?: string
	tutor?: boolean
	message?: string
	groupId?: string
	timestamp?: string
	observerId?: string
	translation?: string
	translated?: boolean
	privateMsg?: boolean
	participantId?: string
	participantName?: string
}

const sortAscending = (a, b) => a.timestamp - b.timestamp

function messagesForGroupSelector(state: StateTree, groupId?: string, showWhoMessageIsTo = false): MessageDetails[] {
	const _groupId = groupId || state?.group?.id
	if (!_groupId) return

	const { SUPERVISOR, TUTOR, TO_ALL_GROUPS } = config.strings

	const { viewingPreviousSession } = state
	const groupData = state.groupData || ({} as GroupData)
	let conversation = groupData.conversation || []

	const currentSession = groupData.currentSession || ({} as CurrentSession)
	const previousSession = state.previousSession || ({} as PreviousSession)
	const session = viewingPreviousSession ? previousSession : currentSession

	let allGroupMessages = session.allGroupMessages || []

	// If viewing previous session, get conversation from there
	if (viewingPreviousSession) {
		conversation = previousSession?.conversations?.[_groupId] || []
		allGroupMessages = previousSession?.conversations?.all || []
	}

	const msgsToAllGrps = allGroupMessages.map(o => ({ ...o, tutor: true, allGroups: true }))

	const fullConversation = (conversation || []).concat(msgsToAllGrps).sort(sortAscending)

	const group = state.group || ({} as GroupDetails)
	const participants = state.participants || []
	const groupColour = group.colour

	// If we were getting the messages for the tutor, we would get the group colour a different way...
	// const groups = groupsSelector(state) || []
	// const groupDetails = (groupId !== 'all' && groups.find(g => g.id === groupId)) || group || ({} as GroupDetails)

	const messageDetailsArray: MessageDetails[] = fullConversation.map(m => {
		let from = ''

		if (m.observerId && m.allGroups) {
			from = `${SUPERVISOR} (${TO_ALL_GROUPS})`
		} else if (m.observerId && showWhoMessageIsTo && groupColour) {
			from = `${SUPERVISOR} (to ${teamNameFromColour(groupColour)})`
		} else if (m.observerId) {
			from = SUPERVISOR
		} else if (m.tutor && m.allGroups) {
			from = `${TUTOR} (${TO_ALL_GROUPS})`
		} else if (m.tutor && showWhoMessageIsTo && groupColour) {
			from = `${TUTOR} (to ${teamNameFromColour(groupColour)})`
		} else if (m.tutor) {
			from = TUTOR
		} else {
			from = teamNameFromColour(groupColour)
		}

		return {
			from,
			id: m.id,
			tutor: m.tutor,
			groupId: m.groupId,
			message: m.message,
			timestamp: m.timestamp,
			observerId: m.observerId,
			translated: m.translated,
			translation: m.translation,
			participantId: m.participantId,
			participantName: m.participantId
				? participants?.find(p => p.id === m.participantId)?.name
				: m.observerName || null,
		}
	})

	return messageDetailsArray
}

export default messagesForGroupSelector
