import { connect } from 'react-redux'
import React, { FunctionComponent, useState } from 'react'

import config from '../../../config'
import Actions from '../../actions'

import InputFieldManager from '../InputFieldManager'

import PhoneNumberInput from '../../elements/PhoneNumberInput'
import Transition from '../../elements/Transition'
import Preloader from '../../elements/Preloader'
import Button from '../../elements/Button'
import Error from '../../elements/Error'

type LoginParticipantPhoneProps = {
	updateParticipantPhoneNumber: (phoneNumber: string) => void
	onClickBack: () => void
	errors?: string[]
	initialValue?: string
	clearErrors: () => void
	navigateTo: (target: string) => void
}

type TransitionProps = {
	in: boolean
}
const TransitionFadeIn: FunctionComponent<TransitionProps> = props => (
	/* eslint-disable react/jsx-props-no-spreading */
	<Transition className="login__fadeIn" {...props} />
)

const LoginParticipantPhone: FunctionComponent<LoginParticipantPhoneProps> = props => {
	const { updateParticipantPhoneNumber, errors = [], clearErrors, navigateTo, initialValue } = props
	const { OK, MOBILE_NUMBER_PLACEHOLDER, MOBILE_NUMBER_TITLE, MOBILE_NUMBER_DESC, MOBILE_NUMBER_SKIP } = config.strings

	const [initialised, setInitialised] = useState(false)
	const [busy, setBusy] = useState(false)

	if (!initialised) {
		if (initialValue) InputFieldManager.setValue('phone', initialValue)
		setInitialised(true)
	}

	const onClickOk = async () => submitPhoneNumber(InputFieldManager.getValue('phone'))
	const onClickSkip = async () => submitPhoneNumber('')

	const submitPhoneNumber = async (number: string) => {
		clearErrors()
		setBusy(true)
		try {
			InputFieldManager.setValue('phone', number)
			await updateParticipantPhoneNumber(number)
			navigateTo('group-view')
		} catch (err) {
			console.error(err)
		}
		setBusy(false)
	}

	return (
		<div className="login-group">
			<div className="login-group__main">
				<h3>{MOBILE_NUMBER_TITLE}</h3>
				<p>{MOBILE_NUMBER_DESC}</p>
				<div className="login__input-row">
					<PhoneNumberInput name="phone" onEnter={onClickOk} placeholder={MOBILE_NUMBER_PLACEHOLDER} />
				</div>
			</div>
			<div className="login__error-space">
				{errors.map(error => (
					<Error key={error}>{error}</Error>
				))}
			</div>
			<div className="login__otherbuttons">
				<Button text={OK} onClick={onClickOk} />
				<Button secondary text={MOBILE_NUMBER_SKIP} onClick={onClickSkip} />
			</div>
			<TransitionFadeIn in={busy}>
				<div className="login__overlay">
					<Preloader />
				</div>
			</TransitionFadeIn>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	initialValue: state?.group?.phoneNumber,
	errors: state.errors || [],
})
const actions = {
	updateParticipantPhoneNumber: Actions.participants.updateParticipantPhoneNumber,
	clearErrors: Actions.misc.clearErrors,
	navigateTo: Actions.misc.navigateTo,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<LoginParticipantPhoneProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(LoginParticipantPhone)
