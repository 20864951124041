/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

const TUTOR_LOGIN_START: Reducer = (state, action) => ({
	...state,
	tutor: { ...state.tutor, loginError: null, loggingIn: true },
})

const TUTOR_LOGIN_SUCCESS: Reducer = (state, action) => {
	const loggedInAs = (action.payload || {}).username
	const tutor = { ...state.tutor, ...action.payload, loggedIn: true, loggingIn: null, loggedInAs }

	const { ipAddress } = action.payload || {}
	return { ...state, tutor, caseList: [], openCase: null, ipAddress, group: {} as GroupDetails }
}

const TUTOR_LOGIN_ERROR: Reducer = (state, action) => {
	const loginError = action.payload
	return { ...state, tutor: { ...state.tutor, loginError, loggingIn: null } }
}

const TUTOR_SIGN_OUT: Reducer = state => ({
	...state,
	tutor: {},
	caseList: [],
	linkedAccess: {},
	navigatingToModal: null,
})

const GOT_IP_ADDRESS: Reducer = (state, action) => ({ ...state, ipAddress: action.payload })

const TUTOR_GOT_LICENCE: Reducer = (state, action) => ({ ...state, licence: action.payload })

//==================================================================================================

const reducers = {
	TUTOR_LOGIN_START,
	TUTOR_LOGIN_SUCCESS,
	TUTOR_LOGIN_ERROR,
	TUTOR_SIGN_OUT,
	GOT_IP_ADDRESS,
	TUTOR_GOT_LICENCE,
}

//==================================================================================================

const handler: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	if (!newState.tutor) newState.tutor = {}
	return newState
}

export default handler
