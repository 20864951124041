export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'Annuleer',
	TUTOR: 'Gespreksleider',
	CURRENT_SESSION: 'Huidige Sessie',
	TEAM: 'Team',
	SIGNOUT: 'Uitloggen',
	INITIAL_UPDATE: 'Sessie informatie',
	ADVICE: 'Beslissing',
	RATIONALE: 'Motivering/beweegreden',
	MEDIA_FEED: 'Ontvangen informatie',
	MESSAGES: 'Berichten',
	PREVSESSIONS: 'Vorige sessies',
	RETURN_TO_CURRENT: 'Keer terug naar de huidige sessie',
	SESSION_IS_PAUSED: 'De sessie is momenteel gepauzeerd',
	ATTRIB_CURR_AUTHOR: 'Beslisser',
	SAVE: 'Opslaan',
	CHANGE: 'Veranderen',
	NO_AUTHOR: 'Niet ingesteld',
	NO_INITIAL_INFO: 'Wacht aub tot de volgende sessie begint',
	DECISION_MAKER_SELECT: 'Selecteer aub een beslisser',
	SESSION_COMPLETE: 'De vorige sessie is afgelopen',
	OPENED_AT: 'Geopend om',
	SEND_MESSAGE_PLACEHOLDER: 'Schrijf een bericht',
	SEND: 'Verstuur',
	TO_ALL_GROUPS: 'Aan alle groepen',
	SHOW_TRANSLATION: 'Toon vertaling',
	SHOW_ORIGINAL: 'Toon origineel',
	CREDENTIALS_EXPIRED: 'Uw wachtwoord is verlopen. Log opnieuw in.',
	INCOMING_MESSAGE: 'Inkomend bericht',
	INCOMING_MESSAGE_TEXT: 'U heeft een nieuw bericht ontvangen',
	INCOMING_MEDIA: 'Inkomend mediabericht',
	INCOMING_MEDIA_TEXT: 'U heeft een nieuw media bericht ontvangen',
	INCOMING_UPDATE: 'Inkomende update',
	INCOMING_UPDATE_TEXT: 'U heeft een nieuwe update ontvangen',
	CONFIRM_LOGOUT: 'Weet u zeker dat u wilt uitloggen?',
	MUTED_MESSAGE: 'Je microfoon is uitgeschakeld',
	NEWSTYPE_UPDATE: 'Update ontvangen',
	WARNING_TIME_MSG: 'Er zijn nog 5 minuten over',
	VIEW_INITIAL_UPDATE: 'Bekijk sessie informatie',
	DECISION_LOG: 'Beslissingslog',
	NOTEPAD: 'Notitieblok',
	NOTETAKER: 'Notulist',
	NO_DECISION_LOGS: 'Geen beslissingslog',
	TO: 'Aan',
	EVERYONE: 'Iedereen',
	POPUP_TITLE_CONFIGUREVIDEO: 'Configureer audio/video',
	CAMERA: 'Camera',
	MICROPHONE: 'Microfoon',
	AUDIO_OUTPUT_DEVICE: 'Audio output apparaat',
	DFT_NOTIFICATIONS_TEXT:
		'Nieuwe media wordt hier getoond. Houd dit apparaat in de gaten voor nieuwe media en updates. De originele sessie informatie is beschikbaar via de knop bovenaan het scherm. Communiceer met de gespreksleider via de Berichten app in de linker onderhoek van dit apparaat.',
	DFT_MESSAGES_TEXT:
		'Deze app stelt u in staat om berichten te sturen en te ontvangen van de gespreksleider tijdens de sessie.',
	ADD_DECISION_LOG: 'Voeg beslissingslog toe',
	ENTER_ADVICE: 'Voer uw beslissing in',
	ENTER_RATIONALE: 'Voer uw motivatie in',
}
