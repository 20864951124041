import { connect } from 'react-redux'
import React, { FunctionComponent, useState } from 'react'

import config from '../../config'

import loggedInAsParticipantSelector from '../selectors/loggedInAsParticipant'
import selectedMediaSelector from '../selectors/selectedMedia'
import sessionSelector from '../selectors/session'
import inCallSelector from '../selectors/inCall'

import ParticipantDecisionLog from '../components/ParticipantDecisionLog'
import ParticipantHeaderBar from '../components/ParticipantHeaderBar'
import MediaCacheComponent from '../components/MediaCacheComponent'
import VirtualPhone from '../components/phone/VirtualPhone'
import SyncManagerGroup from '../components/SyncManagerGroup'
import TeamBreakoutRoom from '../components/videoconf/TeamBreakoutRoom'
import StatusBarMessage from '../components/StatusBarMessage'
import MediaViewer from '../components/MediaViewer'
import Modal from '../components/Modal'
import getTime from '../util/getTime'
// import DarkModeSwitch from '../elements/DarkModeSwitch'
import { sessionIsActive } from '../util/SessionHelpers'
import VideoCall from '../components/videoconf/VideoCall'
import VideoCallTwilio from '../components/videoconf/VideoCallTwilio'
import teamNameFromColour from '../util/teamNameFromColour'
import VideoCallWaitMessage from '../components/videoconf/VideoCallWaitMessage'
import Notepad from '../components/Notepad'
import TabsRow from '../elements/TabRow'
import Tab from '../elements/Tab'
import TabsContainer from '../elements/TabsContainer'
import VideoCallAgora from '../components/videoconf/VideoCallAgora'

const { AUDIO_TYPES, VIDEO_TYPES } = config

// =================================================================================================
type ParticipantViewProps = {
	viewingPreviousSession: boolean
	loggedInAsParticipant: boolean
	selectedScheduleRowId: string
	participantDomain: string
	breakoutRoomsId: string
	selectedRegion: string
	serverStatus: string
	mainCallId: string
	displayName: string
	inCall: boolean
	sessionActive: boolean
	sessionInfoClosed: boolean
	mediaExpanded: boolean
	notepadEnabled: boolean
	session: CurrentSession | PreviousSession
	selectedMediaItem?: MediaItem
	selectedScheduleRow?: ScheduleRow
}

const ParticipantView: FunctionComponent<ParticipantViewProps> = props => {
	const { loggedInAsParticipant, selectedScheduleRowId, session, viewingPreviousSession, sessionInfoClosed } = props
	const { participantDomain, serverStatus, breakoutRoomsId, inCall, selectedMediaItem, selectedScheduleRow } = props
	const { sessionActive, mediaExpanded, mainCallId, displayName, notepadEnabled, selectedRegion } = props

	const { DECISION_LOG, NOTEPAD } = config.strings

	const [activeTab, setActiveTab] = useState(0)

	// const showSideCall = !inCall && breakoutRoomsId && participantDomain && loggedInAsParticipant && serverOnline
	let showMediaViewer = Boolean(selectedScheduleRowId)

	// Don't show media viewer if it is a video file being viewed on the phone
	const videoSelected = VIDEO_TYPES.includes(selectedMediaItem?.extension) && !mediaExpanded
	// Don't show media viewer if it is an audio file
	const audioSelected = AUDIO_TYPES.includes(selectedMediaItem?.extension)
	// Don't show media viewer if it is an update
	const updateSelected = selectedScheduleRow?.type === 'update'

	if (audioSelected || videoSelected || updateSelected) {
		showMediaViewer = false
	}

	const showingPdf = selectedMediaItem?.extension === '.pdf'

	let className = 'participant-view'
	if (mediaExpanded && showingPdf) className += ' participant-view--expanded'

	const sessionJustStarted = !viewingPreviousSession && session.startTime > getTime() - 600 * 1000 && !sessionInfoClosed
	const showDecisionLog = session?.id && !sessionJustStarted
	if (showDecisionLog) className += ' participant-view--insession'
	if (showDecisionLog && showMediaViewer) className += ' participant-view--mediaopen'
	if (sessionActive) className += ' participant-view--sessionactive'

	const cloudVideo = selectedRegion === 'twilio' || selectedRegion === 'agora'
	const vcAvailable = cloudVideo || (participantDomain && serverStatus === 'online')
	const showSideCall = !inCall && breakoutRoomsId && loggedInAsParticipant && vcAvailable

	let middle = null
	if (inCall) {
		if (selectedRegion === 'twilio') {
			middle = <VideoCallTwilio callId={mainCallId} domain={participantDomain} displayName={displayName} />
		} else if (selectedRegion === 'agora') {
			middle = <VideoCallAgora callId={mainCallId} displayName={displayName} />
		} else {
			middle = <VideoCall callId={mainCallId} domain={participantDomain} displayName={displayName} />
		}
	} else {
		middle = (
			<div className="row">
				<VirtualPhone />
				{/* <DarkModeSwitch /> */}
				<div className="participant-view__middle" role="main">
					{showMediaViewer && <MediaViewer />}

					{!notepadEnabled && <ParticipantDecisionLog />}

					{notepadEnabled && (
						<>
							<TabsRow activeTab={activeTab}>
								<Tab id="declog" onClick={() => setActiveTab(0)}>
									{DECISION_LOG}
								</Tab>
								<Tab id="notepad" onClick={() => setActiveTab(1)}>
									{NOTEPAD}
								</Tab>
							</TabsRow>
							<TabsContainer activeTab={activeTab}>
								<ParticipantDecisionLog id="declog" showTitle={false} />
								<Notepad id="notepad" />
							</TabsContainer>
						</>
					)}
				</div>
			</div>
		)
	}

	return (
		<>
			<MediaCacheComponent />
			<SyncManagerGroup />
			<div className={className}>
				<ParticipantHeaderBar />
				<StatusBarMessage />
				{middle}
				<Modal />
				<VideoCallWaitMessage />
			</div>
			{showSideCall && <TeamBreakoutRoom />}
		</>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const loggedInAsParticipant = loggedInAsParticipantSelector(state)

	const videoconf = state.videoconf || {}
	const { selectedScheduleRowId, viewingPreviousSession, sessionInfoClosed, mediaExpanded } = state
	const { breakoutRoomsId, participantDomain, status, mainCallId, serverId } = videoconf

	const session = sessionSelector(state)
	const { selectedRow: scheduleRow, mediaItem } = selectedMediaSelector(state)
	const sessionActive = !viewingPreviousSession && sessionIsActive(session)
	const inCall = inCallSelector(state)

	const displayName = state.group.name || teamNameFromColour(state.group.colour)

	const { notepadEnabled } = state.settings

	return {
		selectedScheduleRow: scheduleRow,
		selectedMediaItem: mediaItem,
		selectedRegion: serverId,
		viewingPreviousSession,
		loggedInAsParticipant,
		serverStatus: status,
		selectedScheduleRowId,
		sessionInfoClosed,
		participantDomain,
		breakoutRoomsId,
		notepadEnabled,
		sessionActive,
		mediaExpanded,
		displayName,
		mainCallId,
		session,
		inCall,
	}
}
const actions = {}

export default connect(mapStateToProps, actions)(ParticipantView)
