import React, { ReactElement } from 'react'

type ErrorProps = {
	children: string
}

function Error(props: ErrorProps): ReactElement<ErrorProps> {
	const { children } = props
	return (
		<div className="error" role="alert">
			{children}
		</div>
	)
}

export default Error
