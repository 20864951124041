import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import config from '../../../config'
import Actions from '../../actions'

import getTextDirection from '../../util/getTextDirection'
import Icon from '../../elements/Icon'

type NewSessionNotificationProps = {
	session: CurrentSession
	onClick: () => void
	displayModalPopup: (name: string) => void
	flashingEnabled: boolean
}

function formatTime(time: number | string): string {
	return new Date(time).toTimeString().substr(0, 5)
}

const NewSessionNotification: FunctionComponent<NewSessionNotificationProps> = props => {
	const { session, displayModalPopup, flashingEnabled } = props
	const { SESSION_INFO_RECEIVED } = config.strings

	const title = SESSION_INFO_RECEIVED

	let className = 'new-session-notification notification'
	if (flashingEnabled) className += ' notification--recent'

	const onClick = () => displayModalPopup('modal-initial-information')

	return (
		<div className={className} onClick={onClick} onKeyPress={onClick} role="button" aria-label={title} tabIndex={0}>
			<div className="notification__icon-container" style={{ backgroundColor: 'transparent' }}>
				<Icon name="circle-info" />
			</div>

			<div className="notification__details">
				<div className="left">
					<h3 className="notification__title" title={title} style={{ direction: getTextDirection(title) }}>
						{title}
					</h3>
					<div className="row">
						<div className="notification__time">{formatTime(session?.startTime || Date.now())}</div>
					</div>
				</div>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<NewSessionNotificationProps> => ({
	session: state?.currentSession,
	flashingEnabled: state?.settings?.flashingEnabled,
})
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
}

export default connect(mapStateToProps, actions)(NewSessionNotification)
