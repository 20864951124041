import Video, {
	CreateLocalTrackOptions,
	LocalAudioTrack,
	LocalAudioTrackPublication,
	LocalDataTrack,
	LocalTrack,
	LocalVideoTrack,
	LocalVideoTrackPublication,
	NetworkQualityStats,
	PreflightTestReport,
	RemoteParticipant,
	Room,
	runPreflight,
} from 'twilio-video'

import parse from 'try-json'

import {
	ParticipantTrack,
	ParticipantDetails,
	ClientParams,
	ParticipantStats,
	ConnectParams,
	IVideoClient,
	VideoChatMessage,
	VideoClientEvents,
	VideoClientEvent,
	EventCallback,
} from '../components/videoconf/VideoTypes'

import { getDeviceInfo, fetchToken } from './twilioUtils'

// import { VideoRoomMonitor } from '@twilio/video-room-monitor'
require('../lib/twilio-video-room-monitor.min.js')

const { VideoRoomMonitor } = window.Twilio

const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

type PartialRecord<K extends keyof any, T> = { [P in K]?: T }

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
	width: 1280,
	height: 720,
	frameRate: 24,
}

class TwilioClient implements IVideoClient {
	room: Room
	isConnecting: boolean
	localTracks: LocalTrack[] = []
	videoInputDevices: MediaDeviceInfo[] = []
	localVideoTrack: LocalVideoTrack | null = null
	localAudioTrack: LocalAudioTrack | null = null
	screenTrack: LocalVideoTrack | null = null
	remoteParticipants: ParticipantDetails[] = []
	localParticipant: ParticipantDetails = null
	twilioToken?: string
	// eslint-disable-next-line react/static-property-placement
	displayName: string
	participantId: string
	conferenceId?: string
	videoDeviceId?: string
	audioDeviceId?: string
	videoError?: string
	audioOutputDeviceId?: string
	logging?: boolean
	properties: SimpleObject = {}
	dataTrack: LocalDataTrack | null = null
	chat: VideoChatMessage[] = []

	// Event handlers is a map of functions that map to a key, being any one of the TwilioClientEvents
	// eventHandlers: PartialRecord<TwilioClientEvent, EventCallback> = {}
	eventHandlers: PartialRecord<VideoClientEvent, Record<string, EventCallback>> = {}

	constructor({ displayName, conferenceId, logging = true }: ClientParams) {
		this.displayName = displayName
		this.conferenceId = conferenceId
		this.logging = logging

		getDeviceInfo().then(({ videoInputDevices }) => {
			this.videoInputDevices = videoInputDevices
		})
		this.onDisconnect = this.onDisconnect.bind(this)
		this.unload = this.unload.bind(this)
		this.dispatch = this.dispatch.bind(this)
		this.onRemoteParticipantConnected = this.onRemoteParticipantConnected.bind(this)
		this.onRemoteParticipantDisconnected = this.onRemoteParticipantDisconnected.bind(this)
		this.setProperty = this.setProperty.bind(this)

		window.addEventListener('beforeunload', this.unload)
	}

	// ===============================================================================================

	on(eventType: VideoClientEvent, callback: EventCallback): number {
		if (!this.eventHandlers[eventType]) this.eventHandlers[eventType] = {}
		const id = Math.floor(Math.random() * 100000000)
		this.eventHandlers[eventType][id] = callback
		return id
	}

	off(ref: number): void {
		// Find event handler that matches ref
		Object.keys(this.eventHandlers).forEach(eventType => {
			const handlers = this.eventHandlers[eventType]
			if (handlers[ref]) delete handlers[ref]
		})
	}

	dispatch(eventType: VideoClientEvent, data?: unknown): void {
		const handlers = this.eventHandlers[eventType]
		if (!handlers) return
		Object.keys(handlers).forEach(ref => {
			const callback = handlers[ref]
			callback(data)
		})
	}

	// ===============================================================================================

	log(str, ...data) {
		if (this.logging) {
			console.log(str, ...data)
		}
	}

	private async getToken(): Promise<string> {
		const name = JSON.stringify({ ...this.properties, name: this.displayName })
		this.twilioToken = this.twilioToken || (await fetchToken(this.conferenceId, name))
		return this.twilioToken
	}
	// const { cameraDeviceId, audioInputDeviceId, startAudioMuted, noAudio, noVideo, mode = 'presentation' } = params

	async connect(params: ConnectParams = {}): Promise<void> {
		try {
			const { cameraDeviceId, audioInputDeviceId, startAudioMuted, noAudio, noVideo } = params
			await this.getToken()

			this.dataTrack = new LocalDataTrack()

			/* 
				We would like to connect with dominant speaker detection enabled and automatic switch-off of video tracks
				based on the Network Bandwidth Profiler.
				See: https://www.twilio.com/docs/video/tutorials/using-bandwidth-profile-api#understanding-clientTrackSwitchOffControl
			*/
			// Recommended settings: https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#desktop-browser-presentation-recommended-settings
			const connectOpts: Video.ConnectOptions = {
				tracks: [this.dataTrack],
				audio: true,
				// video: { height: 480, frameRate: 24, width: 640 },
				video: { height: 720, frameRate: 24, width: 1280 },
				bandwidthProfile: {
					video: {
						mode: 'presentation',
						clientTrackSwitchOffControl: 'auto',
						contentPreferencesMode: 'auto',
						dominantSpeakerPriority: 'standard',
						// trackSwitchOffMode: undefined,
					},
				},
				preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
				dominantSpeaker: true,
				maxAudioBitrate: 16000,
				networkQuality: { local: 3, remote: 3 }, // Network Quality verbosity [1-3 / 0-3]
			}

			this.videoDeviceId = cameraDeviceId
			this.audioDeviceId = audioInputDeviceId

			if (!noVideo) {
				try {
					await this.createLocalVideoTrack(cameraDeviceId)
					connectOpts.tracks.push(this.localVideoTrack)
					this.videoError = null
				} catch (err) {
					console.log(`Could not create local video track: ${err}`)
					this.videoError = `Camera unavailable: ${err.message}`
				}
			}

			if (!noAudio) {
				try {
					await this.createLocalAudioTrack(audioInputDeviceId)
					connectOpts.tracks.push(this.localAudioTrack)
					if (startAudioMuted) this.localAudioTrack.disable()
				} catch (err) {
					console.log(`Could not create local audio track: ${err}`)
				}
			}

			this.updateLocalParticipantDetails()

			const room = await Video.connect(this.twilioToken, connectOpts)
			this.log('TwilioClient:onConnectedToRoom', room)
			this.room = room

			VideoRoomMonitor.registerVideoRoom(room)

			// Update local participant details again after connecting to the room
			this.updateLocalParticipantDetails()

			room.participants.forEach(participant => {
				this.onRemoteParticipantConnected(participant)
			})

			room.on('disconnected', this.onDisconnect)
			room.on('participantConnected', this.onRemoteParticipantConnected)
			room.on('participantDisconnected', this.onRemoteParticipantDisconnected)

			room.on('reconnected', () => this.log('Reconnected.'))
			room.on('reconnecting', () => this.log('Reconnecting...'))

			room.on('dominantSpeakerChanged', participant => {
				console.log('The new dominant speaker in the Room is:', participant)
				this.dispatch(VideoClientEvents.DOMINANT_SPEAKER_CHANGED, { participantId: participant?.sid })
			})

			// This app can add up to 13 'participantDisconnected' listeners to the room object, which can trigger
			// a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
			// room.setMaxListeners(15)  // No longer supported?

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.twilioRoom = room

			this.dispatch(VideoClientEvents.ROOM_JOINED, room)

			room.localParticipant.videoTracks.forEach(publication =>
				// All video tracks are published with 'low' priority because the video track
				// that is displayed in the 'MainParticipant' component will have it's priority
				// set to 'high' via track.setPriority()
				publication.setPriority('low')
			)

			room.localParticipant.on(
				'networkQualityLevelChanged',
				(networkQualityLevel, networkQualityStats: NetworkQualityStats) => {
					this.dispatch(VideoClientEvents.LOCAL_STATISTICS_RECEIVED, networkQualityStats)
					this.updateLocalParticipantDetails()
				}
			)
			// this.setIsConnecting(false)

			// Add a listener to disconnect from the room when a user closes their browser
			window.addEventListener('beforeunload', this.unload)

			// setTimeout(async () => {
			// 	console.log('Getting stats...')
			// 	const stats = await room.getStats()
			// 	console.log(`stats (${this.displayName})`, stats)
			// }, 10000)

			// Add a listener to disconnect from the room when a mobile user closes their browser
			// if (isMobile) window.addEventListener("pagehide", disconnect);
		} catch (err) {
			console.error(err)
		}
	}

	setProperty(name: string, value: unknown): void {
		this.properties[name] = value
		if (this.localParticipant) {
			this.localParticipant.properties = this.localParticipant.properties || {}
			this.localParticipant.properties[name] = value
		}
	}

	detachTrack(track: LocalAudioTrack | LocalVideoTrack): void {
		if (track) {
			if (this.room) this.room.localParticipant.unpublishTrack(track)
			track.stop()
			const htmlElements = track.detach()
			// eslint-disable-next-line no-restricted-syntax
			for (const htmlElement of htmlElements) {
				htmlElement.src = null
				htmlElement.srcObject = null
			}
		}
	}

	async unload(): Promise<void> {
		this.detachTrack(this.localVideoTrack)
		this.localVideoTrack = null
		this.detachTrack(this.localAudioTrack)
		this.localAudioTrack = null

		VideoRoomMonitor.closeMonitor()

		if (this.room) {
			this.room.disconnect()
		}
	}

	async createLocalAudioTrack(deviceId?: string): Promise<LocalAudioTrack> {
		const newTrack = (await this.createLocalTrack(deviceId, 'audio')) as LocalAudioTrack
		return newTrack
	}

	async createLocalVideoTrack(deviceId?: string): Promise<LocalVideoTrack> {
		const newTrack = (await this.createLocalTrack(deviceId, 'video')) as LocalVideoTrack
		return newTrack
	}

	async createLocalTrack(deviceId = '', kind = 'video'): Promise<LocalTrack> {
		const options: CreateLocalTrackOptions = {}

		if (deviceId) options.deviceId = { exact: deviceId }

		let newTrack: LocalTrack = null
		let resultDeviceId = null
		const func = kind === 'video' ? Video.createLocalVideoTrack : Video.createLocalAudioTrack
		try {
			newTrack = await func(options)
			resultDeviceId = deviceId
		} catch (err) {
			// If we had an overconstrained error it can imply that the specified device is no longer
			// available (e.g. not plugged in) so we should revert to the default device.
			delete options.deviceId
			newTrack = await func(options)
		}

		if (kind === 'video') {
			this.videoDeviceId = resultDeviceId
			this.localVideoTrack = newTrack as LocalVideoTrack
		} else {
			this.audioDeviceId = resultDeviceId
			this.localAudioTrack = newTrack as LocalAudioTrack
		}

		this.updateLocalParticipantDetails()
		return newTrack
	}

	/**
	 * Create a LocalVideoTrack for your screen. You can then share it
	 * with other Participants in the Room.
	 * @param {number} height - Desired vertical resolution in pixels
	 * @param {number} width - Desired horizontal resolution in pixels
	 * @returns {Promise<LocalVideoTrack>}
	 */
	async createScreenTrack(height: number, width: number): Promise<LocalVideoTrack> {
		if (!this.room) return
		if (typeof navigator === 'undefined' || !navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
			return Promise.reject(new Error('getDisplayMedia is not supported'))
		}
		try {
			const stream = await navigator.mediaDevices.getDisplayMedia({ video: { height, width } })
			this.screenTrack = new LocalVideoTrack(stream.getVideoTracks()[0])
			const publishedTrack = await this.room.localParticipant.publishTrack(this.screenTrack)
			publishedTrack.setPriority('high')
		} catch (err) {
			// const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
			// eslint-disable-next-line no-alert
			alert(`Screen sharing unavailable: ${err.message}`)
		}
		this.updateLocalParticipantDetails()
		// this.dispatch(VideoClientEvents.LOCAL_PARTICIPANT_CHANGED, this.room.localParticipant)
		// eslint-disable-next-line no-unused-expressions
		this.screenTrack.on('stopped', () => this.toggleScreenShare())
		return this.screenTrack
	}

	async toggleScreenShare(): Promise<void> {
		if (!this.room) return
		if (this.screenTrack) {
			this.screenTrack.stop()
			if (this?.room?.localParticipant) await this.room.localParticipant.unpublishTrack(this.screenTrack)
			this.screenTrack = undefined
			this.updateLocalParticipantDetails()
		} else {
			this.screenTrack = await this.createScreenTrack(720, 1280)
		}
	}

	isScreenshareActive(): boolean {
		return !!this.screenTrack
	}

	async toggleMuteAudio(): Promise<boolean> {
		if (!this.localParticipant) return false
		const newval = await this.toggleMuteMedia('audio')
		this.localParticipant.isMuted = newval
		this.dispatch(VideoClientEvents.LOCAL_PARTICIPANT_CHANGED)
		return newval
	}

	async toggleMuteCamera(): Promise<boolean> {
		if (!this.localParticipant) return false
		const newval = await this.toggleMuteMedia('video')
		this.localParticipant.isCameraMuted = newval
		this.dispatch(VideoClientEvents.LOCAL_PARTICIPANT_CHANGED)
		return newval
	}

	async toggleMuteMedia(kind: string): Promise<boolean> {
		const publications: IterableIterator<LocalAudioTrackPublication | LocalVideoTrackPublication> =
			kind === 'audio'
				? this.room.localParticipant.audioTracks.values()
				: this.room.localParticipant.videoTracks.values()

		const publicationArray = Array.from(publications)
		const publication = publicationArray[0]
		let muted = true
		// Check if we have a track of this kind yet
		if (!publication) {
			try {
				const track =
					kind === 'audio'
						? await this.createLocalAudioTrack(this.audioDeviceId)
						: await this.createLocalVideoTrack(this.videoDeviceId)
				await this.room.localParticipant.publishTrack(track)
				muted = false
			} catch (err) {
				console.log(`Could not create local ${kind} track: ${err}`)
				muted = true
			}
		} else {
			muted = !publication.isTrackEnabled
			for (let i = 0; i < publicationArray.length; i++) {
				const p = publicationArray[i]
				if (muted) {
					await p.track.restart()
					p.track.enable()
					muted = false
				} else {
					p.track.disable()
					p.track.stop()
					muted = true
				}
			}
		}
		return muted
	}

	async changeVideoInput(deviceId: string): Promise<void> {
		if (!this.room || this.videoDeviceId === deviceId || !deviceId) return

		this.detachTrack(this.localVideoTrack)
		this.localVideoTrack = null
		this.updateLocalParticipantDetails()

		try {
			this.localVideoTrack = await this.createLocalVideoTrack(deviceId)
			this.room.localParticipant.publishTrack(this.localVideoTrack)
			this.videoError = null
		} catch (err) {
			console.log('Could not create local video track:', err)
			console.log('Retrying after 2 seconds...')
		}

		// We try again after two seconds in case the previous device was assocaited with the new device
		// (such as a virtual device that makes use of the initial physical device) and it was still
		// locking the device.
		if (!this.localVideoTrack) {
			await wait(2000)
			try {
				this.localVideoTrack = await this.createLocalVideoTrack(deviceId)
				this.room.localParticipant.publishTrack(this.localVideoTrack)
				this.videoError = null
			} catch (err) {
				console.log('Could not create local video track:', err)
				this.videoError = `Camera unavailable: ${err.message}`
			}
		}

		if (!this.localVideoTrack) {
			this.localParticipant.isCameraMuted = true
		}

		this.updateLocalParticipantDetails()
	}

	async changeAudioInput(deviceId: string): Promise<void> {
		if (!this.room || this.audioDeviceId === deviceId || !deviceId) return

		this.detachTrack(this.localAudioTrack)
		this.localAudioTrack = null
		this.updateLocalParticipantDetails()

		try {
			this.localAudioTrack = await this.createLocalAudioTrack(deviceId)
			this.room.localParticipant.publishTrack(this.localAudioTrack)
		} catch (err) {
			console.log('Could not create local audio track:', err)
			console.log('Retrying after 2 seconds...')
		}

		// We try again after two seconds in case the previous device was assocaited with the new device
		// (such as a virtual device that makes use of the initial physical device) and it was still
		// locking the device.
		if (!this.localAudioTrack) {
			await wait(2000)
			try {
				this.localAudioTrack = await this.createLocalAudioTrack(deviceId)
				this.room.localParticipant.publishTrack(this.localAudioTrack)
			} catch (err) {
				console.log('Could not create local audio track:', err)
			}
		}

		this.updateLocalParticipantDetails()
	}

	async changeAudioOutput(deviceId: string): Promise<void> {
		// Not implemented.
		// Audio output can only be changed at the audio element level and we do not have visibility of
		// the audio elements that the local audio track has been attached to.
		// Instead, when the selected audio output device ID is selected, the setSinkId method of each
		// relevant audio element must be called to dictate where they output to.
	}

	async setPrimaryRemoteParticipant(participantId: string): Promise<void> {
		if (!this.room) return

		// Set the priority of all participants to 'low' except for the primary participant
		const twilioRemoteParticipants = Array.from(this.room.participants.values())
		twilioRemoteParticipants.forEach(rp => {
			const vidTracks = Array.from(rp.videoTracks.values())
			if (vidTracks.length === 0) return
			if (rp.sid === participantId) {
				console.log(`Setting priority of participant ${participantId} to 'high'`)
				vidTracks[vidTracks.length - 1].track.setPriority('high')
			} else {
				vidTracks.forEach(pub => pub.track.setPriority('low'))
			}
		})
	}

	async getPreflightStats(): Promise<Video.PreflightTestReport> {
		const token = await this.getToken()
		return new Promise((resolve, reject) => {
			const preflightTest = runPreflight(token)

			preflightTest.on('progress', (progress: string) => {
				console.log('preflight progress:', progress)
			})

			preflightTest.on('failed', (error: Error) => {
				console.error('preflight error:', error)
				reject(error)
			})

			preflightTest.on('completed', (report: PreflightTestReport) => {
				console.log(`Test completed in ${report.testTiming.duration} milliseconds.`)
				console.log(` It took ${report.networkTiming.connect?.duration} milliseconds to connect`)
				console.log(` It took ${report.networkTiming.media?.duration} milliseconds to receive media`)
				console.log('report', report)
				resolve(report)
			})
		})
	}

	// ===============================================================================================

	private onDisconnect(): void {
		this.log('TwilioClient:onDisconnect')
		// Reset the room only after all other `disconnected` listeners have been called.
		setTimeout(() => {
			this.room = null
		})
		// if (isMobile) window.removeEventListener("pagehide", this.disconnect);
	}

	private updateRemoteParticipantDetails(participant: Video.RemoteParticipant): ParticipantDetails {
		const id = participant.sid

		let details: ParticipantDetails = this.remoteParticipants.find(p => p.participantId === id)
		if (!details) {
			details = { participantId: id }
			const properties = parse(participant.identity)
			if (properties) {
				details.displayName = properties.name
			} else {
				details.displayName = participant.identity
			}
			details.properties = properties
			this.remoteParticipants.push(details)
		}

		// Set the appropriate propertes of the remote participant videoTrack
		const videoTrackPublications = Array.from(participant?.videoTracks?.values())
		const videoTrack = videoTrackPublications[videoTrackPublications.length - 1]?.track

		if (videoTrack) {
			// if (videoTrack.isSwitchedOff) {
			// 	details.status = 'inactive'
			// } else {
			// 	details.status = 'connected'
			// }
			details.videoTrack = details.videoTrack || ({} as ParticipantTrack)
			details.videoTrack.attach = videoTrack?.attach.bind(videoTrack)
			details.videoTrack.detach = videoTrack?.detach.bind(videoTrack)
			details.videoTrack.getType = () => videoTrack?.kind
			// If participant is broadcasting multiple tracks, assume that they are sharing their screen
			details.videoTrack.videoType = videoTrackPublications.length >= 2 ? 'desktop' : 'video'
			details.isCameraMuted = !videoTrack?.isEnabled

			details.stats = details.stats || ({} as ParticipantStats)
			details.stats.receivingResolution = videoTrack?.dimensions
		} else {
			details.videoTrack = null
		}

		const audioTrack = Array.from(participant?.audioTracks?.values())[0]?.track
		if (audioTrack) {
			details.audioTrack = details.audioTrack || ({} as ParticipantTrack)
			details.audioTrack.attach = audioTrack?.attach.bind(audioTrack)
			details.audioTrack.detach = audioTrack?.detach.bind(audioTrack)
			details.isMuted = !audioTrack?.isEnabled
		} else {
			details.audioTrack = null
		}

		// Format statistics
		const { networkQualityStats, networkQualityLevel } = participant
		if (networkQualityStats) {
			details.stats = details.stats || ({} as ParticipantStats)
			details.stats.uploadRate = Math.round((networkQualityStats?.video?.sendStats?.bandwidth?.actual || 0) / 1024)
			details.stats.downloadRate = Math.round((networkQualityStats?.video?.recvStats?.bandwidth?.actual || 0) / 1024)
		}
		if (networkQualityStats || networkQualityLevel) {
			details.stats.connectionQuality = networkQualityStats
				? networkQualityStats?.level * 20 // 0 - 5 => 0 - 100%
				: networkQualityLevel * 20
		}

		this.dispatch(VideoClientEvents.REMOTE_PARTICIPANTS_CHANGED)

		return details
	}

	private updateLocalParticipantDetails() {
		const twilioLocalParticipant = this.room?.localParticipant
		if (!twilioLocalParticipant) return

		this.localParticipant = this.localParticipant || ({} as ParticipantDetails)
		this.localParticipant.isLocal = true
		this.participantId = twilioLocalParticipant?.sid
		this.localParticipant.participantId = this.participantId
		this.localParticipant.displayName = this.displayName
		const videoTrack = this.screenTrack || this.localVideoTrack
		const audioTrack = this.localAudioTrack

		if (videoTrack && videoTrack.attach) {
			this.localParticipant.videoTrack = this.localParticipant.videoTrack || ({} as ParticipantTrack)
			this.localParticipant.videoTrack.attach = videoTrack?.attach.bind(videoTrack)
			this.localParticipant.videoTrack.detach = videoTrack?.detach.bind(videoTrack)
			this.localParticipant.videoTrack.getType = () => videoTrack?.kind
			this.localParticipant.videoTrack.videoType = this.screenTrack ? 'desktop' : 'video'
			// this.localParticipant.videoTrack.deviceId = videoTrack?.
			this.localParticipant.isCameraMuted = !videoTrack?.isEnabled
			this.localParticipant.stats = this.localParticipant.stats || ({} as ParticipantStats)
			this.localParticipant.stats.sendingResolution = videoTrack?.dimensions
		} else {
			this.localParticipant.videoTrack = null
			this.localParticipant.isCameraMuted = true
		}

		if (audioTrack && audioTrack.attach) {
			this.localParticipant.audioTrack = this.localParticipant.audioTrack || ({} as ParticipantTrack)
			this.localParticipant.audioTrack.attach = audioTrack?.attach.bind(audioTrack)
			this.localParticipant.audioTrack.detach = audioTrack?.detach.bind(audioTrack)
			this.localParticipant.isMuted = !audioTrack?.isEnabled
		} else {
			this.localParticipant.audioTrack = null
			this.localParticipant.isMuted = true
		}

		const { networkQualityStats, networkQualityLevel } = twilioLocalParticipant

		this.localParticipant.stats = this.localParticipant.stats || ({} as ParticipantStats)
		if (networkQualityStats) {
			this.localParticipant.stats.uploadRate = Math.round(
				networkQualityStats?.video?.sendStats?.bandwidth?.actual / 1024
			)
			this.localParticipant.stats.downloadRate = Math.round(
				networkQualityStats?.video?.recvStats?.bandwidth?.actual / 1024
			)
		}
		if (networkQualityStats || networkQualityLevel) {
			this.localParticipant.stats.connectionQuality = networkQualityStats
				? networkQualityStats?.level * 20 // 0 - 5 => 0 - 100%
				: networkQualityLevel * 20
		}

		this.dispatch(VideoClientEvents.LOCAL_PARTICIPANT_CHANGED)
	}

	private onRemoteParticipantConnected(participant: Video.RemoteParticipant) {
		this.log('TwilioClient:onRemoteParticipantConnected', participant)

		participant.on('networkQualityLevelChanged', () => {
			this.updateRemoteParticipantDetails(this.room.participants.get(participant.sid))
		})

		// this.remoteParticipants = [...this.remoteParticipants, participant]
		this.updateRemoteParticipantDetails(participant)

		participant.on('trackEnabled', () => {
			this.updateRemoteParticipantDetails(participant)
		})
		participant.on('trackDisabled', () => {
			this.updateRemoteParticipantDetails(participant)
		})

		participant.on('trackSwitchedOn', () => {
			this.updateRemoteParticipantDetails(participant)
		})
		participant.on('trackSwitchedOff', () => {
			this.updateRemoteParticipantDetails(participant)
		})

		// participant.on('disconnected', () => {
		// 	this.log('TwilioClient:onRemoteParticipant:disconnected')
		// })
		// participant.on('trackSubscriptionFailed', () => { })

		participant.on('trackPublished', publication => {
			this.onRemoteParticipantTrackPublished(publication, participant)
		})

		participant.on('trackUnpublished', () => {
			this.updateRemoteParticipantDetails(participant)
		})

		// Handle the TrackPublications already published by the Participant.
		participant.tracks.forEach(publication => {
			this.onRemoteParticipantTrackPublished(publication, participant)
		})
	}

	private onRemoteParticipantDisconnected(participant: RemoteParticipant) {
		this.remoteParticipants = this.remoteParticipants.filter(p => p.participantId !== participant.sid)
		this.dispatch(VideoClientEvents.REMOTE_PARTICIPANTS_CHANGED)
	}

	private onRemoteParticipantTrackPublished(publication: Video.RemoteTrackPublication, participant) {
		// publication.on('trackEnabled', data => this.log('TwilioClient:publication:trackEnabled', data))
		// publication.on('trackDisabled', data => this.log('TwilioClient:publication:trackDisabled', data))
		// publication.on('trackSwitchedOn', data => this.log('TwilioClient:publication:trackSwitchedOn', data))
		// publication.on('trackSwitchedOff', data => this.log('TwilioClient:publication:trackSwitchedOff', data))

		publication.on('subscribed', track => {
			this.updateRemoteParticipantDetails(participant)
			if (track.kind === 'data') {
				track.on('message', data => this.onDataTrackMessageReceived(data))
			}
			// track.on('switchedOn', () => console.log('TwilioClient:track:switchedOn'))
			// track.on('switchedOff', () => console.log('TwilioClient:track:switchedOff'))
		})

		publication.on('unsubscribed', () => {
			this.updateRemoteParticipantDetails(participant)
		})

		this.updateRemoteParticipantDetails(participant)
	}

	private onDataTrackMessageReceived(message: string | ArrayBuffer) {
		try {
			const data = JSON.parse(message as string)

			if (data.to && data.to !== this.participantId) {
				return
			}
			if (data.to) {
				data.to = this.displayName
			}
			this.chat.push(data)
			this.dispatch(VideoClientEvents.MESSAGE_RECEIVED, data)
		} catch (err) {
			console.log(err)
		}
	}

	async sendChatMessage(message: string, participantId: string): Promise<void> {
		const data: VideoChatMessage = {
			message,
			to: participantId,
			timestamp: Date.now(),
			privateMsg: Boolean(participantId),
			participantId: this.localParticipant.participantId,
		}
		this.dataTrack.send(JSON.stringify(data))

		// Get recipients actual display name
		if (participantId) {
			const participant = this.remoteParticipants.find(p => p.participantId === participantId)
			if (participant) {
				data.to = participant.displayName
			}
		}
		this.chat.push(data)
		this.dispatch(VideoClientEvents.MESSAGE_RECEIVED, data)
	}

	showStatistics(): void {
		VideoRoomMonitor.toggleMonitor()
	}
}

export default TwilioClient
