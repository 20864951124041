export default {
	/* Croatian */
	OK: `U redu`,
	CANCEL: `Poništi`,
	TUTOR: `Fascilator`,
	CURRENT_SESSION: `Trenutna sesija:`,
	TEAM: `Ekipa`,
	SIGNOUT: `Odjava`,
	INITIAL_UPDATE: `Informacije o sesiji`,
	ADVICE: `Odluka`,
	RATIONALE: `Obrazloženje`,
	MEDIA_FEED: `Medijski sadržaj`,
	MESSAGES: `Poruke`,
	PREVSESSIONS: `Prethodne sesije`,
	RETURN_TO_CURRENT: `Povratak na trenutnu sesiju`,
	SESSION_IS_PAUSED: `Sesija je trenutno PAUZIRANA`,
	ATTRIB_CURR_AUTHOR: `Donositelj odluka`,
	SAVE: `Snimi`,
	CHANGE: `Promijeni`,
	NO_AUTHOR: `Nije postavljeno`,
	NO_INITIAL_INFO: `Molimo pričekajte da sljedeća sesija počne`,
	DECISION_MAKER_SELECT: `Molimo odaberite donositelja odluke...`,
	SESSION_COMPLETE: `Prethodna sesija je završena`,
	OPENED_AT: `Otvoreno u`,
	SEND_MESSAGE_PLACEHOLDER: `Napišite poruku`,
	SEND: `Pošalji`,
	TO_ALL_GROUPS: `svim ekipama`,
	SHOW_TRANSLATION: `Prikaži prijevod`,
	SHOW_ORIGINAL: `Prikaži izvornu datoteku`,
	CREDENTIALS_EXPIRED: `Vaši vjerodajnice su istekli. Molimo prijavite se ponovo.`,
	INCOMING_MESSAGE: `Dolazna poruka`,
	INCOMING_MESSAGE_TEXT: `Primljena je nova poruka`,
	INCOMING_MEDIA: `Dolazna medijska objava`,
	INCOMING_MEDIA_TEXT: `Primljena je nova medijska objava`,
	INCOMING_UPDATE: `Dolazno ažuriranje`,
	INCOMING_UPDATE_TEXT: `Primljeno je ažuriranje`,
	CONFIRM_LOGOUT: `Jeste li sigurni da se želite odjaviti?`,
	MUTED_MESSAGE: `Trenutno ste prigušeni`,
	NEWSTYPE_UPDATE: `Primljeno ažuriranje`,
	WARNING_TIME_MSG: `Ostalo je još 5 minuta`,
	VIEW_INITIAL_UPDATE: `Pogledajte informacije o sesiji`,
	INITIAL_UPDATE_INDEX: `%%% informacija o sesiji`,
	VIEWING_PREVIOUS: `Gledanje sesije $$ od %%%`,
	TO: `Prema`,
	EVERYONE: `Svima`,
	MESSAGE_ENTRY_PLACEHOLDER: `Poruka`,
	DEFAULT_VIDEO_WARNING_MESSSAGE: `Video poziv počinje uskoro`,
	DEFAULT_VIDEO_WARNING_TITLE: `Upozorenje`,
	SESSION_INFO_RECEIVED: `Primljene informacije o sesiji`,
	DECISION_LOG: `Dnevnik odluka`,
	ADD_DECISION_LOG: `Dodati u dnevnik odluka`,
	DFT_WELCOME_MESSAGE: `Dobrodošli na View360 Global platformu za iskustveno učenje. 

Molimo pričekajte da sesija počne.`,
	CONFIRM_DELETE_DECISIONLOG: `Jeste li sigurni da želite ukloniti ovu odluku?`,
	SESSION_HAS_STARTED: `Sesija je počela`,
	CLICK_TO_OPEN: `Kliknite da biste otvorili`,
	CLICK_TO_EXPAND: `Kliknite da biste proširili`,
	EXPAND_TEXT: `Kliknite na gumb ispod da biste vidjeli video u punoj veličini`,
	COLLAPSE: `Sažmi`,
	EXPAND: `Proširi`,
	RECEIVED_AT: `Primljeno u`,
	AUDIO_PLAYER: `Audio reproduktor`,
	VIDEO_PLAYER: `Video reproduktor`,
	DFT_MESSAGES_TEXT: `Ova aplikacija će vam omogućiti da šaljete i primate poruke prema/od facilitatora tjekom sesije`,
	DFT_NOTIFICATIONS_TEXT: `Mediji će se pojaviti ovdje. Pratite ovaj uređaj za nove medije i ažuriranja. 
Početnim informacijama o sesiji može se pristupiti pomoću gumba na vrhu ekrana. 
Komunicirajte s voditeljem koristeći aplikaciju Poruke u donjem lijevom kutu ovog uređaja.`,
	MOBILE_NUMBER_TITLE: `Broj telefona`,
	MOBILE_NUMBER_PLACEHOLDER: `Broj mobitela`,
	MOBILE_NUMBER_DESC: `Molimo navedite broj svog mobilnog telefona kako biste mogli primati poruke tjekom sesije. Vaš broj telefona će se koristiti samo tjekom osposobljavanja i automatski će se izbrisati nakon 24 sata sukladno našim GDPRom i pravilima Zakona o zaštiti podataka.`,
	MOBILE_NUMBER_SKIP: `Preskoči ovo`,
	INCOMING_CALL: `Dolazni poziv`,
	CALL_IN_PROGRESS: `Poziv u tjeku`,
	PHONE_MESSAGE_SENT: `Telefonska poruka poslana`,
	CALL_FAILED: `Poziv nije uspio`,
	NO_ANSWER: `Bez odgovora`,
	CLICK_TO_RESEND: `Kliknite na gumb POŠALJI PONOVO da bi se poruka ponovo poslala na vaš uređaj.`,
	VOICE_MESSAGE_SENT: `Glasovna poruka je poslana %%%.`,
	WENT_TO_VOICEMAIL: `Glasovna poruka je poslana na %%%, ali je otišla na vašu govornu poštu.`,
	CALL_FAILED_TEXT: `Glasovna poruka je poslana %%% ali nije prošla.`,
	CHECK_PHONE: `Molimo provjerite svoj telefon.`,
	AT: `u`,
	CALLING: `Pozivanje`,
	RESEND: `Ponovo pošalji`,
	PLAY_HERE: `Pokreni ovdje`,
	SESSION: `Sesija`,
	CAMERA: `Kamera`,
	MICROPHONE: `Mikrofon`,
	AUDIO_OUTPUT_DEVICE: `Audio izlazni uređaj`,
	SELECT_CAMERA: `Odaberite kameru`,
	SELECT_MICROPHONE: `Odaberite mikrofon`,
	SELECT_AUDIO_OUTPUT_DEVICE: `Odaberite audio izlazni uređaj`,
	SAME_AS_SYSTEM: `Isti kao sustav`,

	CLOSE: `Zatvoriti`,
	CONTINUE: `Nastaviti`,
	DECISION_LOG_HELP_TEXT: `Ovdje će se pojaviti odluke i obrazloženje vašeg tima. Moći ćete urediti vlastite odluke i obrazloženje klikom na njih.`,
	NO_DECISION_LOGS: `Nema zapisnika odluka`,
	NOTEPAD: `Bilježnica`,
	NOTETAKER: `Bilježnik`,
	ENTER_ADVICE: `Unesite odluku`,
	ENTER_RATIONALE: `Unesite obrazloženje`,
}
