/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEventHandler, FunctionComponent, useState } from 'react'

import config from '../../config'

import getTextDirection from '../util/getTextDirection'

type DecisionLogEntryRowProps = {
	advice?: string
	rationale?: string
	author: string
	time?: number | string
	enabled: boolean
	index: number
	translatedRationale?: string
	translatedAdvice?: string
	onChangeAdvice?: ChangeEventHandler<HTMLTextAreaElement>
	onChangeRationale?: ChangeEventHandler<HTMLTextAreaElement>
	onRemove?: () => void
}

const DecisionLogEntryRow: FunctionComponent<DecisionLogEntryRowProps> = props => {
	const { SHOW_ORIGINAL, SHOW_TRANSLATION, REMOVE, ADVICE, RATIONALE } = config.strings
	// prettier-ignore
	const { advice = '', rationale = '', author = '', time, index, enabled, translatedRationale, translatedAdvice } = props
	const { onChangeAdvice, onChangeRationale, onRemove } = props

	// timeFormatted = time of decision log in user's local timezone
	const timeFormatted = time ? new Date(time).toTimeString().substr(0, 8) : ''

	// =================================================================================================
	// Translation link
	const hasTranslation =
		(translatedAdvice && translatedAdvice !== advice) || (translatedRationale && translatedRationale !== rationale)
	const [showTranslationIfAvailable, setShowTranslation] = useState(true)
	const showTranslation = showTranslationIfAvailable && hasTranslation

	let linkClass = 'decision-log__translate'
	if (showTranslation) {
		linkClass += ' decision-log__translate--active'
	}
	const label = showTranslation ? SHOW_ORIGINAL : SHOW_TRANSLATION

	const translateLink = (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div className={linkClass} onClick={() => setShowTranslation(!showTranslation)}>
			{label}
		</div>
	)
	// =================================================================================================

	const _advice = showTranslation ? translatedAdvice || advice : advice
	const _rationale = showTranslation ? translatedRationale || rationale : rationale

	const metadata = `${author}${timeFormatted ? ` - ${timeFormatted}` : ''}`
	return (
		<>
			<div className="decision-log__advice">
				<div className="resizer">{`${_advice}\n`}</div>
				<textarea
					name="advice"
					value={_advice}
					onChange={onChangeAdvice}
					style={{ direction: getTextDirection(_advice) }}
					disabled={!enabled}
					aria-label={`${ADVICE} ${index + 1}`}
				/>
			</div>
			<div className="decision-log__rationale">
				<div className="resizer">{`${_rationale}\n`}</div>
				<textarea
					name="rationale"
					value={_rationale}
					onChange={onChangeRationale}
					style={{ direction: getTextDirection(_rationale) }}
					disabled={!enabled}
					aria-label={`${RATIONALE} ${index + 1}`}
				/>
			</div>
			<div className="decision-log__metadata">
				<div className="decision-log__auth-and-time">{metadata}</div>
				{hasTranslation && !enabled && <div className="decision-log__translate">{translateLink}</div>}
				{enabled && (
					<div
						className="decision-log__delete"
						role="button"
						tabIndex={0}
						onClick={onRemove}
						onKeyPress={onRemove}
						aria-label="Remove decision log"
					>
						{REMOVE}
					</div>
				)}
			</div>
		</>
	)
}

export default DecisionLogEntryRow
