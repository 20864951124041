/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
// import { useMount, useUnmount } from 'react-use'

import config from '../../../config'
import Actions from '../../actions'

import JitsiClient, { JitsiClientEvents } from '../../util/JitsiClient'

import facilitatorIdSelector from '../../selectors/facilitatorId'
import teamNameFromColour from '../../util/teamNameFromColour'
import VideoCallCameraButton from './VideoCallCameraButton'
import VideoCallParticipant from './VideoCallParticipant'
import VideoCallMuteButton from './VideoCallMuteButton'
import IconButton from '../../elements/IconButton'
import convertColour from '../../util/convertColour'
import { IVideoClient } from './VideoTypes'

type SideCallJitsiProps = {
	displayModalPopup: (popupId: string, parms?: SimpleObject | string) => void
	displayName: string
	domain: string
	callId: string
	facilitatorId: string
	selectedCamera?: string
	selectedMic?: string
	selectedAudioOut?: string
	selectAudioOut?: typeof Actions.videoconf.selectAudioOut
	selectCamera?: typeof Actions.videoconf.selectCamera
	selectMic?: typeof Actions.videoconf.selectMic
	groups?: GroupDetails[]
	joinedBreakout: string
}

function SideCallJitsi(props: SideCallJitsiProps): ReactElement<SideCallJitsiProps> {
	const { domain, callId, displayName, displayModalPopup, selectedCamera, selectedMic, selectedAudioOut } = props
	const { selectCamera, selectMic, selectAudioOut, facilitatorId } = props
	const { groups, joinedBreakout } = props

	const client = useRef<JitsiClient>(null)
	const [remoteParticipants, setRemoteParticipants] = useState<JitsiParticipantDetails[]>([])
	const [localParticipant, setLocalParticipant] = useState({} as JitsiParticipantDetails)
	const [localError, setLocalError] = useState('')

	const eventHandlerRefs = useRef<number[]>([])

	useEffect(() => {
		// Initialise Jitsi client
		client.current = new JitsiClient({ domain, conferenceId: callId })
		client.current.setDisplayName(displayName)

		window.JitsiClient = client.current

		// Helper function for adding event listeners to the jitsi client and keeping an array of the
		// references we get back, which allows us to easily detach those event handlers later.
		const addEventListener = (type: JitsiClientEvents, callback: (...args: Array<unknown>) => void) => {
			eventHandlerRefs.current.push(client.current.on(type, callback))
		}

		// Add event listeners
		addEventListener(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED, () => {
			const jitsiClient = client.current
			setLocalParticipant({ ...jitsiClient.localParticipant })

			// Save the video/audio devices that are being used into global state if we don't already have
			// user-selected devices.
			const outputDevice = jitsiClient.getAudioOutputDevice()
			const { videoTrack, audioTrack } = jitsiClient.localParticipant
			if (videoTrack && videoTrack.getType() === 'video' && !selectedCamera) selectCamera(videoTrack.deviceId)
			if (audioTrack && !selectedMic) selectMic(audioTrack.deviceId)
			if (outputDevice && !selectedAudioOut) selectAudioOut(outputDevice)

			if (client.current.videoUnavailable) {
				setLocalError('Video device unavailable')
			} else {
				setLocalError('')
			}
		})

		addEventListener(JitsiClientEvents.REMOTE_PARTICIPANTS_CHANGED, () => {
			setRemoteParticipants([...client.current.remoteParticipants])
		})

		client.current.connect({
			cameraDeviceId: selectedCamera || null,
			audioInputDeviceId: selectedMic || null,
			audioOutputDeviceId: selectedAudioOut || null,
		})

		return () => {
			// Detach/remove all event listeners from the active JitsiClient
			eventHandlerRefs.current.forEach(ref => client.current.off(ref))
			client.current.unload()
			window.JitsiClient = null
		}
	}, [callId])

	// Switch camera/audio devices used by JitsiClient if they have changed in global state (i.e. in the user's settings)
	useEffect(() => {
		client.current.changeVideoInput(selectedCamera).catch(err => console.error('Error switching video source', err))
	}, [selectedCamera])

	useEffect(() => {
		client.current.setAudioOutputDevice(selectedAudioOut)
	}, [selectedAudioOut])

	useEffect(() => {
		client.current.changeAudioInput(selectedMic)
	}, [selectedMic])

	const onClickParticipant = (participant: JitsiParticipantDetails) => {
		config.strings.POPUP_TITLE_VIDEOPARTICIPANT = participant.displayName
		displayModalPopup('modal-video-participant', participant.participantId || 'local')
	}

	const participants = remoteParticipants.map(participant => {
		// eslint-disable-next-line no-shadow
		const { participantId } = participant
		return (
			<VideoCallParticipant
				key={participantId}
				participant={participant}
				facilitatorId={facilitatorId}
				onClick={() => onClickParticipant(participant)}
			/>
		)
	})

	let message = null
	if (joinedBreakout) {
		const group = groups.find(g => g.id === joinedBreakout)
		if (group) {
			message = (
				<>
					<div className="dot" style={{ backgroundColor: convertColour(group.colour) }} />
					{teamNameFromColour(group.colour)}
				</>
			)
		}
	}

	const jitsiClient = (client.current as unknown) as IVideoClient

	return (
		<div className="side-call">
			{message && <div className="side-call__message">{message}</div>}
			<div className="side-call__participants">{participants}</div>
			{/* Local participant video */}
			<VideoCallParticipant
				participant={localParticipant}
				facilitatorId={facilitatorId}
				onClick={() => onClickParticipant(localParticipant)}
				error={localError}
				uploadToImageCache
			/>
			<div className="video-call-buttons">
				<VideoCallMuteButton client={jitsiClient} />
				<VideoCallCameraButton client={jitsiClient} />
				<IconButton iconName="cog" onClick={() => displayModalPopup('modal-configure-video')} />
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	facilitatorId: facilitatorIdSelector(state),
	selectedAudioOut: state.videoconf.selectedAudioOut,
	selectedCamera: state.videoconf.selectedCamera,
	selectedMic: state.videoconf.selectedMic,
	joinedBreakout: state.joinedBreakout,
	groups: state.groups || [],
})
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	selectAudioOut: Actions.videoconf.selectAudioOut,
	selectCamera: Actions.videoconf.selectCamera,
	selectMic: Actions.videoconf.selectMic,
}
export default connect(mapStateToProps, actions)(SideCallJitsi)
