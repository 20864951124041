import React, { FunctionComponent } from 'react'

const Preloader: FunctionComponent = () => (
	<div className="preloader preloader-dual-ring">
		<div className="preloader-dual-ring__ring1" />
		<div className="preloader-dual-ring__ring2" />
	</div>
)

export default Preloader
