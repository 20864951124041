export default {
	/* Team view */
	OK: '好',
	CANCEL: '取消',
	TUTOR: '服务商',
	CURRENT_SESSION: '当前会议',
	TEAM: '小组',
	SIGNOUT: '登出',
	INITIAL_UPDATE: '会议信息',
	ADVICE: '决定',
	RATIONALE: '基本原理',
	MEDIA_FEED: '媒体来源',
	MESSAGES: '信息',
	PREVSESSIONS: '之前会议',
	RETURN_TO_CURRENT: '返回当前会议',
	SESSION_IS_PAUSED: '会议当前已暂停',
	ATTRIB_CURR_AUTHOR: '决策者',
	SAVE: '保存',
	CHANGE: '更改',
	NO_AUTHOR: '未设置',
	NO_INITIAL_INFO: '请等待下一个会议开始',
	DECISION_MAKER_SELECT: '请选择决策者',
	SESSION_COMPLETE: '之前会议已结束',
	OPENED_AT: '打开于',
	SEND_MESSAGE_PLACEHOLDER: '留言',
	SEND: '发送',
	TO_ALL_GROUPS: '对所有组',
	SHOW_TRANSLATION: '显示译文',
	SHOW_ORIGINAL: '显示原始文件',
	CREDENTIALS_EXPIRED: '您的密码已过期。请重新登录。',
	INCOMING_MESSAGE: '传入信息',
	INCOMING_MESSAGE_TEXT: '已收到新信息',
	INCOMING_MEDIA: '传入媒体项',
	INCOMING_MEDIA_TEXT: '已收到新的媒体项',
	INCOMING_UPDATE: '传入更新',
	INCOMING_UPDATE_TEXT: '已收到更新',
	CONFIRM_LOGOUT: '您确定要注销吗？',
	MUTED_MESSAGE: '您的麦克风已关闭',
	NEWSTYPE_UPDATE: '更新',
	WARNING_TIME_MSG: '剩下5分钟',
	TO: '到',
	MESSAGE_ENTRY_PLACEHOLDER: '输入信息',
	EVERYONE: '所有人',
	POPUP_TITLE_CONFIGUREVIDEO: '配置音频/视频',
	CAMERA: '相机',
	MICROPHONE: '麦克风',
	AUDIO_OUTPUT_DEVICE: '音频输出设备',
	SAME_AS_SYSTEM: '与系统相同',
	SELECT_AUDIO_OUTPUT_DEVICE: '选择音频输出设备',
	VIEW_INITIAL_UPDATE: '查看会议信息',
	DECISION_LOG: '决策日志',
	NOTEPAD: '记事本',
	NOTETAKER: '记录员',
	CLICK_TO_OPEN: '点击打开',
	CLICK_TO_EXPAND: '点击展开',
	ENTER_ADVICE: '输入决定',
	ENTER_RATIONALE: '输入基本原理',
	CONTINUE: '继续',
	CONFIRM_DELETE_DECISIONLOG: '您确定要删除此决定吗？',
	CONFIRM_DELETE_NOTE: '您确定要删除此笔记吗？',
	CONFIRM_DELETE_NOTETAKER: '您确定要删除此记录员吗？',
	CONFIRM_DELETE_SESSION: '您确定要删除此会议吗？',
	CONFIRM_DELETE_MESSAGE: '您确定要删除此信息吗？',
	CONFIRM_DELETE_MEDIA: '您确定要删除此媒体项吗？',
	ADD_DECISION_LOG: '添加决策日志',
	DECISION_LOG_HELP_TEXT: '您团队的回应和理由将显示在此处 您将能够通过单击来编辑您自己的回复和理由',
	DFT_MESSAGES_TEXT: '该应用程序将允许您在会议期间向协调员发送消息或从协调员接收消息',
	DFT_NOTIFICATIONS_TEXT:
		'媒体将出现在这里。请密切关注此设备的新媒体和更新。可以通过屏幕顶部的按钮访问初始会话信息。使用此设备左下角的消息应用程序与协调员进行交流。',
	INITIAL_UPDATE_INDEX: '会议信息',
}
