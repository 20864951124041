const MAX_TRANSLATIONS = 100

export const STARTED_GROUP_LOGIN: Reducer = (state, action) => {
	const { ipAddress } = action.payload
	return {
		...state,
		group: { ...state.group, loggingIn: true, ipAddress },
		groupIpAddress: ipAddress,
	}
}

export const GROUP_GENERATED_ID: Reducer = (state, action) => {
	const id = action.payload
	return { ...state, group: { ...state.group, id } }
}

export const LOGGED_IN_FAIL_GROUP: Reducer = (state, action) => ({
	...state,
	errors: [action.payload],
	group: { ...state.group, loginError: action.payload, loggingIn: false },
})

export const LOGGED_IN_SUCCESS_GROUP: Reducer = (state, action) => ({
	...state,
	navigatingTo: 'group-view',
	group: { ...state.group, loggedIn: true, loggingIn: false, ...action.payload },
})

const GROUP_SIGN_OUT: Reducer = state => ({
	...state,
	group: { ...state.group, loggedIn: false },
	groupData: null,
	currentSession: null,
	mediaSeen: [],
	navigatingToModal: null,
})

export const GROUP_KICKED: Reducer = (state, action) => GROUP_SIGN_OUT(state, action)

export const GROUPS_CHANGED_LANGUAGE: Reducer = (state, action) => {
	const language = action.payload
	return { ...state, group: { ...state.group, language } }
}

export const GROUPS_GOT_DECISION_LOG: Reducer = (state, action) => {
	const { currAuthor, currAuthorId, advice, rationale, authors, times, participants, languages } = action.payload
	const { participantId } = state.group
	let group = { ...state.group, currAuthor, currAuthorId }

	// Make sure that if the current participant is already writing, then we don't overwrite what they are doing
	if (!participantId || currAuthorId !== participantId || !group.advice.length) {
		group = { ...group, advice, rationale, authors, times, participants, languages }
	}

	return { ...state, group }
}

export const GROUP_STORE_DECISIONLOG: Reducer = (state, action) => ({
	...state,
	group: {
		...state.group,
		advice: action.payload.advice,
		rationale: action.payload.rationale,
		authors: action.payload.authors,
		times: action.payload.times,
		participants: action.payload.participants,
		languages: action.payload.languages,
	},
})

export const PARTICIPANTS_GOT_LIST: Reducer = (state, action) => {
	const participants = action.payload || []

	const newState = { ...state, participants }

	// Check if the current participant's colour has changed
	const participantId = state.group && state.group.participantId
	const participant = participantId && participants.find(p => p.id === participantId)
	if (participant && participant.colour !== state.group.colour) {
		newState.group = { ...newState.group, colour: participant.colour, id: participant.colour }
	}

	return newState
}
/**
 * Result of group fetching the currentSession from server
 */
export const GROUPS_FETCH_DATA_SUCCESS: Reducer = (state, action) => {
	const newGroupData = action.payload || {}
	const newCurrSession: CurrentSession = newGroupData.currentSession || ({} as CurrentSession)
	const prvGroupData: GroupData = state.groupData || ({} as GroupData)
	const prvCurrSession: CurrentSession | PreviousSession =
		prvGroupData.currentSession || ({} as CurrentSession | PreviousSession)

	// Prevent this from overwriting current author
	// newCurrSession.currAuthor = newCurrSession.currAuthor || ''

	// Only add messages to state that we don't already have, as we may have translated them
	const currAllGroupMessages = prvCurrSession.allGroupMessages || []
	const newAllGroupMessages = newCurrSession.allGroupMessages || []

	newCurrSession.allGroupMessages = [
		...currAllGroupMessages,
		...newAllGroupMessages.filter(m => !currAllGroupMessages.find(m2 => m2.id === m.id)),
	]

	const combinedOldAndNew = { ...prvGroupData, ...newGroupData }
	if (JSON.stringify(combinedOldAndNew) === JSON.stringify(prvGroupData)) {
		return state
	}

	// Check if there are any new messages
	let messagesUnread = Boolean(state.messagesUnread)
	if (currAllGroupMessages.length < newAllGroupMessages.length) {
		messagesUnread = true
	}

	const newState = { ...state, groupData: combinedOldAndNew, messagesUnread }

	// Check if session has changed
	if (newCurrSession.startTime && newCurrSession.startTime !== newState.group.decisionLogSessionId) {
		newState.group.decisionLogSessionId = newCurrSession.startTime
		newState.group.advice = ['']
		newState.group.rationale = ['']
		newState.mediaSeen = []
	}

	return newState
}

export const GROUP_LOG_BACK_IN: Reducer = (state, action) => {
	const groups = state.groups.map(g => ({ ...g }))
	const groupId = action.payload
	const group = groups.find(g => g.id === groupId)
	if (group) {
		group.lastLogin = new Date().getTime()
		group.lastAction = group.lastLogin
	}
	return { ...state, groups }
}

export const GROUP_LOGIN_REPLACE: Reducer = (state, action) => {
	const groups = state.groups.map(g => ({ ...g }))
	const groupIndex = groups.findIndex(g => g.colour === action.payload.colour)
	if (groupIndex >= 0) {
		groups[groupIndex] = {
			...action.payload,
			lastLogin: new Date().getTime(),
			lastAction: new Date().getTime(),
		}
	}
	return { ...state, groups }
}

export const GROUPS_FETCHED_DECISION_LOG: Reducer = (state, action) => ({ ...state, decisionLog: action.payload })

export const GROUP_GOT_LOG_TRANSLATION: Reducer = (state, action) => {
	const { key, text } = action.payload
	let { decisionLogTranslations = [] } = state
	if (decisionLogTranslations.length > MAX_TRANSLATIONS) {
		decisionLogTranslations.sort((a, b) => (a.time < b.time ? 1 : -1))
		decisionLogTranslations = decisionLogTranslations.slice(0, MAX_TRANSLATIONS)
	}
	const time = Date.now()
	return { ...state, decisionLogTranslations: [...decisionLogTranslations, { key, text, time }] }
}

export const GROUPS_TRANSLATED_DECISION_LOG: Reducer = (state, action) => {
	const { groupId, language, advice, rationale, authors } = action.payload
	return { ...state, translatedDecisionLog: { groupId, language, advice, rationale, authors } }
}

export const GROUP_OPENED_ITEM: Reducer = (state, action) => {
	const openings = [...(state.openings || [])]
	const { rowId, sessionId, groupId, lastUpdate, participantId, markAsOpened = true } = action.payload
	if (markAsOpened && !openings.find(o => o.rowId === rowId && o.sessionId === sessionId && o.groupId === groupId)) {
		openings.push({ key: rowId, sessionId, groupId, lastUpdate, participantId })
	}
	return { ...state, openings, selectedScheduleRowId: rowId, mediaExpanded: false }
}

/**
 * Result of loadGroupsList action. Populates the current list of groups in both
 * state.groups and state.currentSession.groups, for some reason.
 * State.groups was originally populated when we received a new login (electron back-end).
 * Now I think this is the only place.
 */
export const GROUPS_LIST_LOADED: Reducer = (state, action) => {
	const groups = action.payload
	const currentSession = { ...(state.currentSession || {}) } as CurrentSession
	currentSession.groups = groups
	return { ...state, currentSession, groups }
}

export const PARTICIPANTS_UPDATED_COLOUR: Reducer = (state, action) => {
	const { participantId, colour } = action.payload
	const participants = state.participants.map(p => ({ ...p }))
	const participant = participants.find(p => p.id === participantId) || ({} as Participant)
	participant.colour = colour
	return { ...state, participants }
}

export const SET_EDITING_DECISION_LOG: Reducer = (state, action) => {
	return {
		...state,
		editingDecisionLog: action.payload || false,
	}
}

//==================================================================================================

const reducers = {
	GROUP_KICKED,
	GROUP_SIGN_OUT,
	GROUP_LOG_BACK_IN,
	GROUP_OPENED_ITEM,
	GROUP_GENERATED_ID,
	GROUPS_LIST_LOADED,
	STARTED_GROUP_LOGIN,
	GROUP_LOGIN_REPLACE,
	LOGGED_IN_FAIL_GROUP,
	PARTICIPANTS_GOT_LIST,
	LOGGED_IN_SUCCESS_GROUP,
	GROUPS_CHANGED_LANGUAGE,
	GROUPS_GOT_DECISION_LOG,
	GROUP_STORE_DECISIONLOG,
	SET_EDITING_DECISION_LOG,
	GROUPS_FETCH_DATA_SUCCESS,
	GROUP_GOT_LOG_TRANSLATION,
	GROUPS_FETCHED_DECISION_LOG,
	PARTICIPANTS_UPDATED_COLOUR,
	GROUPS_TRANSLATED_DECISION_LOG,
}

// =================================================================================================

const _removeOldGroups = (state: StateTree): StateTree => {
	const SIX_HOURS = 8 * 60 * 60 * 1000
	const groups = state.groups || []
	const now = new Date().getTime()
	return { ...state, groups: groups.filter(g => g.lastAction > now - SIX_HOURS) }
}

// =================================================================================================

export const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	// Remove groups from list of connected groups if they have been inactive for a while
	newState = _removeOldGroups(newState)
	if (!newState.group) newState.group = { id: null, facilitatorId: null, colour: null }
	return newState
}

export default switchcase
