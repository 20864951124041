import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import Icon from '../elements/Icon'

import config from '../../config'

const { OPENED_AT } = config.strings

function formatTime(time: string | number) {
	return new Date(time).toTimeString().substr(0, 5)
}

// =================================================================================================

type MediaOpeningsRowProps = {
	opening: Opening
	participant: Participant
}
const MediaOpeningsRow: FunctionComponent<MediaOpeningsRowProps> = props => {
	const { opening, participant = {} as Participant } = props

	const isOpened = opening?.lastUpdate
	const openedAt = isOpened ? `${OPENED_AT} ${formatTime(opening.lastUpdate)}` : 'Not opened'

	const isOffline = !participant?.online

	let className = 'media-openings-modal__row'
	if (isOpened || isOffline) {
		className += ' media-openings-modal__row--opened'
	}
	return (
		<div className={className}>
			<span className="media-openings-modal__name">
				{participant.name || ''}
				{isOffline ? ' - Offline' : ''}
			</span>
			<span className="media-openings-modal__time">{openedAt}</span>
			{isOpened ? <Icon name="check" /> : <Icon name="close" />}
		</div>
	)
}

// =================================================================================================

type MediaOpeningsModalProps = {
	rowId?: string
	openings: Opening[]
	participants: Participant[]
	groupId?: string
}

const MediaOpeningsModal: FunctionComponent<MediaOpeningsModalProps> = props => {
	const { rowId, openings, participants, groupId } = props

	return (
		<div className="media-openings-modal">
			{participants
				.filter(p => p.colour === groupId)
				.map(p => {
					const opening = openings.find(o => o.key === rowId && o.participantId === p.id)
					return <MediaOpeningsRow key={p.id} opening={opening} participant={p} />
				})}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	participants: state.participants || [],
	openings: state.openings || [],
})
const actions = {}
export default connect(mapStateToProps, actions)(MediaOpeningsModal)
