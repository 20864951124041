import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { useMount } from 'react-use'

import ContentLoadingWrapper from '../components/ContentLoadingWrapper'
import BubbleHeading from '../elements/BubbleHeading'
import InfoSection from '../elements/InfoSection'
import Button from '../elements/Button'
import Case from '../components/Case'

import config from '../../config'
import Actions from '../actions'

import { sessionIsActive } from '../util/SessionHelpers'

type TutorCasesProps = {
	displayModalPopup: (modalId: string, parms?: Record<string, unknown>) => Promise<void>
	deleteCase: (caseId: string) => Promise<void>
	loadCase: (caseId: string) => Promise<void>
	loadCaseList: () => Promise<void>
	currentSession: CurrentSession
	openCase: OpenCaseDetails
	loadingCases: boolean
	caseList: Case[]
}

function TutorCases(props: TutorCasesProps): ReactElement<TutorCasesProps> {
	const { caseList, loadingCases, openCase, currentSession } = props
	const { displayModalPopup, deleteCase, loadCase, loadCaseList } = props
	const { CASES, ADD_CASE, CASE_CANNOT_BE_CHANGED } = config.strings

	useMount(() => loadCaseList())

	const isCurrentSessionActive = sessionIsActive(currentSession)

	const _caseList = [...caseList].sort((a, b) => (a.title < b.title ? -1 : 1))

	const cases = _caseList.map(c => {
		const isOpen = openCase.id === c.id
		return (
			<Case
				data={c}
				key={c.id}
				onClickDelete={() => deleteCase(c.id)}
				onClickOpen={() => loadCase(c.id)}
				allowSelect={!isOpen && !isCurrentSessionActive}
				isSelected={isOpen}
				allowDelete={!isOpen}
				onClickEdit={() => displayModalPopup('modal-edit-case', c)}
			/>
		)
	})

	const infoMsg = isCurrentSessionActive ? CASE_CANNOT_BE_CHANGED : ''

	return (
		<div className="tutor-cases">
			<div className="tutor-cases__top">
				<BubbleHeading>{CASES}</BubbleHeading>
				<Button text={ADD_CASE} onClick={() => displayModalPopup('modal-newcase')} />
			</div>
			<div className="tutor-cases__content">
				{infoMsg && <InfoSection warning>{infoMsg}</InfoSection>}
				<ContentLoadingWrapper loading={loadingCases}>{cases}</ContentLoadingWrapper>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<TutorCasesProps> => ({
	currentSession: state.currentSession || ({} as CurrentSession),
	loadingCases: state.loadingCases || false,
	openCase: state.openCase || ({} as OpenCaseDetails),
	caseList: state.caseList || [],
})
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	loadCaseList: Actions.cases.loadCaseList,
	deleteCase: Actions.cases.deleteCase,
	loadCase: Actions.cases.loadCase,
}
export default connect(mapStateToProps, actions)(TutorCases)
