import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useRafLoop } from 'react-use'

type AudioVisualiserProps = {
	audioElement?: HTMLAudioElement
}

function drawSpectrumAnalyser(canvas: HTMLCanvasElement, analyser: AnalyserNode) {
	if (!analyser || !canvas) return
	const c2d: CanvasRenderingContext2D = canvas.getContext('2d')

	// power of "time domain data"(=sampled gain) as 0-255
	// (window function applyed: reducing weight from center to past/future)
	const tds = new Uint8Array(analyser.fftSize)
	// power of frequencies (0-255 rate) as dB(= 20*log10(|gain_of_freq|))
	// (smoothing filter applied)
	const freqs = new Uint8Array(analyser.frequencyBinCount)
	analyser.getByteFrequencyData(freqs)
	analyser.getByteTimeDomainData(tds)

	c2d.clearRect(0, 0, canvas.width, canvas.height)
	c2d.fillStyle = '#282c4400'
	c2d.fillRect(0, 0, canvas.width, canvas.height)
	c2d.save()

	c2d.scale(canvas.width / analyser.fftSize, canvas.height / 256)

	// This will display bars which are 128 pixels high when there is no sound,
	// and then vary from 0 - 255 pixels high when there is sound.

	//           ....              ....                 .....
	//          ......           ........             .........
	// .................        ..........           ...........        ...............
	// ..................     .............      ......................................
	// ................................................................................

	// for (let i = 0; i < analyser.fftSize; i++) {
	// 	const v = tds[i]
	// 	const y = 256 - v
	// 	c2d.fillStyle = '#00ecff'
	// 	c2d.fillRect(i, y, 1, v)
	// }

	// This will display each "bar" as a line which is vertically centered,
	// with a height of 0 - 255

	//      ....      ....      ....         ....
	//     ......    ......    ......       ......
	// ..............................................................................
	//     ......    ......    ......       ......
	//      ....      ....      ....         ....

	// if (prevValues) {
	// 	for (let i = 0; i < analyser.fftSize; i++) {
	// 		// v = 128 when muted, and then anything between 0 and 255, I think
	// 		const v = prevValues[i] + (tds[i] - prevValues[i]) * 0.1
	// 		prevValues[i] = v
	// 		const y = 256 - v
	// 		c2d.fillStyle = '#cf167f'
	// 		c2d.fillRect(i, y, 0.6, (v - 128) * 2 || 1)
	// 	}
	// }

	for (let i = 0; i < analyser.fftSize; i++) {
		// v = 128 when muted, and then anything between 0 and 255, I think
		const v = tds[i]
		const y = 256 - v
		c2d.fillStyle = '#00ecff'
		c2d.fillRect(i, y, 0.5, (v - 128) * 2 || 1)
	}

	// if (!prevValues) prevValues = tds

	c2d.restore()
}

const loadAudioAnalyser = (audioElement: HTMLAudioElement) => {
	try {
		const audioContext = new AudioContext()

		// Create an "AudioContext" using the audio element as a source
		const audioSrc = audioContext.createMediaElementSource(audioElement)
		// audioContext.create

		// Create audio analyser
		const analyser = audioContext.createAnalyser()
		analyser.smoothingTimeConstant = 1
		analyser.fftSize = 64

		// Feed the audio into the analyser
		audioSrc.connect(analyser)
		// Then feed the audio out to the original audio destination (i.e. speaker)
		analyser.connect(audioContext.destination)

		return { analyser, audioContext }
	} catch (err) {
		console.log('err', err)
	}
	return { analyser: null, audioContext: null }
}

const AudioVisualiser: FunctionComponent<AudioVisualiserProps> = props => {
	const { audioElement } = props
	const canvas = useRef<HTMLCanvasElement>(null)
	const audioContext = useRef<AudioContext>(null)
	const analyser = useRef(null)

	useRafLoop(() => {
		drawSpectrumAnalyser(canvas.current, analyser.current)
	})

	useEffect(() => {
		if (audioContext.current) audioContext.current.close()

		const result = loadAudioAnalyser(audioElement)
		analyser.current = result.analyser
		audioContext.current = result.audioContext
	}, [audioElement])

	return (
		<div className="audio-visualiser">
			<canvas ref={canvas} width="314" height="220" />
		</div>
	)
}

export default AudioVisualiser
