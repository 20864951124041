import sessionSelector from './session'

function groupsSelector(state: StateTree): Array<GroupDetails> {
	const { viewingPreviousSession } = state
	const session = sessionSelector(state)
	const groups = (viewingPreviousSession ? (session as PreviousSession).groups : state.groups) || []
	return groups
}

export default groupsSelector
