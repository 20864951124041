const MAX_ITEMS = 100
type MediaCacheItem = { id: string; data: Blob }

class MediaLibrary {
	media: MediaCacheItem[]
	constructor() {
		this.media = []
	}

	push(mediaItem: MediaCacheItem): void {
		this.media.push(mediaItem)
		if (this.media.length > MAX_ITEMS) {
			this.media.shift()
		}
	}

	get(id: string): MediaCacheItem {
		return this.media.find(m => m.id === id)
	}
}
const singleton = new MediaLibrary()
export default singleton
