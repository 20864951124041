import defaultMediaIcon from './defaultMediaIcon'
import mediaIconList from '../../config/mediaIconList'
import config from '../../config'

function getIconForMediaItem(mediaitem: MediaItem): { icon: string; colour: string } {
	const defaultIcon = mediaIconList.find(mi => mi.value === config.UPDATE_ITEM_ICON_TYPE)
	let colour = defaultIcon.color
	let { icon } = defaultIcon

	if (mediaitem) {
		const mediaIconName = mediaitem.icon || defaultMediaIcon(mediaitem.extension)
		const iconObj = mediaIconList.find(mi => mi.value === mediaIconName) || { icon: null, color: null }
		icon = iconObj.icon || 'file-alt'
		colour = iconObj.color || '#74CB66'
	}
	return { icon, colour }
}

export default getIconForMediaItem
