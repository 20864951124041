import * as Sentry from '@sentry/browser'
import decode from 'jwt-decode'

import config from '../../config'

import ConfirmationHelper from '../util/ConfirmationHelper'
import teamNameFromColour from '../util/teamNameFromColour'
import { sessionIsActive } from '../util/SessionHelpers'
import OnlineComms from '../core/OnlineComms'
import SocketComms from '../core/SocketComms'
import inCall from '../selectors/inCall'
import history from '../core/history'
import action from '../util/action'
import types from './types'

import videoConfActions from './videoconf'
import settingsActions from './settings'
import groupActions from './groups'
import caseActions from './cases'
import miscActions from './misc'

const { fetchGroupData, fetchDecisionLog } = groupActions
const { fetchSettings } = settingsActions

const { TUTOR } = config.strings

// =================================================================================================

function switchLanguagePack(language = 'EN') {
	// Remove any previous class applied to the body to indicate the current language
	const { classList } = document.body
	classList.forEach(c => {
		if (c.includes('lang-')) classList.remove(c)
	})

	// Remove any hyphens from the language code
	const key = language.split('-').join('')

	if (language && config.LANGUAGE_PACKS[key]) {
		// Add a class to the body, e.g. "lang-ar"
		classList.add(`lang-${language.toLowerCase()}`)
		config.strings = { ...config.LANGUAGE_PACKS.EN, ...config.LANGUAGE_PACKS[key] }
	} else {
		config.strings = { ...config.LANGUAGE_PACKS.EN }
	}
}

// =================================================================================================

async function onTutorLoginSuccess(username, serverDtls, dispatch: Dispatch, getState: GetState): Promise<void> {
	if (config.SHOW_INFO_IN_WINDOW_TITLE) {
		document.title = `V360: ${TUTOR}`
	}
	await fetchSettings()(dispatch)
	await caseActions.loadCaseList()(dispatch)
	await caseActions.getOpenCase()(dispatch, getState)
	await videoConfActions.getServerStatus()(dispatch, getState)
	videoConfActions.getServerRegions()(dispatch, getState)

	dispatch(action(types.TUTOR_LOGIN_SUCCESS, { ...serverDtls, username }))

	Sentry.setUser({ email: username })

	SocketComms.facilitatorlogin(username)
}

// =================================================================================================
async function checkShowWaitingRoomMessage(groupData: GroupData, settings: Settings, dispatch: Dispatch) {
	const { waitingRoomEnabled, waitingRoomMessage } = settings
	const { currentSession } = groupData
	const activeSession = currentSession && sessionIsActive(currentSession)
	if (waitingRoomEnabled && waitingRoomMessage && !activeSession) {
		// Display waiting room message
		dispatch(action(types.SHOW_WAITING_ROOM_MESSAGE))
	}
}

// =================================================================================================

async function onGroupLoginSuccess(
	colour: string,
	groupId: string,
	language: string,
	dispatch: Dispatch,
	getState: GetState
) {
	if (config.SHOW_INFO_IN_WINDOW_TITLE) {
		document.title = `V360: ${teamNameFromColour(colour)}`
	}

	// Get facilitator ID from auth token
	const { authToken } = OnlineComms
	const decoded = decode(authToken)
	const { facilitatorId } = decoded
	switchLanguagePack(language)
	dispatch(action(types.LOGGED_IN_SUCCESS_GROUP, { colour, facilitatorId }))
	miscActions.navigateTo('group-view')(dispatch, getState)

	Sentry.setTag('groupId', groupId)
	Sentry.setUser({ id: groupId })

	await getServerTime()(dispatch, getState)
	const groupData = await fetchGroupData()(dispatch, getState)
	await fetchDecisionLog()(dispatch, getState)
	const settings = await fetchSettings()(dispatch)

	SocketComms.groupLogin(facilitatorId, groupId, colour)

	await checkShowWaitingRoomMessage(groupData, settings, dispatch)
}

// =================================================================================================

async function onParticipantLoginSuccess(
	colour,
	participantId,
	name,
	language,
	phoneNumber,
	promptParticipantPhone,
	dispatch,
	getState
) {
	if (config.SHOW_INFO_IN_WINDOW_TITLE) {
		document.title = `V360: ${name} - ${colour}`
	}

	// Get facilitator ID from auth token
	const { authToken } = OnlineComms
	const decoded = decode(authToken)
	const { facilitatorId } = decoded

	await getServerTime()(dispatch, getState)
	const groupData = await fetchGroupData()(dispatch, getState)
	await fetchDecisionLog()(dispatch, getState)
	const settings = await fetchSettings()(dispatch)

	dispatch(action(types.SETTINGS_LOADED, settings))

	switchLanguagePack(language)
	dispatch(action(types.LOGGED_IN_SUCCESS_PARTICIPANT, { participantId, colour, name, phoneNumber, facilitatorId }))

	// Check if we need to prompt the participant for their phone number
	if (promptParticipantPhone && settings.promptParticipantPhone) {
		dispatch(miscActions.navigateTo('login-participantphone'))
		return
	}

	miscActions.navigateTo('group-view')(dispatch, getState)

	Sentry.setTag('participantId', participantId)
	Sentry.setUser({ id: participantId, username: name })

	SocketComms.participantLogin(facilitatorId, participantId, name, colour)

	await checkShowWaitingRoomMessage(groupData, settings, dispatch)
}

// =================================================================================================

const checkLogin: ActionCreator = () => async (dispatch, getState) => {
	const { authToken } = OnlineComms
	if (!authToken) {
		if (!history.location.pathname.includes('/login')) {
			miscActions.navigateTo('login')(dispatch, getState)
		}
		return
	}

	try {
		// Decode the auth token payload
		const decoded = decode(authToken)
		console.log('Auth token: ', decoded)
		const { passcode, name, participantId, language, groupId, exp, linkedFacilitatorId, facilitatorId } = decoded

		// Check if token in cache has expired
		if (exp && exp * 1000 <= Date.now()) return

		if (groupId) {
			// We have to login again as we need to fetch the team colour.
			// The team colour is not held in the token because it can change.
			await loginAsGroup(passcode, groupId, language)(dispatch, getState)
			return
		}
		if (participantId) {
			// We have to login again as we need to fetch the participant colour.
			// The participant colour is not held in the token because it can change.
			await loginAsParticipant(passcode, name, participantId, language, false)(dispatch, getState)
			return
		}
		if (linkedFacilitatorId) {
			await loginAsObserver(name, passcode, false)(dispatch, getState)
			return
		}
		if (facilitatorId) {
			await onTutorLoginSuccess(facilitatorId, decoded, dispatch, getState)
			return
		}
	} catch (e) {
		console.error('Could not decode auth token held in localstorage')
	}
}

// =================================================================================================

const loginAsGroup: ActionCreator<string, string, string> = (passcode: string, groupId = null, lang = null) => async (
	dispatch,
	getState
) => {
	const state = getState()
	const _groupId = groupId || localStorage.getItem('groupid')
	const language = lang || (state && state.group && state.group.language)
	if (!passcode) {
		dispatch(action(types.LOGGED_IN_FAIL_GROUP, config.strings.ERROR_NO_PASSCODE))
		return
	}
	dispatch(action(types.STARTED_GROUP_LOGIN, {}))

	try {
		const colour = await OnlineComms.loginAsGroup(_groupId, passcode, language)
		await onGroupLoginSuccess(colour, _groupId, language, dispatch, getState)
	} catch (err) {
		dispatch(action(types.LOGGED_IN_FAIL_GROUP, err.message || config.strings.ERROR_COULD_NOT_CONNECT))
	}
}

// =================================================================================================

const loginAsParticipant: ActionCreator<string, string, string, string, boolean> = (
	passcode,
	name,
	participantId = null,
	lang = null,
	promptParticipantPhone = true
) => async (dispatch, getState) => {
	const state = getState()

	dispatch(action(types.STARTED_PARTICIPANT_LOGIN, {}))

	const _participantId = participantId || localStorage.getItem('groupid')
	const language = lang || state?.group?.language
	if (!passcode || !name) {
		return dispatch(action(types.LOGGED_IN_FAIL_PARTICIPANT, config.strings.ERROR_NO_PASSCODE))
	}

	localStorage.setItem('groupid', _participantId)
	localStorage.setItem('name', name)
	dispatch(action(types.STARTED_PARTICIPANT_LOGIN, {}))
	try {
		const response = await OnlineComms.loginAsParticipant(_participantId, passcode, language, name)
		const { colour, phoneNumber } = response

		await onParticipantLoginSuccess(
			colour,
			_participantId,
			name,
			language,
			phoneNumber,
			promptParticipantPhone,
			dispatch,
			getState
		)
	} catch (err) {
		dispatch(action(types.LOGGED_IN_FAIL_PARTICIPANT, err.message || config.strings.ERROR_COULD_NOT_CONNECT))
	}
}

// =================================================================================================

const loginAsTutor: ActionCreator<string, string> = (username, password) => async (dispatch, getState) => {
	if (!username) return

	// eslint-disable-next-line no-param-reassign
	username = username.trim().toLowerCase()

	dispatch(action(types.TUTOR_LOGIN_START, {}))
	try {
		const serverDtls = await OnlineComms.loginAsTutor(username, password)
		await onTutorLoginSuccess(username, serverDtls, dispatch, getState)
		miscActions.navigateTo('tutor-view')(dispatch, getState)
	} catch (err) {
		let message = err.message || config.strings.ERROR_PASSWORD_INCORRECT
		if (message === 'Failed to fetch') {
			message = 'Could not communicate with the server'
		}
		dispatch(action(types.TUTOR_LOGIN_ERROR, message))
	}
}
// =================================================================================================

const loginForOpenId: ActionCreator<string, string, SimpleObject, HTMLFormElement> = (
	username,
	password,
	openIdParams,
	form
) => async (dispatch, getState) => {
	if (!username) return

	// eslint-disable-next-line no-param-reassign
	username = username.trim().toLowerCase()

	dispatch(action(types.TUTOR_LOGIN_START, {}))
	try {
		const resp = await OnlineComms.loginAsTutor(username, password)

		form.setAttribute('action', openIdParams.redirect_uri as string)
		// eslint-disable-next-line no-param-reassign
		form.querySelector<HTMLInputElement>('[name=code]').value = OnlineComms.authToken
		form.submit()

		// await onTutorLoginSuccess(username, serverDtls, dispatch, getState)
	} catch (err) {
		let message = err.message || config.strings.ERROR_PASSWORD_INCORRECT
		if (message === 'Failed to fetch') {
			message = 'Could not communicate with the server'
		}
		dispatch(action(types.TUTOR_LOGIN_ERROR, message))
	}
}

// =================================================================================================

const loginAsObserver: ActionCreator<string, string, boolean> = (name, passcode, nav = true) => async (
	dispatch,
	getState
) => {
	dispatch(action(types.OBSERVER_CONNECTING, { name, passcode }))
	try {
		const token = await OnlineComms.loginAsObserver(name, passcode)
		if (!token) throw 'Could not connect to facilitator'

		const decoded = decode(token)
		const { linkedFacilitatorId: facilitatorId, clientId } = decoded

		await videoConfActions.getServerStatus()(dispatch, getState)

		dispatch(action(types.OBSERVER_CONNECTED, { token, name, facilitatorId }))
		SocketComms.observerlogin(decoded.linkedFacilitatorId, clientId, name)

		Sentry.setTag('observerId', clientId)
		Sentry.setUser({ id: decoded.linkedFacilitatorId })

		if (nav) {
			const state = getState()
			if (inCall(state)) {
				miscActions.navigateTo('tutor-videoconf')(dispatch, getState)
			} else {
				miscActions.navigateTo('tutor-groups')(dispatch, getState)
			}
		}
	} catch (err) {
		console.error('err: ', err)
		let message = err.message || 'Could not connect to facilitator'
		if (message === 'Failed to fetch') {
			message = 'Could not communicate with the server'
		}
		dispatch(action(types.OBSERVER_FAILED, message))
	}
}

// =================================================================================================

const signOut: ActionCreator = () => () => {
	const { CONFIRM_LOGOUT } = config.strings
	ConfirmationHelper.confirm(CONFIRM_LOGOUT).then(() => {
		sessionStorage.removeItem('authtoken')
		window.location.href = window.location.origin
	})
}

// =================================================================================================

const getServerTime: ActionCreator = () => async dispatch => {
	async function getServerTimeDiff() {
		const startTime = new Date().getTime()
		const resp = await OnlineComms.getServerTime()
		const serverTime = resp.time
		const endTime = new Date().getTime()
		const ourTime = startTime + (endTime - startTime) / 2
		const diff = Math.round(serverTime - ourTime)
		// Positive number = server time is ahead of our time
		return diff
	}

	// We call this function firstly to ensure the lambda function has woken up.
	// Then we take an average of three attempts.
	await getServerTimeDiff()
	const diffs = [await getServerTimeDiff(), await getServerTimeDiff(), await getServerTimeDiff()]

	const avgDiff = Math.round((diffs[0] + diffs[1] + diffs[2]) / 3)

	dispatch(action(types.GOT_SERVER_TIME_DIFF, avgDiff))
}

// =================================================================================================

export default {
	loginAsParticipant,
	loginAsObserver,
	loginForOpenId,
	getServerTime,
	loginAsGroup,
	loginAsTutor,
	checkLogin,
	signOut,
}
