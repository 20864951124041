export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'Batal',
	TUTOR: 'Người điều hành',
	CURRENT_SESSION: 'Phiên hiện tại',
	TEAM: 'Đội',
	SIGNOUT: 'Thoát ra',
	INITIAL_UPDATE: 'Thông tin của phiên',
	ADVICE: 'Quyết định',
	RATIONALE: 'Lí do căn bản',
	MEDIA_FEED: 'Nguồn cấp dữ liệu phương tiện',
	MESSAGES: 'Các tin nhắn',
	PREVSESSIONS: 'Các phiên trước',
	RETURN_TO_CURRENT: 'Quay lại phiên hiện tại',
	SESSION_IS_PAUSED: 'Phiên hiện đang bị TẠM DỪNG',
	ATTRIB_CURR_AUTHOR: 'Người ra quyết định',
	SAVE: 'Lưu',
	CHANGE: 'Thay đổi',
	NO_AUTHOR: 'Không thiết lập',
	NO_INITIAL_INFO: 'Vui lòng chờ phiên tiếp theo bắt đầu',
	DECISION_MAKER_SELECT: 'Vui lòng chọn người ra quyết định',
	SESSION_COMPLETE: 'Phiên trước đã kết thúc',
	OPENED_AT: 'Đã mở lúc',
	SEND_MESSAGE_PLACEHOLDER: 'Viết tin nhắn',
	SEND: 'Gửi',
	TO_ALL_GROUPS: 'cho tất cả các nhóm',
	SHOW_TRANSLATION: 'Hiển thị bản dịch',
	SHOW_ORIGINAL: 'Hiển thị bản gốc',
	CREDENTIALS_EXPIRED: 'Mật mã của bạn đã hết hạn. Vui lòng đăng nhập lại',
	INCOMING_MESSAGE: 'Tin nhắn đến',
	INCOMING_MESSAGE_TEXT: 'Đã nhận một tin nhắn mới',
	INCOMING_MEDIA: 'Muc phương tiện đến',
	INCOMING_MEDIA_TEXT: 'Đã nhận một mục phương tiện mới',
	INCOMING_UPDATE: 'Bản cập nhật đến',
	INCOMING_UPDATE_TEXT: 'Đã nhận một bản cập nhật',
	CONFIRM_LOGOUT: 'Bạn có chắc chắn bạn muốn thoát?',
	MUTED_MESSAGE: 'Micrô của bạn đã tắt',
	NEWSTYPE_UPDATE: 'Cập nhật đã nhận',
	WARNING_TIME_MSG: 'Còn 5 phút nữa',
	SESSION_HAS_STARTED: 'Phiên đã bắt đầu',
	SESSION_HAS_ENDED: 'Phiên đã kết thúc',
	SESSION_INFO_RECEIVED: 'Thông tin phiên đã nhận',
	CLICK_TO_OPEN: 'Nhấn để mở',
	CONTINUE: 'Tiếp tục',
	VIEW_INITIAL_UPDATE: 'Xem thông tin phiên',
	DECISION_LOG: 'Nhật ký quyết định',
	ADD_DECISION_LOG: 'Thêm vào nhật ký quyết định',
	CONFIRM_DELETE_DECISIONLOG: 'Bạn có chắc chắn bạn muốn xóa quyết định này?',
	CLICK_TO_EXPAND: 'Nhấn để mở rộng',
	ENTER_ADVICE: 'Nhập quyết định',
	ENTER_RATIONALE: 'Nhập lý do căn bản',
	NOTEPAD: 'Ghi chú',
	NOTETAKER: 'Người ghi chú',
	TO: 'đến',
	NO_NOTES: 'Không có ghi chú',
	EVERYONE: 'Tất cả mọi người',
	MESSAGE_ENTRY_PLACEHOLDER: 'Nhập tin nhắn',
	POPUP_TITLE_CONFIGUREVIDEO: 'Định cấu hình âm thanh/video',
	POPUP_TITLE_CONFIGUREVIDEO_CLOSE: 'Đóng',
	CAMERA: 'Máy ảnh',
	MICROPHONE: 'Micrô',
	AUDIO_OUTPUT_DEVICE: 'Thiết bị đầu ra âm thanh',
	SELECT_AUDIO_OUTPUT_DEVICE: 'Chọn thiết bị đầu ra âm thanh',
	SAME_AS_SYSTEM: 'Giống như hệ thống',
	DFT_NOTIFICATIONS_TEXT:
		'Phương tiện truyền thông sẽ xuất hiện ở đây. Hãy theo dõi thiết bị này để biết các phương tiện và thông tin cập nhật mới. Thông tin phiên đầu tiên có thể được truy cập từ nút ở đầu màn hình của bạn. Giao tiếp với người hướng dẫn bằng ứng dụng Tin nhắn ở góc dưới cùng bên trái của thiết bị này.',
	DECISION_LOG_HELP_TEXT:
		'Câu trả lời và lý do của nhóm bạn sẽ xuất hiện ở đây Bạn sẽ có thể chỉnh sửa câu trả lời và lý do của riêng mình bằng cách nhấp vào chúng.',
	DFT_MESSAGES_TEXT:
		'Ứng dụng này sẽ cho phép bạn gửi tin nhắn cho người hướng dẫn hoặc nhận tin nhắn từ người hướng dẫn trong suốt phiên.',
}
