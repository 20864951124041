import React, { FunctionComponent } from 'react'

import VideoCallAgora from '../components/videoconf/VideoCallAgora'
import Modal from '../components/Modal'

const VideoTestAgora: FunctionComponent = () => {
	const displayName = `test-${Math.floor(Math.random() * 1000)}`
	return (
		<div className="video-test video-test--agora">
			<VideoCallAgora testMode callId="v360gtest" displayName={displayName} />
			<Modal />
		</div>
	)
}

export default VideoTestAgora
