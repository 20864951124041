/* eslint-disable no-nested-ternary */
import React, { FunctionComponent, useState } from 'react'
import { useInterval, useMount } from 'react-use'
import { connect } from 'react-redux'

import config from '../../config'

import Actions from '../actions'

import loggedInAsFacilitator from '../selectors/loggedInAsFacilitator'
import BubbleHeading from '../elements/BubbleHeading'
import Participant from '../components/Participant'

const { NO_RECORDS, PARTICIPANTS } = config.strings

type TutorParticipantsProps = {
	updateParticipantColour: (participantId: string, colour: string) => void
	updateParticipantAllowed: boolean
	participants: Participant[]
	passcodes: Passcode[]
	getPasswords?: () => void
}

const TutorParticipants: FunctionComponent<TutorParticipantsProps> = props => {
	const { participants, updateParticipantAllowed, passcodes, getPasswords } = props
	const [colours, setColours] = useState<Record<string, string>>({})
	useMount(() => getPasswords())
	useInterval(() => getPasswords(), 3000)

	const onDropdownChange = (participant, e) => {
		const participantId = participant.id || participant.participantId
		const { updateParticipantColour } = props
		const colour = e.currentTarget.value
		const obj = { ...colours }
		obj[participantId] = colour
		setColours(obj)
		updateParticipantColour(participantId, colour)
	}

	const participantsArray = [...(participants || [])]

	// Sort by colour and then name
	const sortByColour = (a, b) => (a.colour === b.colour ? 0 : a.colour > b.colour ? 1 : -1)
	const sortByName = (a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1)
	participantsArray.sort((a, b) => sortByColour(a, b) || sortByName(a, b))

	let participantsList
	if (participantsArray.length) {
		participantsList = participantsArray.map(p => {
			const passcode = passcodes.find(code => code.participantId === p.id)?.key
			return (
				<Participant
					key={p.id}
					passcode={passcode}
					participant={p}
					onChange={e => onDropdownChange(p, e)}
					allowChange={updateParticipantAllowed}
				/>
			)
		})
	} else {
		participantsList = <div className="none-found">{NO_RECORDS}</div>
	}

	const className = 'tutor-participants'

	return (
		<div className={className}>
			<div className={`${className}__top`}>
				<BubbleHeading>{PARTICIPANTS}</BubbleHeading>
			</div>
			<div className={`${className}__content`}>{participantsList}</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const updateParticipantAllowed = loggedInAsFacilitator(state) || state?.settings?.observerUpdateParticipantTeamAllowed
	return {
		participants: state.participants || [],
		passcodes: state.passwords || [],
		updateParticipantAllowed,
	}
}
const actions = {
	getPasswords: Actions.passwords.getPasswords,
	updateParticipantColour: Actions.participants.updateParticipantColour,
}
export default connect(mapStateToProps, actions)(TutorParticipants)
