import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import Actions from '../../actions'

import getIconForMediaItem from '../../util/getIconForMediaItem'
import currentCaseSelector from '../../selectors/currentCase'
import releasesSelector from '../../selectors/releases'
import sessionSelector from '../../selectors/session'
import getMediaFeed from '../../selectors/mediaFeed'
import Icon from '../../elements/Icon'

type NotificationData = ScheduleRow & {
	time: number
	rowId: string
}

// =================================================================================================
type AppButtonProps = {
	onClick?: () => void
	title: string
	iconName: string
	iconColour: string
	showBadge: boolean
	alert?: boolean
}

const AppButton: FunctionComponent<AppButtonProps> = props => {
	const { onClick, title, iconName, iconColour, showBadge, alert } = props
	const contClassName = 'virtual-phone-apps__app-container'
	let appClassName = 'virtual-phone-apps__app'

	if (alert) appClassName += ' virtual-phone-apps__app--alert'
	return (
		<div className={contClassName} onClick={onClick} role="button" aria-label={title} tabIndex={0} onKeyPress={onClick}>
			<div className={appClassName} style={{ background: iconColour }}>
				<div className="virtual-phone-apps__app-overlay" />
				{showBadge ? <div className="badge" /> : null}
				<Icon name={iconName} />
			</div>
		</div>
	)
}
// =================================================================================================

type VirtualPhoneAppsProps = {
	sessionId: string
	groupId: string
	notifications: NotificationData[]
	caseMedia?: MediaItem[]
	openings?: Opening[]
	participantId?: string
	showMessages?: () => void
	openItem?: (rowId: string, sessionId: string) => void
	messagesUnread?: boolean
}

const VirtualPhoneApps: FunctionComponent<VirtualPhoneAppsProps> = props => {
	const { notifications, caseMedia, openings, participantId, groupId, sessionId } = props
	const { showMessages, openItem, messagesUnread } = props

	notifications.sort((a, b) => (a.time > b.time ? 1 : -1))

	const appDetailsForEachNotification = []

	for (let i = 0; i < notifications.length; i++) {
		const notification = notifications[i]
		const { rowId } = notification
		const mediaId = notification.mediaitem
		const mediaitem = mediaId && caseMedia.find(m => m.id === mediaId)

		// Get icon to display
		const { icon, colour: iconColour } = getIconForMediaItem(mediaitem)

		// Check which apps have an unopened notification

		// Get list of openings. There might be multiple if there are multiple participants in team
		let itemOpenings = openings.filter(o => o.key === rowId && o.sessionId === sessionId && o.groupId === groupId)
		if (participantId) {
			itemOpenings = itemOpenings.filter(o => o.participantId === participantId)
		}
		// Find earliest opening
		itemOpenings.sort((a, b) => (a.lastUpdate < b.lastUpdate ? -1 : 1))

		// eslint-disable-next-line no-param-reassign
		const opened = itemOpenings.length > 0

		appDetailsForEachNotification.push({
			icon,
			rowId,
			iconColour,
			opened,
			name: mediaitem?.name,
		})
	}

	// Remove any apps that appeared earlier in the array (i.e. they already appeared earlier in time)
	let apps = appDetailsForEachNotification.filter(
		(a, i) => !appDetailsForEachNotification.find((a2, i2) => a2.icon === a.icon && i2 < i)
	)

	// Check which 'apps' have an unopened notification
	for (let i = 0; i < apps.length; i++) {
		const app = apps[i]
		const unopened = appDetailsForEachNotification.find(a => a.icon === app.icon && !a.opened)
		if (unopened) {
			// If there is an unopened notification for this app, display that media when the app is clicked
			app.opened = false
			app.rowId = unopened?.rowId
		} else {
			// Otherwise, when app is clicked display most recent media for that app
			const mostRecentNotification = appDetailsForEachNotification.reverse().find(n => n.icon === app.icon)
			app.rowId = mostRecentNotification?.rowId
		}
	}

	// The max number of apps we can display is 7
	apps = apps.slice(0, 7)

	const appElements = [
		<AppButton
			key="messages"
			showBadge={messagesUnread}
			alert={messagesUnread}
			onClick={showMessages}
			title="Messages"
			iconName="message"
			iconColour="#cf167f"
		/>,
	]

	// Convert list of apps, to list of components to render
	// appElements.push(
	// 	...apps.map(app => {
	// 		const { rowId, icon, iconColour, opened, name } = app
	// 		const onClick = () => openItem(rowId, sessionId)
	// 		return (
	// 			<AppButton
	// 				key={rowId}
	// 				showBadge={!opened}
	// 				onClick={onClick}
	// 				title={name}
	// 				iconName={icon}
	// 				iconColour={iconColour}
	// 			/>
	// 		)
	// 	})
	// )

	// If we don't have 7 apps to show, we pad the list out to 4 or 8
	// while (appElements.length < 4 || (appElements.length > 4 && appElements.length < 8)) {
	// 	appElements.push(
	// 		<div key={`app_${appElements.length}`} className="virtual-phone-apps__app-container">
	// 			<div className="virtual-phone-apps__app" />
	// 		</div>
	// 	)
	// }

	return <div className="virtual-phone-apps">{appElements}</div>
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree, props): Partial<VirtualPhoneAppsProps> => {
	const currentCase = currentCaseSelector(state) || ({} as OpenCaseDetails)
	const group = state.group || ({} as GroupDetails)
	const groupId = group.id

	const session = sessionSelector(state)
	const releases = releasesSelector(state)
	const notifications = groupId ? getMediaFeed(groupId, session, releases) : []

	return {
		messagesUnread: state.messagesUnread,
		participantId: group.participantId,
		caseMedia: currentCase.media || [],
		openings: state.openings || [],
		sessionId: session.id,
		groupId: group.id,
		notifications,
	}
}
const actions = {
	openItem: Actions.groups.openItem,
}

export default connect(mapStateToProps, actions)(VirtualPhoneApps)
