/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import { useInterval } from 'react-use'
import loggedInAsParticipantSelector from '../../selectors/loggedInAsParticipant'
// import JitsiClient from '../util/JitsiClient'
import { IVideoClient } from './VideoTypes'
// import VideoCallParticipant from './VideoCallParticipant'
import VideoCallParticipantTwilio from './VideoCallParticipantTwilio'

type VideoParticipantModalProps = {
	loggedInAsParticipant: boolean
	participantId: string
}

const VideoParticipantModal: FunctionComponent<VideoParticipantModalProps> = props => {
	const { participantId } = props
	console.log('participantId', participantId)

	const videoClient: IVideoClient = window.VideoClient || window.JitsiClient
	if (!videoClient) return null
	console.log('videoClient', videoClient)

	const getParticipant = () => {
		const remoteParticipants = videoClient.remoteParticipants || []
		const remoteParticipant = remoteParticipants.find(p => p.participantId === participantId)
		return remoteParticipant || videoClient.localParticipant
	}

	const [participant, setParticipant] = useState(getParticipant())
	const [status, setStatus] = useState(participant?.status)

	useInterval(() => {
		if (!participant) setParticipant(getParticipant())
		if (status !== participant?.status) setStatus(participant?.status)
	}, 1000)

	const isLocal = participant && videoClient.localParticipant.participantId === participant.participantId

	if (participant && !isLocal) {
		videoClient.setPrimaryRemoteParticipant(participantId)
	}

	// <VideoCallParticipant participant={participant} showName={false} volume={0} showVolumeStat={false} />

	return (
		<div className="video-participant-modal">
			<div className="video-participant-modal__main" role="dialog">
				<div className="video-participant-modal__content">
					{participant ? (
						<VideoCallParticipantTwilio participant={participant} showName={false} volume={0} showVolumeStat={false} />
					) : (
						<div className="error">Error: Participant unavailable</div>
					)}
				</div>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	loggedInAsParticipant: loggedInAsParticipantSelector(state),
})
const actions = {}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<VideoParticipantModalProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(VideoParticipantModal)
