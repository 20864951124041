import FineUploaderTraditional from 'fine-uploader-wrappers'
import config from '../../config'

const { SERVER_URL } = config

type AddToCaseCallback = (filename: string, id: string) => void
type ErrorCallback = (errorReason: string) => void

function traditionalMediaUploader(
	addToCase: AddToCaseCallback,
	errorCallback: ErrorCallback
): typeof FineUploaderTraditional {
	return new FineUploaderTraditional({
		options: {
			request: {
				endpoint: `${SERVER_URL}/uploads`,
			},
			validation: {
				acceptFiles: '.jpg, .png, .jpeg, .gif, .tif, .mp4, .m4a, .mp3, .pdf',
			},
			callbacks: {
				onComplete: (id, fileName, response) => {
					// handle completed upload
					console.info('Upload complete', response.id, response.fileName, 'filename: ', fileName)
					addToCase(response.fileName, response.id)
				},
				onError: (id, name, errorReason) => {
					errorCallback(errorReason)
				},
			},
		},
	})
}

export default traditionalMediaUploader
