export default {
	/* Team view */
	OK: 'حسناً',
	CANCEL: 'الغي',
	TUTOR: 'منسق ',
	CURRENT_SESSION: 'الجلسة الحالية',
	TEAM: 'فريق',
	SIGNOUT: 'تسجيل خروج',
	INITIAL_UPDATE: 'معلومات عن الجلسة',
	ADVICE: 'القرار',
	RATIONALE: 'حيثيات',
	MEDIA_FEED: 'تغذية وسائل الإعلام',
	MESSAGES: 'رسائل',
	PREVSESSIONS: 'لجلسة السابقة',
	RETURN_TO_CURRENT: 'العودة للجلسة الحالية',
	SESSION_IS_PAUSED: 'الجلسة متوقفة مؤقتًا',
	ATTRIB_CURR_AUTHOR: 'صانع القرار',
	SAVE: 'احفظ',
	CHANGE: 'التغيير',
	NO_AUTHOR: 'غير محدد',
	NO_INITIAL_INFO: 'يرجى الانتظار حتى تبدأ الجلسة التالية',
	DECISION_MAKER_SELECT: 'الرجاء اختيار صانع القرار',
	SESSION_COMPLETE: 'انتهت الجلسة السابقة',
	OPENED_AT: 'افتتح في',
	SEND_MESSAGE_PLACEHOLDER: 'اكتب رسالة',
	SEND: 'ارسل',
	TO_ALL_GROUPS: 'لجميع الفرق',
	SHOW_TRANSLATION: 'عرض الترجمة',
	SHOW_ORIGINAL: 'إظهار النسخة الأصلية',
	CREDENTIALS_EXPIRED: 'انتهت صلاحية بيانات الاعتماد الخاصة بك. الرجاد الدخول على الحساب من جديد.',
	INCOMING_MESSAGE: 'رسالة واردة',
	INCOMING_MESSAGE_TEXT: 'تم استلام رسالة جديدة',
	INCOMING_MEDIA: 'عنصر وسائط وارد',
	INCOMING_MEDIA_TEXT: 'تم استلام عنصر وسائط جديد',
	INCOMING_UPDATE: 'تحديث قادم',
	INCOMING_UPDATE_TEXT: 'تم استلام تحديث ',
	CONFIRM_LOGOUT: 'هل أنت متأكد أنك ترغب في تسجيل الخروج؟',
	MUTED_MESSAGE: 'أنت الآن في وضع الصامت',
	NEWSTYPE_UPDATE: 'تم استلام التحديث',
	WARNING_TIME_MSG: 'هناك 5 دقائق متبقية',
	VIEW_INITIAL_UPDATE: 'عرض معلومات الجلسة',
	INITIAL_UPDATE_INDEX: 'معلومات الجلسة %%%',
	VIEWING_PREVIOUS: 'عرض الجلسة $$ من %%%',
	TO: 'إلى',
	EVERYONE: 'الجميع',
	MESSAGE_ENTRY_PLACEHOLDER: 'رسالة',
	DEFAULT_VIDEO_WARNING_MESSSAGE: 'ستبدأ مكالمة الفيديو قريبًا',
	DEFAULT_VIDEO_WARNING_TITLE: 'تحذير',
	SESSION_INFO_RECEIVED: 'تم استلام معلومات الجلسة',
	DECISION_LOG: 'قرارات',
	SCROLL_TO_BOTTOM: 'انتقل إلى الأسفل',
	ADD_DECISION_LOG: 'سجل القرار',
	DFT_WELCOME_MESSAGE: `مرحبًا بك في منصة التعلم التجريبية العالمية View360
	
	يرجى الانتظار حتى تبدأ الجلسة`,
	CONFIRM_DELETE_DECISIONLOG: `هل أنت متأكد أنك ترغب في إزالة هذا القرار؟`,
	SESSION_HAS_STARTED: `بدأت الجلسة`,
	CLICK_TO_OPEN: `انقر لفتح`,
	CLICK_TO_EXPAND: `انقر  للتوسيع`,
	EXPAND_TEXT: `انقر فوق الزر أدناه لعرض الفيديو بالحجم الكامل`,
	COLLAPSE: `تصغير`,
	EXPAND: `توسيع`,
	RECEIVED_AT: `تم الاستلام في`,
	AUDIO_PLAYER: `مشغل الصوت`,
	VIDEO_PLAYER: `مشغل فديوهات`,
	DFT_MESSAGES_TEXT: `سيسمح لك هذا التطبيق بإرسال واستقبال الرسائل من / إلى المنسق أثناء الجلسة.`,
	DFT_NOTIFICATIONS_TEXT: `ستظهر الوسائط هنا. ترقب هذا الجهاز للوسائط الجديدة والتحديثات.
	يمكن الوصول إلى معلومات الجلسة الأولية من الزر الموجود أعلى الشاشة.
	تواصل مع المنسق باستخدام تطبيق الرسائل في الزاوية اليسرى السفلية من هذا الجهاز.`,
	MOBILE_NUMBER_TITLE: `رقم الهاتف`,
	MOBILE_NUMBER_PLACEHOLDER: `رقم الجوال`,
	MOBILE_NUMBER_DESC: `يرجى تقديم رقم هاتفك المحمول حتى تتمكن من استقبال الرسائل أثناء الجلسة. سيتم استخدام رقم هاتفك فقط أثناء التدريب الشامل وسيتم حذفه تلقائيًا بعد 24 ساعة وفقًا لسياسات القانون العام لحماية البيانات وقانون حماية البيانات الخاصة بنا.`,
	MOBILE_NUMBER_SKIP: `تخطى هذا`,
	INCOMING_CALL: `مكالمة واردة`,
	CALL_IN_PROGRESS: `مكالمة قيد الاجراء`,
	PHONE_MESSAGE_SENT: `تم إرسال رسالة هاتفية`,
	CALL_FAILED: `فشلت المكالمة`,
	NO_ANSWER: `لا يوجد رد`,
	CLICK_TO_RESEND: `انقر فوق الزر إعادة إرسال لإعادة إرسال الرسالة إلى جهازك.`,
	VOICE_MESSAGE_SENT: `تم إرسال رسالة صوتية إلى ....`,
	WENT_TO_VOICEMAIL: `تم إرسال الرسالة الصوتية إلى %%% لكنها ذهبت إلى بريدك الصوتي.`,
	CALL_FAILED_TEXT: `تم إرسال رسالة صوتية إلى %%% لكنها لم تصل.`,
	CHECK_PHONE: `يرجى التحقق من هاتفك.`,
	AT: `في`,
	CALLING: `الاتصال`,
	RESEND: `إعادة إرسال`,
	PLAY_HERE: `شغل هنا`,
	SESSION: `جلسة`,
	CAMERA: `كاميرا`,
	MICROPHONE: `ميكروفون`,
	AUDIO_OUTPUT_DEVICE: `جهاز إخراج الصوت`,
	SELECT_CAMERA: `اختر الكاميرا`,
	SELECT_MICROPHONE: `اختر الميكروفون`,
	SELECT_AUDIO_OUTPUT_DEVICE: `حدد جهاز إخراج الصوت`,
	SAME_AS_SYSTEM: `مثل النظام`,

	CONTINUE: 'حسناً',
	CLOSE: `أغلق`,
	UPDATE: `التحديث`,
	NO_RECORDS: `لا تسجيلات`,
	DECISION_LOG_HELP_TEXT: `ستظهر قرارات فريقك والأسباب المنطقية هنا.
	ستكون قادرًا على تعديل قراراتك والأسباب المنطقية من خلال النقر عليها.`,

	PRIVATE_MESSAGE: `رسالة خاصة`,

	POPUP_TITLE_CONFIGUREVIDEO: 'تكوين الصوت / الفيديو',

	CHANNEL_NAME: 'اسم القناة',
	INTERPRETER: 'مترجم',
	SUPERVISOR: 'مراقب',
	NO_DECISION_LOGS: 'لا توجد سجلات قرار لعرضها.',
	NOTEPAD: 'المفكرة',
	NOTETAKER: 'مدون ملاحظات',
	ENTER_ADVICE: 'أدخل ردك',
	ENTER_RATIONALE: 'أدخل الأساس المنطقي الخاص بك',
}
