/* eslint-disable no-param-reassign */
import OnlineComms from '../core/OnlineComms'
import action from '../util/action'
import getTime from '../util/getTime'
import types from './types'

const { NOTES_TAKER_CHANGED } = types
let updateNotesTimeout: number

export const updateNotes: ActionCreator<string> = (fullnotes: string) => async (dispatch, getState) => {
	const lines: Note[] = fullnotes.split('\n').map((text, index) => ({ text, index }))
	const { participantId } = getState().group

	const { notes = [] } = getState()

	const newNotes = [...notes.filter(n => lines.find(n2 => n.index === n2.index))]

	newNotes.forEach(n => {
		const line = lines.find(l => l.index === n.index)
		if (line && line.text !== n.text) {
			n.text = lines.find(l => l.index === n.index).text
			n.participantId = participantId
		}
	})

	newNotes.push(...lines.filter(l => !newNotes.find(n => n.index === l.index)))

	newNotes.sort((a, b) => a.index - b.index)
	dispatch(action(types.NOTES_UPDATED, newNotes))

	clearTimeout(updateNotesTimeout)

	updateNotesTimeout = window.setTimeout(async () => {
		const { group } = getState()
		const groupId = group.id
		const total = newNotes.length
		// In future, we might want to just send the lines that have changed, and the total number
		// of lines so that the server can tell if some have been deleted. But for now we'll send every
		// line to the server. It will still have to check which ones have been deleted.
		await OnlineComms.updateNotes(groupId, newNotes, total)
	}, 1000)
}

export const getNotes: ActionCreator = () => async (dispatch, getState) => {
	const state = getState()
	const groupId = state.group.id
	const groupData = state.groupData || ({} as GroupData)
	const { viewingPreviousSession, previousSession } = state
	const sessionId = viewingPreviousSession ? previousSession.id : (groupData.currentSession || {}).id
	const notes = await OnlineComms.getNotes(groupId, sessionId)
	dispatch(action(types.NOTES_LOADED, notes))
}

let holdoff = false
let holdoffTimeout = null
export const updateNoteTaker: ActionCreator<string> = (noteTaker: string) => async dispatch => {
	holdoff = true
	clearTimeout(holdoffTimeout)
	dispatch({ type: NOTES_TAKER_CHANGED, payload: noteTaker || '' })
	await OnlineComms.updateNoteTaker(noteTaker).catch(err => console.error(err))
	holdoffTimeout = setTimeout(() => (holdoff = false), 2000)
}

export const getNoteTaker: ActionCreator = () => async (dispatch, getState) => {
	const response = await OnlineComms.getNoteTaker()
	const { noteTaker } = response
	if (getState().notetaker !== noteTaker && !holdoff) {
		dispatch({ type: NOTES_TAKER_CHANGED, payload: noteTaker || '' })
	}
}

export default {
	updateNoteTaker,
	getNoteTaker,
	updateNotes,
	getNotes,
}
