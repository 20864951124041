/* eslint-disable no-param-reassign */

import produce from 'immer'
import calculateTranslationHash from '../util/calculateTranslationHash'

function TEXT_TRANSLATED(state: StateTree, action: ReduxAction) {
	const { fromLang, toLang, text, translation } = action.payload

	const hash = calculateTranslationHash(text, fromLang, toLang)
	state.translations[hash] = translation
	return state
}

//==================================================================================================

const reducers = {
	TEXT_TRANSLATED,
}

//==================================================================================================

function handler(state: StateTree, action: ReduxAction): StateTree {
	let newState = state

	// Default state
	if (!state.translations) {
		newState = { ...state, translations: {} }
	}

	const _action: ReduxAction = action || { type: '', payload: {} }
	const { type } = _action

	// If we have a reducer that matches the action type, apply it
	if (!reducers[type]) return newState

	return produce(newState, _newState => reducers[type](_newState, _action))
}

export default handler
