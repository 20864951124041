import { connect } from 'react-redux'
import React from 'react'

import ConfirmationHelper from '../util/ConfirmationHelper'
import releasedItemsSelector from '../selectors/releasedItems'
import sessionSelector from '../selectors/session'
import getTime from '../util/getTime'

import config from '../../config'

const { INCOMING_MESSAGE, INCOMING_MESSAGE_TEXT, INCOMING_MEDIA, INCOMING_MEDIA_TEXT } = config.strings
const { INCOMING_UPDATE, INCOMING_UPDATE_TEXT } = config.strings

type IncomingItemToastProps = {
	releasedItems: Array<ReleaseItem>
	conversation: Array<Message>
}

type IncomingItemToastState = {
	lastItemAlert: number
	startTime: number
}

class IncomingItemToast extends React.Component<IncomingItemToastProps, IncomingItemToastState> {
	constructor(props: IncomingItemToastProps) {
		super(props)
		this.state = { lastItemAlert: 0, startTime: getTime() }
	}

	static getDerivedStateFromProps(nextProps: IncomingItemToastProps, prevState: IncomingItemToastState) {
		const { lastItemAlert, startTime } = prevState || {}
		const { releasedItems, conversation } = nextProps
		const newItem = releasedItems.find(i => i.time > startTime && i.time > lastItemAlert)
		const newMessage = conversation.find(
			i => parseInt(i.timestamp) > startTime && parseInt(i.timestamp) > lastItemAlert
		)

		const newState = { ...prevState }

		if (newMessage) {
			newState.lastItemAlert = parseInt(newMessage.timestamp)
			ConfirmationHelper.toast(INCOMING_MESSAGE, INCOMING_MESSAGE_TEXT)
		}

		if (newItem) {
			newState.lastItemAlert = newItem.time
			if (newItem.type === 'media') {
				ConfirmationHelper.toast(INCOMING_MEDIA, INCOMING_MEDIA_TEXT)
			} else {
				ConfirmationHelper.toast(INCOMING_UPDATE, INCOMING_UPDATE_TEXT)
			}
		}

		return newState
	}

	render() {
		return null
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<IncomingItemToastProps> => {
	const session = sessionSelector(state)
	const allGroupMessages: Message[] = session.allGroupMessages || []
	const conversation: Message[] = (state.groupData || {}).conversation || []
	const groupId = (state.group || {}).id
	const releases: Release[] = state.releases || []

	const releasedItems = releasedItemsSelector(session, releases, groupId)

	return { conversation: [...conversation, ...allGroupMessages], releasedItems }
}
const actions = {}
export default connect(mapStateToProps, actions)(IncomingItemToast)
