import React, { FunctionComponent, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import config from '../../config'
import Actions from '../actions'

import DecisionMaker from './DecisionMaker'
import Textarea from '../elements/Textarea'
import Button from '../elements/Button'
import Row from '../elements/Row'

import onChangeDecisionLog, { UpdateDecisionLog } from '../util/onChangeDecisionLog'
import { sessionIsActive } from '../util/SessionHelpers'

type AddDecisionLogProps = {
	//
	setEditingDecisionLog: (val: boolean) => void
	editingDecisionLog: boolean
	sessionActive: boolean
	sessionPaused: boolean
	loggedInAsGroup: boolean

	participantId: string
	currAuthorId: string
	currAuthor: string
	language: string

	advice: string[]
	rationale: string[]
	authors: string[]
	times: number[]
	participants: string[]
	languages: string[]

	updateDecisionLog: UpdateDecisionLog
}

const AddDecisionLog: FunctionComponent<AddDecisionLogProps> = props => {
	const { currAuthorId, sessionActive, sessionPaused, participantId, currAuthor, language } = props
	const { editingDecisionLog, updateDecisionLog } = props
	const { advice, rationale, participants } = props
	const { setEditingDecisionLog } = props

	const { strings } = config
	const { ADD_DECISION_LOG } = config.strings

	const ADVICE = strings.ENTER_ADVICE || strings.ADVICE
	const RATIONALE = strings.ENTER_RATIONALE || strings.RATIONALE

	let enabled = sessionActive && !sessionPaused

	// Disable controls if logged in as participant, and currentAuthorID is not equal to our participant ID
	if (participantId && currAuthorId !== participantId) enabled = false

	// Disable controls if logged in as group and we haven't been given a current author name
	if (!currAuthorId && !currAuthor) enabled = false

	// If editing decision log, position of advice must be latest log that has a participantID of ours
	let index = advice.length
	if (editingDecisionLog) {
		const lastIndex = Math.max(...participants.map((p, i) => (p === participantId ? i : -1)))
		if (lastIndex >= 0) index = lastIndex
	}

	const adviceVal = editingDecisionLog ? advice[index] : ''
	const rationaleVal = editingDecisionLog ? rationale[index] : ''

	const onChangeAdvice = e => {
		onChangeDecisionLog(props, updateDecisionLog, currAuthor, participantId, language, 'advice', index, e)
		if (!e.target.value.trim() && !rationaleVal) setEditingDecisionLog(false)
		else setEditingDecisionLog(true)
	}

	const onChangeRationale = e => {
		onChangeDecisionLog(props, updateDecisionLog, currAuthor, participantId, language, 'rationale', index, e)
		if (!e.target.value.trim() && !adviceVal) setEditingDecisionLog(false)
		else setEditingDecisionLog(true)
	}

	const onClickAddDecisionLog = () => {
		const a = adviceVal ? adviceVal.trim() : ''
		const r = rationaleVal ? rationaleVal.trim() : ''
		if (a + r !== '') {
			setEditingDecisionLog(false)
			if (adviceText.current) adviceText.current.focus()

			// Delay the scroll operation until after the new content has been rendered
			setTimeout(() => {
				const scroller = document.querySelector('.decision-log__scroller')
				if (scroller) {
					scroller.scrollTop = scroller.scrollHeight
				}
			}, 0)
		}
	}

	const adviceText = useRef<HTMLTextAreaElement>()
	const [adviceFocus, setAdviceFocus] = React.useState(false)
	const adviceLabelClass = `textarea-with-label ${adviceFocus ? 'textarea-with-label--focus' : ''}`
	const scroller = document.querySelector('.decision-log__scroller')

	return (
		<div className="add-decision-log" role="form" aria-label={ADD_DECISION_LOG}>
			<Row>
				<h2>{ADD_DECISION_LOG}</h2>
				<DecisionMaker />
			</Row>
			<Row className="input-row">
				<Textarea ref={adviceText} enabled={enabled} value={adviceVal} onChange={onChangeAdvice} placeholder={ADVICE} />
				<Textarea placeholder={RATIONALE} enabled={enabled} value={rationaleVal} onChange={onChangeRationale} />
			</Row>
			<Row className="button-row">
				<Button primary icon="check-alt" enabled={enabled} onClick={onClickAddDecisionLog}>
					{ADD_DECISION_LOG}
				</Button>
			</Row>
		</div>
	)
}

// export default AddDecisionLog

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<AddDecisionLogProps> => {
	const { viewingPreviousSession } = state

	const previousSession = (viewingPreviousSession && state.previousSession) || ({} as PreviousSession)
	const prevSessGroups = previousSession.groups || []
	const groupInPrevSession = prevSessGroups.find(g => g.id === state?.group?.id)

	const group = groupInPrevSession || state.group || ({} as GroupDetails)
	const { currAuthor, currAuthorId, participantId, language } = group
	const { advice, rationale, languages, authors, participants, times } = group

	const sessionActive = !viewingPreviousSession && sessionIsActive(state?.groupData?.currentSession)
	const sessionPaused = !viewingPreviousSession && state?.groupData?.currentSession?.paused

	return {
		editingDecisionLog: Boolean(state.editingDecisionLog),
		participants: participants || [],
		rationale: rationale || [],
		languages: languages || [],
		authors: authors || [],
		advice: advice || [],
		times: times || [],
		sessionPaused,
		sessionActive,
		participantId,
		currAuthorId,
		currAuthor,
		language,
	}
}
const actions = {
	setEditingDecisionLog: Actions.groups.setEditingDecisionLog,
	updateDecisionLog: Actions.groups.updateDecisionLog,
}

export default connect(mapStateToProps, actions)(AddDecisionLog)
