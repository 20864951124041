/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, useState } from 'react'
import Select from 'react-select'
import { Components } from 'react-select/src/components'
import Icon from './Icon'

import mediaIconList from '../../config/mediaIconList'

// =================================================================================================
/* Custom dropdown <option> element */
const CustomOption: Components['Option'] = ({ innerProps, isDisabled, data: { value, label } }) => {
	if (isDisabled) return null
	const option = mediaIconList.find(o => o.value === value) || { icon: null, color: null }
	const { color } = option
	return (
		<div className="icon-select__option" {...innerProps}>
			<Icon name={option.icon} color={color} />
			{label}
		</div>
	)
}
// =================================================================================================
/* Custom selected value element (no label, just the icon) */
const CustomSingleValue: Components['SingleValue'] = ({ innerProps, isDisabled, data: { value } }) => {
	if (isDisabled) return null
	const option = mediaIconList.find(o => o.value === value) || { icon: null, color: null }
	const { color, icon } = option
	return (
		<div className="icon-select__selected-value" {...innerProps}>
			{icon && <Icon color={color} name={icon} />}
		</div>
	)
}
// =================================================================================================

type IconSelectProps = {
	onChange: (value: string) => void
	initialValue: string
}

const IconSelect: FunctionComponent<IconSelectProps> = props => {
	const { onChange, initialValue } = props
	const [value, setValue] = useState(initialValue)
	return (
		<Select
			className="icon-select"
			classNamePrefix="icon-select__"
			components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
			options={mediaIconList}
			value={{ value }}
			onChange={e => {
				const newValue = e.value
				setValue(newValue)
				if (onChange) onChange(newValue)
			}}
		/>
	)
}

export default IconSelect
