import { connect } from 'react-redux'
import React from 'react'

import InputFieldManager from '../InputFieldManager'
import Button from '../../elements/Button'
import Input from '../../elements/Input'
import Icon from '../../elements/Icon'
import config from '../../../config'
import Actions from '../../actions'

const { strings } = config

type LoginObserverProps = {
	error?: string
	loggingIn?: boolean
	clearErrors: () => void
	onClickBack?: () => void
	loginAsObserver: (name: string, passcode: string) => void
}

class LoginObserver extends React.Component<LoginObserverProps> {
	constructor(props) {
		super(props)
		this._onClickOk = this._onClickOk.bind(this)

		InputFieldManager.clearValue('name')
		InputFieldManager.clearValue('passcode')
		const { clearErrors } = props
		clearErrors()
	}

	_onClickOk() {
		const { props } = this
		const { clearErrors, loginAsObserver } = props
		clearErrors()
		const passcode = InputFieldManager.getValue('passcode')
		const name = InputFieldManager.getValue('name')
		loginAsObserver(name, passcode)
	}

	_errorBit() {
		const { props } = this
		const { error } = props
		const errors = error ? [error] : []
		return errors.map(e => (
			<div key={e} className="error">
				{e}
			</div>
		))
	}

	render() {
		const { props } = this
		const { loggingIn, onClickBack } = props
		const { NAME, PASSCODE, BACK, OK } = strings

		const errorBit = loggingIn ? <div /> : this._errorBit()

		return (
			<div className="login-group">
				<h2>Please enter your log in details</h2>
				<div className="login-group__main">
					<div className="login__input-row">
						<Icon name="user" />
						<Input name="name" focus onEnter={this._onClickOk} placeholder={NAME} />
					</div>
					<div className="login__input-row">
						<Icon name="key" />
						<Input name="passcode" onEnter={this._onClickOk} placeholder={PASSCODE} />
					</div>
				</div>
				<div className="login__error-space">{errorBit}</div>
				<div className="login__otherbuttons">
					<Button text={OK} onClick={this._onClickOk} />
					<Button level="secondary" text={BACK} onClick={onClickBack} />
				</div>
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	loggingIn: state.linkedAccess.connecting,
	error: state.linkedAccess.error,
})
const actions = {
	loginAsObserver: Actions.login.loginAsObserver,
	clearErrors: Actions.misc.clearErrors,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<LoginObserverProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(LoginObserver)
