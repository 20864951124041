import { useEffect, useRef } from 'react'

/*
This hook will automatically scroll an element to its bottom when the numItem increases.
It returns a React ref to be given to the scrollable element.
*/
export default function useAutoScrollBottom(numItems: number): React.MutableRefObject<HTMLDivElement> {
	const scrollerRef = useRef<HTMLDivElement>(null)
	const prevLength = useRef(numItems)
	useEffect(() => {
		if (!scrollerRef.current) return
		if (numItems > prevLength.current) {
			scrollerRef.current.scrollIntoView({ behavior: 'smooth' })
		}
		prevLength.current = numItems
	}, [numItems])

	return scrollerRef
}
