// =============================================================================
// Components > Tutor Sidebar
// =============================================================================

import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import SidebarButton from '../elements/SidebarButton'
import inCallSelector from '../selectors/inCall'
import config from '../../config'
import Actions from '../actions'

import loggedInAsFacilitatorSelector from '../selectors/loggedInAsFacilitator'
import loggedInAsObserverSelector from '../selectors/loggedInAsObserver'

type SidebarProps = {
	linkedAccess?: boolean
	signout?: () => void
	viewingPreviousSession?: boolean
	returnToCurrentSession?: () => void
	messagesUnread?: boolean
	inCall?: boolean
	location?: string
	navigateTo?: (location: string) => void
	loggedInAsFacilitator: boolean
	loggedInAsObserver: boolean
	displayModalPopup: (popupId: string, parms?: SimpleObject | string) => void
}

const Sidebar: FunctionComponent<SidebarProps> = props => {
	const { linkedAccess, signout, viewingPreviousSession, returnToCurrentSession, inCall, displayModalPopup } = props
	const { loggedInAsFacilitator, loggedInAsObserver } = props

	function _renderButton(name: string, iconName: string, onClick = null) {
		const { messagesUnread, location, navigateTo } = props
		const _onClick = onClick || (() => navigateTo(`tutor-${name}`))
		const label = config.strings[name.toUpperCase()]
		const selected = location && location.indexOf(`-${name}`) > 0
		const showBadge = (name === 'groups' && messagesUnread) || (name === 'videoconf' && inCall)

		return (
			<SidebarButton
				key={name}
				name={name}
				label={label}
				onClick={_onClick}
				iconName={iconName}
				selected={selected}
				showBadge={showBadge}
			/>
		)
	}

	let topButtons = []
	let bottomButtons = []
	if (loggedInAsFacilitator || loggedInAsObserver) {
		topButtons = [
			!linkedAccess && _renderButton('cases', 'briefcase'),
			!linkedAccess && _renderButton('sessions', 'clipboard-list'),
			linkedAccess && _renderButton('initialinformationpage', 'clipboard-list'),
			// _renderButton('messages', 'comments-alt'),
			_renderButton('groups', 'users'),
			_renderButton('media', 'images'),
			_renderButton('passcodes', 'key'),
			!linkedAccess && _renderButton('linkedaccesspasswords', 'user-plus'),
			_renderButton('participants', 'user'),
			_renderButton('videoconf', 'video'),
			viewingPreviousSession && _renderButton('RETURN_TO_CURRENT', 'arrow-circle-left', returnToCurrentSession),
		]

		bottomButtons = [
			!linkedAccess && _renderButton('settings', 'cog'),
			_renderButton('prevsessions', 'history'),
			_renderButton('signout', 'power-off', signout),
		]
	} else {
		bottomButtons = [
			_renderButton('prevsessions', 'history', () => displayModalPopup('modal-prevsessions')),
			_renderButton('signout', 'power-off', signout),
		]
	}

	return (
		<div className="sidebar">
			<div className="sidebar__top"> {topButtons} </div>
			<div className="sidebar__bottom"> {bottomButtons} </div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<SidebarProps> => ({
	location: state.navigatingTo,
	messagesUnread: state.messagesUnread,
	linkedAccess: state.linkedAccess && state.linkedAccess.connected,
	viewingPreviousSession: state.viewingPreviousSession,
	inCall: inCallSelector(state),
	loggedInAsFacilitator: loggedInAsFacilitatorSelector(state),
	loggedInAsObserver: loggedInAsObserverSelector(state),
})
const actions = {
	signout: Actions.login.signOut,
	navigateTo: Actions.misc.navigateTo,
	returnToCurrentSession: Actions.sessions.returnToCurrentSession,
	displayModalPopup: Actions.misc.displayModalPopup,
}
export default connect(mapStateToProps, actions)(Sidebar)
