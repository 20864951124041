/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import config from '../../config'

import selectedMediaSelector from '../selectors/selectedMedia'
import getMediaUrlForData from '../util/getMediaUrlForData'
import MediaLibrary from '../core/MediaLibrary'
import Preloader from '../elements/Preloader'
import Button from '../elements/Button'
import Row from '../elements/Row'
import Actions from '../actions'
import { sessionIsActive } from '../util/SessionHelpers'
import sessionSelector from '../selectors/session'
import API from '../core/OnlineComms'

const IMAGE_TYPES = ['.png', '.jpg', '.jpeg', '.gif', '.tif']
const VIDEO_TYPES = ['.mp4']
const AUDIO_TYPES = ['.m4a', '.mp3']

// =================================================================================================

const Video: FunctionComponent<{ src: string; onClick: () => void }> = ({ src, onClick }) => (
	<video
		autoPlay
		controls
		disablePictureInPicture
		controlsList="nodownload nofullscreen noremoteplayback"
		onClick={e => {
			const elem = e.target as HTMLVideoElement
			elem.pause()
			onClick()
		}}
	>
		<source src={src} />
	</video>
)

// =================================================================================================

const Audio: FunctionComponent<{ src: string }> = ({ src }) => (
	<audio controls controlsList="nodownload">
		<source src={src} />
	</audio>
)

// =================================================================================================

const PDF: FunctionComponent<{ src: string }> = ({ src }) => (
	<iframe className="pdf-iframe" title="Document" src={`${src}#toolbar=0&view=FitH`} />
)

// =================================================================================================

type MediaViewerProps = {
	scheduleRow: ScheduleRow
	mediaItem: MediaItem
	getMediaData: (id: string) => Promise<Blob>
	expandMedia: (expanded: boolean) => void
	closeMedia: () => void
	displayModalPopup?: (name: string, params?: SimpleObject) => void
	sessionActive: boolean
	mediaExpanded: boolean
}

const MediaViewer: FunctionComponent<MediaViewerProps> = props => {
	const { mediaItem, closeMedia, getMediaData, scheduleRow, sessionActive } = props
	const { mediaExpanded, expandMedia, displayModalPopup } = props
	const [mediaUrl, setMediaUrl] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [update, setUpdate] = useState(scheduleRow?.type === 'update' && scheduleRow?.update)

	const { NEWSTYPE_UPDATE, COLLAPSE, EXPAND, CLOSE } = config.strings

	useEffect(() => {
		if (!scheduleRow || !scheduleRow.key) return
		if (scheduleRow?.type === 'update') {
			setLoading(false)
			setUpdate(scheduleRow?.update)
			return
		}
		if (!mediaItem) {
			setLoading(false)
			return
		}
		setLoading(true)

		// Don't use media library (cache) for PDFs
		if (mediaItem.extension === '.pdf') {
			API.getMediaUrl('', mediaItem.id).then(response => {
				setMediaUrl(response.url)
				setLoading(false)
			})
			return
		}

		const mediaFromLibrary = MediaLibrary.get(mediaItem.id)
		if (mediaFromLibrary) {
			const url = getMediaUrlForData(mediaFromLibrary.data, mediaItem.extension)
			setMediaUrl(url)
			setLoading(false)
			return
		}

		// We need to get the media data/URL and then update this in state so we can re-render
		getMediaData(mediaItem.id)
			.then(data => {
				setLoading(false)
				setMediaUrl(getMediaUrlForData(data, mediaItem.extension))
			})
			.catch(err => {
				console.error(err)
				setLoading(false)
				setError('Media could not be loaded')
			})
	}, [mediaItem, scheduleRow])

	let content = null

	const title = mediaItem ? mediaItem.name : NEWSTYPE_UPDATE

	if (mediaItem && mediaUrl) {
		const { extension } = mediaItem
		const ext = extension.toLowerCase()
		// if (ext === '.pdf') content = <PDFViewer src={mediaUrl} />
		if (ext === '.pdf') content = <PDF src={mediaUrl} />
		if (IMAGE_TYPES.includes(ext))
			content = <img src={mediaUrl} alt={title} onClick={() => displayModalPopup('modal-media', mediaItem)} />
		if (VIDEO_TYPES.includes(ext))
			content = <Video src={mediaUrl} onClick={() => displayModalPopup('modal-media', mediaItem)} />
		if (AUDIO_TYPES.includes(ext)) content = <Audio src={mediaUrl} />
	} else {
		content = <p>{update}</p>
	}

	// Display preloader if media is loading
	if (loading) content = <Preloader />
	// Display error if media failed to load
	if (error) content = <p>{error}</p>

	const showExpandButton = sessionActive && mediaItem?.extension === '.pdf'

	let className = 'media-viewer'
	if (mediaExpanded && showExpandButton) className += ' media-viewer--expanded'

	const onClickExpand = () => {
		expandMedia(!mediaExpanded)
	}

	const onClickClose = () => {
		closeMedia()
		expandMedia(false)
	}

	return (
		<div className={className} aria-label="Media viewer">
			<div className="media-viewer__media-container">{content}</div>
			<Row className="media-viewer__footer">
				<label>{title}</label>
				<Row className="media-viewer__buttons">
					{showExpandButton && (
						<Button icon={mediaExpanded ? 'chevrons-up' : 'chevrons-down'} onClick={onClickExpand}>
							{mediaExpanded ? COLLAPSE : EXPAND}
						</Button>
					)}
					<Button icon="xmark" onClick={onClickClose}>
						{CLOSE}
					</Button>
				</Row>
			</Row>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
let cache = {}
// For this particular component, we cache the result of this selector function. This is because we
// noticed an issue whereby the MediaViewer would rerender (and noticeably redisplay) when a new
// media item is received by the participant, despite the contents of the media viewer remaining
// the same. If we cache the result, we can check if the items in state have actually changed, or
// if hte items in state are just new *objects* containing the same data as they did before.
const mapStateToProps = (state: StateTree) => {
	const { selectedRow: scheduleRow, mediaItem } = selectedMediaSelector(state)
	const { viewingPreviousSession } = state
	const session = sessionSelector(state)
	const sessionActive = !viewingPreviousSession && sessionIsActive(session)
	const { mediaExpanded } = state
	const result = { scheduleRow, mediaItem, sessionActive, mediaExpanded }

	if (JSON.stringify(result) === JSON.stringify(cache)) return cache
	cache = result

	return result
}

const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	getMediaData: Actions.media.getMediaData,
	expandMedia: Actions.media.expandMedia,
	closeMedia: Actions.media.closeMedia,
}

type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<MediaViewerProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(MediaViewer)
