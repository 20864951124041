import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import SideCallTwilio from './SideCallTwilio'
import SideCallAgora from './SideCallAgora'
import SideCallJitsi from './SideCallJitsi'

type SideCallProps = {
	displayName: string
	serverId: string
	callId: string
	domain: string
}

const SideCall: FunctionComponent<SideCallProps> = props => {
	const { serverId, callId, domain, displayName } = props

	if (serverId === 'twilio') return <SideCallTwilio callId={callId} domain={domain} displayName={displayName} />

	if (serverId === 'agora') return <SideCallAgora callId={callId} domain={domain} displayName={displayName} />

	return <SideCallJitsi callId={callId} domain={domain} displayName={displayName} />
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	serverId: state.videoconf?.serverId,
})
const actions = {}
export default connect(mapStateToProps, actions)(SideCall)
