import currentCaseSelector from './currentCase'
import sessionSelector from './session'

function selectedMediaSelector(state: StateTree): { selectedRow: ScheduleRow; mediaItem: MediaItem } {
	const { selectedScheduleRowId } = state
	const session = sessionSelector(state)
	const schedule = session?.schedule || []
	const scheduleRow = schedule.find(r => r.id === selectedScheduleRowId) || ({} as ScheduleRow)
	const { media } = currentCaseSelector(state) || {}
	const mediaItem = scheduleRow.type === 'media' && media?.find(m => m.id === scheduleRow.mediaitem)

	return { selectedRow: scheduleRow, mediaItem }
}

export default selectedMediaSelector
