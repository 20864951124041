import config from '../../config'

import types from './types'
import action from '../util/action'
import miscActions from './misc'

import loggedInAsObserverSelector from '../selectors/loggedInAsObserver'

const groupConnected = (details: SimpleObject) => (dispatch: Dispatch): void => {
	dispatch(action(types.SOCKET_GROUP_CONNECTED, details))
}

const groupDisconnected = (details: SimpleObject) => (dispatch: Dispatch): void => {
	dispatch(action(types.SOCKET_GROUP_DISCONNECTED, details))
}

const mainCallStarted = (details: SimpleObject) => (dispatch: Dispatch, getState: GetState): void => {
	// const socketPayload = { modId, mainCallId, mainCallStarted, mainCallWarningEnabled }

	// Close any active modal popup
	const state = getState()

	const loggedInAsObserver = loggedInAsObserverSelector(state)
	const loggedInAsFacilitator = state.tutor && state.tutor.loggedIn

	// If logged in as a facilitator or observer, redirect to the videoconf page
	if (loggedInAsFacilitator || loggedInAsObserver) miscActions.navigateTo('tutor-videoconf')(dispatch, getState)

	dispatch(action(types.SOCKET_MAIN_CALL_STARTED, details))
}

const mainCallEnded = () => (dispatch: Dispatch): void => {
	dispatch(action(types.SOCKET_MAIN_CALL_ENDED, {}))
}

const breakoutRoomsActivated = (details: SimpleObject) => (dispatch: Dispatch): void => {
	const { callId } = details
	dispatch(action(types.SOCKET_BREAKOUT_ROOMS_ACTIVATED, { callId }))
}

const breakoutRoomsDeactivated = (_details: SimpleObject) => (dispatch: Dispatch): void => {
	dispatch(action(types.SOCKET_BREAKOUT_ROOMS_DEACTIVATED, {}))
}

const observerBreakoutRoomActivated = (details: SimpleObject) => (dispatch: Dispatch): void => {
	const { callId } = details
	dispatch(action(types.SOCKET_OBSERVER_BREAKOUT_ACTIVATED, { callId }))
}

const observerBreakoutRoomDeactivated = (_details: SimpleObject) => (dispatch: Dispatch): void => {
	dispatch(action(types.SOCKET_OBSERVER_BREAKOUT_DEACTIVATED))
}

const chatOpened = (_details: SimpleObject) => (dispatch: Dispatch): void => {
	dispatch(action(types.SOCKET_CHAT_OPENED, {}))
}

const chatClosed = (_details: SimpleObject) => (dispatch: Dispatch): void => {
	dispatch(action(types.SOCKET_CHAT_CLOSED, {}))
}

const phoneLogUpdate = (_details: SimpleObject) => (dispatch: Dispatch, getState: GetState): void => {
	dispatch(action(types.SOCKET_PHONELOGUPDATE, _details))

	console.log('_details?.status', _details?.status)
	// If a new call has been initiated, immediately open it up
	if (_details?.status === 'ringing') {
		const state = getState()
		const rowId = _details?.scheduleRowId
		console.log('rowId', rowId)
		const groupId = state?.group?.id
		console.log('groupId', groupId)
		const participantId = state?.group?.participantId
		console.log('participantId', participantId)
		const sessionId = state?.groupData?.currentSession?.id
		console.log('sessionId', sessionId)
		if (rowId && groupId && participantId && sessionId) {
			dispatch(action(types.GROUP_OPENED_ITEM, { rowId, groupId, participantId, sessionId, markAsOpened: true }))
		}
	}
}

const interpreterChangeChannel = (details: { clientId: string; onMainChannel: boolean }) => (
	dispatch: Dispatch
): void => {
	dispatch(action(types.VIDEOCONF_INTERPRETER_CHANGED_CHANNEL, details))
}

const participantColourChange = (details: SimpleObject) => (dispatch: Dispatch, getState: GetState): void => {
	const state = getState()
	const { name } = state.group || {}
	if (config.SHOW_INFO_IN_WINDOW_TITLE) {
		document.title = `${name} - ${details.colour}`
	}
	dispatch(action(types.SOCKET_PARTICIPANT_COLOUR_CHANGE, details))
}

export default {
	observerBreakoutRoomDeactivated,
	observerBreakoutRoomActivated,
	interpreterChangeChannel,
	breakoutRoomsDeactivated,
	participantColourChange,
	breakoutRoomsActivated,
	groupDisconnected,
	mainCallStarted,
	groupConnected,
	phoneLogUpdate,
	mainCallEnded,
	chatOpened,
	chatClosed,
}
