import React, { FunctionComponent, useState } from 'react'
import { useInterval } from 'react-use'
import { connect } from 'react-redux'

import { sessionSecondsLeft } from '../../util/SessionHelpers'
import sessionSelector from '../../selectors/session'

import NewMediaAlert from './NewMediaAlert'

type AppHeaderBarProps = {
	session: CurrentSession | PreviousSession
	onClickAlert?: () => void
	active?: boolean
}

const AppHeaderBar: FunctionComponent<AppHeaderBarProps> = props => {
	const { session, onClickAlert, active } = props

	// Format the time
	const secsLeft = sessionSecondsLeft(session)
	const [timeRemaining, setTimeRemaining] = useState(secsLeft || 0)
	useInterval(() => setTimeRemaining(sessionSecondsLeft(session) || 0), 1)

	const mins = String(Math.floor(timeRemaining / 60)).padStart(2, '0')
	const secs = String(timeRemaining % 60).padStart(2, '0')
	const timeFormatted = `${mins}:${secs}`

	let className = 'app-header-bar'
	if (timeRemaining === 0) {
		className += ' app-header-bar--zero'
	}

	// Every second update the number of seconds remaining
	return (
		<>
			<div className={className}>
				<div>{timeFormatted}</div>
			</div>
			<NewMediaAlert active={active} onClickAlert={onClickAlert} />
		</>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const session = sessionSelector(state)
	return {
		session,
	}
}
const actions = {}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<AppHeaderBarProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(AppHeaderBar)
