import React, { ReactElement } from 'react'

import ReactSwitch from 'react-switch'

type SwitchProps = {
	value: boolean
	onChange?: (checked: boolean) => void
	disabled?: boolean
}

function Switch(props: SwitchProps): ReactElement<SwitchProps> {
	const { value, onChange, disabled = false } = props
	return (
		<ReactSwitch
			className="switch react-switch"
			onChange={checked => {
				if (onChange) onChange(checked)
			}}
			checked={value}
			handleDiameter={22}
			onColor="#267a26"
			offColor="#555555"
			disabled={disabled}
		/>
	)
}

export default Switch
