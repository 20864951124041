import React, { FunctionComponent } from 'react'

type PillProps = {
	className?: string
}

const Pill: FunctionComponent<PillProps> = props => {
	const { className, children } = props
	return <div className={`pill ${className || ''}`}>{children}</div>
}

export default Pill
