{
  "name": "twilio-video",
  "title": "Twilio Video",
  "description": "Twilio Video JavaScript Library",
  "version": "2.28.1",
  "homepage": "https://twilio.com",
  "author": "Mark Andrus Roberts <mroberts@twilio.com>",
  "contributors": [
    "Ryan Rowland <rrowland@twilio.com>",
    "Manjesh Malavalli <mmalavalli@twilio.com>",
    "Makarand Patwardhan <mpatwardhan@twilio.com>"
  ],
  "keywords": [
    "twilio",
    "webrtc",
    "library",
    "javascript",
    "video",
    "rooms"
  ],
  "repository": {
    "type": "git",
    "url": "https://github.com/twilio/twilio-video.js.git"
  },
  "devDependencies": {
    "@babel/core": "^7.14.2",
    "@babel/plugin-proposal-class-properties": "^7.18.6",
    "@babel/plugin-proposal-object-rest-spread": "^7.20.7",
    "@babel/preset-env": "^7.14.2",
    "@babel/preset-typescript": "^7.13.0",
    "@types/express": "^4.11.0",
    "@types/node": "^8.5.1",
    "@types/selenium-webdriver": "^3.0.8",
    "@types/ws": "^3.2.1",
    "@typescript-eslint/eslint-plugin": "^4.13.0",
    "@typescript-eslint/parser": "^4.0.0",
    "babel-cli": "^6.26.0",
    "babel-preset-es2015": "^6.24.1",
    "browserify": "^17.0.0",
    "cheerio": "^0.22.0",
    "cors": "^2.8.5",
    "electron": "^17.2.0",
    "envify": "^4.0.0",
    "eslint": "^6.2.1",
    "eslint-config-standard": "^14.0.0",
    "eslint-plugin-import": "^2.18.2",
    "eslint-plugin-node": "^9.1.0",
    "eslint-plugin-promise": "^4.2.1",
    "eslint-plugin-standard": "^4.0.1",
    "express": "^4.16.2",
    "glob": "^7.1.7",
    "ink-docstrap": "^1.3.2",
    "inquirer": "^7.0.0",
    "is-docker": "^2.0.0",
    "jsdoc": "^3.5.5",
    "jsdoc-babel": "^0.5.0",
    "json-loader": "^0.5.7",
    "karma": "6.4.1",
    "karma-browserify": "^8.0.0",
    "karma-chrome-launcher": "^2.0.0",
    "karma-edgium-launcher": "^4.0.0-0",
    "karma-electron": "^6.1.0",
    "karma-firefox-launcher": "^1.3.0",
    "karma-htmlfile-reporter": "^0.3.8",
    "karma-junit-reporter": "^1.2.0",
    "karma-mocha": "^1.3.0",
    "karma-safari-launcher": "^1.0.0",
    "karma-spec-reporter": "0.0.32",
    "karma-typescript": "^5.5.1",
    "karma-typescript-es6-transform": "^5.5.1",
    "mocha": "^3.2.0",
    "mock-require": "^3.0.3",
    "ncp": "^2.0.0",
    "node-http-server": "^8.1.2",
    "npm-run-all": "^4.0.2",
    "nyc": "^15.1.0",
    "requirejs": "^2.3.3",
    "rimraf": "^2.6.1",
    "simple-git": "^1.126.0",
    "sinon": "^4.0.1",
    "ts-node": "4.0.1",
    "tslint": "5.8.0",
    "twilio": "^3.49.0",
    "twilio-release-tool": "^1.0.2",
    "typescript": "4.2.2",
    "uglify-js": "^2.8.22",
    "vinyl-fs": "^2.4.4",
    "vinyl-source-stream": "^1.1.0",
    "watchify": "^3.11.1",
    "webrtc-adapter": "^7.7.1"
  },
  "engines": {
    "node": ">=0.12"
  },
  "license": "BSD-3-Clause",
  "main": "./es5/index.js",
  "types": "./tsdef/index.d.ts",
  "scripts": {
    "lint:js": "eslint ./lib ./test/*.js ./docker/**/*.js ./test/framework/*.js ./test/lib/*.js ./test/integration/** ./test/unit/** ",
    "lint:ts": "eslint ./tsdef/*.ts ./lib/**/*.ts",
    "lint": "npm-run-all lint:js lint:ts",
    "printVersion": "node --version && npm --version",
    "test:unit": "npm-run-all printVersion build:es5 && nyc --report-dir=./coverage --include=lib/**/* --reporter=html --reporter=lcov --reporter=text mocha -r ts-node/register ./test/unit/*",
    "test:unit:quick": "nyc --report-dir=./coverage --include=lib/**/* --reporter=html --reporter=lcov mocha -r ts-node/register",
    "test:serversiderender": "mocha ./test/serversiderender/index.js",
    "test:integration:adapter": "node ./scripts/karma.js karma/integration.adapter.conf.js",
    "test:integration": "npm run build:es5 && node ./scripts/karma.js karma/integration.conf.js",
    "test:umd:install": "npm install puppeteer@19.2.2",
    "test:umd": "mocha ./test/umd/index.js",
    "test:crossbrowser:build:clean": "rimraf ./test/crossbrowser/lib ./test/crossbrowser/src/browser/index.js",
    "test:crossbrowser:build:lint": "cd ./test/crossbrowser && tslint --project tsconfig.json",
    "test:crossbrowser:build:tsc": "cd ./test/crossbrowser && tsc",
    "test:crossbrowser:build:browser": "cd ./test/crossbrowser && browserify lib/crossbrowser/src/browser/index.js > src/browser/index.js",
    "test:crossbrowser:build": "npm-run-all test:crossbrowser:build:*",
    "test:crossbrowser:test": "cd ./test/crossbrowser && mocha --compilers ts:ts-node/register test/integration/spec/**/*.ts",
    "test:crossbrowser": "npm-run-all test:crossbrowser:*",
    "test:sdkdriver:build:clean": "rimraf ./test/lib/sdkdriver/lib ./test/lib/sdkdriver/test/integration/browser/index.js",
    "test:sdkdriver:build:lint": "cd ./test/lib/sdkdriver && tslint --project tsconfig.json",
    "test:sdkdriver:build:tsc": "cd ./test/lib/sdkdriver && tsc --rootDir src",
    "test:sdkdriver:build": "npm-run-all test:sdkdriver:build:*",
    "test:sdkdriver:test:unit": "cd ./test/lib/sdkdriver && mocha --compilers ts:ts-node/register test/unit/spec/**/*.ts",
    "test:sdkdriver:test:integration:browser": "cd ./test/lib/sdkdriver/test/integration && browserify browser/browser.js > browser/index.js",
    "test:sdkdriver:test:integration:run": "cd ./test/lib/sdkdriver && mocha --compilers ts:ts-node/register test/integration/spec/**/*.ts",
    "test:sdkdriver:test:integration": "npm-run-all test:sdkdriver:test:integration:*",
    "test:sdkdriver:test": "npm-run-all test:sdkdriver:test:*",
    "test:sdkdriver": "npm-run-all test:sdkdriver:*",
    "test:framework:angular:install": "cd ./test/framework/twilio-video-angular && rimraf ./node_modules package-lock.json && npm install",
    "test:framework:angular:run": "mocha ./test/framework/twilio-video-angular.js",
    "test:framework:angular": "npm-run-all test:framework:angular:*",
    "test:framework:no-framework:run": "mocha ./test/framework/twilio-video-no-framework.js",
    "test:framework:no-framework": "npm-run-all test:framework:no-framework:*",
    "test:framework:react:install": "cd ./test/framework/twilio-video-react && rimraf ./node_modules package-lock.json && npm install",
    "test:framework:react:test": "node ./scripts/framework.js twilio-video-react",
    "test:framework:react:build": "cd ./test/framework/twilio-video-react && npm run build",
    "test:framework:react:run": "mocha ./test/framework/twilio-video-react.js",
    "test:framework:react": "npm-run-all test:framework:react:*",
    "test:framework:install": "npm install chromedriver && npm install selenium-webdriver && npm install geckodriver && npm install puppeteer",
    "test:framework": "npm-run-all test:framework:install test:framework:no-framework test:framework:react",
    "test": "npm-run-all test:unit test:integration",
    "build:es5": "rimraf ./es5 && mkdir -p es5 && tsc tsdef/twilio-video-tests.ts --noEmit --lib es2018,dom && tsc",
    "build:js": "node ./scripts/build.js ./src/twilio-video.js ./LICENSE.md ./dist/twilio-video.js",
    "build:min.js": "uglifyjs ./dist/twilio-video.js -o ./dist/twilio-video.min.js --comments \"/^! twilio-video.js/\" -b beautify=false,ascii_only=true",
    "build": "npm-run-all clean lint docs test:unit test:integration build:es5 build:js build:min.js test:umd",
    "build:quick": "npm-run-all clean lint docs build:es5 build:js build:min.js",
    "docs": "node ./scripts/docs.js ./dist/docs",
    "watch": "tsc -w",
    "clean": "rimraf ./coverage ./es5 ./dist"
  },
  "dependencies": {
    "events": "^3.3.0",
    "util": "^0.12.4",
    "ws": "^7.4.6",
    "xmlhttprequest": "^1.8.0"
  },
  "browser": {
    "ws": "./src/ws.js",
    "xmlhttprequest": "./src/xmlhttprequest.js"
  }
}
