import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import config from '../../../config'
import Actions from '../../actions'

import { IVideoClient, ParticipantDetails, VideoChatMessage, VideoClientEvents } from './VideoTypes'
import MessageEntryElem from '../../elements/MessageEntryElem'
import ChatMessage from './ChatMessage'

type ChatProps = {
	client: IVideoClient
	checkChatMessagesForTranslation?: typeof Actions.videoconf.checkChatMessagesForTranslation
}

const Chat: FunctionComponent<ChatProps> = props => {
	const { client, checkChatMessagesForTranslation } = props
	const { TO, EVERYONE, MESSAGE_ENTRY_PLACEHOLDER } = config.strings

	const [chat, setChat] = useState<VideoChatMessage[]>([])
	const scroller = useRef<HTMLDivElement>()
	const [selected, setSelected] = useState<string>('')
	const [remoteParticipants, setRemoteParticipants] = useState<ParticipantDetails[]>([])

	// Add event handlers to JitsiClient on mount
	useEffect(() => {
		if (!client) return

		setChat([...(client.chat || [])])
		setRemoteParticipants([...(client.remoteParticipants || [])])

		const eventRef1 = client.on(VideoClientEvents.MESSAGE_RECEIVED, () => {
			setChat([...(client.chat || [])])
		})
		const eventRef2 = client.on(VideoClientEvents.REMOTE_PARTICIPANTS_CHANGED, () => {
			// This will force a rerender to update the list of participants to choose from
			setRemoteParticipants([...(client.remoteParticipants || [])])
		})

		// On dismount, remove event handlers
		return () => {
			client.off(eventRef1)
			client.off(eventRef2)
		}
	}, [client])

	// Side-effects for when the chat is updated
	useEffect(() => {
		// Scroll to bottom of scroller
		scroller.current.scrollTop = scroller.current.scrollHeight
		checkChatMessagesForTranslation(chat, remoteParticipants)
	}, [chat, remoteParticipants])

	const chatElems = chat.map(msg => <ChatMessage key={msg.timestamp} message={msg} client={client} />)

	return (
		<div className="chat">
			<div ref={scroller} className="chat__scroller">
				{chatElems}
			</div>
			<div className="chat__recipient-row">
				<label>{TO}:</label>
				<div className="input">
					<select onChange={e => setSelected(e.target.value)} value={selected}>
						<option value="">{EVERYONE}</option>
						{remoteParticipants.map(p => (
							<option key={p.participantId} value={p.participantId}>
								{p.displayName}
							</option>
						))}
					</select>
				</div>
			</div>
			<MessageEntryElem
				placeholder={MESSAGE_ENTRY_PLACEHOLDER}
				onSubmit={message => client.sendChatMessage(message, selected)}
			/>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = () => ({})
const actions = {
	checkChatMessagesForTranslation: Actions.videoconf.checkChatMessagesForTranslation,
}
export default connect(mapStateToProps, actions)(Chat)
