import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import config from '../../../config'
import Actions from '../../actions'

import getTextDirection from '../../util/getTextDirection'
import convertColour from '../../util/convertColour'
import getTime from '../../util/getTime'

import loggedInAsParticipantSelector from '../../selectors/loggedInAsParticipant'
import loggedInAsGroupSelector from '../../selectors/loggedInAsGroup'
import currentCaseSelector from '../../selectors/currentCase'
import sessionSelector from '../../selectors/session'

import IconButton from '../../elements/IconButton'
import Icon from '../../elements/Icon'
import getIconForMediaItem from '../../util/getIconForMediaItem'

const TWENTY_SECONDS = 20 * 1000

const { OPENED_AT } = config.strings

// =================================================================================================

function formatTime(time: number | string): string {
	return new Date(time).toTimeString().substring(0, 5)
}

// =================================================================================================

type NotificationProps = {
	item?: ReleaseItem
	session?: CurrentSession | PreviousSession
	groupId?: string
	openItem?: (rowId: string, sessionId: string) => void
	openings?: Opening[]
	caseMedia?: MediaItem[]
	participantId?: string
	displayModalPopup?: (name: string, params?: SimpleObject) => void
	viewingPreviousSession?: boolean
	loggedInAsGroup?: boolean
	loggedInAsParticipant?: boolean
	phoneNumber?: string
	flashingEnabled?: boolean
}

const Notification: FunctionComponent<NotificationProps> = props => {
	const rootClassName = 'notification'
	let className = rootClassName

	const { item, session, groupId, openItem, openings, caseMedia, participantId, flashingEnabled } = props
	const { displayModalPopup, loggedInAsGroup, loggedInAsParticipant } = props

	// Get associated media
	const mediaItemId = (item.mediaitem || '').toString()
	let mediaitem = item.mediaitem ? caseMedia.find(m => m.timestamp.toString() === mediaItemId) : null

	// Return null if this is an invalid newfeed item
	if (item.type === 'media' && !mediaitem) return null
	if (item.type !== 'media') mediaitem = null

	// Get icon to display
	const { icon, colour: iconColour } = getIconForMediaItem(mediaitem)

	// Get title to show - either media item name or just "Update received"
	const ITEM_TYPE = item.type.toUpperCase()
	const title = mediaitem ? mediaitem.name : config.strings[`NEWSTYPE_${ITEM_TYPE}`]

	// Check if item has been opened by this participant
	const { rowId } = item
	const sessionId = session.id
	// Get list of openings. There might be multiple if there are multiple participants in team
	let itemOpenings = openings.filter(o => o.key === rowId && o.sessionId === sessionId && o.groupId === groupId)
	if (participantId) {
		itemOpenings = itemOpenings.filter(o => o.participantId === participantId)
	}
	// Find earliest opening
	itemOpenings.sort((a, b) => (a.lastUpdate < b.lastUpdate ? -1 : 1))
	const opening = itemOpenings[0]

	const seen = Boolean(opening)
	if (seen) {
		className += ' notification--seen'
	}

	// Check if item is recent
	const now = getTime()
	const timeNum = parseInt(item.time.toString(), 10)
	const recent = timeNum > now - TWENTY_SECONDS && timeNum < now
	if (recent && !seen && flashingEnabled) {
		className += ' notification--recent'
	}

	// -----------------------------------------------------------------------------------------------
	function onClickMedia() {
		// If this isn't a previous session, mark media as opened
		if (loggedInAsGroup || loggedInAsParticipant) {
			openItem(item.rowId, session.id)
		}
		// if (mediaitem.phoneEnabled && phoneNumber) {
		// 	return displayModalPopup('modal-media-for-phone', { mediaitem, rowId: item.rowId })
		// }
	}

	// -----------------------------------------------------------------------------------------------
	// Check if we shall show a button for the facilitator to display more details about who
	// has opened the media item
	const showOpeningsInfoModal = Boolean(
		itemOpenings.length && itemOpenings[0].participantId && !loggedInAsGroup && !loggedInAsParticipant
	)

	const onClickOpeningsInfo = e => {
		e.stopPropagation()
		displayModalPopup('modal-media-openings', { rowId: item.rowId, sessionId: session.id })
	}
	// -----------------------------------------------------------------------------------------------

	const openTime = opening ? `${OPENED_AT} ${formatTime(opening.lastUpdate)}` : ''

	return (
		<div className={className} onClick={onClickMedia} role="button" onKeyPress={onClickMedia} tabIndex={0}>
			<div className="notification__icon-container" style={{ backgroundColor: convertColour(iconColour) }}>
				<Icon name={icon} />
			</div>
			<div className="notification__details">
				<div className="left">
					<h3 className="notification__title" title={title} style={{ direction: getTextDirection(title) }}>
						{title}
					</h3>
					<div className="row">
						<div className="notification__time">{formatTime(item.time)}</div>
						{openTime && <div className="notification__opentime">{openTime}</div>}
						{openTime && <Icon name="check" className="notification__openicon" />}
					</div>
				</div>
				{showOpeningsInfoModal && (
					<div className="right">
						<IconButton iconName="users" onClick={onClickOpeningsInfo} />
					</div>
				)}
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<NotificationProps> => {
	const currentCase = currentCaseSelector(state) || ({} as OpenCaseDetails)
	const viewingPreviousSession = Boolean(state.viewingPreviousSession)

	const group = state.group || ({} as GroupDetails)

	return {
		loggedInAsGroup: loggedInAsGroupSelector(state),
		loggedInAsParticipant: loggedInAsParticipantSelector(state),
		caseMedia: currentCase.media || [],
		participantId: group.participantId,
		session: sessionSelector(state),
		openings: state.openings || [],
		viewingPreviousSession,
		phoneNumber: group?.phoneNumber,
		flashingEnabled: state.settings.flashingEnabled,
	}
}
const actions: Partial<NotificationProps> = {
	openItem: Actions.groups.openItem,
	displayModalPopup: Actions.misc.displayModalPopup,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Pick<NotificationProps, 'item' | 'groupId'>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(Notification)
