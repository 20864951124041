import React, { FunctionComponent } from 'react'

import LoginButton from '../elements/LoginButton'
import config from '../../config'

const { strings } = config

type LoginMainProps = {
	onClickTutor: () => void
	onClickLinkedAccess: () => void
	onClickParticipant: () => void
}

// {@link ../style/views/_Login.scss}

const LoginMain: FunctionComponent<LoginMainProps> = props => {
	const { onClickTutor, onClickLinkedAccess, onClickParticipant } = props
	const { TUTOR, SUPERVISOR, PARTICIPANT } = strings

	return (
		<div className="login__main">
			<h2>Please select your role</h2>
			<div className="login__buttons">
				<LoginButton iconName="user" text={PARTICIPANT} onClick={onClickParticipant} />
				{/* <LoginButton iconName="users" text={TEAM} onClick={onClickGroup} /> */}
				<LoginButton iconName="chalkboard-teacher" text={TUTOR} onClick={onClickTutor} />
				<LoginButton onClick={onClickLinkedAccess} iconName="user-plus" text={SUPERVISOR} />
			</div>
		</div>
	)
}

export default LoginMain
