import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import convertColour from '../util/convertColour'
import Icon from '../elements/Icon'

// =================================================================================================

type GroupColourDotProps = {
	groupColour: string
}

const GroupColourDot: FunctionComponent<GroupColourDotProps> = props => {
	const { groupColour } = props
	const style = { backgroundColor: convertColour(groupColour) }
	return (
		<div className="group-colour-dot" style={style}>
			<Icon name="user" />
		</div>
	)
}

// =================================================================================================

const mapStateToProps = (state: StateTree) => ({
	groupColour: (state.group || {}).colour || '',
})
export default connect(mapStateToProps, {})(GroupColourDot)
