import React, { FunctionComponent } from 'react'
import Icon from './Icon'

type PhonePreloaderProps = {
	idle?: boolean
}

const PhonePreloader: FunctionComponent<PhonePreloaderProps> = ({ idle }) => (
	<div className={`phone-preloader ${idle ? 'phone-preloader--idle' : ''}`}>
		<div className="phone-preloader__ring1" />
		<Icon name="phone" />
		<div className="phone-preloader__ring2" />
	</div>
)

export default PhonePreloader
