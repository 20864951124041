export default {
	OK: `OK`,
	CANCEL: `Canslo`,
	TUTOR: `Hwylusydd`,
	CURRENT_SESSION: `Sesiwn cyfredol:`,
	TEAM: `Tîm`,
	SIGNOUT: `Allgofnodi`,
	INITIAL_UPDATE: `Gwybodaeth sesiwn`,
	ADVICE: `Ymateb`,
	RATIONALE: `Sail resymegol`,
	MEDIA_FEED: `Ffrwd y cyfryngau`,
	MESSAGES: `Negeseuon`,
	PREVSESSIONS: `Sesiynau blaenorol`,
	RETURN_TO_CURRENT: `Dychwelyd at y sesiwn cyfredol`,
	SESSION_IS_PAUSED: `Mae'r sesiwn AR SAIB`,
	ATTRIB_CURR_AUTHOR: `Mewngofnodwr`,
	SAVE: `Cadw`,
	CHANGE: `Newid`,
	NO_AUTHOR: `Heb ei osod`,
	NO_INITIAL_INFO: `Arhoswch i'r sesiwn nesaf ddechrau`,
	DECISION_MAKER_SELECT: `Dewiswch gyfranogwr`,
	SESSION_COMPLETE: `Mae'r sesiwn blaenorol wedi gorffen`,
	OPENED_AT: `Wedi'i agor`,
	SEND_MESSAGE_PLACEHOLDER: `Ysgrifennu neges`,
	SEND: `Anfon`,
	TO_ALL_GROUPS: `at yr holl dimau`,
	SHOW_TRANSLATION: `Dangos y cyfieithiad`,
	SHOW_ORIGINAL: `Dangos y gwreiddiol`,
	CREDENTIALS_EXPIRED: `Mae eich manylion wedi dod i ben. Dylech fewngofnodi eto.`,
	INCOMING_MESSAGE: `Neges yn cyrraedd`,
	INCOMING_MESSAGE_TEXT: `Derbyniwyd neges newydd`,
	INCOMING_MEDIA: `Eitem gyfryngol yn cyrraedd`,
	INCOMING_MEDIA_TEXT: `Derbyniwyd eitem gyfryngol newydd`,
	INCOMING_UPDATE: `Diweddariad yn cyrraedd`,
	INCOMING_UPDATE_TEXT: `Derbyniwyd diweddariad`,
	CONFIRM_LOGOUT: `Ydych chi'n siŵr yr hoffech chi allgofnodi?`,
	MUTED_MESSAGE: `Mae eich sain wedi'i diffodd ar hyn o bryd`,
	NEWSTYPE_UPDATE: `Derbyniwyd diweddariad`,
	WARNING_TIME_MSG: `Mae 5 munud ar ôl`,
	VIEWING_PREVIOUS: `Sesiwn gwylio $$ o %%%`,
	CLOSE: `Cau`,
	CONTINUE: `Parhau`,
	VIEW_INITIAL_UPDATE: `Gweld gwybodaeth y sesiwn`,
	INITIAL_UPDATE_INDEX: `Gwybodaeth sesiwn %%%`,
	TO: `At`,
	EVERYONE: `Pawb`,
	MESSAGE_ENTRY_PLACEHOLDER: `Neges`,
	DEFAULT_VIDEO_WARNING_MESSSAGE: `Galwad fideo'n dechrau'n fuan`,
	DEFAULT_VIDEO_WARNING_TITLE: `Rhybudd`,
	SESSION_INFO_RECEIVED: `Gwybodaeth y sesiwn wedi'i derbyn`,
	DECISION_LOG: `Log penderfyniadau`,
	SCROLL_TO_BOTTOM: `Sgrolio i'r gwaelod`,
	ADD_DECISION_LOG: `Ychwanegu at y log penderfyniadau`,
	ENTER_ADVICE: `Rhowch eich ymateb`,
	ENTER_RATIONALE: `Rhowch eich sail resymegol`,
	DECISION_LOG_HELP_TEXT: `Bydd ymatebion a sail resymegol eich tîm yn ymddangos yma. Gallwch olygu eich ymatebion a sail resymegol drwy glicio arnynt.`,
	NO_DECISION_LOGS: `Dim ymatebion i'w dangos.`,
	DFT_WELCOME_MESSAGE: `Croeso i blatfform dysgu drwy brofiadau View360 Global.

 Arhoswch i'r sesiwn ddechrau.`,
	CONFIRM_DELETE_DECISIONLOG: `Ydych chi'n siŵr yr hoffech chi ddileu'r ymateb hwn?`,
	SESSION_HAS_STARTED: `Mae'r sesiwn wedi dechrau`,
	CLICK_TO_OPEN: `Clicio i agor`,
	CLICK_TO_EXPAND: `Clicio i fwyafu`,
	EXPAND_TEXT: `Cliciwch y botwm isod i weld y fideo ar faint llawn`,
	COLLAPSE: `Lleiafu`,
	EXPAND: `Mwyafu`,
	RECEIVED_AT: `Wedi'i derbyn`,
	AUDIO_PLAYER: `Chwaraeydd sain`,
	VIDEO_PLAYER: `Chwaraeydd fideo`,
	DFT_MESSAGES_TEXT: `Bydd yr ap hwn yn eich galluogi chi i anfon a derbyn negeseuon i/o'r Hwylusydd yn ystod sesiwn.`,
	DFT_NOTIFICATIONS_TEXT: `Bydd y cyfryngau'n ymddangos yma. Cadwch lygad ar y ddyfais hon am negeseuon a diweddariadau o'r cyfryngau.
Gellir gweld gwybodaeth gychwynnol y sesiwn drwy glicio ar y botwm ar frig eich sgrin.
Gallwch gyfathrebu â'r Hwylusydd drwy ddefnyddio'r ap Negeseuon ar gornel chwith waelod y ddyfais hon.`,
	MOBILE_NUMBER_TITLE: `Rhif ffôn`,
	MOBILE_NUMBER_PLACEHOLDER: `Rhif ffôn symudol`,
	MOBILE_NUMBER_DESC: `Rhowch eich rhif ffôn symudol er mwyn ichi allu derbyn negeseuon yn ystod y sesiwn. Dim ond yn ystod yr hyfforddiant trochi y defnyddir eich rhif ffôn a chaiff ei ddileu'n awtomatig ar ôl 24 awr yn unol  â'n polisïau ar y GDPR  a'r Ddeddf Diogelu Data.`,
	MOBILE_NUMBER_SKIP: `Hepgor hwn`,
	INCOMING_CALL: `Galwad yn cyrraedd`,
	CALL_IN_PROGRESS: `Galwad yn mynd rhagddo`,
	PHONE_MESSAGE_SENT: `Neges ffôn wedi'i hanfon`,
	CALL_FAILED: `Galwad wedi methu`,
	NO_ANSWER: `Dim ateb`,
	CLICK_TO_RESEND: `Cliciwch y botwm AILANFON i ailanfon y neges i'ch dyfais.`,
	VOICE_MESSAGE_SENT: `Anfonwyd neges llais at %%%.`,
	WENT_TO_VOICEMAIL: `Anfonwyd y neges llais at %%% ond aeth i'ch lleisbost.`,
	CALL_FAILED_TEXT: `Anfonwyd neges llais at %%% ond ni chyrhaeddodd.`,
	CHECK_PHONE: `Gwiriwch eich ffôn.`,
	AT: `at`,
	CALLING: `yn galw`,
	RESEND: `Ailanfon`,
	PLAY_HERE: `Chwarae yma`,
	SESSION: `Sesiwn`,
	CAMERA: `Camera`,
	MICROPHONE: `Microffon`,
	AUDIO_OUTPUT_DEVICE: `Dyfais allbwn sain`,
	SELECT_CAMERA: `Dewis camera`,
	SELECT_MICROPHONE: `Dewis microffon`,
	SELECT_AUDIO_OUTPUT_DEVICE: `Dewis dyfais allbwn sain`,
	SAME_AS_SYSTEM: `Yr un peth â'r system`,
	CHAT: `Sgwrs`,
	CHANGE_LAYOUT: `Newid y cynllun`,
	VIDEO_SETTINGS: `Gosodiadau`,
	PRIVATE_MESSAGE: `Neges breifat`,
	POPUP_TITLE_CONFIGUREVIDEO: `Ffurfweddu sain/fideo`,
	NOTEPAD: `Notepad`,
	NOTETAKER: `Notetaker`,
	CASES: `Achosion`,
	SESSIONS: `Sesiynau`,
	INITIALINFORMATIONPAGE: `Gwybodaeth am y sesiwn`,
	VIDEOCONF: `Fideo gynadledda`,
	PASSCODES: `Codau cyfrin tîm`,
	CASE_LIST: `Rhestr achosion`,
	ADD_CASE: `Ychwanegu un newydd`,
	PARTICIPANTS: `Cyfranogwyr`,
	LINKEDACCESS_CLOUDVERSION: `Cyrchiad arsylwyr`,
	LINKEDACCESS: `Cyrchiad arsylwyr`,
	LINKEDACCESSPASSWORDS: `Codau cyfrin arsylwyr`,
	SUPERVISOR: `Arsylwr`,
	CONNECT: `Cysylltu`,
	DISCONNECT: `Datgysylltu`,
	OFFLINE: `All-lein`,
	ONLINE: `Ar-lein`,
	PARTICIPANTS_ONLINE: `Cyfranogwyr ar-lein`,
	TEAMS_ONLINE: `Timau ar-lein`,
	NO_RECORDS: `Dim cofnodion`,
	REGION: `Gweinydd fideo`,
	SELECT_REGION: `Dewis gweinydd fideo gynadledda`,
	NONE_SELECTED: `Dim wedi'u dewis`,
	START_SERVER: `Cychwyn y gweinydd`,
	STOP_SERVER: `Atal y gweinydd`,
	START_CALL: `Cychwyn galwad`,
	START_CALL_WITH_WARNING: `Cychwyn galwad gyda rhybudd`,
	START_CALL_TITLE: `Cychwyn galwad gyda'r holl gyfranogwyr`,
	START_CALL_MESSAGE: `Clicio "Cychwyn galwad" i gychwyn galwad fideo gynadledda sgrin lawn gyda'r holl dimau/cyfranogwyr. Defnyddio'r opsiwn "Cychwyn galwad gyda rhybudd" i ddangos rhybudd a chyfrif i lawr i'r holl gyfranogwyr`,
	SERVER_STATUS_MESSAGE_ONLINE: `Mae'r gweinydd fideo-gynadledda ar lein yn y rhanbarth a ddewiswyd. Caiff ei roi yn y modd segur pan fydd yr holl hwyluswyr a chyfranogwyr wedi allgofnodi am fwy na dwy awr.`,
	SERVER_STATUS_MESSAGE_AVAILABLE: `Mae'r math hwn o weinydd ar gael bob amser. Nid oes angen cychwyn neu atal gweinydd pan ddewisir y math hwn.`,
	SERVER_STATUS_MESSAGE_STARTING: `Mae'r gweinydd fideo-gynadledda yn cychwyn. Arhoswch.`,
	SERVER_STATUS_MESSAGE_STOPPING: `Mae'r gweinydd fideo-gynadledda yn mynd all-lein. Arhoswch.`,
	SERVER_STATUS_MESSAGE_UNAVAILABLE: `Nid yw swyddogaeth fideo-gynadledda ar gael ar gyfer eich sefydliad. Cysylltwch â'r opsiwn cymorth i drefnu uwchraddio er mwyn ichi allu defnyddio swyddogaeth fideo-gynadledda integredig View360Global.`,
	SERVER_STATUS_MESSAGE_OFFLINE: `Mae eich gweinydd fideo-gynadledda all-lein. Cliciwch "Cychwyn y gweinydd" i ymgychwyn y gweinydd yn y rhanbarth a ddewiswyd. Bydd hyn yn cymryd llai na 30 eiliad. Bydd y gweinydd yn mynd i'r modd segur os yw'r holl hwyluswyr a chyfranogwyr wedi allgofnodi am fwy na dwy awr.`,
	BREAKOUT_STATUS_MESSAGE_ENABLED: `Mae ystafelloedd trafod yn weithredol i'r holl gyfranogwyr. Mae ystafelloedd trafod yn caniatáu i holl aelodau tîm gyfathrebu yn ystod sesiwn trwy ein gwasanaethau fideo-gynadledda.`,
	BREAKOUT_STATUS_MESSAGE_DISABLED: `Mae'r ystafelloedd trafod wedi'u hanalluogi. Cliciwch "Galluogi" i alluogi ystafelloedd trafod awtomatig i'r holl gyfranogwyr. Mae ystafelloedd trafod yn caniatáu i holl aelodau tîm gyfathrebu yn ystod sesiwn trwy ein gwasanaethau fideo-gynadledda.`,
	SHARE_SCREEN: `Rhannu sgrin`,
	END_CALL: `Gorffen galwad`,
	POPUP_TITLE_NEWCASE: `Ychwanegu achos newydd`,
	POPUP_TITLE_MEDIAUPLOAD: `Lanlwytho cyfryngau`,
	POPUP_TITLE_EDITCASE: `Golygu manylion achos`,
	POPUP_TITLE_GROUPRELEASE: `Rhyddhau i'r timau`,
	POPUP_TITLE_MEDIAOPENINGS: `Agorwyd gan gyfranogwyr`,
	POPUP_TITLE_SELECTINTERPRETER: `Dewis sianel dehonglydd`,
	POPUP_TITLE_MEDIAFORPHONE: `Mae gennych neges newydd`,
	POPUP_TITLE_EDITMEDIA: `Golygu manylion cyfryngau`,
	POPUP_TITLE_VIDEOPARTICIPANT: `Fideo cyfranogwr`,
	CASE_TITLE: `Teitl`,
	CASE_DESCRIPTION: `Disgrifiad:`,
	CASE_CANNOT_BE_CHANGED: `Ni ellir newid yr achos pan fo sesiwn yn mynd rhagddo`,
	ADD_SESSION: `Ychwanegu sesiwn`,
	SEND_TO_ALL_PLACEHOLDER: `Anfon neges i'r holl dimau`,
	NEVER: `Byth`,
	UNKNOWN: `Anhysbys`,
	LAST_UPDATED: `Diweddarwyd diwethaf`,
	SESSION_TIMEFRAME: `munudau`,
	DURATION: `Hyd`,
	SESSION_TRANSLATE_INFO: `Cyfieithu gwybodaeth sesiwn i'r cyfranogwyr`,
	SESSION_DURATION_MAX_WARNING: `Hyd mwyaf: %%% munud`,
	SESSION_DURATION_MIN_WARNING: `Hyd lleiaf: %%% munud`,
	PARTICIPANT: `Cyfranogwr`,
	GROUP: `Tîm`,
	RUN: `Rhedeg`,
	DELETE: `Dileu`,
	STOP: `Atal`,
	PAUSE: `Saib`,
	RESUME: `Ailgychwyn`,
	NO_SESSIONS: `Nid oes unrhyw sesiynau ar gyfer yr achos hwn ar hyn o bryd`,
	NO_OPEN_CASE: `Agorwch achos cyn ychwanegu unrhyw sesiynau neu gyfryngau.`,
	ADD_MEDIA: `Ychwanegu cyfryngau`,
	NO_MEDIA: `Nid oes unrhyw gyfryngau ar gyfer yr achos hwn ar hyn o bryd`,
	RELEASE_SCHEDULE: `Rhyddhau amserlen`,
	RELEASE_NOW: `Rhyddhau nawr`,
	RELEASE_TO_GROUPS: `Rhyddhau i'r timau`,
	RELEASE: `Rhyddhau`,
	MEDIA: `Cyfryngau cynorthwyol`,
	MEDIA_ITEM: `Eitem gyfryngol`,
	MEDIA_FILES: `Eitemau cyfryngol`,
	MEDIA_ITEM_NAME: `Enw`,
	MEDIA_PHONE_ENABLED: `Gellir ei anfon i ffôn`,
	EDIT: `Golygu`,
	UPDATE: `Diweddaru`,
	UPDATE_TEXT_PLACEHOLDER: `Rhowch y testun diweddaru yma`,
	ADD_RELEASE_ROW: `Ychwanegu rhes rhyddhau`,
	MANUALLY: `Ȃ llaw`,
	AUTOMATIC: `Yn awtomatig`,
	REMOVE: `Dileu`,
	UPLOADED: `Wedi lanlwytho:`,
	SESSION_IS_ACTIVE: `Sesiwn yn weithredol`,
	OPEN: `Dewis`,
	SELECT: `Dewiswch…`,
	ACTIVE: `Wedi dewis`,
	IP_ADDRESS: `Cyfeiriad darparwr rhyngrwyd:`,
	EXPIRES: `Yn dod i ben`,
	AVAILABLE: `Ar gael`,
	ALLOCATED_TO: `Dyrannwyd i`,
	CANNOT_EDIT_PREV_SESSIONS: `Rydych yn gweld sesiwn blaenorol ar hyn o bryd. I olygu templedi sesiwn, ewch nôl i'r sesiwn cyfredol. `,
	VIEWING_REMOTE: `Yn gweld sesiynau o bell`,
	SUBSCRIPTION_NOTICE: `Mae gan eich sefydliad %%% o sesiynau ar ôl yn eich tanysgrifiad. Y tanysgrifiad yn dod i ben: %%% Cysylltwch â'r opsiwn cymorth os oes angen mwy o sesiynau arnoch.`,
	SUBSCRIPTION_NOTICE_UNLIMITED: `Mae tanysgrifiad eich sefydliad yn caniatáu sesiynau digyfyngiad. Y tanysgrifiad yn dod i ben:`,
	PASSCODE_DELETED_TITLE: `Dilëwyd`,
	PASSCODE_DELETED_MSG: `Dilëwyd cod cyfrin`,
	PASSCODE_COPIED_TITLE: `Copïwyd`,
	PASSCODE_COPIED_MSG: `Copïwyd cod cyfrin i'ch clipfwrdd`,
	MEDIA_DELETED_TITLE: `Dilëwyd`,
	MEDIA_DELETED_MSG: `Dilëwyd eitem gyfryngol`,
	CONFIRM_RELEASE_TO_GROUP: `Ydych chi'n siŵr yr hoffech ryddhau i'r tîm hwn?`,
	CONFIRM_DELETE_SESSION: `Ydych chi'n siŵr yr hoffech ddileu'r sesiwn hwn?`,
	CONFIRM_BEGIN_SESSION: `Ydych chi'n siŵr yr hoffech ddechrau'r sesiwn hwn?`,
	CONFIRM_END_SESSION: `Ydych chi'n siŵr yr hoffech orffen y sesiwn hwn?`,
	CONFIRM_BEGIN_SESSION_MESSAGE: `Os yw'r sesiwn yn rhedeg am fwy na phum munud, caiff ei ddidynnu o gwota eich trwydded. Mae gennych %%% o sesiynau ar ôl yn eich cynllun tanysgrifio.`,
	CONFIRM_RELEASE_NOW: `Ydych chi'n siŵr yr hoffech ryddhau hyn i'r holl dimau nawr?`,
	CONFIRM_DELETE_CASE: `Ydych chi'n siŵr yr hoffech ddileu'r achos hwn?`,
	CONFIRM_DELETE_ROW: `Ydych chi'n siŵr yr hoffech ddileu'r rhes hon?`,
	CONFIRM_DELETE_MEDIA: `Ydych chi'n siŵr yr hoffech ddileu'r eitem gyfryngol hon?`,
	CONFIRM_DELETE_PASSWORD: `Ydych chi'n siŵr yr hoffech ddileu'r cod cyfrin hwn?`,
	CONFIRM_DELETE_PREV_SESSION: `Ydych chi'n siŵr yr hoffech ddileu'r sesiwn hwn?`,
	PAUSE_CONFIRM: `Ydych chi'n siŵr yr hoffech oedi'r sesiwn hwn?`,
	RESUME_CONFIRM: `Ydych chi'n siŵr yr hoffech ailgychwyn y sesiwn?`,
	CONTENT_FORMAT_NOT_RECOGNISED: `Nid yw'r rhaglen yn adnabod fformat y ffeil hon`,
	GROUPS: `Timau`,
	NO_GROUPS: `Dim timau`,
	ALL_GROUPS: `Pob tîm`,
	GROUP_PASSWORDS: `Codau cyfrin tîm`,
	GENERATE_NEW_PASSWORD: `Cynhyrchu cod cyfrin newydd`,
	DOWNLOAD: `Lawrlwytho`,
	DOWNLOAD_VERBATIM: `Lawrlwytho dogfen gair-am-air`,
	DOWNLOAD_TRANSLATED: `Lawrlwytho dogfen wedi'i chyfieithu`,
	ERROR_PASSWORD_INCORRECT: `Enw defnyddiwr neu gyfrinair yn anghywir`,
	ERROR_COULD_NOT_CONNECT: `Wedi methu cysylltu â'r cyfeiriad a roddwyd`,
	ERROR_NO_PASSCODE: `Rhowch enw a chod cyfrin`,
	ERROR_TITLE_BLANK: `Ni all teitl yr achos fod yn wag`,
	ERROR_DESCRIPTION_BLANK: `Ni all y disgrifiad fod yn wag`,
	ERROR_TITLE_ALREADY_EXISTS: `Mae achos â'r teitl hwn yn bod eisoes`,
	LOGIN: `Mewngofnodi`,
	PASSCODE: `Cod cyfrin`,
	EMAIL: `Cyfeiriad e-bost`,
	PASSWORD: `Cyfrinair`,
	VERSION: `fersiwn`,
	NAME: `Enw`,
	BACK: `Yn ôl`,
	SETTINGS: `Gosodiadau`,
	SETTINGS_WAITING_ROOM: `Neges groeso`,
	SETTINGS_WAITING_ROOM_MSG: `Neges`,
	SETTINGS_WAITING_ROOM_INFO: `Caiff y neges hon ei dangos i'r holl gyfranogwyr pan nad oes sesiwn ar waith neu newydd orffen. Os gadewir hon yn wag, dangosir neges ragosodedig.`,
	VIDEOCONF_JOIN_BREAKOUT_MSG: `Gallwch ymuno ag ystafell drafod i sgwrsio â chyfranogwyr mewn tîm. Y gwahaniaeth rhwng yr opsiwn hwn a 'galwad â thîm' yw na fydd yr alwad yn cael ei dangos i'r cyfranogwyr ar sgrin lawn. Yn hytrach, cewch eich cynnwys yn eu bar ochr. `,
	VIDEOCONF_SELECT_TEAM: `Dewiswch dîm`,
	VIDEOCONF_CHECKING: `Gwirio`,
	VIDEOCONF_LEAVE: `Gadael`,
	VIDEOCONF_JOIN: `Ymuno`,
	VIDEOCONF_STATUS: `Statws y gweinydd`,
	VIDEOCONF_OBSERVER_ROOM: `Ystafell drafod arsylwyr`,
	VIDEOCONF_OBSERVER_ROOM_MSG: `Mae'r ystafell drafod arsylwyr yn caniatáu i'r Hwylusydd a'r arsylwyr gyfathrebu â'i gilydd.`,
	INTERPRETER: `Dehonglwr`,
	INTERPRETER_AVAILABLE: `Dehonglwr ar gael`,
	CHANNEL_NAME: `Enw'r sianel`,
	NO_INTERPRETER: `Dim dehonglwr`,
	BROADCASTING_ON_MAIN: `Darlledu ar y brif sianel`,
	BROADCASTING_ON_X: `Darlledu ar sianel %%%`,
	LISTENING_ON_MAIN: `Gwrando ar y brif sianel`,
	LISTENING_ON_X: `Gwrando ar sianel %%%`,
	MUTE_MAIN_AUDIO: `Prif sain yn fud`,
}
