/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent, useEffect } from 'react'

import config from '../../config'

import BubbleHeading from '../elements/BubbleHeading'
import DecisionLogRow from '../elements/DecisionLogRow'
import useAutoScrollBottom from '../util/useAutoScrollBottom'

const { ADVICE, RATIONALE } = config.strings

type DecisionLogProps = {
	advice: Array<string>
	rationale: Array<string>
	authors: Array<string>
	times: Array<string>
	translatedDecisionLog: {
		advice?: Array<string>
		rationale?: Array<string>
		authors?: Array<string>
	}
}

const DecisionLog: FunctionComponent<DecisionLogProps> = props => {
	// function DecisionLog(props) {
	const adviceArr = props.advice || []
	const rationaleArr = props.rationale || []
	const authors = props.authors || []
	const times = props.times || []
	const translatedDecisionLog = props.translatedDecisionLog || {}
	const translatedAdvice = translatedDecisionLog.advice || []
	const translatedRationale = translatedDecisionLog.rationale || []
	const translatedAuthors = translatedDecisionLog.authors || []
	const [hasScrolled, setHasScrolled] = React.useState(true)

	const numRows = Math.max(adviceArr.length, rationaleArr.length)

	const scrollerRef = useAutoScrollBottom(numRows)
	// Map the advice and rationale to rows of text areas
	const rows = []
	for (let r = 0; r < numRows; r++) {
		const advice = adviceArr[r] || ''
		const rationale = rationaleArr[r] || ''

		rows.push(
			<DecisionLogRow
				key={r}
				advice={advice}
				rationale={rationale}
				author={authors[r]}
				time={times[r]}
				translatedAdvice={translatedAdvice[r]}
				translatedRationale={translatedRationale[r]}
				translatedAuthor={translatedAuthors[r]}
			/>
		)
	}

	useEffect(() => {
		if (hasScrolled) {
			scrollerRef.current.scrollIntoView({ behavior: 'smooth' })
			setHasScrolled(false)
		}
	}, [])

	return (
		<div className="decision-log">
			<div className="decision-log__header">
				<BubbleHeading>{ADVICE}</BubbleHeading>
				<BubbleHeading>{RATIONALE}</BubbleHeading>
			</div>
			<div className="decision-log__scroller">
				<div className="decision-log__inner" ref={scrollerRef}>
					{rows}
				</div>
				<div ref={scrollerRef} />
			</div>
		</div>
	)
}

export default DecisionLog
