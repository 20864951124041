import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import config from '../../config'
import Actions from '../actions'

import getIconForMediaItem from '../util/getIconForMediaItem'
import getTextDirection from '../util/getTextDirection'
import convertColour from '../util/convertColour'
import getTime from '../util/getTime'

import loggedInAsParticipantSelector from '../selectors/loggedInAsParticipant'
import loggedInAsGroupSelector from '../selectors/loggedInAsGroup'
import currentCaseSelector from '../selectors/currentCase'
import sessionSelector from '../selectors/session'

import IconButton from '../elements/IconButton'
import Icon from '../elements/Icon'

const TWENTY_SECONDS = 20 * 1000

const { OPENED_AT } = config.strings

// =================================================================================================

function formatTime(time: number | string): string {
	return new Date(time).toTimeString().substr(0, 5)
}

// =================================================================================================

type MediaFeedItemProps = {
	item?: ReleaseItem
	session?: CurrentSession | PreviousSession
	groupId?: string
	openItem?: (rowId: string, sessionId: string) => void
	openings?: Opening[]
	caseMedia?: MediaItem[]
	participantId?: string
	displayModalPopup?: (name: string, params?: SimpleObject) => void
	viewingPreviousSession?: boolean
	loggedInAsGroup?: boolean
	loggedInAsParticipant?: boolean
	phoneNumber?: string
	participants?: Participant[]
	flashingEnabled?: boolean
}

const MediaFeedItem: FunctionComponent<MediaFeedItemProps> = props => {
	const rootClassName = 'media-feed-item'
	let className = rootClassName

	const { item, session, groupId, openItem, openings, caseMedia, phoneNumber, flashingEnabled } = props
	const { displayModalPopup, viewingPreviousSession, loggedInAsGroup, loggedInAsParticipant } = props
	const { participants } = props

	// Get associated media
	const mediaItemId = (item.mediaitem || '').toString()
	let mediaitem = item.mediaitem ? caseMedia.find(m => m.timestamp.toString() === mediaItemId) : null

	// Return null if this is an invalid newfeed item
	if (item.type === 'media' && !mediaitem) return null
	if (item.type !== 'media') mediaitem = null

	// Get icon to display
	const { icon, colour } = getIconForMediaItem(mediaitem)
	let iconColour = colour

	// Get title to show - either media item name or just "Update received"
	const ITEM_TYPE = item.type.toUpperCase()
	const title = mediaitem ? mediaitem.name : config.strings[`NEWSTYPE_${ITEM_TYPE}`]

	// Check if item has been opened by this participant
	const { rowId } = item
	const sessionId = session.id

	// Get list of openings. There might be multiple if there are multiple participants in team
	const itemOpenings = openings.filter(o => o.key === rowId && o.sessionId === sessionId && o.groupId === groupId)
	const participantsInTeam = participants.filter(p => p.colour === groupId)
	const participantWhoHasNotOpenedItem = participantsInTeam
		.filter(p => p.online)
		.find(p => !itemOpenings.find(o => o.participantId === p.id))

	// Check if the item has been opened by all members of the team.
	// If it is a basic team (no 'participants') then we just need to check if the item has been opened at all.
	// Otherwise, check if there is at least one participant in the team who has not opened the item.
	let seen = true
	if (itemOpenings.length === 0 || participantWhoHasNotOpenedItem) {
		seen = false
	}

	if (seen) {
		className += ' media-feed-item--seen'
		iconColour = null
	}

	// Check if item is recent
	const now = getTime()
	const timeNum = parseInt(item.time.toString(), 10)
	const recent = timeNum > now - TWENTY_SECONDS && timeNum < now

	if (recent && !seen && flashingEnabled) {
		className += ' media-feed-item--recent'
	}

	// -----------------------------------------------------------------------------------------------
	function onClickMedia() {
		// If this isn't a previous session, mark media as opened
		if (!viewingPreviousSession && (loggedInAsGroup || loggedInAsParticipant)) {
			openItem(item.rowId, session.id)
		}
		// Display update
		if (ITEM_TYPE === 'UPDATE') {
			return displayModalPopup('modal-update', item)
		}
		if (mediaitem.phoneEnabled && phoneNumber) {
			return displayModalPopup('modal-media-for-phone', { mediaitem, rowId: item.rowId })
		}
		// Display media
		displayModalPopup('modal-media', mediaitem)
	}

	// -----------------------------------------------------------------------------------------------
	// Check if we shall show a button for the facilitator to display more details about who
	// has opened the media item

	// Check if this group has participants
	const showOpeningsInfoModal = Boolean(participants.filter(p => p.colour === groupId).length)

	const onClickOpeningsInfo = () => {
		displayModalPopup('modal-media-openings', { groupId, rowId, title })
	}

	// -----------------------------------------------------------------------------------------------
	// Display "Opened at xx:xx" if item has been opened. We will display the time that the item
	// was *first* opened.

	const firstOpening = itemOpenings.sort((a, b) => (a.lastUpdate < b.lastUpdate ? -1 : 1))[0]?.lastUpdate
	const openTime = firstOpening ? `${OPENED_AT} ${formatTime(firstOpening)}` : ''

	return (
		<div className={className} onClick={onClickMedia} role="button" onKeyPress={onClickMedia} tabIndex={0}>
			<div className={`${rootClassName}__icon-container`} style={{ backgroundColor: convertColour(iconColour) }}>
				<Icon name={icon} />
			</div>
			<div className={`${rootClassName}__details`}>
				<div className="left">
					<h3 className={`${rootClassName}__title`} title={title} style={{ direction: getTextDirection(title) }}>
						{title}
					</h3>
					<div className={`${rootClassName}__time`}>{formatTime(item.time)}</div>
					{openTime && <div className={`${rootClassName}__opentime`}>{openTime}</div>}
				</div>
				{showOpeningsInfoModal && (
					<div className="right">
						<IconButton iconName="users" onClick={onClickOpeningsInfo} />
					</div>
				)}
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<MediaFeedItemProps> => {
	const currentCase = currentCaseSelector(state) || ({} as OpenCaseDetails)
	const viewingPreviousSession = Boolean(state.viewingPreviousSession)

	const group = state.group || ({} as GroupDetails)

	return {
		loggedInAsGroup: loggedInAsGroupSelector(state),
		loggedInAsParticipant: loggedInAsParticipantSelector(state),
		participants: state.participants || [],
		caseMedia: currentCase.media || [],
		participantId: group.participantId,
		session: sessionSelector(state),
		openings: state.openings || [],
		viewingPreviousSession,
		phoneNumber: group?.phoneNumber,
		flashingEnabled: state.settings.flashingEnabled,
	}
}
const actions: Partial<MediaFeedItemProps> = {
	openItem: Actions.groups.openItem,
	displayModalPopup: Actions.misc.displayModalPopup,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Pick<MediaFeedItemProps, 'item' | 'groupId'>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(MediaFeedItem)
