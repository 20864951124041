export default {
	/* Swahili */
	OK: `Sawa`,
	CANCEL: `Futa`,
	TUTOR: `Mkufunzi`,
	CURRENT_SESSION: `Kipindi kinachoendelea`,
	TEAM: `Timu`,
	SIGNOUT: `Toka nje`,
	INITIAL_UPDATE: `Taarifa za kipindi`,
	ADVICE: `Uamuzi`,
	RATIONALE: `Mantiki`,
	MEDIA_FEED: `Chanzo cha habari`,
	MESSAGES: `Ujumbe`,
	PREVSESSIONS: `Vipindi vilivyopita`,
	RETURN_TO_CURRENT: `Rudi kwenyekipindi kinachoendelea`,
	SESSION_IS_PAUSED: `Kipindi kimesimamishwa kwa muda `,
	ATTRIB_CURR_AUTHOR: `Mtoa maamuzi`,
	SAVE: `Weka kumbukumbu`,
	CHANGE: `Badilisha`,
	NO_AUTHOR: `Haijapangwa `,
	NO_INITIAL_INFO: `Tafadhali subiri kipindi kinachofuata`,
	DECISION_MAKER_SELECT: `Tafadhali chagua mtoa maamuzi`,
	SESSION_COMPLETE: `Kipindi cha kwanza kimeisha`,
	OPENED_AT: `Muda wa kufungua `,
	SEND_MESSAGE_PLACEHOLDER: `Andika ujumbe`,
	SEND: `Tuma`,
	TO_ALL_GROUPS: `Kwa makundi yote`,
	SHOW_TRANSLATION: `Onyesha tafsiri`,
	SHOW_ORIGINAL: `Onyesha halisi`,
	CREDENTIALS_EXPIRED: `Muda wako umeisha tafadhali ingia tena`,
	INCOMING_MESSAGE: `Ujumbe unaoingia`,
	INCOMING_MESSAGE_TEXT: `Ujumbe mpya umepokelewa`,
	INCOMING_MEDIA: `Ujumbe mpya wa habari`,
	INCOMING_MEDIA_TEXT: `Ujumbe mpya wa habari umepokelewa`,
	INCOMING_UPDATE: `Taarifa mwendelezo`,
	INCOMING_UPDATE_TEXT: `Taarifa mwendelezo imepokelewa`,
	CONFIRM_LOGOUT: `Una uhakika unataka kutoka nje ya mfumo?`,
	MUTED_MESSAGE: `Kwa sasa sauti yako imezuiliwa`,
	NEWSTYPE_UPDATE: `Taarifa mwendelezo imepokelewa`,
	WARNING_TIME_MSG: `Dakika 5 zimebaki`,
	VIEW_INITIAL_UPDATE: `Angalia taarifa kuhusu za kipindi`,
	INITIAL_UPDATE_INDEX: `%%% Taarifa za kipindi`,
	VIEWING_PREVIOUS: `Unatazama kipindi  $$ kutoka %%%`,
	TO: `Kwenda`,
	EVERYONE: `Kila mtu`,
	MESSAGE_ENTRY_PLACEHOLDER: `Ujumbe`,
	DEFAULT_VIDEO_WARNING_MESSSAGE: `Ujumbe wa video unaanza hivi karibuni`,
	DEFAULT_VIDEO_WARNING_TITLE: `Onyo`,
	SESSION_INFO_RECEIVED: `Taarifa za kipindi zimepokelewa`,
	DECISION_LOG: `Kumbukumbu za uamuzi`,
	ADD_DECISION_LOG: `Weka katika kumbukumbu za uamuzi`,
	// DFT_WELCOME_MESSAGE: `Karibu katika mfumo wa Mafunzo wa ‘Global View360’

	// Tafadhali subiri kipindi kuanza.`,
	CONFIRM_DELETE_DECISIONLOG: `Una uhakika unataka kuondoa uamuzi huu?`,
	SESSION_HAS_STARTED: `Kipindi kimeanza`,
	CLICK_TO_OPEN: `Bonyeza ili kuanza`,
	CLICK_TO_EXPAND: `Bonyeza ili kukuza mwonekano`,
	EXPAND_TEXT: `Bonyeza kitufe cha chini ili kuongeza mwonekano wa video hii`,
	COLLAPSE: `Imeharibika`,
	EXPAND: `Kuza`,
	RECEIVED_AT: `Muda wa kupokea`,
	AUDIO_PLAYER: `Rekodi ya  sauti`,
	VIDEO_PLAYER: `Rekodi ya video`,
	DFT_MESSAGES_TEXT: `Huduma hii itakusaidia kutuma na kupokea ujumbe kutoka/kwenda kwa mwezeshaji wakati wa kipindi`,
	DFT_NOTIFICATIONS_TEXT: `Habari itaonekana hapa. Endelea kutazama kifaa hiki ili kupata habari mpya na zinazoendelea. 

Taarifa za kipindi cha awali zinaweza kutazamwa kwa kubonyeza kitufe kilichoko juu ya kompyuta yako. 

Wasiliana na Mkufunzi kwa kutumia huduma ya kutuma ujumbe iliyoko mkono wa kushoto upande wa chini ya kifaa hiki.`,
	MOBILE_NUMBER_TITLE: `Namba ya simu ya mezani`,
	MOBILE_NUMBER_PLACEHOLDER: `Simu ya mkononi`,
	MOBILE_NUMBER_DESC: `Tafadhali andika namba yako ya simu ya mkononi ili uweze kupokea ujumbe wa maandishi wakati wa kipindi. Namba yako itatumika wakati wa mafunzo tu na itafutika baada ya saa 24 kulingana na Kanuni zetu za GDPR  na Sheria ya Utunzaji  sera.`,
	MOBILE_NUMBER_SKIP: `Acha hii`,
	INCOMING_CALL: `Simu inayoingia`,
	CALL_IN_PROGRESS: `Simu inayoongea `,
	PHONE_MESSAGE_SENT: `Ujumbe wa simu umetumwa`,
	CALL_FAILED: `Simu haijapatikana`,
	NO_ANSWER: `Hakuna majibu`,
	CLICK_TO_RESEND: `Bonyeza kitufe cha kutuma ujumbe ili uweze kutuma tena ujumbe uliorudishwa katika kifaa chako`,
	VOICE_MESSAGE_SENT: `Ujumbe wa sauti ulitumwa kwenda %%%.`,
	WENT_TO_VOICEMAIL: `Ujumbe wa sauti ulitumwa kwenda %%% lakini ukaenda ukaenda kwenye ujumbe uliotunzwa.`,
	CALL_FAILED_TEXT: `Ujumbe wa sauti ulitumwa kwenda %%% lakini haukufanikiwa `,
	CHECK_PHONE: `Tafadhali angalia simu yako`,
	AT: `Saa`,
	CALLING: `Anapiga`,
	RESEND: `Tuma tena`,
	PLAY_HERE: `Bonyeza hapa`,
	SESSION: `Kipindi`,
	CAMERA: `Kamera`,
	MICROPHONE: `Kilonga longa`,
	AUDIO_OUTPUT_DEVICE: `Kifaa cha kutoa sauti`,
	SELECT_CAMERA: `Chagua kamera`,
	SELECT_MICROPHONE: `Chagua kilonga longa`,
	SELECT_AUDIO_OUTPUT_DEVICE: `Chagua kifaa cha kutoa sauti`,
	SAME_AS_SYSTEM: `Sawa na mfumo`,

	CLOSE: `Funga`,
	CONTINUE: `Endelea`,
	DECISION_LOG_HELP_TEXT: `Maamuzi na mantiki ya timu yako itaonekana hapa. Utaweza kuhariri maamuzi yako mwenyewe na mantiki kwa kubofya juu yao.`,
	NO_DECISION_LOGS: 'Hakuna kumbukumbu za uamuzi za kuonyesha.',
	NOTEPAD: `Karatasi ya kumbukumbu`,
	NOTETAKER: `Mwandishi wa kumbukumbu`,
	ENTER_ADVICE: `Andika uamuzi wako`,
	ENTER_RATIONALE: `Andika mantiki yako`,
}
