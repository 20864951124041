/* eslint-disable no-unused-vars */

const SOCKET_GROUP_CONNECTED: Reducer = (state, action) => {
	const newState = { ...state, participants: [...(state.participants || [])] }
	const { participantId } = action.payload
	newState.participants = newState.participants.filter(g => g.id !== participantId)
	newState.participants.push(action.payload)
	return newState
}

const SOCKET_GROUP_DISCONNECTED: Reducer = (state, action) => {
	const newState = { ...state }
	const { participantId } = action.payload
	newState.participants = newState.participants.filter(g => g.id !== participantId)
	return newState
}

const SOCKET_MAIN_CALL_STARTED: Reducer = (state, action) => {
	const data = { ...action.payload }
	data.mainCallId = data.mainCallId || data.callId
	const videoconf = { ...(state.videoconf || ({} as VideoConfDetails)), ...data }
	return { ...state, videoconf }
}

const SOCKET_MAIN_CALL_ENDED: Reducer = state => {
	const videoconf = { ...(state.videoconf || ({} as VideoConfDetails)) }
	delete videoconf.callId
	delete videoconf.mainCallId
	return { ...state, videoconf }
}

const SOCKET_BREAKOUT_ROOMS_ACTIVATED: Reducer = (state, action) => {
	const videoconf = { ...(state.videoconf || ({} as VideoConfDetails)) }
	videoconf.breakoutRoomsId = action.payload.callId
	const newState: StateTree = { ...state, videoconf }
	return newState
}

const SOCKET_BREAKOUT_ROOMS_DEACTIVATED: Reducer = state => {
	const newState = { ...state }
	const videoconf = { ...(state.videoconf || ({} as VideoConfDetails)) }
	delete videoconf.breakoutRoomsId
	delete newState.joinedBreakout
	return { ...newState, videoconf }
}

const SOCKET_OBSERVER_BREAKOUT_ACTIVATED: Reducer = (state, action) => {
	const videoconf = { ...(state.videoconf || ({} as VideoConfDetails)) }
	videoconf.observerBreakoutRoomsId = action.payload.callId
	return { ...state, videoconf }
}

const SOCKET_OBSERVER_BREAKOUT_DEACTIVATED: Reducer = state => {
	const videoconf = { ...(state.videoconf || ({} as VideoConfDetails)) }
	delete videoconf.observerBreakoutRoomsId
	return { ...state, videoconf }
}

const SOCKET_PARTICIPANT_COLOUR_CHANGE: Reducer = (state, action) => {
	const newState: StateTree = { ...state, group: { ...(state.group || ({} as GroupDetails)) } }
	newState.group.colour = action.payload.colour
	newState.group.id = action.payload.colour
	return newState
}

const SOCKET_PHONELOGUPDATE: Reducer = (state, action) => {
	const details = action.payload as {
		currentSessionId: string
		scheduleRowId: string
		status: string
		statusTime: string
	}
	const { scheduleRowId, status, statusTime } = details
	// const { scheduleRowId } = action.payload as { scheduleRowId: string }
	const releases = [...(state.releases || []).map(r => ({ ...r }))]
	const release = releases.find(r => r.rowId === scheduleRowId)
	if (!release) return state
	release.phonelog = { ...(release.phonelog || {}) } as PhoneLog
	release.phonelog.status = status
	release.phonelog.statusTime = statusTime
	return { ...state, releases }
}

//==================================================================================================

const reducers = {
	SOCKET_PHONELOGUPDATE,
	SOCKET_GROUP_CONNECTED,
	SOCKET_GROUP_DISCONNECTED,
	SOCKET_MAIN_CALL_ENDED,
	SOCKET_MAIN_CALL_STARTED,
	SOCKET_BREAKOUT_ROOMS_ACTIVATED,
	SOCKET_BREAKOUT_ROOMS_DEACTIVATED,
	SOCKET_OBSERVER_BREAKOUT_ACTIVATED,
	SOCKET_OBSERVER_BREAKOUT_DEACTIVATED,
	SOCKET_PARTICIPANT_COLOUR_CHANGE,
}

//==================================================================================================

const handler: Reducer = (state, action) => {
	if (reducers[action.type]) {
		return reducers[action.type](state, action)
	}
	return state
}

export default handler
