import React, { FunctionComponent } from 'react'
import DivWithInert from '../../elements/DivWithInert'
import Notifications from './Notifications'
import VirtualPhoneApps from './VirtualPhoneApps'

type HomeScreenProps = {
	groupId: string
	onClickShowMessages: () => void
	active?: boolean
}

const HomeScreen: FunctionComponent<HomeScreenProps> = ({ groupId, onClickShowMessages, active }) => (
	<DivWithInert className="home-screen" inert={!active}>
		<Notifications groupId={groupId} />
		<VirtualPhoneApps showMessages={onClickShowMessages} />
	</DivWithInert>
)

export default HomeScreen
