// Helper function - for any particular row, returns the timestamp it was
// released to the current group (if it has been released)
const getTimeReleased = (row: ScheduleRow, groupId: string, releases: Array<Release>): number => {
	const _groupId = String(groupId)
	const releasesForRow = releases.filter(release => release.rowId === row.id)
	const releaseToGroup = releasesForRow.find(r => r.groupId === _groupId || r.groupId === 'ALL')
	return releaseToGroup && releaseToGroup.timestamp
}

function getReleasedItems(session: CurrentSession, releases: Array<Release>, groupId: string): Array<ReleaseItem> {
	const schedule = session.schedule || []
	const items = schedule.map(row => ({
		...row,
		rowId: row.id,
		time: getTimeReleased(row, groupId, releases),
	}))

	return items.filter(r => Boolean(r.time))
}

export default getReleasedItems
