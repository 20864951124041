/* eslint-disable no-param-reassign */

import produce from 'immer'

export const OBSERVER_CONNECTED: ImmerReducer = (state, action) => {
	const { token, name, facilitatorId } = action.payload
	state.linkedAccess = {
		connected: true,
		facilitatorId,
		token,
		name,
	}
}

export const OBSERVER_DISCONNECTED: ImmerReducer = state => {
	state.linkedAccess = {}
	state.openCase = null
	state.groups = []
}

export const OBSERVER_CONNECTING: ImmerReducer = state => {
	state.linkedAccess = {
		connecting: true,
	}
}

export const OBSERVER_FAILED: ImmerReducer = (state, action) => {
	state.linkedAccess = {
		error: action.payload,
	}
}

export const OBSERVER_PASSWORDS_LOADING: ImmerReducer = state => {
	state.loadingLinkPasswords = true
}

export const OBSERVER_PASSWORDS_LOADED: ImmerReducer = (state, action) => {
	state.linkPasswords = action.payload || []
	state.loadingLinkPasswords = false
}

export const OBSERVER_PASSWORD_CREATED: ImmerReducer = (state, action) => {
	const password = action.payload
	state.creatingLinkPassword = false
	state.linkPasswords = state.linkPasswords || []
	state.linkPasswords.push(password)
}

export const OBSERVER_PASSWORD_CREATING: ImmerReducer = state => {
	state.creatingLinkPassword = true
}

export const OBSERVER_PASSWORD_CREATE_FAILED: ImmerReducer = state => {
	state.creatingLinkPassword = false
}

export const OBSERVER_PASSWORD_DELETED: ImmerReducer = (state, action) => {
	state.linkPasswords = [...state.linkPasswords.filter(p => p.key !== action.payload)]
}

export const OBSERVER_PASSWORD_UPDATED: ImmerReducer = (state, action) => {
	const { key, data } = action.payload
	const index = state.linkPasswords.findIndex(p => p.key === key)
	if (index === -1) return
	state.linkPasswords[index] = { ...state.linkPasswords[index], ...data }
}

//==================================================================================================

const reducers = {
	OBSERVER_PASSWORD_CREATE_FAILED,
	OBSERVER_PASSWORD_CREATING,
	OBSERVER_PASSWORDS_LOADING,
	OBSERVER_PASSWORD_CREATED,
	OBSERVER_PASSWORD_DELETED,
	OBSERVER_PASSWORD_UPDATED,
	OBSERVER_PASSWORDS_LOADED,
	OBSERVER_DISCONNECTED,
	OBSERVER_CONNECTING,
	OBSERVER_CONNECTED,
	OBSERVER_FAILED,
}

//==================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state || ({} as StateTree)

	// Default state
	if (!newState.linkedAccess) {
		newState = { ...state }
		newState.linkedAccess = {}
	}

	if (!newState.clientId) {
		newState.clientId = localStorage.getItem('clientId')
	}

	// If we have a reducer that matches the action type, apply it
	if (reducers[action.type]) {
		return produce(newState, _newState => {
			const _action = action || { payload: {} }
			const reducer = reducers[action.type]
			return reducer(_newState, _action)
		})
	}
	return newState
}

export default switchcase
