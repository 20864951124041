/* eslint-disable jsx-a11y/click-events-have-key-events */
// import React, { useState } from 'react'
import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import config from '../../config'

import teamNameFromColour from '../util/teamNameFromColour'
// import getTextDirection from '../helpers/getTextDirection'
import groupsSelector from '../selectors/groups'

import Icon from './Icon'
import MessageElem from '../components/MessageElem'

const GroupIcon = ({ colour }) => <Icon name="users" color={colour} />
const TutorIcon = _ => <Icon name="user" />

type MessageProps = {
	showWhoMessageIsTo?: boolean
	message?: Message
	groups?: Array<GroupDetails>
	participants?: Array<Participant>
	group?: GroupDetails
}

const Message: FunctionComponent<MessageProps> = props => {
	// const { SHOW_TRANSLATION, SHOW_ORIGINAL, TO_ALL_GROUPS } = config.strings
	// const [showOriginal, setShowOriginal] = useState(false)
	const { TO_ALL_GROUPS, SUPERVISOR, TUTOR } = config.strings
	const { showWhoMessageIsTo, message, groups, participants } = props
	const { groupId } = message

	// Either get group from groups list (if we are a facilitator) or from state.group (if logged in as group)
	// eslint-disable-next-line react/destructuring-assignment
	const group = (groupId !== 'all' && groups.find(g => g.id === groupId)) || props.group || ({} as GroupDetails)
	const groupColour = group.colour

	const m = message
	let from = ''
	if (m.observerId && m.allGroups) {
		from = `${SUPERVISOR} (${TO_ALL_GROUPS})`
	} else if (m.observerId && showWhoMessageIsTo && groupColour) {
		from = `${SUPERVISOR} (to ${teamNameFromColour(groupColour)})`
	} else if (m.observerId) {
		from = SUPERVISOR
	} else if (m.tutor && m.allGroups) {
		from = `${TUTOR} (${TO_ALL_GROUPS})`
	} else if (m.tutor && showWhoMessageIsTo && groupColour) {
		from = `${TUTOR} (to ${teamNameFromColour(groupColour)})`
	} else if (m.tutor) {
		from = TUTOR
	} else {
		from = teamNameFromColour(groupColour)
	}

	if (!from) {
		console.error('Not sure who message is from', message)
		return <div />
	}

	const icon = message.tutor ? <TutorIcon /> : <GroupIcon colour={groupColour || ''} />

	let participantName = null
	if (message.participantId) {
		participantName = participants?.find(p => p.id === message.participantId)?.name
	} else if (message.observerName) {
		participantName = message.observerName
	}

	const translation = message.translated && message.translation !== message.message && message.translation

	return (
		<MessageElem
			message={message.message}
			translation={translation}
			icon={icon}
			from={from}
			timestamp={message.timestamp}
			participantName={participantName}
		/>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const group = state.group || {}
	const groups = groupsSelector(state) || []
	const participants = state.participants || []
	return { group, groups, participants }
}
const actions = {}
export default connect(mapStateToProps, actions)(Message)
