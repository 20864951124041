export default {
	/* Russian */
	OK: `OK`,
	CANCEL: `Отменить`,
	TUTOR: `Координатор`,
	CURRENT_SESSION: `Текущая сессия`,
	TEAM: `Команда`,
	SIGNOUT: `Выход из системы`,
	INITIAL_UPDATE: `Информация о сессии`,
	ADVICE: `Решение`,
	RATIONALE: `Логическое обоснование`,
	MEDIA_FEED: `Информация из открытых источников`,
	MESSAGES: `Сообщения`,
	PREVSESSIONS: `Предыдущие сессии`,
	RETURN_TO_CURRENT: `Возврат к текущей сессии`,
	SESSION_IS_PAUSED: `Сессия в настоящее время ПРИОСТАНОВЛЕНА`,
	ATTRIB_CURR_AUTHOR: `Лицо, принимающее решение`,
	SAVE: `Сохранить`,
	CHANGE: `Изменить`,
	NO_AUTHOR: `Не установлено`,
	NO_INITIAL_INFO: `Пожалуйста, дождитесь начала следующей сессии`,
	DECISION_MAKER_SELECT: `Пожалуйста, выберите лицо, ответственное за принятие решений`,
	SESSION_COMPLETE: `Предыдущая сессия завершена`,
	OPENED_AT: `Открыто в`,
	SEND_MESSAGE_PLACEHOLDER: `Написать сообщение`,
	SEND: `Отправить`,
	TO_ALL_GROUPS: `Всем группам`,
	SHOW_TRANSLATION: `Показать перевод`,
	SHOW_ORIGINAL: `Показать исходный текст `,
	CREDENTIALS_EXPIRED: `Ваш пароль истек. Пожалуйста, пройдите авторизацию заново`,
	INCOMING_MESSAGE: `Входящее сообщение`,
	INCOMING_MESSAGE_TEXT: `Получено новое сообщение`,
	INCOMING_MEDIA: `Входящая информация из медиа источников`,
	INCOMING_MEDIA_TEXT: `Получена новая информация из медиа источников`,
	INCOMING_UPDATE: `Входящие обновления`,
	INCOMING_UPDATE_TEXT: `Получено обновление`,
	CONFIRM_LOGOUT: `Вы уверены, что хотите выйти?`,
	MUTED_MESSAGE: `Звук в данный момент отключен`,
	NEWSTYPE_UPDATE: `Обновление получено`,
	WARNING_TIME_MSG: `Осталось 5 минут`,
	VIEW_INITIAL_UPDATE: `Информация о просмотре сессии`,
	INITIAL_UPDATE_INDEX: `Сессия %%% информация`,
	VIEWING_PREVIOUS: `Просмотр сессии $$ из %%%`,
	TO: `Получатель`,
	EVERYONE: `Все`,
	MESSAGE_ENTRY_PLACEHOLDER: `Сообщение`,
	DEFAULT_VIDEO_WARNING_MESSSAGE: `Видео звонок скоро начнется`,
	DEFAULT_VIDEO_WARNING_TITLE: `Предупреждение`,
	SESSION_INFO_RECEIVED: `Информация о сессии получена`,
	DECISION_LOG: `Журнал решений`,
	ADD_DECISION_LOG: `Добавить в Журнал решений`,
	// WELCOME: `Добро пожаловать на экспериментальную учебную платформу View360 Global. Пожалуйста ждите начала сессии  `,
	CONFIRM_DELETE_DECISIONLOG: `Вы уверены, что хотите удалить данное решение?`,
	SESSION_HAS_STARTED: `Сессия начата`,
	CLICK_TO_OPEN: `Щелкните для открытия`,
	CLICK_TO_EXPAND: `Щелкните для увеличения`,
	EXPAND_TEXT: `Щелкните на кнопку для просмотра видео в полном размере`,
	COLLAPSE: `Свернуть`,
	EXPAND: `Увеличить`,
	RECEIVED_AT: `Получены на`,
	AUDIO_PLAYER: `Аудио проигрыватель`,
	VIDEO_PLAYER: `Видео проигрыватель`,
	DFT_MESSAGES_TEXT: `Данное приложение позволяет отправлять и получать сообщения от Организатора во время сессии`,
	DFT_NOTIFICATIONS_TEXT: `Новости публикуются здесь. Следите за этим устройством для получения новостей и обновлений. 
	Доступ к информации о начальной сессии можно получить, щелкнув по кнопке вверху вашего экрана.  
	Общайтесь с Организатором посредством приложения для отправки сообщений, расположенного в нижнем левом углу данного устройства.`,
	MOBILE_NUMBER_TITLE: `Номер телефона`,
	MOBILE_NUMBER_PLACEHOLDER: `Номер сотового телефона`,
	MOBILE_NUMBER_DESC: `Пожалуйста укажите номер вашего сотового телефона, чтобы получать сообщения в течении сессии. Ваш номер будет использован исключительно в процессе тренинга и будет автоматически удален по истечении 24 часов согласно нашей политике обработки персональных данных и закона о защите данных.  `,
	MOBILE_NUMBER_SKIP: `Пропустить`,
	INCOMING_CALL: `Входящий звонок`,
	CALL_IN_PROGRESS: `Вызов`,
	PHONE_MESSAGE_SENT: `Отправлено сообщение на телефон`,
	CALL_FAILED: `Сбой вызова`,
	NO_ANSWER: `Нет ответа`,
	CLICK_TO_RESEND: `Щелкните кнопку «ПЕРЕСЛАТЬ» для пересылки сообщения на ваше устройство`,
	VOICE_MESSAGE_SENT: `Голосовое сообщение отправлено %%%`,
	WENT_TO_VOICEMAIL: `Голосовое сообщение отправлено %%%, но направлено на голосовую почту`,
	CALL_FAILED_TEXT: `Голосовое сообщение отправлено %%%, но не доставлено`,
	CHECK_PHONE: `Пожалуйста проверьте свой телефон`,
	AT: `на`,
	CALLING: `Вызов`,
	RESEND: `Переслать`,
	PLAY_HERE: `Прослушать`,
	SESSION: `Сессия`,
	CAMERA: `Камера`,
	MICROPHONE: `Микрофон`,
	AUDIO_OUTPUT_DEVICE: `Устройство аудиовыхода`,
	SELECT_CAMERA: `Выбрать камеру`,
	SELECT_MICROPHONE: `Выбрать микрофон`,
	SELECT_AUDIO_OUTPUT_DEVICE: `Выбрать устройство аудиовыхода`,
	SAME_AS_SYSTEM: `Тоже, что и Система`,

	CLOSE: 'закрыть',
	CONTINUE: 'Продолжать',
	DECISION_LOG_HELP_TEXT:
		'Решения и обоснования вашей команды будут отображаться здесь. Вы сможете редактировать собственные решения и обоснования, нажимая на них.',
	NO_DECISION_LOGS: 'Нет журналов решений для отображения.',
	NOTEPAD: 'Блокнот',
	ENTER_ADVICE: 'Введите совет',
	ENTER_RATIONALE: 'Введите обоснование',
	NOTETAKER: 'Записывающий',
	POPUP_TITLE_CONFIGUREVIDEO: 'Настройка видео',
}
