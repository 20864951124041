const PARTICIPANTS_GOT_LIST: Reducer = (state, action) => {
	const participants = (action.payload || []) as Array<Participant>

	const newState = { ...state, participants }

	// Check if the current participant's colour has changed
	const participantId = state.group && state.group.participantId
	const participant = participantId && participants.find(p => p.id === participantId)
	if (participant && participant.colour !== state.group.colour) {
		newState.group = { ...newState.group, colour: participant.colour, id: participant.colour }
	}

	return newState
}

const PARTICIPANTS_UPDATED_COLOUR: Reducer = (state, action) => {
	const { participantId, colour } = action.payload
	const participants = state.participants.map(p => ({ ...p }))
	const participant = participants.find(p => p.id === participantId) || ({} as Participant)
	participant.colour = colour
	return { ...state, participants }
}

const PARTICIPANT_PHONE_UPDATED: Reducer = (state, action) => {
	const { phoneNumber } = action.payload as { phoneNumber: string }
	const group: GroupDetails = state.group || ({} as GroupDetails)
	return { ...state, group: { ...group, phoneNumber } }
}

// =================================================================================================

const reducers = {
	PARTICIPANTS_GOT_LIST,
	PARTICIPANTS_UPDATED_COLOUR,
	PARTICIPANT_PHONE_UPDATED,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
