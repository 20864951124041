export default {
	/* Participant view *****************************************************************************/
	OK: 'OK',
	CANCEL: 'Cancel',
	TUTOR: 'Facilitator',
	CURRENT_SESSION: 'Current session:',
	TEAM: 'Team',
	SIGNOUT: 'Log out',
	INITIAL_UPDATE: 'Session information',
	ADVICE: 'Response',
	RATIONALE: 'Rationale',
	MEDIA_FEED: 'Media feed',
	MESSAGES: 'Messages',
	PREVSESSIONS: 'Previous sessions',
	RETURN_TO_CURRENT: 'Return to current session',
	SESSION_IS_PAUSED: 'Session is currently PAUSED',
	ATTRIB_CURR_AUTHOR: 'Loggist',
	SAVE: 'Save',
	CHANGE: 'Change',
	NO_AUTHOR: 'Not set',
	NO_INITIAL_INFO: 'Please wait for the next session to begin',
	DECISION_MAKER_SELECT: 'Please select a participant...',
	SESSION_COMPLETE: 'The previous session has finished',
	OPENED_AT: 'Opened at',
	SEND_MESSAGE_PLACEHOLDER: 'Write a message',
	SEND: 'Send',
	TO_ALL_GROUPS: 'to all teams',
	SHOW_TRANSLATION: 'Show translation',
	SHOW_ORIGINAL: 'Show original',
	CREDENTIALS_EXPIRED: 'Your credentials have expired. Please log in again.',
	INCOMING_MESSAGE: 'Incoming message',
	INCOMING_MESSAGE_TEXT: 'A new message has been received',
	INCOMING_MEDIA: 'Incoming media item',
	INCOMING_MEDIA_TEXT: 'A new media item has been received',
	INCOMING_UPDATE: 'Incoming update',
	INCOMING_UPDATE_TEXT: 'An update has been received',
	CONFIRM_LOGOUT: 'Are you sure you wish to logout?',
	MUTED_MESSAGE: 'You are currently muted',
	NEWSTYPE_UPDATE: 'Update received',
	WARNING_TIME_MSG: 'There are 5 minutes remaining',
	VIEWING_PREVIOUS: `Viewing session $$ from %%%`,

	CLOSE: 'Close',
	CONTINUE: `Continue`,

	VIEW_INITIAL_UPDATE: 'View session information',
	INITIAL_UPDATE_INDEX: `Session %%% information`,
	TO: 'To',
	EVERYONE: 'Everyone',
	MESSAGE_ENTRY_PLACEHOLDER: 'Message',
	DEFAULT_VIDEO_WARNING_MESSSAGE: 'Video call starting soon',
	DEFAULT_VIDEO_WARNING_TITLE: 'Warning',
	SESSION_INFO_RECEIVED: 'Session information received',
	DECISION_LOG: 'Decision Log',
	SCROLL_TO_BOTTOM: 'Scroll to bottom',
	ADD_DECISION_LOG: 'Add to Decision Log',
	ENTER_ADVICE: 'Enter your response',
	ENTER_RATIONALE: 'Enter your rationale',
	DECISION_LOG_HELP_TEXT: `Your team's responses and rationale will appear here. You will be able to edit your own responses and rationale by clicking on them.`,
	NO_DECISION_LOGS: 'No responses to display.',
	DFT_WELCOME_MESSAGE: `Welcome to the View360 Global experiential learning platform.
	
	Please wait for the session to begin.`,

	CONFIRM_DELETE_DECISIONLOG: 'Are you sure you wish to remove this response?',
	SESSION_HAS_STARTED: 'Session has begun',
	CLICK_TO_OPEN: 'Click to open',
	CLICK_TO_EXPAND: 'Click to expand',
	EXPAND_TEXT: 'Click the button below to view the video at full size',
	COLLAPSE: 'Collapse',
	EXPAND: 'Expand',
	RECEIVED_AT: 'Received at',
	AUDIO_PLAYER: 'Audio player',
	VIDEO_PLAYER: 'Video player',
	DFT_MESSAGES_TEXT: 'This app will allow you to send and receive messages to/from the Facilitator during a session.',
	DFT_NOTIFICATIONS_TEXT: `Media will appear here. Keep an eye on this device for new media and updates.
	The initial session information can be accessed from the button at the top of your screen.
Communicate with the facilitator by using the Messages app in the bottom left corner of this device.`,

	MOBILE_NUMBER_TITLE: 'Phone Number',
	MOBILE_NUMBER_PLACEHOLDER: 'Mobile phone Number',
	MOBILE_NUMBER_DESC: `Please provide your mobile phone number so that you can receive messages during the session. Your phone number
	will only be used during the immersive training and will be deleted automatically after 24 hours in accordance
with our GDPR and Data Protection Act policies.`,
	MOBILE_NUMBER_SKIP: 'Skip this',

	INCOMING_CALL: 'Incoming Call',
	CALL_IN_PROGRESS: 'Call in Progress',
	PHONE_MESSAGE_SENT: 'Phone Message Sent',
	CALL_FAILED: 'Call Failed',
	NO_ANSWER: 'No Answer',
	CLICK_TO_RESEND: `Click the RESEND button to have the message resent to your device.`,
	VOICE_MESSAGE_SENT: `A voice message was sent to %%%.`,
	WENT_TO_VOICEMAIL: `The voice message was sent to %%% but went to your voicemail.`,
	CALL_FAILED_TEXT: `A voice message was sent to %%% but did not get through.`,
	CHECK_PHONE: 'Please check your phone.',
	AT: 'at',
	CALLING: 'calling',
	RESEND: 'Resend',
	PLAY_HERE: 'Play here',
	SESSION: 'Session',

	CAMERA: 'Camera',
	MICROPHONE: 'Microphone',
	AUDIO_OUTPUT_DEVICE: 'Audio output device',
	SELECT_CAMERA: 'Select camera',
	SELECT_MICROPHONE: 'Select microphone',
	SELECT_AUDIO_OUTPUT_DEVICE: 'Select audio output device',
	SAME_AS_SYSTEM: 'Same as System',

	CHAT: 'Chat',
	CHANGE_LAYOUT: 'Change layout',
	VIDEO_SETTINGS: 'Settings',
	PRIVATE_MESSAGE: 'Private message',
	POPUP_TITLE_CONFIGUREVIDEO: 'Configure audio/video',
	NOTEPAD: 'Notepad',
	NOTETAKER: 'Notetaker',

	/* Facilitator view *****************************************************************************/
	CASES: 'Cases',
	SESSIONS: 'Sessions',
	INITIALINFORMATIONPAGE: 'Session information',
	VIDEOCONF: 'Video conferencing',
	PASSCODES: 'Participant passcodes',
	CASE_LIST: 'Case list',
	ADD_CASE: 'Add new',
	PARTICIPANTS: 'Participants',

	LINKEDACCESS_CLOUDVERSION: 'Observer access',
	LINKEDACCESS: 'Observer access',
	LINKEDACCESSPASSWORDS: 'Observer passcodes',
	SUPERVISOR: 'Observer',
	CONNECT: 'Connect',
	DISCONNECT: 'Disconnect',

	OFFLINE: 'offline',
	ONLINE: 'online',

	PARTICIPANTS_ONLINE: 'Participants online',
	TEAMS_ONLINE: 'Teams online',

	NO_RECORDS: 'No records',

	/* Video conferencing */
	REGION: 'Video server',
	SELECT_REGION: 'Select a video conferencing server...',
	NONE_SELECTED: 'None selected',
	START_SERVER: 'Start server',
	STOP_SERVER: 'Stop server',
	START_CALL: 'Start call',
	START_CALL_WITH_WARNING: 'Start call with warning',
	START_CALL_TITLE: 'Start call with all participants',
	START_CALL_MESSAGE:
		'Click "Start call" to start a full-screen video conference call with all teams/participants. Use the "Start call with warning" option to display a warning and countdown to all participants, prior to bringing them into the call. This warning can be configured in Settings.',
	SERVER_STATUS_MESSAGE_ONLINE:
		'The video conferencing server is online in the selected region. It will be put into standby when all facilitators and participants are logged out for longer than two hours.',
	SERVER_STATUS_MESSAGE_AVAILABLE:
		'This server type is always available. There is no requirement to start or stop a server when this type is selected.',
	SERVER_STATUS_MESSAGE_STARTING: 'The video conferencing server is starting. Please wait.',
	SERVER_STATUS_MESSAGE_STOPPING: 'The video conferencing server is going offline. Please wait.',
	SERVER_STATUS_MESSAGE_UNAVAILABLE:
		'Video conferencing functionality is unavailable for your organisation. Please contact support to arrange an upgrade to allow you to use the integrated View360 Global video conferencing functionality.',
	SERVER_STATUS_MESSAGE_OFFLINE:
		'Your video conferencing server is currently offline. Click "Start Server" to initialise the server in the selected region. This will take less than 30 seconds. The server will go on standby if all facilitators and participants are logged out for over two hours.',
	BREAKOUT_STATUS_MESSAGE_ENABLED:
		'Breakout rooms are active for all participants. Breakout rooms allow all members of a team to communicate during a session via our video conferencing services.',
	BREAKOUT_STATUS_MESSAGE_DISABLED:
		'Breakout rooms are currently disabled. Click "Enable" to enable automatic breakout rooms for all participants. Breakout rooms allow all members of a team to communicate during a session via our video conferencing services.',

	SHARE_SCREEN: 'Share screen',
	END_CALL: 'End call',

	POPUP_TITLE_NEWCASE: 'Add new case',
	POPUP_TITLE_MEDIAUPLOAD: 'Upload media',
	POPUP_TITLE_EDITCASE: 'Edit case details',
	POPUP_TITLE_GROUPRELEASE: 'Release to teams',
	POPUP_TITLE_MEDIAOPENINGS: 'Opened by participants',
	POPUP_TITLE_SELECTINTERPRETER: 'Select interpreter channel',
	POPUP_TITLE_MEDIAFORPHONE: 'You have a new message',
	POPUP_TITLE_EDITMEDIA: 'Edit media details',
	POPUP_TITLE_VIDEOPARTICIPANT: 'Participant video',

	CASE_TITLE: 'Title',
	CASE_DESCRIPTION: 'Description:',
	CASE_CANNOT_BE_CHANGED: 'Case cannot be changed whilst a session is in progress',
	ADD_SESSION: 'Add session',
	SEND_TO_ALL_PLACEHOLDER: 'Send message to all teams',
	NEVER: 'Never',
	UNKNOWN: 'Unknown',
	LAST_UPDATED: 'Last updated',
	SESSION_TIMEFRAME: 'minutes',
	DURATION: 'Duration',
	SESSION_TRANSLATE_INFO: 'Translate session information to participants',
	SESSION_DURATION_MAX_WARNING: `Max. duration: %%% minutes`,
	SESSION_DURATION_MIN_WARNING: `Min. duration: %%% minutes`,
	PARTICIPANT: 'Participant',
	GROUP: 'Team',
	RUN: 'Run',
	DELETE: 'Delete',
	STOP: 'Stop',
	PAUSE: 'Pause',
	RESUME: 'Resume',
	NO_SESSIONS: 'There are currently no sessions for this case',
	NO_OPEN_CASE: 'Please open a case before adding any sessions or media.',
	ADD_MEDIA: 'Add media',
	NO_MEDIA: 'There is currently no media for this case',
	RELEASE_SCHEDULE: 'Release schedule',
	RELEASE_NOW: 'Release now',
	RELEASE_TO_GROUPS: 'Release to teams',
	RELEASE: 'Release',
	MEDIA: 'Supporting media',
	MEDIA_ITEM: 'Media item',
	MEDIA_FILES: 'Media items',
	MEDIA_ITEM_NAME: 'Name',
	MEDIA_PHONE_ENABLED: 'Can be sent to phone',
	EDIT: 'Edit',
	UPDATE: 'Update',
	UPDATE_TEXT_PLACEHOLDER: 'Enter update text here',
	ADD_RELEASE_ROW: 'Add release row',
	MANUALLY: 'Manually',
	AUTOMATIC: 'Automatically',
	REMOVE: 'Remove',
	UPLOADED: 'Uploaded: ',
	SESSION_IS_ACTIVE: 'Session is active',
	OPEN: 'Select',
	SELECT: 'Select...',
	ACTIVE: 'Selected',
	IP_ADDRESS: 'IP address:',
	EXPIRES: 'Expires',
	AVAILABLE: 'Available',
	ALLOCATED_TO: 'Allocated to',
	CANNOT_EDIT_PREV_SESSIONS:
		'You are currently viewing a previous session. To edit session templates, please return to current session.',
	VIEWING_REMOTE: 'Currently viewing remote sessions',

	SUBSCRIPTION_NOTICE:
		'Your organisation has %%% sessions remaining in your subscription. Subscription expires: %%% Please contact support if you require more sessions.',

	SUBSCRIPTION_NOTICE_UNLIMITED:
		"Your organisation's subscription allows for unlimited sessions. Subscription expires:",

	PASSCODE_DELETED_TITLE: 'Deleted',
	PASSCODE_DELETED_MSG: 'Passcode deleted',

	PASSCODE_COPIED_TITLE: 'Copied',
	PASSCODE_COPIED_MSG: 'Passcode copied to your clipboard',

	MEDIA_DELETED_TITLE: 'Deleted',
	MEDIA_DELETED_MSG: 'Media item deleted',

	CONFIRM_RELEASE_TO_GROUP: 'Are you sure you wish to release to this team?',
	CONFIRM_DELETE_SESSION: 'Are you sure you wish to delete this session?',
	CONFIRM_BEGIN_SESSION: 'Are you sure you wish to begin this session?',
	CONFIRM_END_SESSION: 'Are you sure you wish to end this session?',
	CONFIRM_BEGIN_SESSION_MESSAGE: `If the session runs for longer than 5 minutes, then it will be deducted from you licence quota. You currently have %%% sessions remaining in your current subscription plan.`,
	CONFIRM_RELEASE_NOW: 'Are you sure you wish to release this to all teams now?',
	CONFIRM_DELETE_CASE: 'Are you sure you wish to delete this case?',
	CONFIRM_DELETE_ROW: 'Are you sure you wish to remove this row?',
	CONFIRM_DELETE_MEDIA: 'Are you sure you wish to remove this media item?',
	CONFIRM_DELETE_PASSWORD: 'Are you sure you wish to delete this passcode?',
	CONFIRM_DELETE_PREV_SESSION: 'Are you sure you wish to delete this session?',
	PAUSE_CONFIRM: 'Are you sure you wish to pause this session?',
	RESUME_CONFIRM: 'Are you sure you wish to resume the session?',

	// Messages to teams
	CONTENT_FORMAT_NOT_RECOGNISED: 'The format of this file is not recognised by the application',

	GROUPS: 'Teams',
	NO_GROUPS: 'No Teams',
	ALL_GROUPS: 'All Teams',

	GROUP_PASSWORDS: 'Participant passcodes',
	GENERATE_NEW_PASSWORD: 'Generate new passcode',

	DOWNLOAD: 'Download',
	DOWNLOAD_VERBATIM: 'Download verbatim document',
	DOWNLOAD_TRANSLATED: 'Download translated document',

	ERROR_PASSWORD_INCORRECT: 'Username or password is incorrect',
	ERROR_COULD_NOT_CONNECT: 'Could not connect to the specified address',
	ERROR_NO_PASSCODE: 'Please enter a name and passcode',
	ERROR_TITLE_BLANK: 'Case title cannot be blank',
	ERROR_DESCRIPTION_BLANK: 'Description cannot be blank',
	ERROR_TITLE_ALREADY_EXISTS: 'A case with this title already exists',

	/* Login screen */
	LOGIN: 'Log in',
	PASSCODE: 'Passcode',
	EMAIL: 'Email address',
	PASSWORD: 'Password',
	VERSION: 'version',
	NAME: 'Name',
	BACK: 'Back',

	SETTINGS: 'Settings',
	SETTINGS_WAITING_ROOM: 'Welcome message',
	SETTINGS_WAITING_ROOM_MSG: 'Message',
	SETTINGS_WAITING_ROOM_INFO:
		'The following message will be displayed to all participants when there is no session in progress or recently finished. If this is left blank, a default message will be displayed.',

	VIDEOCONF_JOIN_BREAKOUT_MSG: `You can join a breakout room to chat with participants in a team. The different between this option and a 'call with a team' is that the call will not be full screen for the participants. Instead, you	will be included in their sidebar.`,
	VIDEOCONF_SELECT_TEAM: 'Select team...',
	VIDEOCONF_CHECKING: 'Checking',
	VIDEOCONF_LEAVE: 'Leave',
	VIDEOCONF_JOIN: 'Join',
	VIDEOCONF_STATUS: 'Server status',
	VIDEOCONF_OBSERVER_ROOM: 'Observer breakout room',
	VIDEOCONF_OBSERVER_ROOM_MSG: `The observer breakout room allows the facilitator and observers to communicate between themselves.`,

	INTERPRETER: 'Interpreter',
	INTERPRETER_AVAILABLE: `Interpreter available`,
	CHANNEL_NAME: 'Channel name',
	NO_INTERPRETER: 'No interpreter',

	BROADCASTING_ON_MAIN: `Broadcasting on main channel`,
	BROADCASTING_ON_X: `Broadcasting on %%% channel`,
	LISTENING_ON_MAIN: `Listening on main channel`,
	LISTENING_ON_X: `Listening on %%% channel`,
	MUTE_MAIN_AUDIO: `Mute main audio`,
}
