import React, { ReactElement, ReactNode } from 'react'

type BubbleHeadingProps = {
	className?: string
	alert?: boolean
	children: ReactNode
}

function BubbleHeading(props: BubbleHeadingProps): ReactElement<BubbleHeadingProps> {
	const { alert, children, className } = props

	let _className = 'bubble-heading'
	if (alert) _className += ' bubble-heading--alert '
	if (className) _className += className

	return <h2 className={_className}>{children}</h2>
}

export default BubbleHeading
