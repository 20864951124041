const NAVIGATING_TO: Reducer = (state, action) => {
	const location = action.payload
	return { ...state, navigatingToModal: '', navigatingTo: location }
}

const NAVIGATING_TO_MODAL: Reducer = (state, action) => {
	const { location, parms } = action.payload
	return { ...state, navigatingToModal: location, modalParms: parms }
}

// =================================================================================================

const reducers = {
	NAVIGATING_TO_MODAL,
	NAVIGATING_TO,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
