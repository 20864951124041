import React, { FunctionComponent } from 'react'

import teamNameFromColour from '../util/teamNameFromColour'
import convertColour from '../util/convertColour'
import IconButton from './IconButton'
import config from '../../config'

type PasscodeProps = {
	deletePassword: (key: string) => void
	copyPasswordToClipboard: (key: string) => void
	password: Passcode
	participant?: Participant
}
const Passcode: FunctionComponent<PasscodeProps> = props => {
	const { deletePassword, copyPasswordToClipboard, password, participant, children } = props
	const { ALLOCATED_TO, AVAILABLE, DELETE, EXPIRES, UNKNOWN } = config.strings
	const { colour, name, clientId, expires, key } = password

	const participantName = participant && participant.name

	let allocatedTo = null
	if (participantName && colour) {
		allocatedTo = `${participantName} - ${teamNameFromColour(colour)}`.toUpperCase()
	} else if (colour) {
		allocatedTo = teamNameFromColour(colour).toUpperCase()
	} else if (name) {
		allocatedTo = name
	} else if (clientId && !name) {
		allocatedTo = UNKNOWN
	}

	const allocationMsg = allocatedTo ? `${ALLOCATED_TO} ${allocatedTo.toUpperCase()}` : AVAILABLE
	const expireMsg = expires && `${EXPIRES}: ${new Date(expires).toLocaleString()}`

	let className = 'password'
	if (allocatedTo) className += ' password--allocated'

	const onCopy = _ => copyPasswordToClipboard(key)

	const onDelete =
		deletePassword &&
		(e => {
			e.stopPropagation()
			deletePassword(key)
		})

	const dot = colour ? (
		<div className="video-call-participant__dot" style={{ backgroundColor: convertColour(colour) }} />
	) : null

	return (
		<div key={key} className={className}>
			<div className="left">
				<h2>{key}</h2>
			</div>
			<div className="middle">{children}</div>
			<div className="right">
				<div>
					{allocationMsg}
					{dot}
				</div>
				<div>{expireMsg}</div>
			</div>
			<IconButton iconName="copy" onClick={onCopy} />
			{onDelete && <IconButton iconName="trash-alt" title={DELETE} onClick={onDelete} />}
		</div>
	)
}

export default Passcode
