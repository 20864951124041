import React, { FunctionComponent } from 'react'
import config from '../../config'
import Modal from '../components/Modal'
import VideoCallTwilio from '../components/videoconf/VideoCallTwilio'

type VideoTestProps = {
	//
}

const VideoTestTwilio: FunctionComponent<VideoTestProps> = props => {
	const { VIDEO_TEST_DOMAIN } = config

	return (
		<div className="video-test video-test--twilio">
			<VideoCallTwilio
				callId="v360gvideotest"
				domain={VIDEO_TEST_DOMAIN}
				displayName={`test-${Math.floor(Math.random() * 1000)}`}
			/>
			<Modal />
		</div>
	)
}

export default VideoTestTwilio
