export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'поништити',
	TUTOR: 'Водитељ',
	CURRENT_SESSION: 'Тренутна сесија',
	TEAM: 'Тим',
	SIGNOUT: 'Одјавити се',
	INITIAL_UPDATE: 'Информација о цесији',
	ADVICE: 'Одлука',
	RATIONALE: 'Образложење',
	MEDIA_FEED: 'Информација примљена',
	MESSAGES: 'Поруке',
	PREVSESSIONS: 'Претходна',
	RETURN_TO_CURRENT: 'Повратак на тренутну сесију',
	SESSION_IS_PAUSED: 'Сесија је тренутно заустављена',
	ATTRIB_CURR_AUTHOR: 'Доносилац одлуке',
	SAVE: 'Сачувај',
	CHANGE: 'Промијени',
	NO_AUTHOR: 'Није подешено',
	NO_INITIAL_INFO: 'Молимо сачекајте да започне следећа сесија',
	DECISION_MAKER_SELECT: 'Молимо изаберите доносиоца одлуке',
	SESSION_COMPLETE: 'Претходна сесија је завршена',
	OPENED_AT: 'Отворено у',
	SEND_MESSAGE_PLACEHOLDER: 'Напиши поруку',
	SEND: 'Пошаљи',
	TO_ALL_GROUPS: 'Свим групама',
	SHOW_TRANSLATION: 'Покажи превод',
	SHOW_ORIGINAL: 'Покажи оригинал',
	CREDENTIALS_EXPIRED: 'Ваша лозинка је истекла. Молимо вас пријавите се поново',
	INCOMING_MESSAGE: 'Долазна порука',
	INCOMING_MESSAGE_TEXT: 'Примљена је нова порука',
	INCOMING_MEDIA: 'Долазна медијска ставка',
	INCOMING_MEDIA_TEXT: 'Примљена је нова медијска ставка',
	INCOMING_UPDATE: 'Долазно ажурирање',
	INCOMING_UPDATE_TEXT: 'Примљено је ажурирање',
	CONFIRM_LOGOUT: 'Да ли сте сигурни да желите да се одјавите?',
	MUTED_MESSAGE: 'Микрофон је искључен',
	NEWSTYPE_UPDATE: 'Ажурирање примљено',
	WARNING_TIME_MSG: 'Преостало је 5 минута',
	NOTEPAD: 'Белешке',
	DECISION_LOG: 'Регистар одлука',
	VIEW_INITIAL_UPDATE: 'Погледај информације о сесији',
	NOTETAKER: 'Бележник',
	DFT_MESSAGES_TEXT: 'Примљена је нова порука',
	POPUP_TITLE_CONFIGUREVIDEO: 'Конфигуриши видео',
	CAMERA: 'Камера',
	MICROPHONE: 'Микрофон',
	AUDIO_OUTPUT_DEVICE: 'Изаберите аудио излазни уређај',
	ENTER_ADVICE: 'Унесите вашу одлуку',
	ENTER_RATIONALE: 'Унесите ваше образложење',
	ADD_DECISION_LOG: 'Додај у регистар одлука',
	DFT_NOTIFICATIONS_TEXT:
		'Медији ће се појавити овде. Пратите овај уређај за нове медије и ажурирања. Почетним информацијама о сесији може се приступити помоћу дугмета на врху екрана. Комуницирајте са водитељем користећи апликацију Поруке у доњем левом углу овог уређаја.',
	DECISION_LOG_HELP_TEXT:
		'Овде ће се појавити одговори и образложење вашег тима Моћи ћете да уредите сопствене одговоре и образложење тако што ћете кликнути на њих.',
	NO_DECISION_LOGS: 'Нема одлука за приказ',
	SAME_AS_SYSTEM: 'Исто као и систем',
	SELECT_AUDIO_OUTPUT_DEVICE: 'Изаберите аудио излазни уређај',
	TO: 'До',
	EVERYONE: 'Свима',
	MESSAGE_ENTRY_PLACEHOLDER: 'Унесите поруку',
}
