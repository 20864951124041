function getTime(): number {
	const time = new Date().getTime()

	// Redux complained if we get the state here directly as this function is called from within
	// a reducer.
	// const state = (store && store.getState()) || {}
	const state = window.state || ({} as StateTree)
	const diff = state.serverTimeDiff || 0

	return time + diff
}

export default getTime
