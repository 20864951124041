import React, { FunctionComponent } from 'react'

import TwilioClient from '../../util/TwilioClient'

import IconButton from '../../elements/IconButton'
// import VideoCallChatButton from './VideoCallChatButton'
import VideoCallMuteButton from './VideoCallMuteButton'
import VideoCallCameraButton from './VideoCallCameraButton'
import VideoCallScreenshareButton from './VideoCallScreenshareButton'
import VideoCallChatButton from './VideoCallChatButton'

type VideoCallButtonsTwilioProps = {
	onClickChangeLayout: () => unknown
	onClickStatistics: () => unknown
	onClickSettings: () => unknown
	onClickEndCall: () => unknown
	onClickChat: () => unknown
	showScreenShare: boolean
	showStatistics: boolean
	showSettings: boolean
	showChat: boolean
	showEndCall: boolean
	client: TwilioClient
	viewMode?: string
	allowChangeLayout?: boolean
}

const VideoCallButtonsTwilio: FunctionComponent<VideoCallButtonsTwilioProps> = props => {
	const { onClickChangeLayout, onClickSettings, onClickEndCall, onClickChat, onClickStatistics } = props
	const { showEndCall, showScreenShare, showStatistics, viewMode, client, allowChangeLayout = true } = props
	const { showSettings, showChat } = props
	console.log('showScreenShare', showScreenShare)

	const changeViewIcon = viewMode === 'TILE' ? 'speaker-view-light' : 'grid'
	const hangUpClass = 'video-call-buttons__hang-up'
	return (
		<div className="video-call-buttons">
			{showChat && <VideoCallChatButton client={client} onClick={onClickChat} />}
			{showScreenShare && <VideoCallScreenshareButton client={client} />}
			<IconButton
				iconName={changeViewIcon}
				onClick={onClickChangeLayout}
				title="Change layout"
				enabled={allowChangeLayout}
			/>
			{showEndCall && <IconButton className={hangUpClass} iconName="phone" onClick={onClickEndCall} title="End call" />}
			<VideoCallMuteButton client={client} />
			<VideoCallCameraButton client={client} />
			{showSettings && <IconButton iconName="cog" onClick={onClickSettings} title="Settings" />}
			{showStatistics && <IconButton iconName="chart-line" onClick={onClickStatistics} title="Statistics" />}
		</div>
	)
}

export default VideoCallButtonsTwilio
