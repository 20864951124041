import { connect } from 'react-redux'
import React, { useEffect, useRef, FunctionComponent } from 'react'

import releasesSelector from '../selectors/releases'
import sessionSelector from '../selectors/session'

import MediaFeedItem from './MediaFeedItem'

type NewsfeedItem = ScheduleRow & {
	time: number
	rowId: string
}

function getNewsfeed(groupId: string, session: MediaFeedProps['session'], releases: Release[]): NewsfeedItem[] {
	const schedule = session.schedule || []

	// Helper function - for any particular row, returns the timestamp it was
	// released to the current group (if it has been released)
	const getTimeReleased = (row: ScheduleRow) => {
		const _groupId = String(groupId)
		const releasesForRow = releases.filter(release => release.rowId === row.id)
		const releaseToGroup = releasesForRow.find(r => r.groupId === _groupId || r.groupId === 'ALL')
		return releaseToGroup && releaseToGroup.timestamp
	}

	const newsfeed = schedule.map(r => ({ ...r, time: getTimeReleased(r), rowId: r.id }))
	return newsfeed.filter(r => Boolean(r.time))
}

// =================================================================================================

type MediaFeedProps = {
	groupId: string
	session: CurrentSession | PreviousSession
	releases: Release[]
}

const MediaFeed: FunctionComponent<MediaFeedProps> = props => {
	const { groupId, session, releases } = props
	const newsfeed = groupId ? getNewsfeed(groupId, session, releases) : []

	// ===============================================================================================
	// The following block is to ensure that the media feed scrolls to the bottom if a new item is
	// received.
	const scroller = useRef(null)
	const prevLength = useRef(newsfeed.length)
	useEffect(() => {
		// This will get called whenever newsfeed.length changes
		// We can store the previous number of items as a ref (mutable value like instance variable.
		// It's like state but doesn't trigger a re-render), so we can check if it has increased.
		// If it has increased we will scroll to the bottom of the newsfeed.
		// This was so much easier with componentWillReceiveProps...
		if (newsfeed.length > prevLength.current) {
			// Scroll to bottom
			scroller.current.scrollTop = scroller.current.scrollHeight
		}
		prevLength.current = newsfeed.length
	}, [newsfeed.length])
	// ===============================================================================================

	// Map each newsfeed item to a MediaFeedItem component
	const content = newsfeed
		.sort((a, b) => a.time - b.time) // Oldest item first
		.map(item => <MediaFeedItem key={item.id} item={item} groupId={groupId} />)

	return (
		<div className="media-feed">
			<div className="media-feed__content" ref={scroller}>
				{content}
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<MediaFeedProps> => {
	const releases = releasesSelector(state)
	const session = sessionSelector(state)

	return { releases, session }
}
const actions = {}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Pick<MediaFeedProps, 'groupId'>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(MediaFeed)
