import mime from './mime-types'

export default function getMediaUrlForData(data: Blob | ArrayBuffer, extension: string): string {
	let mediaUrl
	// data = blob or arrayBuffer
	if (extension.toLowerCase() === '.pdf' && data instanceof ArrayBuffer) {
		mediaUrl = data
	} else {
		// data = blob
		const type = mime.lookup(`file${extension}`)
		const blob = new Blob([data], { type })
		const urlCreator = window.URL || window.webkitURL
		mediaUrl = urlCreator.createObjectURL(blob)
	}
	return mediaUrl
}
