import { connect } from 'react-redux'
import React, { FunctionComponent, useState } from 'react'

import { useMount } from 'react-use'

import config from '../../config'
import Actions from '../actions'

import Preloader from '../elements/Preloader'
import Button from '../elements/Button'

function errorBit(errors: Array<string>) {
	return (errors || []).map(e => (
		<div key={e} className="error">
			{e}
		</div>
	))
}

type EditCaseModalProps = {
	data: Case
	updateCase: (id: string, details: Partial<Case>) => void
	savingCase?: boolean
	errors: Array<string>
	clearErrors: () => void
}

const EditCaseModal: FunctionComponent<EditCaseModalProps> = props => {
	const { CASE_TITLE, CASE_DESCRIPTION, SAVE } = config.strings

	const { data = {} as Case, updateCase, savingCase, errors, clearErrors } = props

	const [title, setTitle] = useState(data.title || '')
	const [description, setDescription] = useState(data.description || '')

	useMount(() => clearErrors())

	// Handlers
	const onClickSave = async () => {
		updateCase(data.id, { title, description })
	}

	return (
		<div className="edit-case-modal">
			<div className="input">
				{CASE_TITLE}
				<input value={title} onChange={e => setTitle(e.target.value)} required />
			</div>
			<div className="input">
				{CASE_DESCRIPTION}
				<input value={description} onChange={e => setDescription(e.target.value)} required />
			</div>
			{errorBit(errors)}
			<div className="modal-footer">
				<Button text={SAVE} onClick={onClickSave} />
			</div>
			{savingCase && <Preloader />}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	errors: state.errors,
	saving: state.savingCase,
})
const actions = {
	updateCase: Actions.cases.updateCase,
	clearErrors: Actions.misc.clearErrors,
}
export default connect(mapStateToProps, actions)(EditCaseModal)
