/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import ReleaseScheduleRow from './ReleaseScheduleRow'

type ReleaseScheduleProps = {
	sessionId: string
	schedule: Array<SimpleObject>
	onRowChange: (data) => void
}

/**
 * Function to be called when a schedule row has been dragged and dropped into a new position.
 * @param {Object} result Metadata associated with item dropped and it's destination, provided react-beautiful-dnd.
 * @param {Array} schedule Original array of schedule rows.
 * @param {Function} onRowChange Function to call with updated schedule details to update app state.
 */
const onDragEnd = (result, schedule, originalData, onRowChange) => {
	// If item not dragged to a new position, return
	if (!result.destination) return

	// Sort schedule by index
	const newSchedule = [...schedule.map(s => ({ ...s }))]

	// Remove item from array and re-insert at new position
	const originalPosition = newSchedule.findIndex(r => r.index === result.source.index)
	const [removed] = newSchedule.splice(originalPosition, 1)
	newSchedule.splice(result.destination.index, 0, removed)

	// Update row indexes as a result
	newSchedule.forEach((row, i) => {
		const origRow = originalData.find(r => r.id === row.id)
		// console.log(`~ origRow`, origRow)
		if (row && origRow && origRow.index !== i) {
			onRowChange({ ...row, index: i })
		}
	})
}

function renderScheduleRow(sessionId, row, onDataChange) {
	const rowWithSessionId = { ...row, sessionId }

	return (
		<Draggable key={row.id} draggableId={row.id} index={row.index}>
			{provided => {
				const { innerRef, draggableProps, dragHandleProps } = provided
				return (
					<div className="schedule-row-wrapper" ref={innerRef} {...draggableProps} {...dragHandleProps} tabIndex={-1}>
						<ReleaseScheduleRow key={row.timestamp} data={rowWithSessionId} onDataChange={onDataChange} />
					</div>
				)
			}}
		</Draggable>
	)
}

const ReleaseSchedule: React.FC<ReleaseScheduleProps> = (props: ReleaseScheduleProps) => {
	const { sessionId, schedule, onRowChange } = props

	// Sort schedule rows by their 'index' and then reassign consecutive indexes to each row (for the
	// purposes of drag-and-drop functionality).
	// This is maintains backwards compatibility whilst we have schedule rows that might not yet be
	// assigned an index.
	const indexedSchedule = (schedule || []).map(r => ({ ...r }))
	indexedSchedule.sort((a, b) => {
		if (a.index !== b.index) return a.index > b.index ? 1 : -1
		// If rows do not have an index, order by ID
		return a.id > b.id ? 1 : -1
	})
	// eslint-disable-next-line no-param-reassign
	indexedSchedule.forEach((row, i) => (row.index = i))

	const releaseSchedRows = indexedSchedule.map(row => renderScheduleRow(sessionId, row, onRowChange))

	return (
		<DragDropContext onDragEnd={result => onDragEnd(result, indexedSchedule, schedule, onRowChange)}>
			<Droppable droppableId={`droppable-${sessionId}`}>
				{provided => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{releaseSchedRows}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	)
}

export default ReleaseSchedule
