export default {
	/* Macedonian */
	OK: `Во ред`,
	CANCEL: `Откажи`,
	TUTOR: `Фасилитатор`,
	CURRENT_SESSION: `Актуелна сесија:`,
	TEAM: `Тим`,
	SIGNOUT: `Одјави се`,
	INITIAL_UPDATE: `Информации за сесијата`,
	ADVICE: `Одлука`,
	RATIONALE: `Образложение`,
	MEDIA_FEED: `Медиумски прилози`,
	MESSAGES: `Пораки`,
	PREVSESSIONS: `Претходни сесии`,
	RETURN_TO_CURRENT: `Врати се на актуелната сесија`,
	SESSION_IS_PAUSED: `Сесијата е моментално ПАУЗИРАНА `,
	ATTRIB_CURR_AUTHOR: `Одлучувач`,
	SAVE: `Зачувај`,
	CHANGE: `Промени`,
	NO_AUTHOR: `Не е подесено`,
	NO_INITIAL_INFO: `Ве молиме почекајте да почне следната сесија`,
	DECISION_MAKER_SELECT: `Ве молиме изберете одлучувач...`,
	SESSION_COMPLETE: `Претходната сесија заврши`,
	OPENED_AT: `Отворена на`,
	SEND_MESSAGE_PLACEHOLDER: `Напиши порака`,
	SEND: `Испрати`,
	TO_ALL_GROUPS: `До сите тимови`,
	SHOW_TRANSLATION: `Покажи превод`,
	SHOW_ORIGINAL: `Покажи на изворен јазик`,
	CREDENTIALS_EXPIRED: `Вашите креденцијали истекоа. Ве молиме најавете се повторно.`,
	INCOMING_MESSAGE: `Дојдовна порака`,
	INCOMING_MESSAGE_TEXT: `Примена е нова порака`,
	INCOMING_MEDIA: `Дојдовна медиумска содржина `,
	INCOMING_MEDIA_TEXT: `Примена е нова медиумска содржина`,
	INCOMING_UPDATE: `Се прима ажурирање`,
	INCOMING_UPDATE_TEXT: `Примено е ажурирање`,
	CONFIRM_LOGOUT: `Сигурни ли сте дека сакате да се одјавите?`,
	MUTED_MESSAGE: `Во моментов микрофонот е исклучен`,
	NEWSTYPE_UPDATE: `Ажурирањето е преземено`,
	WARNING_TIME_MSG: `Остануваат уште 5 минути`,
	VIEW_INITIAL_UPDATE: `Преглед на сесијата`,
	INITIAL_UPDATE_INDEX: `Податоци за %%% сесијата`,
	VIEWING_PREVIOUS: `Преглед на сесија $$ од %%%`,
	TO: `До`,
	EVERYONE: `Сите`,
	MESSAGE_ENTRY_PLACEHOLDER: `Порака`,
	DEFAULT_VIDEO_WARNING_MESSSAGE: `Видео повикот ќе започне наскоро`,
	DEFAULT_VIDEO_WARNING_TITLE: `Предупредување`,
	SESSION_INFO_RECEIVED: `Примени се податоците за сесијата`,
	DECISION_LOG: `Дневник за одлуки`,
	ADD_DECISION_LOG: `Додади во дневникот за одлуки`,
	DFT_WELCOME_MESSAGE: `Добредојдовте на практичната платформа за учење „View360 Global“ 

Ве молиме почекајте да започне сесијата.`,
	CONFIRM_DELETE_DECISIONLOG: `Сигурни ли сте дека сакате да ја отстраните оваа одлука?`,
	SESSION_HAS_STARTED: `Сесијата започна`,
	CLICK_TO_OPEN: `Кликнете за да се отвори`,
	CLICK_TO_EXPAND: `Кликнете за да се зголеми`,
	EXPAND_TEXT: `Кликнете на копчето долу за видео во цела големина`,
	COLLAPSE: `Скриј`,
	EXPAND: `Прошири`,
	RECEIVED_AT: `Примено на`,
	AUDIO_PLAYER: `Аудио плеер`,
	VIDEO_PLAYER: `Видео плеер`,
	DFT_MESSAGES_TEXT: `Оваа апликација ќе ви овозможи да праќате и примате пораки од/до Фасилитаторот за време на сесијата.`,
	DFT_NOTIFICATIONS_TEXT: `Овде ќе се појавуваат медиумски содржини. На овој уред ќе добивате нови медиумски содржини и информации. 
Податоците за првичната сесија се достапни преку копчето најгоре на вашиот екран. 
Комуницирајте со Фасилитаторот со користење на апликацијата за пораки во долниот лев агол на овој уред.`,
	MOBILE_NUMBER_TITLE: `Телефонски број`,
	MOBILE_NUMBER_PLACEHOLDER: `Мобилен телефон `,
	MOBILE_NUMBER_DESC: `Ве молиме наведете го бројот на вашиот мобилен телефон за да добивате пораки за време на сесијата. Вашиот телефонски број ќе се користи само за време на интерактивната обука и автоматски ќе се избрише по 24 часа во согласност со нашата Општа регулатива за заштита на лични податоци и политиките за заштита на податоците.`,
	MOBILE_NUMBER_SKIP: `Прескокни`,
	INCOMING_CALL: `Дојдовен повик`,
	CALL_IN_PROGRESS: `Тековен повик`,
	PHONE_MESSAGE_SENT: `Телефонската порака е испратена`,
	CALL_FAILED: `Неуспешен повик`,
	NO_ANSWER: `Нема одговор`,
	CLICK_TO_RESEND: `Кликнете го копчето ИСПРАТИ ПОВТОРНО за пораката повторно да се испрати на вашиот уред.`,
	VOICE_MESSAGE_SENT: `Говорна порака е испратена до %%%.`,
	WENT_TO_VOICEMAIL: `Гласовната порака беше испратена до %%% но премина во вашата говорна пошта.`,
	CALL_FAILED_TEXT: `Гласовна порака беше испратена до %%% но не е успешно примена.`,
	CHECK_PHONE: `Ве молиме проверете го Вашиот телефон.`,
	AT: `На/во`,
	CALLING: `повикува`,
	RESEND: `Испрати повторно`,
	PLAY_HERE: `Пушти тука`,
	SESSION: `Сесија`,
	CAMERA: `Камера`,
	MICROPHONE: `Микрофон`,
	AUDIO_OUTPUT_DEVICE: `Уред за аудио излез`,
	SELECT_CAMERA: `Избери камера`,
	SELECT_MICROPHONE: `Избери микрофон`,
	SELECT_AUDIO_OUTPUT_DEVICE: `Избери уред за аудио излез`,
	SAME_AS_SYSTEM: `Исто како Системскиот`,

	CLOSE: `затвора`,
	CONTINUE: `Продолжи`,
	DECISION_LOG_HELP_TEXT: `Одлуките и образложението на вашиот тим ќе се појават овде. Ќе можете да ги уредувате вашите сопствени одлуки и образложение со кликнување на нив.`,
	NO_DECISION_LOGS: `Нема никакви одлуки.`,
	NOTEPAD: `Бележник`,
	NOTETAKER: `Носителот на белешки`,
	POPUP_TITLE_CONFIGUREVIDEO: 'КОНФИГУРИРАЈТЕ АУДИО/ВИДЕО',
	ENTER_ADVICE: `Внесете ја вашата одлука`,
	ENTER_RATIONALE: `Внесете го вашето образложение`,
}
