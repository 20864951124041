const NOTES_LOADED: Reducer = (state, action) => ({ ...state, notes: action.payload, loadingNotes: false })

const NOTES_UPDATED: Reducer = (state, action) => {
	const notes = [...(state.notes || [])]
	const newNotes = action.payload

	// Check for any lines which are new/updated
	newNotes.forEach(note => {
		const existing = notes.find(n => n.index === note.index)
		if (!existing) {
			notes.push(note)
		} else {
			existing.text = note.text
		}
	})

	return { ...state, notes: newNotes }
}

const NOTES_TAKER_CHANGED: Reducer = (state, action) => {
	return { ...state, notetaker: action.payload }
}

// =================================================================================================

const reducers = {
	NOTES_LOADED,
	NOTES_UPDATED,
	NOTES_TAKER_CHANGED,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
