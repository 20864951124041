import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import Actions from '../actions'
import config from '../../config'

import BubbleHeading from '../elements/BubbleHeading'
import Textarea from '../elements/Textarea'
import Switch from '../elements/Switch'
import Input from '../elements/Input'

const { NOTEPAD_ENABLED, PHONE_MESSAGES_ENABLED } = config

// =================================================================================================

type SectionProps = {
	disabled?: boolean
	className?: string
}
const Section: FunctionComponent<SectionProps> = props => {
	const { disabled = false, children, className = '' } = props
	let cls = `section ${className}`
	if (disabled) cls += ' section--disabled'
	return <div className={cls}>{children}</div>
}

type RowProps = {
	className?: string
}
const Row: FunctionComponent<RowProps> = props => {
	const { children, className = '' } = props
	return <div className={`row ${className}`}>{children}</div>
}

// =================================================================================================

type TutorSettingsProps = {
	settings: Settings
	updateSettings: (settings: Partial<Settings>) => void
}

const TutorSettings: FunctionComponent<TutorSettingsProps> = props => {
	const {
		SETTINGS,
		SETTINGS_WAITING_ROOM,
		SETTINGS_WAITING_ROOM_MSG,
		SETTINGS_WAITING_ROOM_INFO,
		// DFT_WELCOME_MESSAGE,
	} = config.strings

	const { settings, updateSettings } = props
	const {
		// waitingRoomEnabled = false,
		waitingRoomMessage,
		flashingEnabled = true,
		translateInitialUpdate = false,
		allowAnyoneToChangeAuthor = false,
		allowAnyoneToSendMessages = false,
		promptParticipantPhone = false,
		videoCallWarningTime = 0,
		videoCallWarningMessage = '',
		videoCallWarningTitle = '',
		videoCallParticipantsStartMuted = true,
		videoCallObserversStartMuted = false,
		videoCallFacilitatorsStartMuted = false,
		observerNewPasscodesAllowed = false,
		observerUpdateParticipantTeamAllowed = false,
		notepadEnabled = false,
		// observerSendMessagesAllowed = true,
	} = settings

	const onChange = (field, value) => {
		const update = { [field]: value }
		updateSettings(update)
	}

	const onChangeForSwitch = field => checked => onChange(field, checked)

	return (
		<div className="tutor-settings">
			<div className="tutor-settings__top">
				<BubbleHeading>{SETTINGS}</BubbleHeading>
			</div>
			<div className="tutor-settings__content">
				<Section>
					<Row>
						<h2>{SETTINGS_WAITING_ROOM}</h2>
						{/* <Switch value={waitingRoomEnabled} onChange={onChangeForSwitch('waitingRoomEnabled')} /> */}
					</Row>
					<p>{SETTINGS_WAITING_ROOM_INFO}</p>
					<Textarea
						onChange={e => onChange('waitingRoomMessage', e.target.value)}
						placeholder={SETTINGS_WAITING_ROOM_MSG}
						value={waitingRoomMessage}
					/>
				</Section>

				<Section>
					<Row>
						<h2>Translate session information</h2>
						<Switch value={translateInitialUpdate} onChange={onChangeForSwitch('translateInitialUpdate')} />
					</Row>
					<p>
						If enabled, the session information will automatically be translated for all participants into their
						selected language. They will also be given the option to see the information as it was originally written.
					</p>
				</Section>

				<Section>
					<Row>
						<h2>Allow any participant to change loggist</h2>
						<Switch value={allowAnyoneToChangeAuthor} onChange={onChangeForSwitch('allowAnyoneToChangeAuthor')} />
					</Row>
					<p>
						If enabled, any participant in a team will have the ability to change the loggist. Otherwise, only the
						<em> current </em>loggist can switch control to a different team member.
					</p>
				</Section>

				<Section>
					<Row>
						<h2>Allow any participant to send messages</h2>
						<Switch value={allowAnyoneToSendMessages} onChange={onChangeForSwitch('allowAnyoneToSendMessages')} />
					</Row>
					<p>Only the current loggist will be allowed to send messages to the facilitator unless enabled.</p>
				</Section>

				<Section>
					<Row>
						<h2>Flashing notifications</h2>
						<Switch value={flashingEnabled} onChange={onChangeForSwitch('flashingEnabled')} />
					</Row>
					<p>Enable/disable flashing notifications for all users and participants.</p>
				</Section>

				{NOTEPAD_ENABLED && (
					<Section>
						<Row>
							<h2>Session notepad</h2>
							<Switch value={notepadEnabled} onChange={onChangeForSwitch('notepadEnabled')} />
						</Row>
						<p>If enabled, participants will have accessed to a shared notepad during each session.</p>
					</Section>
				)}

				{PHONE_MESSAGES_ENABLED && (
					<Section>
						<Row>
							<h2>Ask for phone number at login</h2>
							<Switch value={promptParticipantPhone} onChange={onChangeForSwitch('promptParticipantPhone')} />
						</Row>
						<p>
							If enabled, participants will be asked for their phone number at login. This will then be used to send
							media as a voice call to their device. Media must be flagged as &quot;Can be sent to phone&quot;.
							Participants can choose not to provide their phone number.
						</p>
					</Section>
				)}

				<Section className="warningSection">
					<Row>
						<h2>Video call warning</h2>
					</Row>
					<p>
						Configure the message to be displayed to participants when a countdown warning is used prior to a video
						call. If this is left blank a warning will not be displayed.
					</p>
					<Input
						placeholder="Title"
						value={videoCallWarningTitle === ' ' ? '' : videoCallWarningTitle}
						onChange={e => onChange('videoCallWarningTitle', (e.target as HTMLInputElement).value || '')}
					/>
					<Textarea
						placeholder="Message"
						value={videoCallWarningMessage}
						onChange={e => onChange('videoCallWarningMessage', e.target.value)}
					/>
					<Row>
						<label htmlFor="video-call-warning-time">Number of seconds</label>
						<input
							type="number"
							id="video-call-warning-time"
							value={videoCallWarningTime}
							onChange={e => onChange('videoCallWarningTime', parseInt(e.target.value) || 0)}
						/>
					</Row>
				</Section>

				<Section>
					<Row>
						<h2>Video call default settings</h2>
					</Row>
					<Row className="label-paragraph-switch-block">
						<div>
							<label>Facilitator starts muted</label>
							<p>If selected, facilitators will begin on mute when starting a video call.</p>
						</div>
						<Switch
							value={videoCallFacilitatorsStartMuted}
							onChange={onChangeForSwitch('videoCallFacilitatorsStartMuted')}
						/>
					</Row>
					<Row className="label-paragraph-switch-block">
						<div>
							<label>Observers start muted</label>
							<p>If selected, observers will begin on mute when joining a video call.</p>
						</div>
						<Switch value={videoCallObserversStartMuted} onChange={onChangeForSwitch('videoCallObserversStartMuted')} />
					</Row>
					<Row className="label-paragraph-switch-block">
						<div>
							<label>Participants start muted</label>
							<p>If selected, participants will begin on mute when joining a video call.</p>
						</div>
						<Switch
							value={videoCallParticipantsStartMuted}
							onChange={onChangeForSwitch('videoCallParticipantsStartMuted')}
						/>
					</Row>
				</Section>

				<Section>
					<Row>
						<h2>Observer permissions</h2>
					</Row>
					<Row className="label-paragraph-switch-block">
						<div>
							<label>Create new participant passcodes</label>
							<p>If allowed, observers can create new passwords for teams/participants.</p>
						</div>
						<Switch value={observerNewPasscodesAllowed} onChange={onChangeForSwitch('observerNewPasscodesAllowed')} />
					</Row>
					<Row className="label-paragraph-switch-block">
						<div>
							<label>Update participant team</label>
							<p>If allowed, observers can change the team that each participant belongs to.</p>
						</div>
						<Switch
							value={observerUpdateParticipantTeamAllowed}
							onChange={onChangeForSwitch('observerUpdateParticipantTeamAllowed')}
						/>
					</Row>
				</Section>
			</div>
		</div>
	)
}

/*
	{NOTEPAD_ENABLED && (
		<SwitchBlock
			id="notepadEnabled"
			value={notepadEnabled}
			label="Session notepad"
			description="If enabled, participants will have accessed to a shared notepad during each session."
			onChange={onChange}
		/>
	)} 
*/

// type SwitchBlockProps = {
// 	label: string
// 	value: boolean
// 	id: string
// 	onChange: (id: string, val: boolean) => void
// 	description?: string
// }

// const SwitchBlock: FunctionComponent<SwitchBlockProps> = ({ id, label, onChange, value, description }) => (
// 	<Section>
// 		<Row>
// 			<h2>{label}</h2>
// 			<Switch value={value} onChange={checked => onChange(id, checked)} />
// 		</Row>
// 		{description && <p>{description}</p>}
// 	</Section>
// )

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	settings: state.settings || {},
})
const actions = {
	updateSettings: Actions.settings.updateSettings,
}
export default connect(mapStateToProps, actions)(TutorSettings)
