import React, { FunctionComponent } from 'react'

import Button from '../elements/Button'
import config from '../../config'

type CaseProps = {
	data: Case
	allowDelete: boolean
	allowSelect: boolean
	isSelected: boolean
	onClickOpen: () => void
	onClickDelete: () => void
	onClickEdit: () => void
}

const Case: FunctionComponent<CaseProps> = props => {
	// const { data, showOpen, showDelete, onClickOpen, onClickDelete, onClickEdit } = props
	const { data, allowDelete, allowSelect, isSelected, onClickOpen, onClickDelete, onClickEdit } = props
	const { OPEN, ACTIVE, DELETE, EDIT, CASE_DESCRIPTION } = config.strings

	return (
		<div className="case">
			<div className="row">
				<div className="case__left">
					<h2>{data.title}</h2>
					<div className="case__label">{data.description && CASE_DESCRIPTION}</div>
					<div className="case__description">{data.description}</div>
				</div>
				<div className="case__right">
					<Button text={isSelected ? ACTIVE : OPEN} enabled={allowSelect} onClick={onClickOpen} />
					<Button text={EDIT} enabled onClick={onClickEdit} />
					<Button text={DELETE} enabled={allowDelete} onClick={onClickDelete} />
				</div>
			</div>
		</div>
	)
}

export default Case
