import FineUploaderS3 from 'fine-uploader-wrappers/s3'
import { v4 } from 'uuid'

import OnlineComms from '../core/OnlineComms'
import config from '../../config'

type AddToCaseCallback = (filename: string, id: string) => void
type ErrorCallback = (errorReason: string) => void

function s3MediaUploadConfiguration(addToCase: AddToCaseCallback, errorCallback: ErrorCallback): typeof FineUploaderS3 {
	const { SERVER_URL_MEDIA, MEDIA_BUCKET, MEDIA_UPLOAD_ACCESS_KEY, MEDIA_ACL, REGION } = config
	const keys = {}

	return new FineUploaderS3({
		options: {
			request: {
				endpoint: MEDIA_BUCKET,
				accessKey: MEDIA_UPLOAD_ACCESS_KEY,
			},
			signature: {
				endpoint: `${SERVER_URL_MEDIA}/auth/upload`,
				version: 4,
				customHeaders: {
					Authorization: `Bearer ${OnlineComms.authToken}`,
				},
			},
			chunking: {
				enabled: true,
			},
			callbacks: {
				onComplete: (id, fileName, responseJSON) => {
					if (responseJSON.success) addToCase(fileName, keys[id])
				},
				onError: (id, name, errorReason) => {
					errorCallback(errorReason)
				},
			},
			validation: {
				acceptFiles: '.jpg,.png,.jpeg,.gif,.tif,.mp4,.m4a,.mp3,.pdf',
				allowedExtensions: ['jpg', 'png', 'jpeg', 'gif', 'tif', 'mp4', 'm4a', 'mp3', 'pdf'],
			},
			objectProperties: {
				region: REGION,
				key: id => {
					keys[id] = v4()
					return keys[id]
				},
				// Objects cannot be accessed by public using S3 URL
				acl: MEDIA_ACL || 'public-read',
				// Objects are encrypted in bucket (AES-256)
				serverSideEncryption: true,
			},
		},
	})
}

export default s3MediaUploadConfiguration
