type Entity = {
	id?: string
	key?: string
	timestamp?: number
}

function getId(entity: Entity): string {
	return (entity.id || entity.key || entity.timestamp || '').toString()
}

export default getId
