/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useInterval } from 'react-use'

import Actions from '../../actions'
import config from '../../../config'

import getMediaFeed, { NotificationData } from '../../selectors/mediaFeed'
import selectedMediaSelector from '../../selectors/selectedMedia'
import getMediaUrlForData from '../../util/getMediaUrlForData'
import releasesSelector from '../../selectors/releases'
import sessionSelector from '../../selectors/session'
import MediaLibrary from '../../core/MediaLibrary'
import IconButton from '../../elements/IconButton'
import Preloader from '../../elements/Preloader'
import Button from '../../elements/Button'
import AppHeaderBar from './AppHeaderBar'
import Row from '../../elements/Row'
import DivWithInert from '../../elements/DivWithInert'

const { VIDEO_TYPES } = config

type VideoPlayerProps = {
	notification: NotificationData
	scheduleRow: ScheduleRow
	mediaItem: MediaItem
	closeMedia: () => void
	expandMedia: (expanded: boolean) => void
	markMediaAsSeen: (id: string) => void
	getMediaData: (id: string) => Promise<Blob>
	active?: boolean
}

const formatVideoTime = secs => {
	if (!secs) return '00:00'
	const minsStr = String(Math.abs(Math.floor(secs / 60))).padStart(2, '0')
	const secsStr = String(Math.abs(Math.floor(secs % 60))).padStart(2, '0')
	let fullTime = `${minsStr}:${secsStr}`
	if (secs < 0) fullTime = `-${fullTime}`
	return fullTime
}

function formatNotificationTime(time: number | string): string {
	return new Date(time).toTimeString().substr(0, 5)
}

const VideoPlayer: FunctionComponent<VideoPlayerProps> = props => {
	const { notification, mediaItem, getMediaData, closeMedia, markMediaAsSeen, expandMedia, active } = props

	const { VIDEO_PLAYER, CLICK_TO_EXPAND, EXPAND_TEXT, EXPAND, RECEIVED_AT } = config.strings

	const [mediaId, setMediaId] = useState(null)
	const [mediaUrl, setMediaUrl] = useState(null)
	const [playing, setPlaying] = useState(false)
	const [loading, setLoading] = useState(true)
	const [duration, setDuration] = useState(0)
	const [position, setPosition] = useState(0)
	const [error, setError] = useState('')
	const videoPlayer = useRef<HTMLVideoElement>(null)

	// Update progress bar at regular intervals
	useInterval(() => {
		if (videoPlayer.current) setPosition(videoPlayer.current.currentTime)
	}, 500)

	useEffect(() => {
		const { id, extension } = mediaItem || {}
		if (!id) return
		if (!VIDEO_TYPES.includes(extension)) return

		if (mediaId === id) return
		setLoading(true)
		setMediaId(id)

		// If we already have this media cached, get the data URL for it
		const mediaFromLibrary = MediaLibrary.get(id)

		if (mediaFromLibrary) {
			setMediaUrl(getMediaUrlForData(mediaFromLibrary.data, extension))
			setLoading(false)
			markMediaAsSeen(id)
			return
		}

		// We need to get the media data/URL and then update this in state so we can re-render
		getMediaData(id)
			.then(data => {
				setLoading(false)
				setMediaUrl(getMediaUrlForData(data, extension))
				markMediaAsSeen(id)
			})
			.catch(err => {
				console.error(err)
				setLoading(false)
				setMediaUrl(null)
				setError('Media could not be loaded')
			})
	}, [mediaItem])

	// If the media URL for our video player changes then we need to ensure that it is loaded
	// https://stackoverflow.com/a/43578104/757237
	useEffect(() => {
		const videoElement = videoPlayer.current
		if (!videoElement) return
		videoElement.pause()
		videoElement.load()
		// Autoplay the video when it is loaded
		videoElement.play()
	}, [mediaUrl])

	// -----------------------------------------------------------------------------------------------
	// Event handlers

	const onClickPlay = () => {
		const videoElement = videoPlayer.current
		if (!videoElement) return
		if (videoElement.paused) {
			videoElement.play()
		} else {
			videoElement.pause()
		}
	}

	const onVideoStateChanged = () => {
		const videoElement = videoPlayer.current
		setPlaying(!videoElement.paused)
	}

	const onClose = () => {
		closeMedia()
		setMediaId(null)
		const videoElement = videoPlayer.current
		if (videoElement) {
			videoElement.pause()
			videoElement.currentTime = 0
		}
	}

	const onClickExpand = () => {
		setMediaId(null)
		const videoElement = videoPlayer.current
		if (videoElement) {
			videoElement.pause()
			videoElement.currentTime = 0
		}
		expandMedia(true)
	}

	const onClickProgressBar = (e: React.MouseEvent<HTMLProgressElement, MouseEvent>) => {
		if (!videoPlayer.current) return
		const video = videoPlayer.current
		const progressBar = e.target as HTMLProgressElement
		const pos = (e.pageX - progressBar.getBoundingClientRect().x) / progressBar.offsetWidth
		const newPosition = pos * video.duration
		video.currentTime = newPosition
		setPosition(newPosition)
	}

	const onClickSkipForward = () => {
		videoPlayer.current.currentTime += 10
		setPosition(videoPlayer.current.currentTime)
	}

	const onClickSkipBackward = () => {
		videoPlayer.current.currentTime = 0
		setPosition(videoPlayer.current.currentTime)
	}

	// -----------------------------------------------------------------------------------------------

	const playButtonIcon = playing ? 'pause' : 'play'

	let content: ReactElement = null

	const timeRemaining = position - duration

	if (loading) {
		content = <Preloader />
	} else if (error) {
		content = <p>{error}</p>
	} else {
		content = (
			<>
				<div className="video-player__titles">
					<h3>{mediaItem?.name || 'Unknown'}</h3>
					<p>
						{RECEIVED_AT} {formatNotificationTime(notification.time)}
					</p>
				</div>

				<div className="video-player__video-container">
					<video
						ref={videoPlayer}
						controls={false}
						disablePictureInPicture
						onDurationChange={() => setDuration(videoPlayer.current.duration)}
						onEnded={onVideoStateChanged}
						onPause={onVideoStateChanged}
						onPlay={onVideoStateChanged}
					>
						<source src={active ? mediaUrl : null} />
					</video>

					<div className="video-player__controls-overlay" onClick={onClickExpand}>
						<IconButton iconName="arrows-maximize" />
						<p>{CLICK_TO_EXPAND}</p>
					</div>
				</div>

				<Row>
					<div className="video-player__clock1">{formatVideoTime(position)}</div>
					<progress value={position} max={duration} onMouseDown={onClickProgressBar} />
					<div className="video-player__clock2">{formatVideoTime(timeRemaining)}</div>
				</Row>

				<Row className="video-player__controls">
					<IconButton iconName="backward-step" onClick={onClickSkipBackward} />
					<IconButton iconName={playButtonIcon} onClick={onClickPlay} />
					<IconButton iconName="forward-step" onClick={onClickSkipForward} />
				</Row>

				{active && (
					<div className="video-player__expand-section">
						<p>{EXPAND_TEXT}</p>
						<Button primary icon="arrows-maximize" onClick={onClickExpand}>
							{EXPAND}
						</Button>
					</div>
				)}
			</>
		)
	}

	return (
		<DivWithInert className="video-player" inert={!active}>
			<AppHeaderBar active={active} onClickAlert={onClose} />
			<div className="app-header">
				<IconButton iconName="chevron-left-alt" onClick={onClose} />
				<h2>{VIDEO_PLAYER}</h2>
			</div>

			<div className="video-player__content">{content}</div>
		</DivWithInert>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const { selectedScheduleRowId } = state
	const session = sessionSelector(state)
	const { selectedRow: scheduleRow, mediaItem } = selectedMediaSelector(state)

	const releases = releasesSelector(state)
	const groupId = state?.group?.id
	const notifications = groupId ? getMediaFeed(groupId, session, releases) : []
	const notification = notifications.find(n => n.rowId === selectedScheduleRowId) || ({} as NotificationData)

	return {
		notification,
		scheduleRow,
		mediaItem,
	}
}
const actions = {
	markMediaAsSeen: Actions.media.markMediaAsSeen,
	getMediaData: Actions.media.getMediaData,
	expandMedia: Actions.media.expandMedia,
	closeMedia: Actions.media.closeMedia,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<VideoPlayerProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(VideoPlayer)
