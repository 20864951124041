import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useUnmount } from 'react-use'

import { IVideoClient, VideoClientEvents } from './VideoTypes'
import IconButton from '../../elements/IconButton'

import config from '../../../config'

type VideoCallScreenshareButtonProps = {
	client: IVideoClient
}

const VideoCallScreenshareButton: FunctionComponent<VideoCallScreenshareButtonProps> = props => {
	const { client } = props
	const [isScreenshareActive, setIsScreenshareActive] = useState(false)

	const { SHARE_SCREEN } = config.strings

	// Array of JitsiClient event handler references
	const eventHandlerRefs = useRef<number[]>([])

	useEffect(() => {
		if (!client) return
		const refresh = () => setIsScreenshareActive(client?.isScreenshareActive())

		// When the local participant is changed in some way, check that the button state matches the client
		const eventHandlers = eventHandlerRefs.current
		eventHandlers.push(client.on(VideoClientEvents.LOCAL_PARTICIPANT_CHANGED, refresh))
		// Also do that now to initialise the state
		refresh()
	}, [client])

	// On unmount, detach event handler callbacks from Jitsi client
	useUnmount(() => eventHandlerRefs.current.forEach(ref => client.off(ref)))

	// Toggle screensharing when button is clicked
	const onClick = () => {
		if (!client) return
		client.toggleScreenShare()
	}

	return <IconButton iconName="desktop" onClick={onClick} selected={isScreenshareActive} title={SHARE_SCREEN} />
}

export default VideoCallScreenshareButton
