/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import config from '../../config'
import Button from '../elements/Button'
import IconButton from '../elements/IconButton'

type PreviousSessionProps = {
	selected?: boolean
	previousSession: PreviousSession
	allowDelete?: boolean
	showExportOptions?: boolean
	clickSession?: (session: SimpleObject) => unknown
	deleteSession?: (session: SimpleObject) => unknown
	exportOriginalSession?: (session: SimpleObject) => unknown
	clickShowExportOptions?: (session: SimpleObject) => unknown
	exportTranslatedSession?: (session: SimpleObject) => unknown
}

const PreviousSession: React.FC<PreviousSessionProps> = (props: PreviousSessionProps) => {
	// function PreviousSession(props: PreviousSessionProps): ReactElement<PreviousSessionProps> {
	const { DOWNLOAD_VERBATIM, DOWNLOAD_TRANSLATED, SESSION } = config.strings

	const {
		selected = false,
		previousSession = {} as PreviousSession,
		allowDelete = true,
		clickSession,
		deleteSession,
		exportOriginalSession,
		exportTranslatedSession,
		showExportOptions = false,
		clickShowExportOptions,
	} = props

	let className = 'prev-session'
	if (selected) className += ' prev-session--selected'

	let buttons
	if (showExportOptions) {
		buttons = (
			<>
				<Button onClick={() => exportOriginalSession(previousSession)} text={DOWNLOAD_VERBATIM} />
				<Button onClick={() => exportTranslatedSession(previousSession)} text={DOWNLOAD_TRANSLATED} />
			</>
		)
	} else {
		buttons = (
			<>
				<IconButton iconName="download" onClick={() => clickShowExportOptions(previousSession)} />
				{allowDelete && <IconButton iconName="trash-alt" onClick={() => deleteSession(previousSession)} />}
			</>
		)
	}

	if (!previousSession.timestamp) return null

	const onClick = () => clickSession(previousSession)
	const formattedTime = new Date(previousSession.timestamp).toString().substr(0, 21)
	return (
		<div
			className={className}
			onClick={onClick}
			role="button"
			aria-label={`View previous session ${previousSession.index || 0} - ${formattedTime}`}
			tabIndex={0}
			onKeyPress={onClick}
		>
			<div className="left">
				<h2>
					{SESSION} {previousSession.index || 0}
				</h2>
				<span className="prev-session__time">{formattedTime}</span>
			</div>
			<div className="right">{buttons}</div>
		</div>
	)
}

export default PreviousSession
