function CHAT_MESSAGE_SENT(state: StateTree, action: ReduxAction) {
	const message: ChatMessage = action.payload
	return { ...state, chat: [...state.chat, message] }
}
function CHAT_MESSAGE_RECEIVED(state: StateTree, action: ReduxAction) {
	const message: ChatMessage = action.payload
	return { ...state, chat: [...state.chat, message] }
}

//==================================================================================================

const reducers = {
	CHAT_MESSAGE_SENT,
	CHAT_MESSAGE_RECEIVED,
}

const INITIAL_STATE = {
	chat: [],
}

// =================================================================================================

function handler(state: StateTree, action: ReduxAction): StateTree {
	let newState = { ...INITIAL_STATE, ...state }
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default handler
