import action from '../util/action'

import types from './types'

const { CHAT_MESSAGE_SENT, CHAT_MESSAGE_RECEIVED } = types

export const sendChatMessage = (message: ChatMessage) => (dispatch: Dispatch): void => {
	dispatch(action(CHAT_MESSAGE_SENT, { ...message, timestamp: message.timestamp || new Date().getTime() }))
}

export const receiveChatMessage = (message: ChatMessage) => (dispatch: Dispatch): void => {
	dispatch(action(CHAT_MESSAGE_RECEIVED, { ...message, timestamp: message.timestamp || new Date().getTime() }))
}

export default {
	receiveChatMessage,
	sendChatMessage,
}
