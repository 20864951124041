import React, { FunctionComponent } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { useMount } from 'react-use'

import Actions from '../actions'

import SocketHandler from '../components/SocketHandler'

import ConfirmationHelper from '../util/ConfirmationHelper'
import ParticipantView from './ParticipantView'
import LoadingScreen from './LoadingScreen'
import TutorView from './TutorView'
import Page404 from './Page404'
import Login from './Login'

import history from '../core/history'
import '../core/SocketComms'

import VideoTestAgora from './VideoTestAgora'
import VideoTestTwilio from './VideoTestTwilio'

// Make available globally to be used from Electron
window.ConfirmationHelper = ConfirmationHelper

type AppProps = {
	initialiseApp: () => void
	initialised: boolean
	darkMode: boolean
}

const App: FunctionComponent<AppProps> = props => {
	const { initialiseApp, initialised, darkMode } = props
	const showVideoTest = window.location.pathname.includes('agora') || window.location.pathname.includes('twilio')

	useMount(() => {
		if (!showVideoTest) initialiseApp()
	})

	if (!showVideoTest && !initialised) {
		return <LoadingScreen />
	}

	const className = `app ${darkMode ? ' dark-theme' : ' light-theme'}`

	return (
		<Router history={history}>
			<div className={className}>
				<Switch>
					<Route path="/twilio" component={VideoTestTwilio} />
					<Route path="/agora" component={VideoTestAgora} />
					<Route path="/facilitator" component={TutorView} />
					<Route path="/participant" component={ParticipantView} />
					<Route path="/login" component={Login} />
					<Route exact path="/" component={Login} />
					<Route component={Page404} />
				</Switch>
				<SocketHandler />
			</div>
		</Router>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	initialised: Boolean(state?.initialised),
	darkMode: state.darkMode,
})
const actions: Partial<AppProps> = {
	initialiseApp: Actions.misc.initialiseApp,
}
export default connect(mapStateToProps, actions)(App)
