/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useMount, useUnmount, useWindowSize } from 'react-use'
import { largestRect } from 'rect-scaler'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { CaptureContext } from '@sentry/types'

import Actions from '../../actions'
import config from '../../../config'

import { ParticipantDetails, VideoClientEvents } from './VideoTypes'
import TwilioClient from '../../util/TwilioClient'

import loggedInAsParticipantSelector from '../../selectors/loggedInAsParticipant'
import loggedInAsObserverSelector from '../../selectors/loggedInAsObserver'
import loggedInAsGroupSelector from '../../selectors/loggedInAsGroup'
import facilitatorIdSelector from '../../selectors/facilitatorId'

import calculateVideoCallParticipantsToDisplay from '../../util/calculateVideoCallParticipantsToDisplay'
import calculateDominantSpeaker from '../../util/calculateDominantSpeaker'

// import InterpreterControls from './VideoCallInterpreterControls'
import Chat from './Chat'

// import Button from '../elements/Button'
// import Switch from '../elements/Switch'
import VideoCallParticipantTwilio from './VideoCallParticipantTwilio'
import VideoCallButtonsTwilio from './VideoCallButtonsTwilio'

type VideoCallTwilioProps = {
	displayName: string
	domain: string
	callId: string

	selectedAudioOut?: string
	selectedCamera?: string
	selectedMic?: string
	selectAudioOut?: (deviceId: string) => void
	selectCamera?: typeof Actions.videoconf.selectCamera
	selectMic?: (deviceId: string) => void

	displayModalPopup?: typeof Actions.misc.displayModalPopup
	notifyMainCall?: (callId: string, participantId: string) => void
	endMainCall?: () => void

	loggedInAsObserver?: boolean
	loggedInAsParticipant?: boolean
	loggedInAsFacilitator?: boolean

	selectedInterpreter?: string
	selectInterpreter?: typeof Actions.videoconf.selectInterpreter
	isInterpreter?: boolean
	interpreterChannel?: string
	interpreterOnMainChannel?: boolean
	interpreterDetails?: InterpreterDetails

	interpreterChangeChannel?: (toMainChannel: boolean) => void

	clientId?: string
	facilitatorId?: string
	participantId?: string
	interpreters?: VideoConfDetails['interpreters']
	participants?: Participant[]

	videoCallParticipantsStartMuted: boolean
	videoCallObserversStartMuted: boolean
	videoCallFacilitatorsStartMuted: boolean
}

const globalVars = {
	interpreters: [],
}

function VideoCallTwilio(props: VideoCallTwilioProps): ReactElement<VideoCallTwilioProps> {
	const { displayName, displayModalPopup, clientId } = props
	const { interpreters, selectedInterpreter, callId } = props
	let { facilitatorId } = props
	if (!facilitatorId) facilitatorId = 'james@jessian.io'
	const { loggedInAsObserver, loggedInAsFacilitator, loggedInAsParticipant } = props
	// const { selectInterpreter, interpreterChangeChannel, interpreterDetails } = props
	const { interpreterOnMainChannel, isInterpreter, participants } = props
	const { interpreterChannel } = props

	const isVideoTest = callId === 'v360gvideotest'

	// const { selectCamera, selectMic, selectAudioOut, selectedAudioOut } = props
	const { selectedAudioOut, selectedCamera = null, selectedMic = null } = props
	const {
		videoCallParticipantsStartMuted = true,
		videoCallObserversStartMuted = false,
		videoCallFacilitatorsStartMuted = false,
	} = props

	const { MUTED_MESSAGE } = config.strings

	type Speakers = Record<string, Date>

	// eslint-disable-next-line react/destructuring-assignment
	const v360ParticipantId = props.participantId

	globalVars.interpreters = interpreters

	const { notifyMainCall, endMainCall } = props

	const client = useRef<TwilioClient>(null)
	const eventHandlerRefs = useRef<Array<number>>([])
	const [remoteParticipants, setRemoteParticipants] = useState<ParticipantDetails[]>([])
	const [localError] = useState('')

	const [viewMode, setViewMode] = useState('TILE')
	const [selectedSpeakerId, setSelectedSpeakerId] = useState(null)
	const [muteMainAudio] = useState(false)
	const [chatOpen, setChatOpen] = useState(false)
	const [localParticipant, setLocalParticipant] = useState({} as ParticipantDetails)
	const [speakers, setSpeakers] = useState<Speakers>({})

	const buttonArea = useRef<HTMLDivElement>()
	const tileview = useRef<HTMLDivElement>()
	const mainArea = useRef<HTMLDivElement>()
	const joined = useRef<boolean>(false)

	useMount(async () => {
		document.body.classList.add('in-call')

		// Initialise Jitsi client
		const twilioClient = new TwilioClient({ conferenceId: callId, displayName })
		client.current = twilioClient
		window.VideoClient = twilioClient

		// Set some custom properties to be assigned to participants in the room (such as the observer ID for observers)
		if (loggedInAsObserver) twilioClient.setProperty('clientId', clientId)
		else twilioClient.setProperty('clientId', '')

		if (loggedInAsParticipant) twilioClient.setProperty('participantId', v360ParticipantId)
		else twilioClient.setProperty('participantId', '')

		if (loggedInAsFacilitator) client.current.setProperty('isFacilitator', 'true')

		if (loggedInAsParticipant) displayModalPopup('')

		const { REMOTE_PARTICIPANTS_CHANGED, LOCAL_PARTICIPANT_CHANGED, DOMINANT_SPEAKER_CHANGED } = VideoClientEvents
		const { ROOM_JOINED } = VideoClientEvents

		// Helper function for adding event listeners to the jitsi client and keeping an array of the
		// references we get back, which allows us to easily detach those event handlers later.
		const addEventListener = (type: VideoClientEvents, callback: (...args: Array<unknown>) => void) => {
			eventHandlers.push(twilioClient.on(type, callback))
		}

		const eventHandlers = eventHandlerRefs.current
		// Add event listeners
		addEventListener(LOCAL_PARTICIPANT_CHANGED, () => {
			const twilioClient = client.current
			setLocalParticipant({ ...twilioClient.localParticipant })
			// setLocalParticipant(twilioClient.localParticipant)

			// const outputDevice = twilioClient.getAudioOutputDevice()
			// if (outputDevice && !selectedAudioOut) selectAudioOut(outputDevice)

			const { videoTrack } = twilioClient.localParticipant

			// const audioDeviceId = audioTrack?.deviceId
			// const selectedMicDifferent = !selectedMic || selectedMic !== audioDeviceId
			// if (audioTrack && selectedMicDifferent) selectMic(audioDeviceId)

			// const videoDeviceId = videoTrack?.deviceId
			// const selectedCameraDifferent = !selectedCamera || selectedCamera !== videoDeviceId
			// if (videoTrack && selectedCameraDifferent && videoTrack.videoType !== 'desktop') selectCamera(videoDeviceId)

			// If we are screensharing, switch to SPEAKER mode
			if (videoTrack && videoTrack.videoType === 'desktop') {
				setViewMode('SPEAKER')
				const context: CaptureContext = { user: { username: displayName } }
				Sentry.captureMessage('Switched to speaker view - LOCAL_PARTICIPANT_CHANGED', context)
			}

			// if (twilioClient.videoUnavailable) {
			// 	setLocalError('Video device unavailable')
			// } else {
			// 	setLocalError('')
			// }
		})

		addEventListener(REMOTE_PARTICIPANTS_CHANGED, () => {
			setRemoteParticipants([...twilioClient.remoteParticipants])

			// If someone is screensharing, switch to SPEAKER mode
			if (twilioClient.remoteParticipants.find(p => p.videoTrack && p.videoTrack.videoType === 'desktop')) {
				setViewMode('SPEAKER')
				const context: CaptureContext = { user: { username: displayName } }
				Sentry.captureMessage('Switched to speaker view - REMOTE_PARTICIPANTS_CHANGED', context)
			}
		})

		// Notify all users that new call has started
		addEventListener(ROOM_JOINED, () => {
			if (joined.current) return
			joined.current = true
			setLocalParticipant({ ...client.current.localParticipant })
			// client.current.setMaxReceivedVideoHeight(480)
			if (loggedInAsFacilitator) notifyMainCall(callId, twilioClient.localParticipant.participantId)
		})

		addEventListener(DOMINANT_SPEAKER_CHANGED, ({ participantId }) => {
			// Update the 'lastSpoke' time for this participant in our list of speakers.
			// It will help us determine if we want to show this participant full screen as the
			// dominant speaker or not. We might choose not to, for instance if they are an interpreter.
			setSpeakers(prevSpeakers => ({ ...prevSpeakers, [participantId]: new Date() }))
		})

		twilioClient.connect({
			cameraDeviceId: selectedCamera,
			audioInputDeviceId: selectedMic,
			startAudioMuted:
				isVideoTest ||
				(loggedInAsFacilitator && videoCallFacilitatorsStartMuted) ||
				(loggedInAsParticipant && videoCallParticipantsStartMuted) ||
				(loggedInAsObserver && videoCallObserversStartMuted),
		})
	})

	useUnmount(async () => {
		console.log('useUnmounting video call')
		document.body.classList.remove('in-call')
		if (!client.current) return

		// Detach/remove all event listeners from the active video client
		// eventHandlerRefs.current.forEach(ref => client.current.off(ref))

		// Also "unload" the video client
		await client.current.unload()
	})

	// Switch camera/audio devices used by video client if they have changed in global state (i.e. in the user's settings)
	useEffect(() => {
		if (client.current) client.current.changeVideoInput(selectedCamera)
	}, [selectedCamera])

	useEffect(() => {
		if (client.current) client.current.changeAudioInput(selectedMic)
	}, [selectedMic])

	// useEffect(() => {
	// 	setMuteMainAudio(false)
	// 	if (loggedInAsParticipant && client.current) {
	// 		client.current.setProperty('selectedInterpreter', selectedInterpreter || '')
	// 	}
	// }, [selectedInterpreter])

	useWindowSize() // use this to trigger re-render when window size changes

	let tileVideoSize = { width: null, height: null, rows: null }

	if (viewMode === 'TILE' && buttonArea.current && mainArea.current) {
		// Calculate the height of the tile area even if it isn't rendered yet
		const containerHeight = mainArea.current.clientHeight - (buttonArea.current.clientHeight + 25)
		const containerWidth = mainArea.current.clientWidth - 14 // 7px margin
		const numRects = remoteParticipants.length + 1
		try {
			const { width, height, rows } = largestRect(containerWidth, containerHeight, numRects, 4, 3) // Aspect-ratio
			// Set window width/height to state
			tileVideoSize = { width, height, rows }
		} catch (err) {
			// Set window width/height to state
			tileVideoSize = { width: 400, height: 300, rows: Math.ceil(numRects / 3) }
		}
	}

	// -----------------------------------------------------------------------------------------------

	// -----------------------------------------------------------------------------------------------
	// When a participant is selected to be viewed full screen, we redisplay the current speaker after 5 seconds
	type Timeout = ReturnType<typeof setTimeout>
	const selectedSpeakerTimer = useRef<Timeout>()
	const onClickParticipant = (id: string) => {
		clearTimeout(selectedSpeakerTimer.current)
		selectedSpeakerTimer.current = setTimeout(() => setSelectedSpeakerId(null), 5000)
		setViewMode('SPEAKER')
		const context: CaptureContext = { user: { username: displayName } }
		Sentry.captureMessage('Switched to speaker view - click participant', context)
		setSelectedSpeakerId(id)
	}

	// -----------------------------------------------------------------------------------------------
	// Determine the fullscreen speaker
	// Check if selected speaker is local participant or a remote participant (or nothing)

	let dominantSpeaker = localParticipant

	if (viewMode === 'SPEAKER') {
		dominantSpeaker = calculateDominantSpeaker({
			speakers,
			clientId,
			remoteParticipants,
			localParticipant,
			interpreters,
			selectedInterpreter,
			loggedInAsFacilitator,
			loggedInAsObserver,
			loggedInAsParticipant,
		})
	}

	const selectedSpeaker =
		(localParticipant?.participantId === selectedSpeakerId && localParticipant) ||
		remoteParticipants.find(p => p.participantId === selectedSpeakerId)

	const participantWhoIsScreensharing =
		(localParticipant?.videoTrack?.videoType === 'desktop' && localParticipant) ||
		remoteParticipants.find(p => p.videoTrack?.videoType === 'desktop')

	const fullScreenSpeaker = selectedSpeaker || participantWhoIsScreensharing || dominantSpeaker || localParticipant

	/* If we have a fullscreen speaker, set them as the primary remote participant (i.e. request high res video stream) */
	useEffect(() => {
		if (fullScreenSpeaker?.participantId && viewMode === 'SPEAKER') {
			client.current.setPrimaryRemoteParticipant(fullScreenSpeaker.participantId)
		}
	}, [fullScreenSpeaker, fullScreenSpeaker?.videoTrack?.videoType])

	/* If switched to tile view, apply consistent height for all receiver video streams */
	// useEffect(() => {
	// 	if (viewMode === 'TILE') {
	// 		client.current.setMaxReceivedVideoHeight(Math.min(tileVideoSize.height, 480))
	// 	}
	// }, [viewMode, tileVideoSize])

	// -----------------------------------------------------------------------------------------------
	// Find selected interpreter details
	// const selectedInterpreterParticipantDetails =
	// 	selectedInterpreter && remoteParticipants.find(p => p?.properties?.clientId === selectedInterpreter)

	// -----------------------------------------------------------------------------------------------
	// Render all participants for tileview or filmstrip
	const participantsToDisplay = calculateVideoCallParticipantsToDisplay({
		selectedInterpreterId: selectedInterpreter,
		interpreterChannel: interpreterOnMainChannel ? '' : interpreterChannel,
		loggedInAsInterpreter: isInterpreter,
		loggedInAsParticipant,
		loggedInAsFacilitator,
		loggedInAsObserver,
		remoteParticipants,
		localParticipant,
		muteMainAudio,
		interpreters,
	})

	// If we are in TILE view, then we include the local participant in this list
	if (viewMode === 'TILE') {
		let subheading = null
		const interpreterDetails = loggedInAsObserver && interpreters.find(i => i.clientId === clientId)
		if (interpreterDetails) {
			const { channel } = interpreterDetails
			subheading = `Interpreter - ${channel}`
		} else if (loggedInAsObserver) {
			subheading = `Observer`
		} else if (loggedInAsFacilitator && localParticipant.displayName !== 'Facilitator') {
			subheading = 'Facilitator'
		}

		participantsToDisplay.push({
			participant: localParticipant,
			subheading,
			mute: true,
		})
	}

	// Create an array of the participant elements/videos
	const participantElems = participantsToDisplay.map(details => {
		const { participant, infoMessage, mute, subheading, volume } = details
		const { participantId } = participant
		const { width, height } = tileVideoSize
		// Get the V360G ID for this participant
		const pParticipantId = participant?.properties?.participantId
		const error = participant.isLocal ? localError : infoMessage
		const participantDetails = participants.find(
			p => p.id === (participant.isLocal ? v360ParticipantId : pParticipantId)
		)

		return (
			<VideoCallParticipantTwilio
				key={participantId || 'local'}
				facilitatorId={facilitatorId}
				onClick={() => onClickParticipant(participantId)}
				uploadToImageCache={participant.isLocal}
				participant={participant}
				participantDetails={participantDetails}
				volume={mute ? 0 : volume}
				selectedAudioOut={selectedAudioOut}
				subheading={subheading}
				height={height}
				error={error}
				width={width}
			/>
		)
	})
	// -----------------------------------------------------------------------------------------------

	// Controls displayed to interpreters for them to choose which channel they wish to broadcast to
	// const interpreterControls = isInterpreter && (
	// 	<InterpreterControls
	// 		onMainChannel={interpreterOnMainChannel}
	// 		channelName={interpreterDetails.channel}
	// 		onChangeChannel={interpreterChangeChannel}
	// 	/>
	// )

	// Interpreter element to be displayed to participants that have selected interpreter
	// let interpreterElem: JSX.Element = null
	// if (selectedInterpreterParticipantDetails) {
	// 	const interpreterDetails = interpreters.find(i => i.clientId === selectedInterpreter)
	// 	if (interpreterDetails) {
	// 		const { channel, onMainChannel } = interpreterDetails
	// 		const subheading = `Interpreter - ${channel}`
	// 		let infoMessage = ''
	// 		let interpreterVolume = loggedInAsFacilitator ? 0.1 : 1.0
	// 		if (onMainChannel) {
	// 			infoMessage = `Broadcasting on main channel`
	// 			interpreterVolume = 0
	// 		}

	// 		interpreterElem = (
	// 			<div className="video-call__interpreter">
	// 				<VideoCallParticipantTwilio
	// 					selectedAudioOut={selectedAudioOut}
	// 					participant={selectedInterpreterParticipantDetails}
	// 					facilitatorId={facilitatorId}
	// 					volume={interpreterVolume}
	// 					subheading={subheading}
	// 					error={infoMessage}
	// 				/>

	// 				{!loggedInAsFacilitator ? (
	// 					<div className="video-call__interpreter-mute">
	// 						<label>Mute main audio</label>
	// 						<Switch value={muteMainAudio} onChange={checked => setMuteMainAudio(checked)} />
	// 					</div>
	// 				) : null}
	// 				<Button onClick={() => selectInterpreter(null)} text={CLOSE} />
	// 			</div>
	// 		)
	// 	}
	// }

	// Check if we have any interpreters available in the current video call
	// If so, we display a button to allow participants to select an interpreter
	// const interpretersInCall = interpreters.filter(i =>
	// 	remoteParticipants.find(p => p.properties && p.properties.clientId === i.clientId)
	// )
	// const interpreterAvailable = Boolean(
	/* 	!selectedInterpreterParticipantDetails && interpretersInCall.length && !isInterpreter*/
	// )
	// const interpreterAvailableButton = interpreterAvailable && (
	// 	<Button onClick={onClickSelectInterpreter} text="Interpreter available" primary />
	// )

	// const onClickSelectInterpreter = () => displayModalPopup('modal-select-interpreter')
	const onClickSettings = () => displayModalPopup('modal-configure-video')
	const onClickChat = () => setChatOpen(chatOpen => !chatOpen)
	const onClickChangeLayout = () => setViewMode(viewMode === 'TILE' ? 'SPEAKER' : 'TILE')
	const onClickStatistics = () => client.current.showStatistics()

	function onClickEndCall() {
		client.current.unload()
		endMainCall()
	}

	const footer = (
		<div ref={buttonArea} className="video-call__buttons">
			{/* <div className="left">{interpreterControls || interpreterAvailableButton}</div> */}
			<div className="left" />
			<div className="middle">
				<VideoCallButtonsTwilio
					client={client.current}
					onClickChangeLayout={onClickChangeLayout}
					onClickEndCall={onClickEndCall}
					onClickSettings={onClickSettings}
					onClickChat={onClickChat}
					onClickStatistics={onClickStatistics}
					showScreenShare={loggedInAsFacilitator || loggedInAsObserver || isVideoTest}
					showEndCall={loggedInAsFacilitator}
					showStatistics={loggedInAsFacilitator || loggedInAsObserver || isVideoTest}
					viewMode={viewMode}
					showSettings
					showChat
				/>
			</div>
			<div className="right" />
		</div>
	)

	let mainContent: JSX.Element
	if (viewMode === 'TILE') {
		const { rows } = tileVideoSize
		const rowElems = []
		const vids = [...participantElems]
		const vidsPerRow = Math.ceil(participantElems.length / rows)
		for (let i = 0; i < rows; i++) {
			const participantsForRow = vids.splice(0, vidsPerRow)
			rowElems.push(
				<div key={i} style={{ display: 'flex' }}>
					{participantsForRow}
				</div>
			)
		}
		mainContent = (
			<div ref={tileview} className="video-call__tileview">
				<div className="video-call__tileview-inner">{rowElems}</div>
			</div>
		)
	} else if (fullScreenSpeaker) {
		const pParticipantId = fullScreenSpeaker?.properties?.participantId
		const participantDetails = participants.find(
			p => p.id === (fullScreenSpeaker.isLocal ? v360ParticipantId : pParticipantId)
		)

		const pClientId = fullScreenSpeaker?.properties?.clientId
		const interpreterDetails = interpreters.find(i => i.clientId === pClientId)
		const pIsObserver = Boolean(pClientId)
		const pIsInterpreter = Boolean(pIsObserver && interpreterDetails)
		const pIsFacilitator = fullScreenSpeaker?.properties?.isFacilitator === 'true'
		let subheading = ''
		// Sort out subheading
		if (!pIsInterpreter && pIsObserver) {
			subheading = `Observer`
		}
		if (pIsFacilitator && fullScreenSpeaker.displayName !== 'Facilitator') {
			subheading = 'Facilitator'
		}
		if (pIsInterpreter) {
			const { channel } = interpreterDetails
			subheading = `Interpreter - ${channel}`
		}
		mainContent = (
			<VideoCallParticipantTwilio
				fullscreen
				volume={0}
				subheading={subheading}
				facilitatorId={facilitatorId}
				participant={fullScreenSpeaker}
				participantDetails={participantDetails}
				key={fullScreenSpeaker.videoTrack?.videoType} // ensure fullscreen video re-renders if videoType changes
				error={fullScreenSpeaker === localParticipant ? localError : ''}
			/>
		)
	}

	const onClickLocalParticipant = () => onClickParticipant(localParticipant.participantId)

	const participantDetails = participants.find(p => p.id === v360ParticipantId)
	// Create filmstrip if in 'speaker' mode
	const filmstrip = viewMode === 'SPEAKER' && (
		<div className="video-call__filmstrip">
			<div className="video-call__participants">{participantElems}</div>
			{/* Local Participant */}
			<VideoCallParticipantTwilio
				participant={localParticipant}
				facilitatorId={facilitatorId}
				onClick={onClickLocalParticipant}
				participantDetails={participantDetails}
				selectedAudioOut={selectedAudioOut}
				uploadToImageCache
				error={localError}
			/>
		</div>
	)

	const muted = client.current?.localParticipant?.isMuted
	const className = `video-call video-call--${viewMode.toLowerCase()}`

	return (
		<div className={className} role="main">
			<div className="video-call__container">
				{chatOpen && <Chat client={client.current} />}
				<div ref={mainArea} className="video-call__main">
					{/* {interpreterElem} */}
					{mainContent}
					{footer}
				</div>
				{filmstrip}
			</div>
			{muted && <div className="video-call__mute-message">{MUTED_MESSAGE}</div>}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const settings = state.settings || ({} as Settings)

	const props: Partial<VideoCallTwilioProps> = {
		selectedAudioOut: state.videoconf.selectedAudioOut,
		selectedCamera: state.videoconf.selectedCamera,
		selectedMic: state.videoconf.selectedMic,
		interpreters: state.videoconf.interpreters || [],
		clientId: state.clientId,
		selectedInterpreter: state.videoconf.selectedInterpreter,
		participants: state.participants || [],
		loggedInAsParticipant: loggedInAsParticipantSelector(state) || loggedInAsGroupSelector(state),
		loggedInAsObserver: loggedInAsObserverSelector(state),
		loggedInAsFacilitator: state.tutor && state.tutor.loggedIn,
		facilitatorId: facilitatorIdSelector(state),
		participantId: state?.group?.participantId,
		videoCallParticipantsStartMuted: settings.videoCallParticipantsStartMuted,
		videoCallObserversStartMuted: settings.videoCallObserversStartMuted,
		videoCallFacilitatorsStartMuted: settings.videoCallFacilitatorsStartMuted,
	}

	const interpreter = props.loggedInAsObserver && props.interpreters.find(i => i.clientId === props.clientId)
	if (interpreter) {
		props.isInterpreter = true
		props.interpreterChannel = interpreter.channel
		props.interpreterOnMainChannel = interpreter.onMainChannel
		props.interpreterDetails = interpreter
	}

	return props
}
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	notifyMainCall: Actions.videoconf.notifyMainCall,
	endMainCall: Actions.videoconf.endMainCall,
	interpreterChangeChannel: Actions.videoconf.interpreterChangeChannel,
	selectInterpreter: Actions.videoconf.selectInterpreter,
	selectAudioOut: Actions.videoconf.selectAudioOut,
	selectCamera: Actions.videoconf.selectCamera,
	selectMic: Actions.videoconf.selectMic,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Pick<VideoCallTwilioProps, 'callId' | 'displayName' | 'domain'>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(VideoCallTwilio)
