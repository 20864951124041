import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import config from '../../config'
import Actions from '../actions'

import defaultMediaIcon from '../util/defaultMediaIcon'
import mediaIconList from '../../config/mediaIconList'

import loggedInAsObserverSelector from '../selectors/loggedInAsObserver'

import IconSelect from '../elements/IconSelect'
import IconButton from '../elements/IconButton'
import Icon from '../elements/Icon'

function formatDateString(date: Date): string {
	return `${date.toDateString().substr(4)} - ${date.toTimeString().substr(0, 8)}`
}

// =================================================================================================
type MediaItemProps = {
	data?: MediaItem
	seen?: boolean
	loggedInAsObserver?: boolean
	downloadMediaItem?: (id: string) => void
	deleteMedia?: (id: string) => void
	onClick?: (e: React.BaseSyntheticEvent) => void
	updateMediaItem?: (id: string, details: Partial<MediaItem>) => void
	displayModalPopup?: (id: string, data: SimpleObject) => void
}

const MediaItem: FunctionComponent<MediaItemProps> = props => {
	const { data, seen, onClick, deleteMedia, updateMediaItem } = props
	const { loggedInAsObserver, displayModalPopup, downloadMediaItem } = props

	const { UPLOADED, DELETE, EDIT, DOWNLOAD } = config.strings

	const { phoneEnabled } = data

	// Uploaded date
	const uploadedDate = new Date(data.lastUpdate || data.timestamp)
	const uploadedDateElem = (
		<span className="uploaded">
			{data.extension.replace('.', '').toUpperCase()} - {UPLOADED} {formatDateString(uploadedDate)}
		</span>
	)

	// Delete button
	const onClickEdit = () => displayModalPopup('modal-edit-media', data)
	const editButton = <IconButton iconName="pen" title={EDIT} onClick={onClickEdit} />
	const onClickDelete = () => deleteMedia(data.id)
	const deleteButton = <IconButton iconName="trash-alt" title={DELETE} onClick={onClickDelete} />
	const onClickDownload = () => downloadMediaItem(data.id)
	const downloadButton = <IconButton iconName="download" title={DOWNLOAD} onClick={onClickDownload} />

	const showDownload = !loggedInAsObserver
	const showDelete = !loggedInAsObserver
	const showEdit = !loggedInAsObserver

	// Icon selection dropdown
	const icon = data.icon || defaultMediaIcon(data.extension)
	const onIconChange = value => updateMediaItem(data.id, { icon: value })
	const rec = mediaIconList.find(o => o.value === icon) || { icon: null, color: null }
	const iconSelection = showEdit ? (
		<IconSelect initialValue={icon} onChange={onIconChange} />
	) : (
		<Icon className="media-item__icon" name={rec.icon} color={rec.color} />
	)

	let className = 'media-item'
	if (seen === false) className += ' media-item--unseen'

	return (
		<div key={data.timestamp} className={className}>
			{iconSelection}
			<div
				className="media-item__middle"
				onClick={onClick}
				role="button"
				aria-label="Display media item"
				tabIndex={0}
				onKeyPress={onClick}
			>
				<h2 className="media-item__name">
					{data.name}
					{phoneEnabled ? <Icon name="phone" /> : ''}
				</h2>
				{uploadedDateElem}
			</div>
			{showDownload && downloadButton}
			{showEdit && editButton}
			{showDelete && deleteButton}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	linkedAccess: state.linkedAccess || {},
	loggedInAsObserver: loggedInAsObserverSelector(state),
})
const actions = {
	downloadMediaItem: Actions.media.downloadMediaItem,
	displayModalPopup: Actions.misc.displayModalPopup,
	updateMediaItem: Actions.media.updateMedia,
	deleteMedia: Actions.media.deleteMedia,
}
export default connect(mapStateToProps, actions)(MediaItem)
