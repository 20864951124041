import { connect } from 'react-redux'
import React from 'react'
import md5 from 'md5'

import config from '../../../config'

import SideCall from './SideCall'

type TeamBreakoutRoomJoinedProps = {
	domain: string
	instanceId: string
	facilitatorId: string
	groupId: string
	displayName: string
}

// For a team breakout room, we open up the SideCall component and use a call ID which is:
// md5(facilitatorId + server instance ID + group ID)
function TeamBreakoutRoomJoined(props: TeamBreakoutRoomJoinedProps) {
	const { domain, instanceId, facilitatorId, groupId, displayName } = props
	const callId = md5(facilitatorId + instanceId + groupId)
	return <SideCall callId={callId} domain={domain} displayName={displayName} />
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): TeamBreakoutRoomJoinedProps => {
	const linkedAccess = state.linkedAccess ?? nullobject
	const videoconf = state.videoconf ?? nullobject
	const { domain, instanceId } = videoconf

	const { SUPERVISOR, TUTOR } = config.strings
	const name = linkedAccess.name ? `${linkedAccess.name} (${SUPERVISOR})` : TUTOR

	return {
		facilitatorId: linkedAccess.facilitatorId || state.tutor.loggedInAs,
		groupId: state.joinedBreakout,
		displayName: name,
		instanceId,
		domain,
	}
}
const actions = {}
export default connect(mapStateToProps, actions)(TeamBreakoutRoomJoined)
