export default {
	/* Team view */
	OK: `OK`,
	CANCEL: `Anulare`,
	TUTOR: `Facilitator`,
	CURRENT_SESSION: `Sesiunea curentă:`,
	TEAM: `Echipa`,
	SIGNOUT: `Ieșire`,
	INITIAL_UPDATE: `Informații despre sesiune`,
	ADVICE: `Decizie`,
	RATIONALE: `Motivație`,
	MEDIA_FEED: `Postări media`,
	MESSAGES: `Mesaje`,
	PREVSESSIONS: `Sesiunile anterioare`,
	RETURN_TO_CURRENT: `Reveniți la sesiunea curentă`,
	SESSION_IS_PAUSED: `În prezent, sesiunea este ÎN PAUZĂ`,
	ATTRIB_CURR_AUTHOR: `Factor de decizie`,
	SAVE: `Salvați`,
	CHANGE: `Schimbare`,
	NO_AUTHOR: `Nu este setat`,
	NO_INITIAL_INFO: `Vă rugăm să așteptați să înceapă următoarea sesiune`,
	DECISION_MAKER_SELECT: `Vă rugăm să selectați un factor de decizie...`,
	SESSION_COMPLETE: `Sesiunea anterioară s-a încheiat`,
	OPENED_AT: `Deschis la`,
	SEND_MESSAGE_PLACEHOLDER: `Scrieți un mesaj`,
	SEND: `Trimite`,
	TO_ALL_GROUPS: `tuturor echipelor`,
	SHOW_TRANSLATION: `Afișați traducerea`,
	SHOW_ORIGINAL: `Afișați originalul`,
	CREDENTIALS_EXPIRED: `Datele dvs. pentru acces au expirat. Vă rugăm să vă logați din nou`,
	INCOMING_MESSAGE: `Mesaj primit`,
	INCOMING_MESSAGE_TEXT: `A fost primit un mesaj nou`,
	INCOMING_MEDIA: `Element media primit`,
	INCOMING_MEDIA_TEXT: `A fost primit un element media nou`,
	INCOMING_UPDATE: `Actualizare primită`,
	INCOMING_UPDATE_TEXT: `A fost primită o actualizare`,
	CONFIRM_LOGOUT: `Sunteți sigur că doriți să vă deconectați?`,
	MUTED_MESSAGE: `Momentan comunicare pentru dvs este dezactivată`,
	NEWSTYPE_UPDATE: `Actualizare primită`,
	WARNING_TIME_MSG: `Au mai rămas 5 minute`,
	VIEW_INITIAL_UPDATE: `Vizualizați informații despre sesiune`,
	INITIAL_UPDATE_INDEX: `Informații despre sesiune %%%`,
	VIEWING_PREVIOUS: `Vizualizare sesiune $$ din %%%`,
	TO: `La`,
	EVERYONE: `Toți`,
	MESSAGE_ENTRY_PLACEHOLDER: `Mesaj`,
	DEFAULT_VIDEO_WARNING_MESSSAGE: `Apelul video începe în curând`,
	DEFAULT_VIDEO_WARNING_TITLE: `Avertizare`,
	SESSION_INFO_RECEIVED: `Informații despre sesiune primite`,
	DECISION_LOG: `Jurnal de decizie`,
	ADD_DECISION_LOG: `Adăugați la jurnalul de decizii`,
	DFT_WELCOME_MESSAGE: `Bine ați venit la platforma de învățare experiențială View360 Global. Vă rugăm să așteptați să înceapă sesiunea`,
	CONFIRM_DELETE_DECISIONLOG: `Sigur doriți să eliminați această decizie?`,
	SESSION_HAS_STARTED: `Sesiunea a început`,
	CLICK_TO_OPEN: `Dați click pentru a deschide`,
	CLICK_TO_EXPAND: `Dați click pentru a extinde`,
	EXPAND_TEXT: `Dați click pe butonul de mai jos pentru a vizualiza videoclipul la dimensiune completă`,
	COLLAPSE: `Micșorează`,
	EXPAND: `Extinde`,
	RECEIVED_AT: `Primit la`,
	AUDIO_PLAYER: `Audio player`,
	VIDEO_PLAYER: `Video player`,
	DFT_MESSAGES_TEXT: `Această aplicație vă va permite să trimiteți și să primiți mesaje către/de la facilitator în timpul unei sesiuni.`,
	DFT_NOTIFICATIONS_TEXT: `Elementele media vor apărea aici. Fiți cu ochii pe acest dispozitiv pentru noi elemente media și actualizări. Informațiile despre sesiunea inițială pot fi accesate cu butonul din partea de sus a ecranului. Comunicați cu facilitatorul utilizând aplicația Messages din colțul din stânga jos al acestui dispozitiv.`,
	MOBILE_NUMBER_TITLE: `Număr de telefon`,
	MOBILE_NUMBER_PLACEHOLDER: `Numar de telefon mobil`,
	MOBILE_NUMBER_DESC: `Vă rugăm să furnizați numărul dvs. de telefon mobil pentru a putea primi mesaje în timpul sesiunii. Numărul dvs. de telefon va fi folosit numai în timpul antrenamentului imersiv și va fi șters automat după 24 de ore, în conformitate cu politicile noastre GDPR și legislația privind protecția datelor.`,
	MOBILE_NUMBER_SKIP: `Treci peste asta`,
	INCOMING_CALL: `Apel de intrare`,
	CALL_IN_PROGRESS: `Apel în curs`,
	PHONE_MESSAGE_SENT: `Mesaj telefonic trimis`,
	CALL_FAILED: `Apel eșuat`,
	NO_ANSWER: `Nici un răspuns`,
	CLICK_TO_RESEND: `Dați click pe butonul RESEND (trimite repetat) pentru ca mesajul să fie retrimis pe dispozitivul dvs.`,
	VOICE_MESSAGE_SENT: `Un mesaj vocal a fost trimis către %%%.`,
	WENT_TO_VOICEMAIL: `Mesajul vocal a fost trimis către %%%, dar a ajuns la mesageria dvs. vocală.`,
	CALL_FAILED_TEXT: `Un mesaj vocal a fost trimis către %%%, dar nu a ajuns la destinație.`,
	CHECK_PHONE: `Vă rugăm să vă verificați telefonul.`,
	AT: `la`,
	CALLING: `se apelează`,
	RESEND: `Retrimiteți`,
	PLAY_HERE: `Rulați aici`,
	SESSION: `Sesiunea`,
	CAMERA: `Camera`,
	MICROPHONE: `Microfon`,
	AUDIO_OUTPUT_DEVICE: `Dispozitiv de ieșire audio`,
	SELECT_CAMERA: `Selectați camera`,
	SELECT_MICROPHONE: `Selectați microfonul`,
	SELECT_AUDIO_OUTPUT_DEVICE: `Selectați dispozitivul de ieșire audio`,
	SAME_AS_SYSTEM: `La fel ca în sistem`,

	CLOSE: `Închide`,
	CONTINUE: `Continuă`,
	DECISION_LOG_HELP_TEXT: `Deciziile și argumentele echipei tale vor apărea aici. Veți putea să vă editați propriile decizii și raționament făcând clic pe ele.`,
	NO_DECISION_LOGS: `Nu există jurnalele de decizie de afișat`,

	CHAT: `Chat`,
	CHANGE_LAYOUT: `Schimbați aspectul`,
	VIDEO_SETTINGS: `Setări`,
	PRIVATE_MESSAGE: `Mesaj privat`,
	POPUP_TITLE_CONFIGUREVIDEO: `Configurați audio/video`,

	UPDATE: 'Actualizare',
	NOTEPAD: 'Blocnotes',
	NOTETAKER: 'Preneur de notes',
	ENTER_ADVICE: 'Introduceți sfatul',
	ENTER_RATIONALE: 'Introduceți raționamentul',
}
