import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useUnmount } from 'react-use'

import { IVideoClient, VideoClientEvents } from './VideoTypes'
import IconButton from '../../elements/IconButton'
import config from '../../../config'

type VideoCallMuteButtonProps = {
	client: IVideoClient
}

const VideoCallMuteButton: FunctionComponent<VideoCallMuteButtonProps> = props => {
	const { client } = props
	const [muted, setMuted] = useState(client?.localParticipant?.isMuted)

	const enabled = Boolean(client?.localParticipant)

	const { MICROPHONE } = config.strings

	// Array of JitsiClient event handler references
	const eventHandlerRefs = useRef<number[]>([])

	useEffect(() => {
		if (!client) return
		const eventHandlers = eventHandlerRefs.current

		// Refresh button state when local participant details change
		const refresh = () => setMuted(client?.localParticipant?.isMuted)
		eventHandlers.push(client.on(VideoClientEvents.LOCAL_PARTICIPANT_CHANGED, refresh))
		// Also do that now to initialise the state
		refresh()
	}, [client])

	// On unmount, detach event handler callbacks from client
	useUnmount(() => eventHandlerRefs.current.forEach(ref => client.off(ref)))

	// Toggle when button is clicked
	const onClickMute = async () => {
		if (!client?.localParticipant) return
		setMuted(!muted)
		// There will also be a LOCAL_PARTICIPANT_CHANGED event that we will respond to
		await client.toggleMuteAudio()
	}

	// Display correct icon
	const muteIcon = muted ? 'microphone-slash' : 'microphone'

	return <IconButton iconName={muteIcon} onClick={onClickMute} selected={muted} title={MICROPHONE} enabled={enabled} />
}

export default VideoCallMuteButton
