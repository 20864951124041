/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { connect } from 'react-redux'
import React from 'react'

import Icon from '../elements/Icon'

import config from '../../config'
import Actions from '../actions'

type GroupReleaseModalProps = {
	releaseToGroup?: (groupId: string, scheduleRowId: string) => void
	groups?: StateTree['groups']
	releases?: StateTree['releases']
	scheduleRowId: string
}

/**
 * @class GroupReleaseModal
 * @extends {React.Component}
 */
class GroupReleaseModal extends React.Component<GroupReleaseModalProps> {
	_onClickGroup(groupId: string) {
		const { releaseToGroup, scheduleRowId } = this.props
		releaseToGroup(groupId, scheduleRowId)
	}

	_renderGroup(group: GroupDetails) {
		let className = 'group-release-modal__group'
		const { releases, scheduleRowId } = this.props
		const itemHasBeenReleased = releases.find(i => i.groupId === group.id && i.rowId === scheduleRowId)

		let onClick: React.MouseEventHandler
		if (itemHasBeenReleased) {
			className += ` ${className}--released`
		} else {
			onClick = this._onClickGroup.bind(this, group.id)
		}

		let otherStuff: JSX.Element
		if (itemHasBeenReleased) {
			const releaseTime = new Date(itemHasBeenReleased.timestamp).toTimeString().substr(0, 8)
			const message = `Released at ${releaseTime}`

			otherStuff = (
				<>
					<Icon name="check" />
					<span className="group-release-modal__sentat">{message}</span>
				</>
			)
		}

		return (
			<div className={className} onClick={onClick} key={group.id}>
				<span className="name">{`${group.colour} ${config.strings.TEAM}`}</span>
				{otherStuff}
			</div>
		)
	}

	render() {
		const className = 'group-release-modal'
		const { NO_GROUPS } = config.strings
		const { groups } = this.props

		// If no groups, display message to indicate this
		if (groups.length === 0) return <div className="group-release-modal">{NO_GROUPS}</div>

		// Render list of groups
		const content = groups.map(g => this._renderGroup(g))
		return <div className={className}>{content}</div>
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const { viewingPreviousSession } = state
	const releases = viewingPreviousSession ? state.previousSession.releases : state.releases
	const groups = state.groups || []
	return { groups, releases }
}
const actions = {
	releaseToGroup: Actions.sessions.releaseToGroup,
}
export default connect(mapStateToProps, actions)(GroupReleaseModal)
