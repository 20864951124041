import { configureStore, Tuple } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'

import reducers from '../reducers'

export const store = configureStore({
	reducer: reducers,
	middleware: () => new Tuple(thunkMiddleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
