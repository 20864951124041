export default {
	/* Team view */
	OK: '好',
	CANCEL: '取消',
	TUTOR: '服務商',
	CURRENT_SESSION: '當前會議',
	TEAM: '小組',
	SIGNOUT: '登出',
	INITIAL_UPDATE: '會議信息',
	ADVICE: '決定',
	RATIONALE: '基本原理',
	MEDIA_FEED: '媒體來源',
	MESSAGES: '信息',
	PREVSESSIONS: '之前會議',
	RETURN_TO_CURRENT: '返回當前會議',
	SESSION_IS_PAUSED: '會議當前已暫停',
	ATTRIB_CURR_AUTHOR: '決策者',
	SAVE: '保存',
	CHANGE: '更改',
	NO_AUTHOR: '未設置',
	NO_INITIAL_INFO: '請等待下一個會議開始',
	DECISION_MAKER_SELECT: '請選擇決策者',
	SESSION_COMPLETE: '之前會議已結束',
	OPENED_AT: '打開於',
	SEND_MESSAGE_PLACEHOLDER: '留言',
	SEND: '發送',
	TO_ALL_GROUPS: '對所有組',
	SHOW_TRANSLATION: '顯示譯文',
	SHOW_ORIGINAL: '顯示原始文件',
	CREDENTIALS_EXPIRED: '您的密碼已過期。請重新登錄。',
	INCOMING_MESSAGE: '傳入信息',
	INCOMING_MESSAGE_TEXT: '收到新信息',
	INCOMING_MEDIA: '傳入媒體項',
	INCOMING_MEDIA_TEXT: '已收到新的媒體項',
	INCOMING_UPDATE: '傳入更新',
	INCOMING_UPDATE_TEXT: '已收到更新',
	CONFIRM_LOGOUT: '您確定要註銷嗎？',
	MUTED_MESSAGE: '您的麥克風已關閉',
	NEWSTYPE_UPDATE: '更新',
	WARNING_TIME_MSG: '剩下5分鐘',
	TO: '到',
	EVERYONE: '所有人',
	MESSAGE_ENTRY_PLACEHOLDER: '輸入您的信息',
	POPUP_TITLE_CONFIGUREVIDEO: '配置音頻/視頻',
	CAMERA: '相機',
	MICROPHONE: '麥克風',
	AUDIO_OUTPUT_DEVICE: '音頻輸出設備',
	SAME_AS_SYSTEM: '與系統相同',
	SELECT_AUDIO_OUTPUT_DEVICE: '選擇音頻輸出設備',
	VIEW_INITIAL_UPDATE: '查看會議信息',
	DECISION_LOG: '決策日誌',
	NOTEPAD: '記事本',
	NOTETAKER: '記錄員',
	CLICK_TO_OPEN: '點擊打開',
	CLICK_TO_EXPAND: '點擊展開',
	ENTER_ADVICE: '輸入決定',
	ENTER_RATIONALE: '輸入基本原理',
	CONTINUE: '繼續',
	CONFIRM_DELETE_DECISIONLOG: '您確定要刪除此決定嗎？',
	CONFIRM_DELETE_NOTE: '您確定要刪除此筆記嗎？',
	ADD_DECISION_LOG: '添加決策日誌',
	DECISION_LOG_HELP_TEXT: '您團隊的回應和理由將顯示在此處 您將能夠透過點擊來編輯您自己的回覆和理由。',
	DFT_NOTIFICATIONS_TEXT:
		'媒體將出現在這裡。請密切關注此設備的新媒體和更新。可以透過螢幕頂部的按鈕存取初始會話資訊。使用此裝置左下角的訊息應用程式與協調員進行交流。',
	REMOVE: '移除',
	SESSION_HAS_STARTED: '會議已開始',
	SESSION_INFO_RECEIVED: '已收到會議信息',
	DFT_MESSAGES_TEXT: '該應用程式將允許您在會議期間向協調員發送訊息或從協調員接收訊息。',
}
