import React, { FunctionComponent, ReactNode, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import Preloader from '../elements/Preloader'
import NoRecords from './NoRecords'

type LoadingWrapperProps = {
	loading: boolean
}

const LoadingWrapper: FunctionComponent<LoadingWrapperProps> = props => {
	const { loading, children } = props

	const records = children as Array<ReactNode>

	const noRecords = !records || !records.length

	const [startedLoading, setStartedLoading] = useState(false)
	const [doneLoading, setDoneLoading] = useState(!noRecords)

	// If we already have some content to display, display it immediately, regardless of whether
	// we are in the process of loading new content to refresh it with.
	if (doneLoading) {
		return <div>{noRecords ? <NoRecords /> : children}</div>
	}

	if (loading && !startedLoading) setStartedLoading(true)
	if (!loading && startedLoading && !doneLoading) setDoneLoading(true)

	return (
		<div className="content-loading-wrapper">
			{loading && !doneLoading && <Preloader />}
			{(!loading || doneLoading) && noRecords && <NoRecords />}
			<CSSTransition
				in={!noRecords && !loading && !doneLoading}
				classNames="animation__slideInUp"
				timeout={300}
				unmountOnExit
			>
				<div>{children}</div>
			</CSSTransition>
		</div>
	)
}

export default LoadingWrapper
