import React from 'react'
import { connect } from 'react-redux'

import config from '../../config'
import sessionSelector from '../selectors/session'
import teamNameFromColour from '../util/teamNameFromColour'

import convertColour from '../util/convertColour'

type GroupsListProps = {
	viewingPreviousSession?: boolean
	unreadGroups?: Array<string>
	groups: StateTree['groups']
	showAllGroups?: boolean
	selected?: string
	onClickGroup: (groupColour: string) => void
	onClickAllGroups?: () => void
}

class GroupsList extends React.Component<GroupsListProps> {
	_onClickGroup(groupColour: string) {
		const { onClickGroup } = this.props
		if (onClickGroup) {
			// Store the last selected group for when screen is redisplayed later
			localStorage.setItem('selected-group', groupColour)
			onClickGroup(groupColour)
		}
	}

	_renderGroup(group: GroupDetails, selected: boolean) {
		const { unreadGroups } = this.props
		const showGroupBadge = unreadGroups && unreadGroups.find(g => g === group.id)

		return this._renderButton(
			selected,
			this._onClickGroup.bind(this, group.colour),
			showGroupBadge,
			group.colour,
			teamNameFromColour(group.colour),
			group.id || group.key || group.colour
		)
	}

	/**
	 * Renders the 'All groups' link
	 */
	_renderAllGroups(selected: boolean) {
		const { onClickAllGroups } = this.props
		const { ALL_GROUPS } = config.strings
		return this._renderButton(selected, onClickAllGroups, false, 'black', ALL_GROUPS, 'all-groups')
	}

	_renderButton(selected, onClick, showBadge, dotColour, label, key) {
		let className = 'groups-list__group'
		if (selected) {
			className += ' groups-list__group--selected'
		}
		return (
			<div className={className} onClick={onClick} key={key}>
				<div className="dot" style={{ backgroundColor: convertColour(dotColour) }} />
				<span>{label}</span>
				{showBadge && <div className="badge" />}
			</div>
		)
	}

	render() {
		const className = 'groups-list'
		const { groups, selected, showAllGroups } = this.props

		// Filter out groups without colour (i.e. 'all' group, when viewing previous session)
		const _groups = (groups || []).filter(g => g.colour).sort((a, b) => (a.colour > b.colour ? 1 : -1))
		const groupItems = _groups.map(g => this._renderGroup(g, selected === g.colour))
		const noGroupsMessage = !_groups.length && <div className="none-found">{config.strings.NO_GROUPS}</div>
		const allGroupsOption = showAllGroups && _groups.length > 0 && this._renderAllGroups(selected === 'all-groups')

		return (
			<div className={className}>
				{groupItems}
				{noGroupsMessage}
				{allGroupsOption}
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<GroupsListProps> => {
	const { viewingPreviousSession } = state
	const session = sessionSelector(state)

	let groups =
		(viewingPreviousSession ? (session as PreviousSession).groups : state.groups) || ([] as Array<GroupDetails>)

	// Ensure that we don't list any groups that have not been active in last 6 hours
	const SIX_HOURS = 1000 * 60 * 60 * 6
	if (!viewingPreviousSession) {
		groups = groups.filter(g => g.lastAction > new Date().getTime() - SIX_HOURS)
	}
	return { viewingPreviousSession, groups }
}

const actions = {}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Pick<
	GroupsListProps,
	'onClickGroup' | 'selected' | 'onClickAllGroups' | 'unreadGroups' | 'showAllGroups'
>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(GroupsList)
