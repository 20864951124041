export default {
	/* Ukrainian */
	OK: `ОК`,
	CANCEL: `Скасувати`,
	TUTOR: `Фасилітатор`,
	CURRENT_SESSION: `Поточна сесія:`,
	TEAM: `Команда`,
	SIGNOUT: `Вийти з системи`,
	INITIAL_UPDATE: `Інформація стосовно сесії`,
	ADVICE: `Рішення`,
	RATIONALE: `Обгрунтування`,
	MEDIA_FEED: `Медіа-стрічка`,
	MESSAGES: `Повідомлення`,
	PREVSESSIONS: `Попередні сесії`,
	RETURN_TO_CURRENT: `Повернутися до поточної сесії`,
	SESSION_IS_PAUSED: `В даний час сесія поставлена на паузу`,
	ATTRIB_CURR_AUTHOR: `Особа, яка приймає рішення`,
	SAVE: `Зберегти`,
	CHANGE: `Змінити`,
	NO_AUTHOR: `Не встановлено`,
	NO_INITIAL_INFO: `Будь ласка, зачекайте, поки почнеться наступна сесія`,
	DECISION_MAKER_SELECT: `Будь ласка, виберіть логіста`,
	SESSION_COMPLETE: `Попередня сесія завершилася`,
	OPENED_AT: `Відкрито за адресою`,
	SEND_MESSAGE_PLACEHOLDER: `Написати повідомлення`,
	SEND: `Надіслати`,
	TO_ALL_GROUPS: `Всім командам`,
	SHOW_TRANSLATION: `Показати переклад`,
	SHOW_ORIGINAL: `Показати оригінал`,
	CREDENTIALS_EXPIRED: `Термін дії ваших облікових даних минув. Будь ласка, увійдіть ще раз.`,
	INCOMING_MESSAGE: `Вхідне повідомлення`,
	INCOMING_MESSAGE_TEXT: `Отримано нове повідомлення`,
	INCOMING_MEDIA: `Вхідний медіа-матеріал`,
	INCOMING_MEDIA_TEXT: `Отримано новий медіа-матеріал`,
	INCOMING_UPDATE: `Вхідне оновлення`,
	INCOMING_UPDATE_TEXT: `Отримано оновлення`,
	CONFIRM_LOGOUT: `Ви впевнені, що хочете вийти з системи?`,
	MUTED_MESSAGE: `Зараз Ваш мікрофон вимкнено`,
	NEWSTYPE_UPDATE: `Отримане оновлення`,
	WARNING_TIME_MSG: `Залишилося 5 хвилин`,
	VIEW_INITIAL_UPDATE: `Перегляд відомостей про сесію`,
	INITIAL_UPDATE_INDEX: `Інформація про сесію %%%`,
	VIEWING_PREVIOUS: `Перегляд сесії $$ від %%%`,
	TO: `До`,
	EVERYONE: `Кожний`,
	MESSAGE_ENTRY_PLACEHOLDER: `Повідомлення`,
	DEFAULT_VIDEO_WARNING_MESSSAGE: `Відеодзвінок розпочнеться найближчим часом`,
	DEFAULT_VIDEO_WARNING_TITLE: `Попередження`,
	SESSION_INFO_RECEIVED: `Отримана інформація про сесію`,
	DECISION_LOG: `Журнал рішень`,
	ADD_DECISION_LOG: `Додати до журналу рішень`,
	// WELCOME: `Ласкаво просимо на Глобальну платформу експериментального навчання View360. Будь ласка, зачекайте, поки почнеться сесія.`,
	CONFIRM_DELETE_DECISIONLOG: `Ви впевнені, що хочете скасувати це рішення?`,
	SESSION_HAS_STARTED: `Сесія розпочалася`,
	CLICK_TO_OPEN: `Натисніть, щоб відкрити`,
	CLICK_TO_EXPAND: `Натисніть, щоб розгорнути`,
	EXPAND_TEXT: `Натисніть кнопку нижче, щоб переглянути відео в повному розмірі`,
	COLLAPSE: `Колапс`,
	EXPAND: `Розширити`,
	RECEIVED_AT: `Отримано на`,
	AUDIO_PLAYER: `Аудіоплеєр`,
	VIDEO_PLAYER: `Відеоплеєр`,
	DFT_MESSAGES_TEXT: `Ця програма дозволить вам надсилати та отримувати повідомлення до/від Фасилітатора під час сесії.`,
	DFT_NOTIFICATIONS_TEXT: `Тут з'являться медіа. Слідкуйте за цим пристроєм, щоб отримати нові медіа та оновлення.
	Початкову інформацію про сесію можна отримати за допомогою кнопки у верхній частині екрана. Спілкуйтеся з фасилітатором за допомогою програми Повідомлення в нижньому лівому куті цього пристрою.`,
	MOBILE_NUMBER_TITLE: `Контактний телефон`,
	MOBILE_NUMBER_PLACEHOLDER: `Номер мобільного телефону`,
	MOBILE_NUMBER_DESC: `Будь ласка, вкажіть свій номер мобільного телефону, щоб ви могли отримувати повідомлення під час сесії. Ваш номер телефону буде використовуватися лише під час імерсивного навчання та буде автоматично видалений через 24 години відповідно до правил Регламенту ЄС та Закону про захист даних.`,
	MOBILE_NUMBER_SKIP: `Пропустити це`,
	INCOMING_CALL: `Вхідний дзвінок`,
	CALL_IN_PROGRESS: `Дзвінок триває`,
	PHONE_MESSAGE_SENT: `Надіслано телефонне повідомлення`,
	CALL_FAILED: `Дзвінок не відбувся`,
	NO_ANSWER: `Немає відповіді`,
	CLICK_TO_RESEND: `Натисніть кнопку ПЕРЕСЛАТИ, щоб повідомлення надійшло на ваш пристрій.`,
	VOICE_MESSAGE_SENT: `Голосове повідомлення надіслано на %%%.`,
	WENT_TO_VOICEMAIL: `Голосове повідомлення було надіслано на %%%, але надійшло на вашу голосову пошту.`,
	CALL_FAILED_TEXT: `Голосове повідомлення було надіслано %%%, але не дійшло.`,
	CHECK_PHONE: `Будь ласка, перевірте свій телефон.`,
	AT: `При`,
	CALLING: `Виклик`,
	RESEND: `Переслати`,
	PLAY_HERE: `Грати тут`,
	SESSION: `Сесія`,
	CAMERA: `Камера`,
	MICROPHONE: `Мікрофон`,
	AUDIO_OUTPUT_DEVICE: `Вихідний аудіопристрій`,
	SELECT_CAMERA: `Вибрати камеру`,
	SELECT_MICROPHONE: `Вибрати мікрофон`,
	SELECT_AUDIO_OUTPUT_DEVICE: `Вибрати пристрій виводу звуку`,
	SAME_AS_SYSTEM: `Те саме, що система`,

	CLOSE: `Продовжити`,
	CONTINUE: `Продовжити`,
	DECISION_LOG_HELP_TEXT: `Тут з'являться відповіді та обґрунтування вашої команди`,
	NO_DECISION_LOGS: `Немає журналів рішень для відображення.`,

	POPUP_TITLE_SELECTINTERPRETER: `Виберіть канал перекладача`, // Select interpreter channel

	INTERPRETER: 'Перекладач',
	INTERPRETER_AVAILABLE: `Перекладач в наявності`,
	NO_INTERPRETER: `Немає перекладача`,

	BROADCASTING_ON_MAIN: `Трансляція на головному каналі`,
	BROADCASTING_ON_X: `Трансляція на %%% каналі`,
	LISTENING_ON_MAIN: `Прослуховування на головному каналі`,
	LISTENING_ON_X: `Прослуховування на %%% каналі`,
	MUTE_MAIN_AUDIO: `Вимкніть основний звук`,
	NOTEPAD: `Блокнот`,
	NOTETAKER: `Записувач`,
	ENTER_RATIONALE: `Введіть обґрунтування`,
	ENTER_ADVICE: `Введіть рішення`,
}
