import React, { FunctionComponent, useState } from 'react'
import { useMount, useInterval } from 'react-use'
import { connect } from 'react-redux'

import Actions from '../actions'
import config from '../../config'

import teamNameFromColour from '../util/teamNameFromColour'
import BubbleHeading from '../elements/BubbleHeading'
import Button from '../elements/Button'
import Switch from '../elements/Switch'
import Pill from '../elements/Pill'

// =================================================================================================

const { TWILIO_ENABLED, AGORA_ENABLED } = config

type SectionProps = {
	disabled?: boolean
}
const Section: FunctionComponent<SectionProps> = props => {
	let className = 'section'
	const { disabled, children } = props
	if (disabled) className += ' section--disabled'
	return <div className={className}>{children}</div>
}

const Row: FunctionComponent = props => {
	const { children } = props
	return <div className="row">{children}</div>
}

type RegionSelectProps = {
	disabled: boolean
	value: string
	onChange: React.ChangeEventHandler<HTMLSelectElement>
	data: Region[]
	id: string
}

const RegionSelect: FunctionComponent<RegionSelectProps> = props => {
	const { SELECT_REGION, NONE_SELECTED } = config.strings
	const { disabled, value, onChange, data, id } = props

	// Sort the regions by name
	const sortedData = [...data].sort((a, b) => (a.name > b.name ? 1 : -1))

	// Remove if id equal video-eu-dev1.v360g.com
	const newData = sortedData.filter(
		item =>
			item.id !== 'video-eu-dev1.v360g.com' &&
			item.id !== 'video-bh-1a.v360g.com' &&
			item.id !== 'video-eu-4a.v360g.com'
	)

	if (TWILIO_ENABLED) newData.push({ id: 'twilio', name: 'Twilio' } as Region)
	if (AGORA_ENABLED) newData.push({ id: 'agora', name: 'Agora' } as Region)

	return (
		<select disabled={disabled} id={id} value={value} onChange={onChange}>
			<option key="" value="">
				{disabled ? NONE_SELECTED : SELECT_REGION}
			</option>
			{newData.map(r => (
				<option key={r.id} value={r.id}>
					{r.name}
				</option>
			))}
		</select>
	)
}

type TeamSelectProps = {
	disabled: boolean
	value: string
	onChange: (value: string) => void
	data: GroupDetails[]
}

const TeamSelect: FunctionComponent<TeamSelectProps> = props => {
	const { VIDEOCONF_SELECT_TEAM } = config.strings
	const { disabled, value, onChange, data } = props
	return (
		<div className="input">
			<select value={value} onChange={e => onChange(e.target.value)} disabled={disabled}>
				<option key="" value="">
					{VIDEOCONF_SELECT_TEAM}
				</option>
				{data.map(g => (
					<option key={g.id} value={g.id}>
						{teamNameFromColour(g.colour)}
					</option>
				))}
			</select>
		</div>
	)
}

// =================================================================================================

function TutorVideoConf(props) {
	const { serverStatus, regions, serverId, updateSelectedRegion, getServerRegions, linkedAccess } = props
	const { getServerStatus, groups, activateBreakoutRooms, deactivateBreakoutRooms, breakoutsActivated } = props
	const { startMainCall, startServer, stopServer, joinBreakoutRoom, joinedBreakout, leaveBreakoutRoom } = props
	const { observerBreakoutActivated, activateObserverBreakoutRoom, deactivateObserverBreakoutRoom } = props

	const { VIDEOCONF_OBSERVER_ROOM, VIDEOCONF_OBSERVER_ROOM_MSG } = config.strings

	const { START_CALL, START_CALL_TITLE, STOP_SERVER, START_CALL_MESSAGE, VIDEOCONF_JOIN_BREAKOUT_MSG } = config.strings
	const { VIDEOCONF_STATUS, REGION, VIDEOCONF, START_SERVER, VIDEOCONF_LEAVE, VIDEOCONF_JOIN } = config.strings
	const { START_CALL_WITH_WARNING } = config.strings

	useMount(() => {
		getServerStatus()
		getServerRegions()
	})

	useInterval(_ => getServerStatus(), 3000)

	const cloudVideo = serverId === 'twilio' || serverId === 'agora'

	let _serverStatus = cloudVideo ? 'available' : serverStatus

	if (!serverId || !regions.find(r => r.id === serverId)) _serverStatus = 'offline'

	const [selectedTeam, setSelectedTeam] = useState('')
	const breakoutRoomsStatus = breakoutsActivated ? 'enabled' : 'disabled'
	const canChangeRegion = !linkedAccess && (_serverStatus === 'offline' || cloudVideo)
	const canStartServer = _serverStatus === 'offline' && serverId && !linkedAccess
	const canStopServer = _serverStatus === 'online' && !linkedAccess

	const sectionsDisabled = !cloudVideo && _serverStatus !== 'online' && _serverStatus !== 'available'
	const onRegionChange = e => updateSelectedRegion(e.target.value)

	if (cloudVideo) _serverStatus = 'AVAILABLE'

	const key = `SERVER_STATUS_MESSAGE_${_serverStatus.toUpperCase()}`

	const pillClass = cloudVideo ? `tutor-videoconf__pill--online` : `tutor-videoconf__pill--${_serverStatus}`

	const serverStatusMessage = config.strings[key] || ''

	const breakoutStatusMessage = config.strings[`BREAKOUT_STATUS_MESSAGE_${breakoutRoomsStatus.toUpperCase()}`] || ''

	return (
		<div className="tutor-videoconf">
			<div className="tutor-videoconf__top">
				<BubbleHeading>{VIDEOCONF}</BubbleHeading>
			</div>
			<div className="tutor-videoconf__content">
				<Section>
					<h2>{VIDEOCONF_STATUS}</h2>
					<Pill className={pillClass}>{_serverStatus}</Pill>
					<div className="input">
						<label htmlFor="tutor-videoconf__region">{REGION}</label>
						<RegionSelect
							id="tutor-videoconf__region"
							disabled={!canChangeRegion}
							onChange={onRegionChange}
							value={serverId}
							data={regions}
						/>
					</div>
					<p>{serverStatusMessage}</p>
					{!linkedAccess && !cloudVideo && (
						<Row>
							<div style={{ flexGrow: 1 }} />
							<Button enabled={canStopServer} text={STOP_SERVER} onClick={_ => stopServer()} />
							<Button enabled={canStartServer} text={START_SERVER} onClick={_ => startServer(serverId)} />
						</Row>
					)}
				</Section>
				{/* ------------------------------------------------------------------------------------ */}
				{!linkedAccess && (
					<Section disabled={sectionsDisabled || linkedAccess}>
						<h2>{START_CALL_TITLE}</h2>
						<p>{START_CALL_MESSAGE}</p>
						<Row>
							<Button text={START_CALL_WITH_WARNING} onClick={_ => startMainCall(true)} />
							<Button text={START_CALL} onClick={_ => startMainCall(false)} />
						</Row>
					</Section>
				)}
				{/* ------------------------------------------------------------------------------------ */}
				<Section disabled={sectionsDisabled}>
					<h2>{VIDEOCONF_OBSERVER_ROOM}</h2>
					<Switch
						value={observerBreakoutActivated}
						onChange={val => (val ? activateObserverBreakoutRoom() : deactivateObserverBreakoutRoom())}
						disabled={linkedAccess}
					/>
					<p>{VIDEOCONF_OBSERVER_ROOM_MSG}</p>
				</Section>
				{/* ------------------------------------------------------------------------------------ */}
				<Section disabled={sectionsDisabled}>
					<h2>Team breakout rooms</h2>
					<p>{breakoutStatusMessage}</p>
					<Switch
						value={breakoutsActivated}
						onChange={val => (val ? activateBreakoutRooms() : deactivateBreakoutRooms())}
						disabled={linkedAccess}
					/>
				</Section>
				{/* ------------------------------------------------------------------------------------ */}
				<Section disabled={sectionsDisabled || !breakoutsActivated}>
					<h2>Join team breakout room</h2>
					<p>{VIDEOCONF_JOIN_BREAKOUT_MSG}</p>
					<TeamSelect data={groups} value={selectedTeam} onChange={v => setSelectedTeam(v)} disabled={joinedBreakout} />
					<Row>
						<Button text={VIDEOCONF_LEAVE} onClick={_ => leaveBreakoutRoom()} enabled={joinedBreakout} />
						<Button text={VIDEOCONF_JOIN} onClick={_ => joinBreakoutRoom(selectedTeam)} enabled={!joinedBreakout} />
					</Row>
				</Section>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const videoconf = state.videoconf || {}
	return {
		linkedAccess: state.linkedAccess?.connected,
		serverStatus: videoconf.status || config.strings.VIDEOCONF_CHECKING,
		observerBreakoutActivated: Boolean(videoconf.observerBreakoutRoomsId),
		breakoutsActivated: Boolean(videoconf.breakoutRoomsId),
		joinedBreakout: Boolean(state.joinedBreakout),
		serverId: videoconf.serverId || '',
		regions: videoconf.regions || [],
		groups: state.groups || [],
	}
}
const actions = {
	deactivateObserverBreakoutRoom: Actions.videoconf.deactivateObserverBreakoutRoom,
	activateObserverBreakoutRoom: Actions.videoconf.activateObserverBreakoutRoom,
	startMainCallWithWarning: Actions.videoconf.startMainCallWithWarning,
	deactivateBreakoutRooms: Actions.videoconf.deactivateBreakoutRooms,
	updateSelectedRegion: Actions.videoconf.updateSelectedRegion,
	activateBreakoutRooms: Actions.videoconf.activateBreakoutRooms,
	getServerRegions: Actions.videoconf.getServerRegions,
	joinBreakoutRoom: Actions.videoconf.joinBreakoutRoom,
	leaveBreakoutRoom: Actions.videoconf.leaveBreakoutRoom,
	getServerStatus: Actions.videoconf.getServerStatus,
	startMainCall: Actions.videoconf.startMainCall,
	startServer: Actions.videoconf.startServer,
	stopServer: Actions.videoconf.stopServer,
}
export default connect(mapStateToProps, actions)(TutorVideoConf)
