/* eslint-disable no-control-regex */
import getTime from './getTime'

export type UpdateDecisionLog = (
	advice: string[],
	rationale: string[],
	authors: string[],
	times: number[],
	participants: string[],
	languages: string[]
) => void

function onChangeDecisionLog(
	decisionLog: DecisionLog,
	updateDecisionLog: UpdateDecisionLog,
	currAuthor: string,
	participantId: string,
	language: string,
	adviceOrRationale: 'advice' | 'rationale',
	index: number,
	e: React.ChangeEvent<HTMLTextAreaElement>
): void {
	const { value } = e.target
	const { advice, rationale, times, authors, participants, languages } = decisionLog
	// const { currAuthor, participantId } = props

	const newAdvice = [...advice]
	newAdvice[index] = adviceOrRationale === 'advice' ? value : newAdvice[index] || ''
	const newRationale = [...rationale]
	newRationale[index] = adviceOrRationale === 'rationale' ? value : newRationale[index] || ''
	const newAuthors = [...authors]
	newAuthors[index] = newAuthors[index] || currAuthor
	const newTimes = [...times]
	newTimes[index] = newTimes[index] || getTime()
	const newParticipants = [...participants]
	newParticipants[index] = newParticipants[index] || participantId
	const newLanguages = [...languages]
	newLanguages[index] = language || newLanguages[index] || 'EN'

	// Even-up arrays and use blank strings if necessary
	for (let i = 0; i < Math.max(newAdvice.length, newRationale.length); i++) {
		newAdvice[i] = newAdvice[i] || ''
		newRationale[i] = newRationale[i] || ''
	}

	// Delete blank rows
	for (let i = newAdvice.length - 1; i >= 0; i--) {
		if (!newAdvice[i] && !newRationale[i]) {
			newAdvice.splice(i, 1)
			newRationale.splice(i, 1)
			newAuthors.splice(i, 1)
			newTimes.splice(i, 1)
			newParticipants.splice(i, 1)
			newLanguages.splice(i, 1)
		}
	}

	updateDecisionLog(newAdvice, newRationale, newAuthors, newTimes, newParticipants, newLanguages)
}

export default onChangeDecisionLog
