import OnlineComms from '../core/OnlineComms'
import action from '../util/action'
import groupActions from './groups'
import types from './types'

const { PARTICIPANTS_GOT_LIST, PARTICIPANTS_UPDATED_COLOUR, PARTICIPANT_PHONE_UPDATED } = types

let holdoff = false

const updateParticipantColour: ActionCreator<string, string> = (participantId, colour) => async dispatch => {
	dispatch(action(PARTICIPANTS_UPDATED_COLOUR, { participantId, colour }))
	holdoff = true
	try {
		await OnlineComms.updateParticipantColour(participantId, colour)
		holdoff = false
	} catch (err) {
		console.error(err)
	}
}

const getParticipants: ActionCreator = () => async (dispatch, getState) => {
	if (holdoff) return

	const state = getState()

	try {
		const participants = await OnlineComms.getParticipants()
		if (holdoff) return

		dispatch(action(PARTICIPANTS_GOT_LIST, participants))

		// If the current participant's colour has changed, fetch the decision log
		const participantId = state.group && state.group.participantId
		const participant = participantId && participants.find(p => p.id === participantId)
		if (participant && participant.colour !== state.group.colour) {
			groupActions.fetchDecisionLog()(dispatch, getState)
		}
	} catch (err) {
		console.error(err)
	}
}

const updateParticipantPhoneNumber: ActionCreator<string> = phoneNumber => async (dispatch, getState) => {
	const state = getState()
	let _phoneNumber = phoneNumber
	const participantId = state.group && state.group.participantId
	if (_phoneNumber.substr(0, 2) === '07') _phoneNumber = `+44${_phoneNumber.substr(1)}`
	dispatch(action(PARTICIPANT_PHONE_UPDATED, { phoneNumber: _phoneNumber }))
	await OnlineComms.updateParticipantPhoneNumber(participantId, _phoneNumber)
}

const actions = {
	updateParticipantColour,
	updateParticipantPhoneNumber,
	getParticipants,
}

export default actions
