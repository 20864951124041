function defaultMediaIcon(extension: string): string {
	const icons = [
		{ key: 'image', extensions: ['.png', '.jpg', '.jpeg', '.gif', '.tif'] },
		{ key: 'video', extensions: ['.mp4'] },
		{ key: 'audio', extensions: ['.m4a', '.mp3', '.wav'] },
		{ key: 'document', extensions: ['.pdf'] },
	]

	return (icons.find(i => i.extensions.includes(extension)) || {}).key || 'image'
}

export default defaultMediaIcon
